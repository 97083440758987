/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import 'moment-timezone'; // Import moment-timezone
import PlayersTree from "../PlayersTree/PlayersTree";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import bgroger from "../assets/image/imgggggggggggggggg.webp";
import { useMyMatchStore } from "./Store/MyMatchStore";
import TimePicker from "react-awesome-time-picker";
import mapblur from "../assets/image/mapblur.webp";
import "react-awesome-time-picker/assets/index.css";
import Popup from "reactjs-popup";
import "react-calendar/dist/Calendar.css";
import Select from "react-select";
import { FaAngleLeft, FaFacebookMessenger, FaTimes,FaCheck } from "react-icons/fa";
import { SlCalender} from "react-icons/sl";
import MapContainer from "../Register/MapContainer/MapContainer";
import { usePlayerSearchStore } from "../PlayerSearch/Store/PlayerSearchStore";
import { useAuthStore } from "../Auth/store/Auth";
import Moment from "react-moment";
import moment from "moment";
import { MessageStore } from "../Message/store/MessageStore";
import { toast } from "react-toastify";
import {
  toggleWidget,
  addUserMessage,
  dropMessages,
  isWidgetOpened,
} from "react-chat-widget";
import ChatWindow from "../ChatWindow/ChatWindow";
import socketIOClient from "socket.io-client";
import { useCommonStore } from "../Common/Store/CommonStore";
import { GoogleApiWrapper } from "google-maps-react";
import { logDOM } from "@testing-library/react";
import { getMethodData } from "../ApiMethods/api";
import { format } from "date-fns";
import { useMyMatchPageStore } from "../MatchPage/store/MyMatchPageStore";
import imageLoader from "../assets/image/MessageLoader.gif"



const momentsss = require('moment-timezone');
const MyMatch = observer(() => {
  const [currentDates, setcurrentDate] = useState(new Date());
  const [wintwo, setWinTwo] = useState(false);
  // const [playoffObj, setPlayoffObj] = useState({});
  
  // console.log(nolistplay)
  // console.log(playoffObj)
  //   const {id} =  useParams()
  //   const {step,getidLeague,gettoken,getuser,getLeaguesid,leaguesiddata,leaugesfalse,popisOpen,setpopisOpen,contactUs,popupload} =  useRegisterStore()
  const { getNotification, authapproved, user, RedirectChanged } =
    useAuthStore();

  // const [defaultMessageSent, setDefaultMessageSent] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const TIMEZONE_URL = process.env.REACT_APP_TIMEZONE_LIVE;
  const {
    currentID,
    getpurposeddate,
    getGroupOfUser,
    setSelectedChat,
    getcurrentID,
  } = MessageStore();
  const {
    setQutarFinal,
    playoffObj,
    setPlayoffObj,
    loadMatch,
    addloaduser,
    loaderstartsetdate,
    SetMaxDate,
    SetMinDate,
    MinDate,
    MaxDate,
    obj,
    getSetting,
    setRoute,
    MapKey,
    grpPlayer,
    QutarFinal,
    popisOpen,
    newpop,
    nolist,
    Daytime,
    mapshow,
    grpName,
    grp_Id,
    grpPlayerName,
    opensubmit,
    openforfietpopup,
    openwithdrawpopup,
    opentables,
    openforfiet,
    openwithdraw,
    leaguesiddata,
    leaguetype,
    getpurpose,
    Schedule,
    tiematch,
    standing,
    Purposecheck,
    calenderdate,
    opponent,
    mate,
    lat,
    lng,
    time,
    MatchTym,
    SetDate,
    homeID,
    ToRange,
    FromRange,
    validatetym,
    defaultMessageSent,
    Purposed,
    messsage,
    PlayOffs,
    fetchData,
    CheckHistory,
    setTitle,
    Round,
    NextMonth,
    DynamicPlayOf,
    setRound,
    setMessage,
    GetPurposed,
    setDefaultMessageSent,
    settime,
    setDaytime,
    gettym,
    setopensubmit,
    setopenforfietpopup,
    setopenwithdrawpopup,
    getuserID,
    PurposeDate,
    getleagueid,
    PostDate,
    removetym,
    setvalidatetym,
    setPlayOffs,
    gethomeID,
    getPurposeDate,
    setmatchid,
    getMatchDate,
    setAddMonth,
    setpopisOpen,
    getScheduled,
    getStanding,
    getPlayOff,
    getLeaguesid,
    setmapshow,
    setnewpopup,
    opentable,
    setopenforfiet,
    setopenwithdraw,
    getMatchdetails,
    PostMatchscore,
    setradiovalue,
    setforfietvalue,
    setwithdrawvalue,
    setmyone,
    setmytwo,
    setmythree,
    setoppone,
    setopptwo,
    SettymOnClick,
    setdatetym,
    setoppthree,
    setmyforfietvalue,
    setoppforfietvalue,
    setmywithdrawvalue,
    setoppwithdrawvalue,
    setmyteamid,
    setoppteamid,
    validate,
    getpurposetym,
    Removevalidate,
    removedate,
    setdate,
    setdatefixed,
    setpurposecheck,
    gethomecourtdropdown,
    gethomecourt,
    setDateEx4,
    setMyhomecourt,
    getlocation,
    getDateRange,
    getGroupDeatails,
    setDefaultMessage,
    DefaultMes,
    demo,
    dateEx4,
    DateRange,
    Myhomecourt,
    homecourtdrop,
    datefixed,
    setDate,
    validateCheck,
    mysetone,
    mysettwo,
    tymOnClick,
    mysetthree,
    oppsetone,
    oppsetwo,
    oppsetthree,
    radiovalue,
    matchdetails,
    forfietvalue,
    myforfietvalue,
    oppforfietvalue,
    mywithdrawvalue,
    oppwithdrawvalue,
    matchid,
    ObjectPlayOff,
    setclearvalue,
    gettoken,
    getuser,
    matchidslect,
    loadMatchdata,
    buttondisable,
    getPlayersTree,
    notificationNo,
    loaderstart,
    Playoff_obj,
    getDynamicPlayOff,
    getbuttondisable,
    getPlayoffData,
    setThirdScoreCheck,
    responseData,
    playroundnolist,
    playertreenolist,
    hieracybtn,
    ThirdScoreCheck,
    loadDates,
    messgae_initate

  } = useMyMatchStore();
  const { setRoutes } = useMyMatchPageStore();

  const listplay = Object.keys(playoffObj).length !== 0
  function jumpToNextInput(event, nextInputId) {
    var input = event.target;
    var maxLength = parseInt(input.getAttribute("maxlength"));

    if (input.value.length >= maxLength) {
      document.getElementById(nextInputId).focus();
    }
  }
  const { id } = useParams();
  const [ishome, setIsHome] = useState("");
  const [activeTab, setActiveTab] = useState("division");
  const [playername, setplayername] = useState([]);
  const [isMobileView, setIsMobileView] = useState(false);
  const [division, setDivision] = useState(true);
  const [Champions, setChampions] = useState(false);

  const [ID, setID] = useState("");
  const location = useLocation();
  const pattern = /^\/\w+/;
  const result = location.pathname.match(pattern);
  const maxDate = new Date(MaxDate === null ? new Date() : MaxDate);

  const [refresh, setRefresh] = useState(false);
  if (MaxDate === null) {
    maxDate.setMonth(maxDate.getMonth() + 5);
  } else {
    maxDate.setDate(maxDate.getDate());
  }
  // add 6 months to the current date
  const myNormalArray = [];
  const pusrposearray = [];
  const purposetym = [];
  // const getPlayoffData = async () => {
  //   let obj = {};
  //   for await (const v of DynamicPlayOf) {
  //     const getDetails = await getMethodData(
  //       `/api/user/playoff/matches?filters[league]=${v.league}&filters[round]=${v.round_name}&filters[level]=${v.level}`,
  //       localStorage.getItem("token")
  //     )
  //     if (getDetails) {
  //       obj[v.round_name] = getDetails.data.data;
  //     }
  //   }
  //   return obj;
  // }
  useEffect(() => {
    // Update isMobileView based on the viewport width
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767); // Adjust the breakpoint as needed for mobile view
    };

    // Call the handleResize function on initial load and window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (notificationNo) {
      getNotification();
    }
  }, [notificationNo]);
  useEffect(() => {
    if (authapproved) {
      gettoken(localStorage.getItem("token"));
      getuser(JSON.parse(localStorage.getItem("user")));
    }
  }, [authapproved]);
  const [isOpen, setIsOpen] = useState(false);

  const [IncorrectTym, setIncorrectTym] = useState(false);
  const [selectedTime, setSelectedTime] = useState(
    moment().tz(TIMEZONE_URL).set({ hour: 0, minute: 0 })
  );
  const handleChange = (newTimeMoment) => {
    const newTime = moment(newTimeMoment, "HH:mm");
    if (new Date().getDate() === currentDates.getDate() && new Date().getMonth() === currentDates?.getMonth() && new Date().getFullYear() === currentDates?.getFullYear() ) {
      const currentDate = moment().tz(TIMEZONE_URL);
      const minAllowedTime = currentDate.clone().add(4, 'hours');
      
      // Convert selected time to EST for comparison
      const selectedTimeEST = newTimeMoment.tz(TIMEZONE_URL);
      const Time = moment(selectedTimeEST, "HH:mm");

      // Calculate the selected time in hours and minutes
      const selectedHours = selectedTimeEST.hours();
      const selectedMinutes = selectedTimeEST.minutes();
  
      // Calculate the minimum allowed time in hours and minutes
      const minAllowedHours = minAllowedTime.hours();
      const minAllowedMinutes = minAllowedTime.minutes();
      if (newTime.isValid()) {
        setSelectedTime(newTime);
      }
      if (
        (selectedHours > minAllowedHours) || 
        (selectedHours === minAllowedHours && selectedMinutes >= minAllowedMinutes)
      ) {
  
        setIncorrectTym(false);
      } else {
        setIncorrectTym(true);
      }
    } else {
      setIncorrectTym(false);
  
      if (newTime.isValid()) {
        setSelectedTime(newTime);
      }
    }
  };
 const byDefault = (e)=>{
  // const newTime = moment(newTimeMoment, "HH:mm");
  if (new Date()?.getDate() == e?.getDate() && new Date().getMonth() === e?.getMonth() && new Date().getFullYear() === e?.getFullYear()) {
    const currentDate = moment().tz(TIMEZONE_URL);
    const minAllowedTime = currentDate.clone().add(4, 'hours');
    
    // Convert selected time to EST for comparison
    const selectedTimeEST =   moment().tz(TIMEZONE_URL).set({ hour: 19 , minute: 0 })

    const Time = moment(selectedTimeEST, "HH:mm");

    // Calculate the selected time in hours and minutes
    const selectedHours = selectedTimeEST.hours();
    const selectedMinutes = selectedTimeEST.minutes();

    // Calculate the minimum allowed time in hours and minutes
    const minAllowedHours = minAllowedTime.hours();
    const minAllowedMinutes = minAllowedTime.minutes();
    if (
      (selectedHours > minAllowedHours) || 
      (selectedHours === minAllowedHours && selectedMinutes >= minAllowedMinutes)
    ) {
      setIncorrectTym(false);
    } else {
      setIncorrectTym(true);
    }
  }
  else{
    setIncorrectTym(false);
      // if (newTime.isValid()) {
      //   setSelectedTime(newTime);
      // }

  }
  

 }

  // const Showbtn =()=>{
  //   setIsOpen(true);
   
  // }

  // const handleOpenDropdown = () => {
  //   setIsOpen(true);
  // };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  // const {  BackSchedule } = usePlayerSearchStore();
  useEffect(() => {
    if (!authapproved) {
      navigate("/");
      return;
    }
    gettoken(localStorage.getItem("token"));
    getuser(JSON.parse(localStorage.getItem("user")));
  }, [authapproved]);

  // const {  BackSchedule } = usePlayerSearchStore();

  const setChatWidget = () => {
    // if (getpurpose.length === 0) {
    if (!isWidgetOpened()) {
      toggleWidget();
    }
    // }
  };
  // console.log("================",isWidgetOpened());
  const purposeChatWidget = () => {
    if (!isWidgetOpened()) {
      toggleWidget();
    }
  };
  
  const DefaultMessage = (newMessage) => {
    const socket = socketIOClient(BASE_URL);

    
    const lastElement = 
    selectedDates
      ?.slice() // Create a copy of the array
      .sort(
        (a, b) =>
          new Date(a.date) -
          new Date(b.date)
      ).map(
      (date, index) =>
        `${" "}${moment(new Date(date.date)).format(
          moment(time?.date).format("MMM D")?.includes("May")
            ? "MMM  D "
            : "MMM.  D "
        )} at ${date.time}`
    )[selectedDates.length - 1];
    const formattedArray =   selectedDates
    ?.slice() // Create a copy of the array
    .sort(
      (a, b) =>
        new Date(a.date) -
        new Date(b.date)
    )
      .map(
        (date, index) =>
          `${" "}${moment(new Date(date.date)).format(
            moment(time?.date).format("MMM D")?.includes("May")
              ? "MMM  D "
              : "MMM.  D "
          )} at ${date.time}`
      )
      .slice(
        0,
        selectedDates.map(
          (date, index) =>
            `${" "}${moment(new Date(date.date)).format(
              moment(time?.date).format("MMM D")?.includes("May")
                ? "MMM  D"
                : "MMM.  D"
            )} at ${date.time}`
        ).length - 1
      )
      .join(", ");
    const result =
      formattedArray + (formattedArray ? " or " : "") + lastElement;
    if (messsage === true) {
      if (opponent.length === 2) {
        socket.emit("send_message", {
          name: user?.firstName + " " + user?.lastName,
          user_id: user?._id,
          roomId: matchid,
          message: `Are you available to play our match on${result}?\n\nIf these dates do not work, please suggest other dates/times.`,
          dateTime: new Date(),
          // });
        });
        addUserMessage(
          `Are you available to play our match on${result}?\n\nIf these dates do not work, please suggest other dates/times.`
        );
        // toggleWidget();
      } else {
        socket.emit("send_message", {
          name: user?.firstName + " " + user?.lastName,
          user_id: user?._id,
          roomId: matchid,
          message: `Are you available to play our match on${result}?\n\nIf these dates do not work, please suggest other dates/times. `,
          dateTime: new Date(),
          // });
        });
        addUserMessage(
          `Are you available to play our match on${result}?\n\nIf these dates do not work, please suggest other dates/times. `
        );
        // toggleWidget();
      }
      setDefaultMessageSent(true);
      setMessage(false);
      // toggleWidget();
    }

    socket.emit("send_message", {
      name: user?.firstName + " " + user?.lastName,
      user_id: user?._id,
      roomId: matchid,
      message: newMessage,
      dateTime: new Date(),
      // });
    });
    // toggleWidget();
  };

if((mysetone > oppsetone ? true : false) &&
(mysettwo > oppsetwo ? true : false) || (oppsetone > mysetone ? true : false) &&
(oppsetwo > mysettwo ? true : false) ){
  setThirdScoreCheck(true);
}
else{
  setThirdScoreCheck(false);

}

if(ThirdScoreCheck || (mysettwo && oppsetwo) == "" || (mysetone && oppsetone) == ""){
  setmythree("")
  setoppthree("")
}

  const NewSetMessage = (newMessage) => {
    const socket = socketIOClient(BASE_URL);
    if (DefaultMes === true) {

      if (opponent.length === 2) {
        socket.emit("send_message", {
          name: user?.firstName + " " + user?.lastName,
          user_id: user?._id,
          roomId: matchid,
          message: `Our Match is set for  ${hoveredTime} on ${
            
            moment(lastSelectedDate?.date).format(
            moment(lastSelectedDate?.date).format("MMM D")?.includes("May")
              ? "MMM  D"
              : "MMM.  D"
          )}  at ${
            ishome.displayName
          }.`,
          dateTime: new Date(),
          // });
        });
        addUserMessage(
          `Our Match is set for  ${hoveredTime} on ${
            
            moment(lastSelectedDate?.date).format(
            moment(lastSelectedDate?.date).format("MMM D")?.includes("May")
              ? "MMM  D"
              : "MMM.  D"
          )}  at ${
            ishome.displayName
          }.`
        );
        // toggleWidget();
      } else {
        socket.emit("send_message", {
          name: user?.firstName + " " + user?.lastName,
          user_id: user?._id,
          roomId: matchid,
          message:`Our Match is set for  ${hoveredTime} on ${moment(new Date(lastSelectedDate?.date)).format(
            moment(lastSelectedDate?.date).format("MMM D")?.includes("May")
              ? "MMM  D "
              : "MMM.  D "
          )} at ${
            ishome.displayName
          }.`,
          dateTime: new Date(),
          // });
        });
        addUserMessage(
          `Our Match is set for  ${hoveredTime} on ${moment(new Date(lastSelectedDate?.date)).format(
            moment(lastSelectedDate?.date).format("MMM D")?.includes("May")
              ? "MMM  D "
              : "MMM.  D "
          )} at ${
            ishome.displayName
          }.`,
        );
      }
      setDefaultMessageSent(true);
      setDefaultMessage(false);
    }
    socket.emit("send_message", {
      name: user?.firstName + " " + user?.lastName,
      user_id: user?._id,
      roomId: matchid,
      message: newMessage,
      dateTime: new Date(),
      // });
    });
    // toggleWidget();
  };
  useEffect(() => {
    const socket = socketIOClient(BASE_URL);
    if (user?._id != undefined) {
      socket.emit("joinRoom", { roomId: matchid });
    }
  }, [user?._id]);
  useEffect(() => {
    if (matchid) {
      getGroupDeatails(matchid);
    }
  }, [grpName]);
  useEffect(() => {
    getSetting();
  }, []);

  const mapStyles = {
    width: "",
    height: "100%",
  };
  const [value, setChange] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [TeamId, setTeamId] = useState();
  const [ProposedBy, setProposedBy] = useState();
  const [buttonText, setButtonText] = useState("Propose  Dates");
  const startDateObj = new Date(FromRange);
  const endDateObj = new Date(ToRange);
  let datesInRange = [];
  for (
    let date = startDateObj;
    date <= endDateObj;
    date.setDate(date.getDate() + 1)
  ) {
    datesInRange.push(new Date(date));
  }

  // const [dateEx4, setDateEx4] = useState("10:12");
  const navigate = useNavigate();
  const handleButtonClick = () => {
    setSelectedDates([]);
  };
  const EmptySetDate=()=>{
    if(schedhule !== null){
      if(
        value?.getDate() === SetDate?.getDate()
    
      ){
        setdatetym(null)
      }
    }
   
  }

  const [newdate, setnewdate] = useState(new Date());
  const [hoveredTime, setHoveredTime] = useState(null); // State to hold hovered time

  const lastSelectedDate = selectedDates[selectedDates.length - 1];
  const [schedhule, setschedhule] = useState("");
  const [TimeCheck, setTimeCheck] = useState(false);
  const [CheckCalender, setCheckCalender] = useState(false);
  const [Check, setCheck] = useState(false);



  var check = ""
if(!CheckCalender){
  check = 3
}
else{
  check = 1
}

  const handleMonthClick = (date) => {
    setAddMonth(true)
   
    getMatchDate(
      new Date(date?.activeStartDate).getFullYear(),
      new Date(date?.activeStartDate).getMonth()+1
      
      // getuserID(res?.players)
      // new Date(
      //   res?.schedule_to_date
      // ).getFullYear(),
      // new Date(res?.schedule_to_date).getMonth()
    );
  };
  // const emptyDates=()=>{
  //   setButtonText("Purposed Dates");
  //   setSelectedDates([]);

  // }
  // console.log("My Match", matchid);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getleagueid(id);
    gethomecourtdropdown();
    // handleDateChange(new Date());
  }, []);
  const handleDateChange = (date, time) => {
    setSelectedTime(
      moment().set({    
        hour: 19,
        minute: 0, }).tz(TIMEZONE_URL)
   );
    setTimeCheck(true)
    var events = getpurposetym.find(
      (event) => new Date(event?.date).toDateString() === date.toDateString()  
    );
    if(events){
      setHoveredTime( events?.time  )
    }
    // console.log("btnClick", events);
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return;
    }

    const dateString = moment(date, "DD/MM/YYYY").format("M/DD/YYYY");

    const newDateObj = {
      date: dateString,
      time: selectedTime.format("hh:mm A"),
    };

    if (selectedDates.length >=  check  ) {
      const index = selectedDates.findIndex((d) => d.date === dateString);
      if (index !== -1) {
        // If the selected date is already in the array, remove it
        setSelectedDates((prevDates) =>
          prevDates.filter((d) => d.date !== dateString)
        );
      } else {
        // If the selected date is not in the array, replace the oldest selected date
        const newDates = [...selectedDates];
        newDates.shift(); // Remove the oldest selected date
        setSelectedDates([...newDates, newDateObj]); // Add the new selected date
      }
    } else {
      const index = selectedDates.findIndex((d) => d.date === dateString);
      if (index !== -1) {
        setSelectedDates((prevDates) =>
          prevDates.filter((d) => d.date !== dateString)
        );
        if (date === selectedDates) {
          setDaytime(false);
        }
      } else {
        setSelectedDates((prevDates) => [...prevDates, newDateObj]);
      }
    }
  };
  const ChatWindowMessage = () => {
    if (grp_Id) {
      if (matchid !== grp_Id) {
        dropMessages();
      }
    }
  };

  useEffect(() => {
    const formattedDates = selectedDates.map((dateString) =>
      moment(dateString?.date, "M/DD/YYYY").format("M/DD")
    );
    setButtonText(
      formattedDates.length > 0 ? formattedDates.join(", ") : "Propose  Dates"
    );
  }, [selectedDates]);

  useEffect(() => {
    if (!authapproved) {
      navigate("/");
      return;
    }

    if (authapproved) {
      getLeaguesid(id);
    }
    // getScheduled();
  }, [authapproved]);

  useEffect(() => {
    getScheduled();
    getStanding();
    // getPlayoffData();
    // getPlayOff(Round, Schedule[0]?.level === undefined ? true :Schedule[0]?.level )
    getDynamicPlayOff(id);
  }, []);

  useEffect(() => {
 
    // setTimeout(() => {
      getPlayoffData().then((r) => {
        // console.log(r)
        // setPlayoffObj({})
        setPlayoffObj(r);
      });
    // }, 100);
  
  }, [loadMatch,addloaduser,loaderstartsetdate]);
  
  
  for (const date of calenderdate) {
    myNormalArray.push(new Date(date));
  }
  const popfun = () => {
    myNormalArray.pop();
  };

  for (const date of getpurpose) {
    pusrposearray.push(new Date(date));
  }

  for (const date of getpurposetym) {
    purposetym.push(date);
  }
  const PurposeContent = ({ date, view }) => {
    setDefaultMessage(true)
    handleMonthClick
    const dates = pusrposearray?.filter((d) => d.getTime() === date.getTime());
    var events = getpurposetym.find(
      (event) => new Date(event?.date).toDateString() === date.toDateString()  
    );

    const fixedDate = new Date(Date.parse(datefixed));
    
    const Bookeddates = myNormalArray?.filter(
      (d) => d.getTime() === date.getTime()
    );
    if (
      view === "month" &&
      dates.some((selected) => selected.toDateString() === date.toDateString())
    ) {
      // If the date is in the selectedDateArray, show a red circle.
      if (
        view === "month" &&
        Bookeddates.some(
          (selected) => selected.toDateString() === date.toDateString()
        )
      ) {
        if (
          Bookeddates.some(
            (selected) => selected.toDateString() === date.toDateString()
          ) 
          ||
          (view === "month" &&
            dates.some(
              (selected) => selected.toDateString() === date.toDateString()
            ) === true)
        ) {
          return (
            <div
              className="back-circle"
              style={{
                background: "red",
              }}
            />
          );
        }
           if (
          Bookeddates.some(
            (selected) => selected.toDateString() === date.toDateString()
          ) ||
          (view === "month" &&
            dates.some(
              (selected) => selected.toDateString() === date.toDateString()
            ) === true)
        ) {
          return (
            <div
              className="back-circle"
              style={{
                background: "#39ff14",
              }}
            />
          );
        }
      }
 
      

      // if (events) {
        const tooltipRef = React.createRef();
  
      
      
        return (
          <>
            <div
              className="back-circle"
              ref={tooltipRef}
              style={{ backgroundColor: "blue" }}
            ></div>
            <div style={{}} className="tooltip">
            {events?.time}
            </div>
          </>
        );
        
      // }
    }
    if (pusrposearray.length === 0 && schedhule === null) {
      if (
        Bookeddates.some(
          (selected) => selected.toDateString() === date.toDateString()
        ) 
        &&
        (
        datesInRange.some(
            (selected) => selected.toDateString() === date.toDateString()))
      ) {
        return (
          <div
            className="back-circle"
            style={{
              background: "red",
            }}
          />
        );
      }
      if (
        datesInRange.some(
          (selected) => selected.toDateString() === date.toDateString()
        )
      ) {
        // If the date is in the selectedDateArray, show a red circle.
        return (
          <div className="back-circle" style={{ backgroundColor: "#39ff14" }} />
        );
      }
   
    }
    if (
      fixedDate &&
      fixedDate instanceof Date &&
      fixedDate.toDateString() === date.toDateString()
    ) {
      return (
        <div className="back-circle" style={{ backgroundColor: "#39ff14" }} />
      );
    }

    if (
      view == "month" &&
      Bookeddates.some(
        (selected) => selected.toDateString() == date.toDateString()
      )
    ) {
      // If the date is in the selectedDateArray, show a red circle.
     return <div className="back-circle" style={{ backgroundColor: "red" }} />;
    } 
    else {
      return null;
    }
  };

  const validateNo = (e) => {
    const inputValue = parseInt(e.target.value);
    if (inputValue > 7) {
      e.target.value = "";
    } else {
      e.target.value = e.target.value.replace(/\D/g, "").slice(0, 1);
    }
  };
  const tileClassName = ({ date }) => {
    // const datenew =  new Date(date)

    if (!date) {
      return null; // or some other default class
    }
    // // console.log("=>_+-=-=-=-=-=-",{date})
    // const formattedDate = `${date?.getMonth() + 1}/${date?.getDate()}/${date?.getFullYear()}`;
    // // console.log("formattedDate===>>>>",formattedDate)

    // const matchingDate = selectedDates.find(
    //   (obj) => obj.date === formattedDate
    // );
    // console.log(date.getDate())
    // if (matchingDate) {
    //   return "selected-date";
    // }
    // // if (selectedDates?.date?.includes(formattedDate)) {
    // //   return 'selected-date';
    // // }

    // return null;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const formattedDate = `${date.getMonth() + 1}/${day}/${date.getFullYear()}`;
    const matchingDate = selectedDates.find(
      (obj) => obj.date === formattedDate
      
    );

    if (matchingDate) {

      return "selected-date";

    }
  
    return null;
  };
  useEffect(() => {
    if (matchid) {
      fetchData(matchid);
    }
  }, [matchid]);
  
  const isDateEnabled = (date) => {
    const demo = [...myNormalArray];
    for (const dates of myNormalArray) {
      demo.push(format(new Date(dates), `yyyy,M,d`));
      // this.getpurposetym.push(date);
    }

    const uncommonDates = getpurpose.filter(date => !demo.includes(date));
    return uncommonDates.some(enabledDate => {
      const [year, month, day] = enabledDate.split(',').map(Number);
      const enabledDateObj = new Date(year, month - 1, day);
      return enabledDateObj.getTime() === date.getTime();
    });
  };

  const BookingDateEnabled = (date) => {
    return myNormalArray.some(enabledDate => {
      const [year, month, day] = format(new Date(enabledDate), `yyyy,M,d`).split(',').map(Number);
      const [year1, month1, day1] = format(new Date(schedhule), `yyyy,M,d`).split(',').map(Number);

      const schedhuleDate = new Date(year1, month1 - 1, day1);
      const enabledDateObj = new Date(year, month - 1, day);

      if (schedhuleDate.getTime() === enabledDateObj.getTime()) {
        return false; // Skip the comparison for this date
      }
      return enabledDateObj.getTime() === date.getTime();
    });
  };

//   const isDateDisabled = (date) => {
//     // Implement your logic to determine if a date should be disabled or not
//     // For example, you can disable dates that are before today or after a certain date
//     const today =new Date( getpurpose[0]);
//     const endDate = new Date( getpurpose[1]);
//  console.log("==========>>>>>>>>>>>",today);

//     return date < today || date > endDate;
//   };
  //   // Function to check if a date is in the enabledDates array

    
  // const isDateEnabled = (date) => {
  //   return getpurpose.some(enabledDate =>
  //     new Date( enabledDate)
  //     // const today =new Date( getpurpose[0]);
  //     // console.log("=============>>>>>>>>>>>>1",new Date( enabledDate))
  //     // enabledDate.getTime() === date.getTime()
  //   );
  // };
  useEffect(()=>{
    if(loaderstart){
      
    }
    if(messgae_initate){
      ChatWindowMessage();
      // grpPlayer(playername);
      handleButtonClick();
      purposeChatWidget();
      DefaultMessage();
      setdatetym(null);
      // setChange(null);
      SettymOnClick(null);
      removedate();
      setButtonText("Purposed Dates");
      setDefaultMessageSent(true);
    }
  },[messgae_initate])
  return (
    <div className="relative h-full">
      <img
      alt=""
        className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top brightness-50"
        src={bgroger}
      />
      <div className="relative z-1 py-5">
        <div className="block">
          <div className="align-baseline ">
            <Link to="/" className=" ">
              <p className="flex items-center md:pl-20 pl-3 top-5 text-[20px] text-[#ffffff]  ">
                <FaAngleLeft /> Back to Leagues
              </p>
            </Link>
          </div>

          <div className="md:px-36 pb-1 ">
            <p className=" text-center text-[#fff] text-3xl md:text-3xl  capitalize">
              {/* {leaguesiddata.replace("mixed", "(Mixed)")} */}
              {leaguesiddata?.replace("(Female)", "")?.replace("(Male)", "")}
              <br />
            </p>
          </div>
        </div>
        <div className=" flex items-center justify-end mr-4 relative z-10">
          <div
            onClick={() => {
              setDivision(true);
              setChampions(false);
              // setQutarFinal(false);
              setActiveTab("division");
            }}
            className={`box-border cursor-pointer flex flex-col mt-5 border-r border-[gray] justify-center items-center  bg-[#fff] h-10 w-[6rem] text-[12px] xl:p-[.30rem]  ${
              activeTab === "division" ? "bg-green" : ""
            }`}
          >
            <p className=" text-[#7E7777] font-medium md:text-3xl text-2xl  relative"></p>
            <p className=" text-[#7E7777] font-light ">Division</p>
          </div>
          {/* {DynamicPlayOf.length !== 0 ?<>   */}
          {/* {DynamicPlayOf.map((data, index) => (
            <div
              key={index}
              onClick={() => {
                setDivision(false);
                setPlayOffs(data?.round_name);
                setChampions(false);
                // setQutarFinal(true);
                getPlayOff(data?.round_name, Schedule[0]?.level);
                setRound(
                  data?.round_name === "semifinal"
                    ? "Semi-Final"
                    : data?.round_name
                );
                setActiveTab(data.round_name);
              }}
              className={`box-border cursor-pointer   flex flex-col mt-5 border-r border-[gray] justify-center items-center  bg-[#fff] h-10 w-[6rem] text-[12px] xl:p-[.30rem]  ${
                activeTab === data.round_name ? "bg-green" : ""
              }`}
            >
              <p className=" text-[#7E7777] font-medium md:text-3xl text-2xl  relative"></p>
              <p className=" text-[#7E7777] font-light capitalize  ">
                {data?.round_name?.replace("semifinal", "Semi-Final")}
              </p>
            </div>
          ))} */}
          {/* <div
            onClick={() => {
              setDivision(false);
              setPlayOffs("quarter");
              setChampions(false);
              // setQutarFinal(true);
              getPlayOff("quarter",Schedule[0]?.level);
              setRound("Quarter Final");
              setActiveTab("qatarFinal");
            }}
            className={`box-border cursor-pointer   flex flex-col mt-5 border-r border-[gray] justify-center items-center  bg-[#fff] h-10 w-[6rem] text-[12px] xl:p-[.30rem]  ${
              activeTab === "qatarFinal" ? "bg-green" : ""
            }`}
          >
            <p className=" text-[#7E7777] font-medium md:text-3xl text-2xl  relative"></p>
            <p className=" text-[#7E7777] font-light  ">Quarter Final</p>
          </div>
          <div
            onClick={() => {
              getPlayOff("semifinal", Schedule[0]?.level);
              setChampions(false);
              setPlayOffs("semifinal");
              setRound("Semi Final");
              setDivision(false);
              setActiveTab("semiFinal");
            }}
            className={`box-border cursor-pointer   flex flex-col mt-5 border-r border-[gray] justify-center items-center  bg-[#fff] h-10 w-[6rem] text-[12px] xl:p-[.30rem]  ${
              activeTab === "semiFinal" ? "bg-green" : ""
            }`}
          >
            <p className=" text-[#7E7777] font-light">Semi Final</p>
          </div>
          <div
            onClick={() => {
              getPlayOff("final",Schedule[0]?.level);
              setPlayOffs("final");
              setChampions(false);
              setDivision(false);
              setRound("Final");
              setActiveTab("final");
            }}
            className={`box-border cursor-pointer  border-r border-[gray]  flex flex-col mt-5 justify-center items-center  bg-[#fff] h-10 w-[6rem] text-[12px] xl:p-[.30rem]  ${
              activeTab === "final" ? "bg-green" : ""
            }`}
          >
            <p className=" text-[#7E7777] font-light">Final</p>
          </div> */}
          {/* </>:null } */}
      {hieracybtn &&
       <div
       onClick={() => {
         getPlayersTree(id, Schedule[0]?.level);
         setChampions(true);
         setDivision(false);
         setActiveTab("Champions");
       }}
       className={`ml-7 box-border cursor-pointer  flex flex-col mt-5 justify-center items-center  bg-[#fff] h-10 w-[7rem] text-[12px] xl:p-[.30rem]  ${
         activeTab === "Champions" ? "bg-green" : ""
       }`}
     >
       <p className=" text-[#7E7777] font-light">Playoff</p>
     </div>
       }
     
 
          
         
        </div>
        {/* {nolist !== 0 ? ( */}
        <div className="border border-[#39fd13]  mt-[-19px] flex flex-col-reverse">
          {division === true ? (
            nolist !== 0 ? (
              <div className="xl:px-35 lg:px-25 md:px-30 flex flex-col lg:flex-row lg:space-x-5 px-4 justify-center">
                <div className="lg:w-9/12 w-full py-11 flex flex-col">
                  <p className=" text-center text-[#fff] text-xl md:text-xl capitalize">
                    Match Schedule
                    <br />
                  </p>

                  <div
                    id="style-1"
                    className=" bg-[#6C6C6C] whitespace-nowrap  flex-grow flex-shrink-0 border-2 border-[#fff] bg-opacity-40 rounded-lg my-5 min-h-[450px] overflow-auto"
                  >
                    <div id="partner">
                      <table className="w-full text-[#fff]" id="partnertable">
                        <thead>
                          <tr className="bg-green sticky top-0 z-[1] ">
                            <th className="md:py-3 text-[black] text-left px-5">Opponent</th>
                            {/* {leaguetype != "singles" ? (
                              <th className="text-left text-[black] pr-3">Partner</th>
                            ) : (
                              ""
                            )} */}
                            <th className="text-left text-[black] md:pr-10 ">Match Period</th>
                            <th className="text-left text-[black]">Match Date</th>
                            <th className="text-left text-[black]">Home Court</th>
                            <th className="text-[black]" >Result</th>
                            {/* <th>Ranking</th> */}
                          </tr>
                        </thead>
                        <tbody className=" text-xs lg:text-sm whitespace-nowrap ">
                          {loadMatchdata && (
                            <>
                              <tr>
                                <td
                                  colSpan={5}
                                  className="animate-pulse py-6 bg-[#ffffff21]"
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={5}
                                  className="animate-pulse py-6 bg-[#ffffff36]"
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={5}
                                  className="animate-pulse py-6 bg-[#ffffff21]"
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={5}
                                  className="animate-pulse py-6 bg-[#ffffff36]"
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={5}
                                  className="animate-pulse py-6 bg-[#ffffff21]"
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={5}
                                  className="animate-pulse py-6 bg-[#ffffff36]"
                                ></td>
                              </tr>
                            </>
                          )}
                          {Schedule.map((res, index) => (
                            <>
                              <tr
                                key={index}
                                className={`hover:bg-[#39fd13] hover:bg-opacity-5 ${
                                  res?.status == "void" ? "avoid_class" : ""
                                } `}
                              >
                                <td className=" px-5 py-1">
                                  {res?.opponent.map((playername, index) => (
                                    <>
                                    <span
                                     title="View Match History"
                                      onClick={(e) => {
                                        RedirectChanged(`/mymatch/${id}`, true);
                                        setRoute(result);
                                        setRoutes("");
                                        navigate(
                                          `/playerdetail/${playername?._id} `
                                        );
                                      }}
                                    >
                                    <span
                                      
                                      key={index}
                                    
                                      className="capitalize cursor-pointer  hover:text-green"
                                    >
                                       </span>
                                       <span className="" > {(index ? " & " : "") }</span>
                                      <span  key={index} className="capitalize cursor-pointer  hover:text-green" >
                                      {
                                        playername?.firstName}{" "}
                                      {playername?.lastName}
                                      </span>
                                   
                                    </span>
                                    
                                    </>
                                  ))}
                                
                           {!res?.is_score_update === true && 
                                    (res?.schedule_date != null ? (
                                        <button
                                          onClick={() => {
                                            navigate(`/message/${res?._id}`);
                                            getGroupOfUser("");
                                            getcurrentID(res?._id);
                                            setSelectedChat(null);
                                          }}
                                          className="cursor-pointer md:ml-3 text-green group relative"
                                          disabled={
                                            res?.status == "void" ? true : false
                                          }
                                        >
                                          <FaFacebookMessenger />
                                          <span className="absolute z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">Chat</span>                                                

                                        </button>
                                      ) : res?.is_score_update === true ? (
                                        ""
                                      ) : res.propose_dates.length != 0 ? (
                                        <button
                                          onClick={() => {
                                            navigate(`/message/${res?._id}`);
                                            getGroupOfUser("");
                                            getcurrentID(res?._id);
                                            setSelectedChat(null);
                                          }}
                                          className="cursor-pointer md:ml-3 text-green group relative"
                                          disabled={
                                            res?.status == "void" ? true : false
                                          }
                                        >
                                          <FaFacebookMessenger />
                                          <span className="absolute z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">Chat</span>                                                
                                        </button>
                                      ) : null)
                                    }
                                  {/* {res.opponent.firstName} {res.opponent.lastName} */}
                                </td>
                                <td className="">
                                {
                                  
                                                    <Moment
                                                      format={
                                                        moment(
                                                          res?.schedule_from_date
                                                        )
                                                          .format("MMM D")
                                                          ?.includes("May")
                                                          ? "MMM DD"
                                                          : "MMM. DD"
                                                      }
                                                    >
                                                      {res?.schedule_from_date}
                                                    </Moment>
                                                  }{" "}
                                                  -{" "}
                                                  {
                                                    <Moment
                                                      format={
                                                        moment(
                                                          res?.schedule_to_date
                                                        )
                                                          .format("MMM D")
                                                          ?.includes("May")
                                                          ? "MMM DD"
                                                          : "MMM. DD"
                                                      }
                                                    >
                                                      {res?.schedule_to_date}
                                                    </Moment>
                                                  }
                                </td>
                                {/* {playername?._id != user?._id ? <>{playername.firstName} {playername.lastName},</>  :"" }  */}
                                {/* {leaguetype != "singles" ? (
                                  <td>
                                    <span
                                      title="View Match History"
                                      className="capitalize cursor-pointer pr-4 hover:text-green"
                                      onClick={(e) => {
                                        RedirectChanged(`/mymatch/${id}`, true);
                                        setRoute(result);
                                        navigate(
                                          `/matchhistory/${res?.mate?._id}/${id}/${leaguesiddata}/${res?.mate?.firstName + " " + res?.mate?.lastName }/${leaguetype}`
                                        );
                                      }}
                                    >
                                      {res?.mate?.firstName}{" "}
                                      {res?.mate?.lastName}
                                    </span>
                                  </td>
                                ) : (
                                  ""
                                )} */}
                                {res?.is_score_update === true ? (
                                  <td className="flex text-left whitespace-nowrap lg:py-4 py-7 md:px-0 lg:px-0 px-3 md:py-2">
                                    <div className="flex">
                                      <div className="avatar">
                                        <div className="flex cursor-pointer  ">
                                          {res?.schedule_date === null ? (
                                            <button
                                              onClick={() => {
                                                setDefaultMessageSent();
                                                dropMessages();
                                              }}
                                              title="Proposed Dates"
                                              disabled={
                                                res?.status == "void"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {res?.propose_dates.length !=
                                              0 ? (
                                                res?.propose_dates.map(
                                                  (purpose, index) => (
                                                    <span
                                                      onClick={() => {
                                                        setMessage(true);
                                                        SetMaxDate(
                                                          res?.date_range_end
                                                           );
                                                        // toggleWidget(true);
                                                        setID(res?._id);
                                                      }}
                                                      key={index}
                                                      title="Proposed Dates"
                                                    >
                                                      {index ? (
                                                        <span
                                                          style={{
                                                            paddingLeft: "5px",
                                                          }}
                                                        >
                                                          /
                                                        </span>
                                                      ) : (
                                                        ""
                                                      )}{" "}
                                                      {
                                                        <Moment
                                                          format={
                                                            moment(
                                                              purpose?.date
                                                            )
                                                              .format("MMM D")
                                                              ?.includes("May")
                                                              ? "MMM DD"
                                                              : "MMM. DD"
                                                          }
                                                        >
                                                          {purpose?.date}
                                                        </Moment>
                                                      }
                                                    </span>
                                                  )
                                                )
                                              ) : (
                                                <>
                                                N/A
                                                  {/* {
                                                  
                                                    <Moment
                                                      format={
                                                        moment(
                                                          res?.schedule_from_date
                                                        )
                                                          .format("MMM D")
                                                          ?.includes("May")
                                                          ? "MMM DD"
                                                          : "MMM. DD"
                                                      }
                                                    >
                                                      {res?.schedule_from_date}
                                                   
                                                    </Moment>
                                                  }{" "}
                                                  -{" "}
                                                  {
                                                    <Moment
                                                      format={
                                                        moment(
                                                          res?.schedule_to_date
                                                        )
                                                          .format("MMM D")
                                                          ?.includes("May")
                                                          ? "MMM DD"
                                                          : "MMM. DD"
                                                      }
                                                    >
                                                      {res?.schedule_to_date}
                                                    </Moment>
                                                  } */}
                                                </>
                                              )}
                                            </button>
                                          ) : (
                                            <>
                                              <button
                                                className="flex"
                                                title="Schedule Dates"
                                                disabled={
                                                  res?.status == "void"
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <Moment
                                                  format={
                                                    moment(res?.schedule_date)
                                                      .format("MMM D")
                                                      ?.includes("May")
                                                      ? "MMM DD"
                                                      : "MMM. DD"
                                                  }
                                                >
                                                  {res?.schedule_date}
                                                </Moment>
                                                <p className="px-1"> @ </p>
                                                {/* {res?.schedule_time} */}
                                                {moment(
                                                 momentsss(res?.schedule_time).tz(TIMEZONE_URL) ).format("hh:mm A")}
                                              </button>
                                            </>
                                          )}
                                        </div>
                                      </div>

                                      {res?.schedule_date != null ? (
                                        ""
                                      ) : res?.is_score_update === true ? (
                                        ""
                                      ) : (
                                        <div className="cursor-pointer md:ml-3 group relative">
                                          <FaFacebookMessenger />
                                          <span className="absolute z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">Chat</span>                                                
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                ) : (
                                 
                                  <td className="flex text-left md:py-2 lg:py-2 py-1 px-3 md:text-sm text-xs md:px-0 whitespace-nowrap ">
                                    <div className="flex  ">
                                      <div className="avatar">
                                        <div
                                        onClick={() => {
                                          setmatchid(res?._id);
                                          setTeamId(res?.my_team_id)
                                          setProposedBy(res?.proposedBy)
                                          GetPurposed(
                                            res.propose_dates.length
                                          );
                                          // setpopisOpen(
                                          //   res?.status == "void"
                                          //     ? false
                                          //     : true
                                          // );
                                          getMatchdetails(res?._id);
                                          gettym(res?.schedule_time);
                                        
                                            setSelectedTime(
                                              moment().set({
                                                hour: 19,
                                                minute: 0,
                                              }).tz(TIMEZONE_URL)
                                            );
                                        
                                        
                                          getPurposeDate(res?._id);
                                          setdatefixed(res?.schedule_date);
                                          gethomeID(res?.home_court?._id);
                                          setIsHome(res?.home_court);
                                          setMyhomecourt(res?.home_court);
                                          gethomecourt(res?.home_court?.name);
                                          // setChange(res?.schedule_date);
                                          getuserID(res?.players);

                                          getDateRange(
                                            new Date(res?.schedule_from_date),
                                            new Date(res?.schedule_to_date)
                                          );

                                          // getMatchDate(
                                          //   new Date(
                                          //     res?.schedule_date == null
                                          //       ? res?.schedule_from_date
                                          //       : res?.schedule_date
                                          //   ).getFullYear(),
                                          //   res?.schedule_date == null ?
                                          //   new Date(
                                          //     res?.schedule_from_date
                                          //   ).getMonth()+1
                                          //   : 
                                          //   new Date(
                                          //       res?.schedule_date
                                          //   ).getMonth()+1
                                          // );
                                          setnewdate(
                                            new Date(
                                              res?.propose_dates?.length !== 0 &&
                                              res?.schedule_date === null ?
                                              (res?.propose_dates[0]?.date)
                                              :
                                              (res?.schedule_date === null
                                                ? res?.schedule_from_date
                                                  : res?.schedule_date)

                                              // res?.schedule_date === null
                                              //   ? res?.schedule_from_date
                                              //   : res?.schedule_date
                                            )
                                          );
                                          setschedhule(res?.schedule_date);
                                          // getdatefunctio(new Date(res?.metch_date).getFullYear())
                                          // setChange(new Date(res?.schedule_from_date));
                                        }}
                                          className="flex"

                                        >
                                          {res?.propose_dates.length != 0 &&
                                          res?.schedule_date === null ? (
                                            <>
                                            <div className="flex flex-col">
                                            <br className="md:block hidden"/>
                                                <div className="flex md:mt-[10px]">
                                                  <span>
                                                  {res?.propose_dates
                                                  ?.slice() // Create a copy of the array
                                                  .sort(
                                                    (a, b) =>
                                                      new Date(a.date) -
                                                      new Date(b.date)
                                                  )
                                                  .map((purpose, index) => (
                                                    <>
                                                   
                                                      {index ? (
                                                        <span
                                                          style={{
                                                            paddingLeft: "5px",
                                                          }}
                                                        >
                                                          /
                                                        </span>
                                                      ) : (
                                                        ""
                                                      )}{" "}
                                                      {
                                                        <Moment
                                                          format={
                                                            moment(purpose?.date)
                                                              .format("MMM D")
                                                              ?.includes("May")
                                                              ? "MMM DD"
                                                              : "MMM. DD"
                                                          }
                                                        >
                                                          {purpose?.date}
                                                        </Moment>
                                                      }
                                                    
                                                    
                                                    </>
                                                    
                                                  ))}

                                                  </span>
                                                
                                                   <div className="flex">
                                           <span>
                                           {!(res?.my_team_id == res?.proposedBy) && 
                                                 <button
                                                    // onClick={() => {
                                                    
                                                    // }}
                                                    className=" ml-2 hover:text-green text-green group relative"
                                                    onClick={() => {
                                                      setmatchid(res?._id);
                                                      getuserID(res?.players);
                                                      setMessage(true);
                                                      setCheckCalender(true)
                                                      SetMaxDate(
                                                        res?.date_range_end
                                                         );
                                                      // toggleWidget(true);
                                                      setID(res?._id);
                                                       setpopisOpen(
                                                    res?.status == "void"
                                                        ? false
                                                        : true
                                          );
                                          setplayername(res?.opponent)
                                          getMatchDate(
                                            new Date(
                                              res?.schedule_date === null
                                                ? res?.schedule_from_date
                                                : res?.schedule_date
                                            ).getFullYear(),
                                            res?.schedule_date == null ?

                                            new Date(
                                              res?.schedule_from_date
                                            ).getMonth() < 
                                            new Date(
                                            ).getMonth()
                                            && 
                                            new Date(
                                              res?.schedule_from_date
                                            ).getFullYear() ==
                                            new Date(
                                            ).getFullYear()
                                            ?
                                            new Date(
                                              ).getMonth()+1  
                                            :
                                            new Date(
                                              res?.schedule_from_date
                                            ).getMonth()+1
                                            : 
                                            new Date(
                                                res?.schedule_date
                                            ).getMonth()+1
                                          );
                                                   
                                                    }}
                                                    key={index}
                                                  >
                                                <FaCheck/>
                                                <span className="absolute z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">Accept Date & Time</span>                                                
                                                </button>
                                              }
                                           </span>
                                            <span>
                                            <button
                                                    // onClick={() => {
                                                    
                                                    // }}
                                                    className=" ml-2  cursor-pointer hover:text-green group relative "
                                                    onClick={() => {
                                                      setMessage(true);
                                                      setCheckCalender(false)
                                                      SetMaxDate(
                                                        res?.date_range_end
                                                         );
                                                      // toggleWidget(true);
                                                      setID(res?._id);

                                                      setmatchid(res?._id);
                                                      GetPurposed(
                                                        res.propose_dates.length
                                                      );
                                                      setpopisOpen(
                                                        res?.status == "void"
                                                          ? false
                                                          : true
                                                      );
                                            
                                                      getMatchdetails(res?._id);
                                                      gettym(res?.schedule_time);
                                                      // if (res?.schedule_time === null) {
                                                          setSelectedTime(
                                                            moment().set({
                                                              hour: 19,
                                                              minute: 0,
                                                            }).tz(TIMEZONE_URL)
                                                          )
                                                      
                                                      // }
                                                      //  else {
                                                      //   setSelectedTime(
                                                      //     moment(res?.schedule_time)
                                                      //   );
                                                      // }
                                                      getPurposeDate(res?._id);
                                                      setplayername(res?.opponent)
                                                      setdatefixed(res?.schedule_date);
                                                      gethomeID(res?.home_court?._id);
                                                      setIsHome(res?.home_court);
                                                      setMyhomecourt(res?.home_court);
                                                      gethomecourt(res?.home_court?.name);
                                                      // setChange(res?.schedule_date);
                                                      getuserID(res?.players);
          
                                                      getDateRange(
                                                        new Date(res?.schedule_from_date),
                                                        new Date(res?.schedule_to_date)
                                                      );
          
                                                      getMatchDate(
                                                        new Date(
                                                          res?.schedule_date === null
                                                            ? res?.schedule_from_date
                                                            : res?.schedule_date
                                                        ).getFullYear(),
                                                        res?.schedule_date == null ?
    
                                                        new Date(
                                                          res?.schedule_from_date
                                                        ).getMonth() < 
                                                        new Date(
                                                        ).getMonth()
                                                        && 
                                                        new Date(
                                                          res?.schedule_from_date
                                                        ).getFullYear() ==
                                                        new Date(
                                                        ).getFullYear()
                                                        ?
                                                        new Date(
                                                          ).getMonth()+1  
                                                        :
                                                        new Date(
                                                          res?.schedule_from_date
                                                        ).getMonth()+1
                                                        : 
                                                        new Date(
                                                            res?.schedule_date
                                                        ).getMonth()+1
                                                      );
                                                      setnewdate(
                                                        new Date(
                                                          res?.propose_dates?.length !== 0 &&
                                                          res?.schedule_date === null ?
                                                          (res?.propose_dates[0]?.date)
                                                          :
                                                          (res?.schedule_date === null
                                                            ? res?.schedule_from_date
                                                              : res?.schedule_date)
          
                                                          // res?.schedule_date === null
                                                          //   ? res?.schedule_from_date
                                                          //   : res?.schedule_date
                                                        )
                                                      );
                                                      // setschedhule(res?.schedule_date);
                                                      // getdatefunctio(new Date(res?.metch_date).getFullYear())
                                                      // setChange(new Date(res?.schedule_from_date));
                                                    }}
                                                    key={index}
                                                  >
                                                <SlCalender/>
                                                <span className="absolute z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">{  res.propose_dates.length != 0 || res?.schedule_date != null  ? "Propose New Dates":"Propose Dates"  } </span>            
                                                         
                                                </button>
                                              </span>
                                              
                                              </div>
                                                </div>
                                             
                                               
                                            
                                         
                                                  <span className=" flex flex-col ">  
                                                  <span className="text-[11px]"> {res?.propose_dates?.map((time, index)=>(
                                                    <span key={index}>
                                                        {index ? (
                                                        <span
                                                          style={{
                                                            paddingLeft: "5px",
                                                            paddingRight:"5px"
                                                          }}
                                                        >
                                                          /
                                                        </span>
                                                      ) : (
                                                        ""
                                                      )}
                                                       {time?.time}
                                                    </span>
                                                  )
                                                    
                                                  )}   </span>   <span className="text-[9px] text-green " > {res?.my_team_id == res?.proposedBy ? "Awaiting opponent to accept date": <div className="flex "> Click <FaCheck className="mt-1 mx-1 text-center"/>  to accept a date </div> }  </span>  </span>     
                                                      
                                          
                                            </div>
                                         
                                              
                                                
                                                {" "}
                                              
                                              
                                              
                                            </>
                                          ) : (
                                            <>
                                             
                                              {res?.schedule_date === null &&
                                              res?.propose_dates.length ===
                                                0 ? (
                                                  <div className="flex items-center my-2 cursor-pointer"

                                                  disabled={
                                                    res?.status == "void"
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={() => {
                                                   setMessage(true);
                                                   dropMessages();
                                                   setCheckCalender(false)
                                                   SetMaxDate(
                                                     res?.date_range_end
                                                   );
                                                   setDefaultMessageSent(
                                                     false
                                                   );
                                                   // dropMessages();
                                                   setmatchid(res?._id);
                                                   GetPurposed(
                                                     res.propose_dates.length
                                                   );
                                                   setpopisOpen(
                                                     res?.status == "void"
                                                       ? false
                                                       : true
                                                   );
                                                   getMatchdetails(res?._id);
                                                   gettym(res?.schedule_time);
                                                   // if (res?.schedule_time === null) {
                                                     setSelectedTime(
                                                       moment().set({
                                                         hour: 19,
                                                         minute: 0,
                                                       }).tz(TIMEZONE_URL)
                                                     )
                                                   // } else {
                                                   //   setSelectedTime(
                                                   //     moment(res?.schedule_time)
                                                   //   );
                                                   // }
                                                   getPurposeDate(res?._id);
                                                   setdatefixed(res?.schedule_date);
                                                   gethomeID(res?.home_court?._id);
                                                   setIsHome(res?.home_court);
                                                   setMyhomecourt(res?.home_court);
                                                   gethomecourt(res?.home_court?.name);
                                                   // setChange(res?.schedule_date);
                                                   getuserID(res?.players);
       
                                                   getDateRange(
                                                     new Date(res?.schedule_from_date),
                                                     new Date(res?.schedule_to_date)
                                                   );
                                                  
                                                   getMatchDate(
                                                     new Date(
                                                       res?.schedule_date === null
                                                         ? res?.schedule_from_date
                                                         : res?.schedule_date
                                                     ).getFullYear(),
                                                     res?.schedule_date == null ?
 
                                                     new Date(
                                                       res?.schedule_from_date
                                                     ).getMonth() < 
                                                     new Date(
                                                     ).getMonth()
                                                     && 
                                                     new Date(
                                                       res?.schedule_from_date
                                                     ).getFullYear() ==
                                                     new Date(
                                                     ).getFullYear()
                                                     ?
                                                     new Date(
                                                       ).getMonth()+1  
                                                     :
                                                     new Date(
                                                       res?.schedule_from_date
                                                     ).getMonth()+1
                                                     : 
                                                     new Date(
                                                         res?.schedule_date
                                                     ).getMonth()+1
                                                   );
                                                   setplayername(res?.opponent)
                                                   setnewdate(
                                                     new Date(
                                                       res?.propose_dates?.length !== 0 &&
                                                       res?.schedule_date === null ?
                                                       (res?.propose_dates[0]?.date)
                                                       :
                                                       (res?.schedule_date === null
                                                         ? res?.schedule_from_date
                                                           : res?.schedule_date)
       
                                                       // res?.schedule_date === null
                                                       //   ? res?.schedule_from_date
                                                       //   : res?.schedule_date
                                                     )
                                                   );
                                                   // setschedhule(res?.schedule_date);
                                                   // getdatefunctio(new Date(res?.metch_date).getFullYear())
                                                   // setChange(new Date(res?.schedule_from_date));
                                                 }}
                                                  >        
                                               {res?.schedule_from_date? 
                                               <>
                                              {  res?.status == "void" ? 
                                              "N/A"
                                              :
                                               "Propose date to play"
                                              }
                                              </>
                                              :
                                              "-"
                                              }
                                             
                                                  {/* <Moment
                                                    format={
                                                      moment(
                                                        res?.schedule_from_date
                                                      )
                                                        .format("MMM D")
                                                        ?.includes("May")
                                                        ? "MMM DD"
                                                        : "MMM. DD"
                                                    }
                                                  >
                                                    {res?.schedule_from_date}
                                                  </Moment>
                                                  <p className="px-1"> -</p>
                                                  <Moment
                                                    format={
                                                      moment(
                                                        res?.schedule_to_date
                                                      )
                                                        .format("MMM D")
                                                        ?.includes("May")
                                                        ? "MMM DD"
                                                        : "MMM. DD"
                                                    }
                                                  >
                                                    {res?.schedule_to_date}
                                                  </Moment> */}
                                               
                                                 <button
                                                 className="flex ml-2 items-center cursor-pointer hover:text-green group relative "
                                                //  onClick={() => {
                                                  
                                                //  }}
                                                
                                              
                                               >
                                               {res?.status == "void" ? null :<SlCalender/> } 
                                                <span className="absolute z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">{  res.propose_dates.length != 0 || res?.schedule_date != null  ? "Propose New Dates":"Propose Dates"  }</span>                                                
                                                </button>
                                                
                                                </div>

                                              ) : (
                                                <div className="flex items-center my-2">
                                                 
                                                    {" "}
                                                    <Moment
                                                      format={
                                                        moment(
                                                          res?.schedule_date
                                                        )
                                                          .format("MMM D")
                                                          ?.includes("May")
                                                          ? "MMM DD"
                                                          : "MMM. DD"
                                                      }
                                                    >
                                                      {res?.schedule_date}
                                                    </Moment>
                                                    <p className="px-1"> @ </p>
                                                    {/* {res?.schedule_time} */}
                                                    {moment(
                                                 momentsss(res?.schedule_time).tz(TIMEZONE_URL) ).format("hh:mm A")}
                                                

                                                   {/* <button
                                                    className="flex items-center ml-2 text-green hover:text-green group relative"
                                                    // onClick={() => {
                                                    //   // toggleWidget();
                                                    //   SetMaxDate(
                                                    //  res?.date_range_end
                                                    //   );
                                                    //   setID(res?._id);
                                                    //   setMessage(true);
                                                    // }}
                                                    title="Schedule Dates"
                                                    disabled={
                                                      res?.status == "void"
                                                        ? true
                                                        : false
                                                    }
                                                     onClick={() => {
                                                    // toggleWidget();
                                                    SetMaxDate(
                                                      res?.date_range_end
                                                       );
                                                       setID(res?._id);
                                                       setMessage(true);
                                                  setmatchid(res?._id);
                                                  setCheckCalender(true)
                                                  GetPurposed(
                                                    res.propose_dates.length
                                                  );
                                                  setpopisOpen(
                                                    res?.status == "void"
                                                      ? false
                                                      : true
                                                  );
                                                  getMatchdetails(res?._id);
                                                  gettym(res?.schedule_time);
                                                  if (res?.schedule_time === null) {
                                                    setSelectedTime(
                                                      moment().set({
                                                        hour: 19,
                                                        minute: 0,
                                                      })
                                                    );
                                                  } else {
                                                    setSelectedTime(
                                                      moment(res?.schedule_time)
                                                    );
                                                  }
                                                  getPurposeDate(res?._id);
                                                  setdatefixed(res?.schedule_date);
                                                  gethomeID(res?.home_court?._id);
                                                  setIsHome(res?.home_court);
                                                  setMyhomecourt(res?.home_court);
                                                  gethomecourt(res?.home_court?.name);
                                                  // setChange(res?.schedule_date);
                                                  getuserID(res?.players);
      
                                                  getDateRange(
                                                    new Date(res?.schedule_from_date),
                                                    new Date(res?.schedule_to_date)
                                                  );
      
                                                  getMatchDate(
                                                    new Date(
                                                      res?.schedule_date === null
                                                        ? res?.schedule_from_date
                                                        : res?.schedule_date
                                                    ).getFullYear(),
                                                    new Date(
                                                      res?.schedule_date === null
                                                        ? res?.schedule_from_date
                                                        : res?.schedule_date
                                                    ).getMonth()
                                                  );
                                                  setnewdate(
                                                    new Date(
                                                      res?.propose_dates?.length !== 0 &&
                                                      res?.schedule_date === null ?
                                                      (res?.propose_dates[0]?.date)
                                                      :
                                                      (res?.schedule_date === null
                                                        ? res?.schedule_from_date
                                                          : res?.schedule_date)
      
                                                      // res?.schedule_date === null
                                                      //   ? res?.schedule_from_date
                                                      //   : res?.schedule_date
                                                    )
                                                  );
                                                  setschedhule(res?.schedule_date);
                                                  // getdatefunctio(new Date(res?.metch_date).getFullYear())
                                                  // setChange(new Date(res?.schedule_from_date));
                                                }}
                                                  >
                                                  <FaCheck />1
                                                <span className="absolute z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">Set Date</span>                                                
                                                  </button> */}



                                                   <button
                                                    // onClick={() => {
                                                    
                                                    // }}
                                                    className="flex ml-2 items-center cursor-pointer hover:text-green group relative "
                                                    onClick={() => {
                                                      setMessage(true);
                                                      setCheckCalender(false)
                                                      SetMaxDate(
                                                        res?.date_range_end
                                                         );
                                                      // toggleWidget(true);
                                                      setID(res?._id);
                                                      setmatchid(res?._id);
                                                      GetPurposed(
                                                        res.propose_dates.length
                                                      );
                                                      setpopisOpen(
                                                        res?.status == "void"
                                                          ? false
                                                          : true
                                                      );
                                                      getMatchdetails(res?._id);
                                                      gettym(res?.schedule_time);
                                                      // if (res?.schedule_time === null) {
                                                        setSelectedTime(
                                                          moment().set({
                                                            hour: 19,
                                                            minute: 0,
                                                          }).tz(TIMEZONE_URL)
                                                        )
                                                      // } else {
                                                      //   setSelectedTime(
                                                      //     moment(res?.schedule_time)
                                                      //   );
                                                      // }
                                                      getPurposeDate(res?._id);
                                                      setdatefixed(res?.schedule_date);
                                                      gethomeID(res?.home_court?._id);
                                                      setIsHome(res?.home_court);
                                                      setMyhomecourt(res?.home_court);
                                                      gethomecourt(res?.home_court?.name);
                                                      // setChange(res?.schedule_date);
                                                      getuserID(res?.players);
          
                                                      getDateRange(
                                                        new Date(res?.schedule_from_date),
                                                        new Date(res?.schedule_to_date)
                                                      );
                                                  
          
                                                      getMatchDate(
                                                        new Date(
                                                          res?.schedule_date === null
                                                            ? res?.schedule_from_date
                                                            : res?.schedule_date
                                                        ).getFullYear(),
                                                        res?.schedule_date == null ?
    
                                                        new Date(
                                                          res?.schedule_from_date
                                                        ).getMonth() < 
                                                        new Date(
                                                        ).getMonth()
                                                        && 
                                                        new Date(
                                                          res?.schedule_from_date
                                                        ).getFullYear() ==
                                                        new Date(
                                                        ).getFullYear()
                                                        ?
                                                        new Date(
                                                          ).getMonth()+1  
                                                        :
                                                        new Date(
                                                          res?.schedule_from_date
                                                        ).getMonth()+1
                                                        : 
                                                        new Date(
                                                            res?.schedule_date
                                                        ).getMonth()+1
                                                      );
                                                      setplayername(res?.opponent)
                                                      setnewdate(
                                                        new Date(
                                                          res?.propose_dates?.length !== 0 &&
                                                          res?.schedule_date === null ?
                                                          (res?.propose_dates[0]?.date)
                                                          :
                                                          (res?.schedule_date === null
                                                            ? res?.schedule_from_date
                                                              : res?.schedule_date)
          
                                                          // res?.schedule_date === null
                                                          //   ? res?.schedule_from_date
                                                          //   : res?.schedule_date
                                                        )
                                                      );
                                                      // setschedhule(res?.schedule_date);
                                                      // getdatefunctio(new Date(res?.metch_date).getFullYear())
                                                      // setChange(new Date(res?.schedule_from_date));
                                                    }}
                                                    key={index}
                                                    
                                                  >
                                                <SlCalender/>
                                                <span className="absolute z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">{  res.propose_dates.length != 0 || res?.schedule_date != null  ? "Propose New Dates":"Propose Dates"  }</span>                                                
                                                </button>
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      {/* {res?.schedule_date != null ? (
                                        <button
                                          onClick={() => {
                                            navigate(`/message/${res?._id}`);
                                            getGroupOfUser("");
                                            getcurrentID(res?._id);
                                            setSelectedChat(null);
                                          }}
                                          className="cursor-pointer md:ml-3"
                                          disabled={
                                            res?.status == "void" ? true : false
                                          }
                                        >
                                          <FaFacebookMessenger />
                                        </button>
                                      ) : res?.is_score_update === true ? (
                                        ""
                                      ) : res.propose_dates.length != 0 ? (
                                        <button
                                          onClick={() => {
                                            navigate(`/message/${res?._id}`);
                                            getGroupOfUser("");
                                            getcurrentID(res?._id);
                                            setSelectedChat(null);
                                          }}
                                          className="cursor-pointer md:ml-3"
                                          disabled={
                                            res?.status == "void" ? true : false
                                          }
                                        >
                                          <FaFacebookMessenger />
                                        </button>
                                      ) : null} */}
                                    </div>
                                  </td>
                                )}

                                <td
                                  title="View Map"
                                  onClick={() => {
                                    setmapshow(true);
                                    getlocation(
                                      res?.home_court?.latitude,
                                      res?.home_court?.longitude
                                    );
                                  }}
                                  className="py-2 hover:text-green cursor-pointer"
                                >
                                  {leaguetype != "singles" &&  res?.home_court?.displayName?.length >= 20 ? (
                                    <div className="group text-left relative flex ">
                                      {`${res?.home_court?.displayName}`
                                        .substring(0, 20)
                                        .concat("...") ?? "N/A"}
                                      <span className="absolute z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">
                                        {" "}
                                        {res?.home_court?.displayName}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="text-left ">
                                      {res?.home_court?.displayName ?? "N/A"}
                                    </div>
                                  )}
                                </td>

                                <td className=" py-2 text-center">
                                  <>
                                    {res?.status == "void" ? (
                                      <span className="font-medium text-sm text-[#fff]  border-2 border-[red]   2xl:px-9 px-5 py-1 md:w-auto  rounded-lg ">
                                        Cancelled
                                      </span>
                                    ) : (
                                      <>
                                        {res?.is_score_update === false ? (
                                          <>
                                            {res?.schedule_date != null ? (
                                              <>
                                                {moment(
                                                  new Date(res?.schedule_date),
                                                  "DD/MM/YYYY"
                                                ).format("M/DD/YYYY") ===
                                                  moment(
                                                    new Date(),
                                                    "DD/MM/YYYY"
                                                  ).format("M/DD/YYYY") ||
                                                moment(
                                                  new Date(),
                                                  "DD/MM/YYYY"
                                                ).format("M/DD/YYYY") >=
                                                  moment(
                                                    new Date(
                                                      res?.schedule_date
                                                    ),
                                                    "DD/MM/YYYY"
                                                  ).format("M/DD/YYYY") ? (
                                                  <button
                                                    className="font-medium text-sm text-green  border-2 border-green  2xl:px-9 px-5 py-1 md:w-auto  rounded-lg "
                                                    type="button"
                                                    onClick={() => {
                                                      setnewpopup(true);
                                                      opentable(false);
                                                      setIsHome(
                                                        res?.home_court
                                                      );
                                                      setMyhomecourt(
                                                        res?.home_court
                                                      );
                                                      setopenforfiet(false);
                                                      setopenwithdraw(false);
                                                      getMatchdetails(res?._id);
                                                      setclearvalue();
                                                    }}
                                                  >
                                                    Click
                                                  </button>
                                                ) : (
                                                  <>
                                                    <button
                                                      className="font-medium text-sm text-green  border-2 border-green  2xl:px-9 px-5 py-1 md:w-auto  rounded-lg "
                                                      type="button"
                                                      onClick={() => {
                                                        setnewpopup(true);
                                                        opentable(false);
                                                        setIsHome(
                                                          res?.home_court?._id
                                                        );
                                                        setopenforfiet(false);
                                                        setopenwithdraw(false);
                                                        getMatchdetails(
                                                          res?._id
                                                        );
                                                        setMyhomecourt(
                                                          res?.home_court
                                                        );
                                                        setclearvalue();
                                                        // setforfietvalue("Forfieted");
                                                      }}
                                                    >
                                                      Click
                                                    </button>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <button
                                                  className="font-medium text-sm text-green  border-2 border-green  2xl:px-9 px-5 py-1 md:w-auto  rounded-lg "
                                                  type="button"
                                                  onClick={() => {
                                                    setnewpopup(true);
                                                    opentable(false);
                                                    setIsHome(
                                                      res?.home_court?._id
                                                    );
                                                    setopenforfiet(false);
                                                    setMyhomecourt(
                                                      res?.home_court
                                                    );
                                                    setopenwithdraw(false);
                                                    getMatchdetails(res?._id);
                                                    setclearvalue();
                                                    // setforfietvalue("Forfieted");
                                                  }}
                                                >
                                                  Click
                                                </button>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <p className="text-green whitespace-nowrap text md:text-sm text-xs">
                                              {res?.result === "score" ? (
                                                <>
                                                  <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                                    {
                                                      res?.team_first_score
                                                        ?.set_one
                                                    }
                                                    -
                                                    {
                                                      res?.team_second_score
                                                        ?.set_one
                                                    }
                                                  </span>
                                                  <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                                    {
                                                      res?.team_first_score
                                                        ?.set_two
                                                    }
                                                    -
                                                    {
                                                      res?.team_second_score
                                                        ?.set_two
                                                    }
                                                  </span>
                                                  {res?.team_first_score
                                                    ?.set_three ||
                                                  res?.team_second_score
                                                    ?.set_three != null ? (
                                                    <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                                      {
                                                        res?.team_first_score
                                                          ?.set_three
                                                      }
                                                      -
                                                      {
                                                        res?.team_second_score
                                                          ?.set_three
                                                      }
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              ) : (
                                                <span className="md:text-sm capitalize">
                                                  {res?.result ===
                                                  "Forfieted" ? (
                                                    <span>
                                                      Forfeited By{" "}
                                                      {res?.forfiet_by}
                                                    </span>
                                                  ) : (
                                                    <span>
                                                      Withdrew By{" "}
                                                      {res?.Withdraw_by}
                                                    </span>
                                                  )}
                                                </span>
                                              )}
                                            </p>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                </td>
                              </tr>
                            </>
                          ))}

                          {/* {tiematch && tiematch.length > 0 && (
                            <tr className="hover:bg-[#39fd13] hover:bg-opacity-5 text-center">
                              <td
                                colSpan={5}
                                className="px-5 py-3 text-xl md:text-xl capitalize text-green"
                              >
                                Tie Breaker
                              </td>
                            </tr>
                          )} */}

                          {/* {tiematch &&
                            tiematch.map((res, index) => (
                              <tr
                                key={index}
                                className="hover:bg-[#39fd13] hover:bg-opacity-5 "
                              >
                                <td className=" px-5 py-3">
                                  {res?.opponent.map((playername, index) => (
                                    <span
                                      title="View Profile"
                                      key={index}
                                      onClick={(e) => {
                                        RedirectChanged(`/mymatch/${id}`, true);
                                        setRoute(result);
                                        navigate(
                                          `/matchhistory/${playername?._id}/${id}/${leaguesiddata}/${playername?.firstName}`
                                        );
                                      }}
                                      className="capitalize cursor-pointer  hover:text-green"
                                    >
                                      {(index ? ", " : "") +
                                        playername?.firstName}{" "}
                                      {playername?.lastName}
                                    </span>
                                  ))}
                                
                                </td>
                              
                                {leaguetype != "singles" ? (
                                  <td>
                                    <span
                                      title="View Profile"
                                      className="capitalize cursor-pointer  hover:text-green"
                                      onClick={(e) => {
                                        navigate(
                                          `/matchhistory/${res?.mate?._id}/${id}/${leaguesiddata}/${res?.mate?.firstName}`
                                        );
                                      }}
                                    >
                                      {res?.mate?.firstName}{" "}
                                      {res?.mate?.lastName}
                                    </span>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {res?.is_score_update === true ? (
                                  <td className="flex whitespace-nowrap py-3 justify-center">
                                    <div className="flex items-center ">
                                      <div className="avatar">
                                        <div className="flex cursor-pointer items-center ">
                                          {res?.schedule_date === null ? (
                                            <span
                                              onClick={() => {
                                                setDefaultMessageSent();
                                                SetMaxDate(res?.date_range_end);

                                                dropMessages();
                                              }}
                                              title="Proposed Dates"
                                            >
                                              {res?.propose_dates.length !=
                                              0 ? (
                                                res?.propose_dates.map(
                                                  (purpose, index) => (
                                                    <span
                                                      onClick={() => {
                                                        setMessage(true);
                                                        setID(res?._id);
                                                      }}
                                                      key={index}
                                                      title="Proposed Dates"
                                                    >
                                                      {(index ? `/` : "") + " "}{" "}
                                                      {
                                                        <Moment
                                                          format={
                                                            moment(
                                                              purpose?.date
                                                            )
                                                              .format("MMM D")
                                                              .includes("May")
                                                              ? "MMM DD"
                                                              : "MMM. DD"
                                                          }
                                                        >
                                                          {purpose?.date}
                                                        </Moment>
                                                      }
                                                    </span>
                                                  )
                                                )
                                              ) : (
                                                <>
                                                  {
                                                    <Moment
                                                      format={
                                                        moment(
                                                          res?.schedule_from_date
                                                        )
                                                          .format("MMM D")
                                                          .includes("May")
                                                          ? "MMM DD"
                                                          : "MMM. DD"
                                                      }
                                                    >
                                                      {res?.schedule_from_date}
                                                    </Moment>
                                                  }{" "}
                                                  -{" "}
                                                  {
                                                    <Moment
                                                      format={
                                                        moment(
                                                          res?.schedule_to_date
                                                        )
                                                          .format("MMM D")
                                                          .includes("May")
                                                          ? "MMM DD"
                                                          : "MMM. DD"
                                                      }
                                                    >
                                                      {res?.schedule_to_date}
                                                    </Moment>
                                                  }
                                                </>
                                              )}
                                            </span>
                                          ) : (
                                            <>
                                              <span
                                                className="flex"
                                                title="Schedule Dates"
                                              >
                                                <Moment
                                                  format={
                                                    moment(res?.schedule_date)
                                                      .format("MMM D")
                                                      .includes("May")
                                                      ? "MMM DD"
                                                      : "MMM. DD"
                                                  }
                                                >
                                                  {res?.schedule_date}
                                                </Moment>
                                                <p className="px-1"> @ </p>
                                                {moment(
                                                  res?.schedule_time
                                                ).format("hh:mm A")}
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </div>

                                      {res?.schedule_date != null ? (
                                        ""
                                      ) : res?.is_score_update === true ? (
                                        ""
                                      ) : (
                                        <div className="cursor-pointer md:ml-3">
                                          <FaFacebookMessenger />
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                ) : (
                                  <td className="flex py-3 md:text-sm text-xs md:px-1 px-1 whitespace-nowrap justify-center">
                                    <div className="flex items-center ">
                                      <div className="avatar">
                                        <div
                                          onClick={() => {
                                            setmatchid(res?._id);
                                            GetPurposed(
                                              res.propose_dates.length
                                            );
                                            setpopisOpen(true);
                                            getMatchdetails(res?._id);
                                            gettym(res?.schedule_time);
                                            if (res?.schedule_time === null) {
                                              setSelectedTime(null);
                                            } else {
                                              setSelectedTime(
                                                moment(res?.schedule_time)
                                              );
                                            }
                                            getPurposeDate(res?._id);
                                            setdatefixed(res?.schedule_date);
                                            gethomeID(res?.home_court?._id);
                                            setIsHome(res?.home_court);
                                            setMyhomecourt(res?.home_court);
                                            gethomecourt(res?.home_court?.name);
                                            // setChange(res?.schedule_date);
                                            getuserID(res?.players);

                                            getDateRange(
                                              new Date(res?.schedule_from_date),
                                              new Date(res?.schedule_to_date)
                                            );

                                            getMatchDate(
                                              new Date(
                                                res?.schedule_date === null
                                                  ? res?.schedule_from_date
                                                  : res?.schedule_date
                                              ).getFullYear(),
                                              new Date(
                                                res?.schedule_date === null
                                                  ? res?.schedule_from_date
                                                  : res?.schedule_date
                                              ).getMonth()
                                            );
                                            setnewdate(
                                              new Date(
                                                res?.schedule_date === null
                                                  ? res?.schedule_from_date
                                                  : res?.schedule_date
                                              )
                                            );
                                            setschedhule(res?.schedule_date);
                                            // getdatefunctio(new Date(res?.metch_date).getFullYear())
                                            // setChange(new Date(res?.schedule_from_date));
                                          }}
                                          className="flex cursor-pointer  hover:text-green items-center "
                                        >
                                          {res?.propose_dates.length != 0 &&
                                          res?.schedule_date === null ? (
                                            <>
                                              {res?.propose_dates.map(
                                                (purpose, index) => (
                                                  <span
                                                    onClick={() => {
                                                      setMessage(true);
                                                      // toggleWidget(true);
                                                      setID(res?._id);
                                                    }}
                                                    key={index}
                                                    title="Proposed Dates"
                                                  >
                                                    {(index ? `/` : "") + " "}{" "}
                                                    {
                                                      <Moment
                                                        format={
                                                          moment(purpose?.date)
                                                            .format("MMM D")
                                                            .includes("May")
                                                            ? "MMM DD"
                                                            : "MMM. DD"
                                                        }
                                                      >
                                                        {purpose?.date}
                                                      </Moment>
                                                    }
                                                  </span>
                                                )
                                              )}{" "}
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              {res?.schedule_date === null &&
                                              res?.propose_dates.length ===
                                                0 ? (
                                                <span
                                                  className="flex items-center"
                                                  onClick={() => {
                                                    setMessage(true);
                                                    SetMaxDate(
                                                      res?.date_range_end
                                                    );
                                                    dropMessages();
                                                    setDefaultMessageSent(
                                                      false
                                                    );
                                                    // dropMessages();
                                                  }}
                                                  title="Proposed Dates"
                                                >
                                                  <Moment
                                                    format={
                                                      moment(
                                                        res?.schedule_from_date
                                                      )
                                                        .format("MMM D")
                                                        .includes("May")
                                                        ? "MMM DD"
                                                        : "MMM. DD"
                                                    }
                                                  >
                                                    {res?.schedule_from_date}
                                                  </Moment>
                                                  <p className="px-1"> - </p>
                                                  <Moment
                                                    format={
                                                      moment(
                                                        res?.schedule_to_date
                                                      )
                                                        .format("MMM D")
                                                        .includes("May")
                                                        ? "MMM DD"
                                                        : "MMM. DD"
                                                    }
                                                  >
                                                    {res?.schedule_to_date}
                                                  </Moment>
                                                </span>
                                              ) : (
                                                <>
                                                  <span
                                                    className="flex"
                                                    onClick={() => {
                                                      // toggleWidget();
                                                      setID(res?._id);
                                                      setMessage(true);
                                                    }}
                                                    title="Schedule Dates"
                                                  >
                                                    {" "}
                                                    <Moment
                                                      format={
                                                        moment(
                                                          res?.schedule_date
                                                        )
                                                          .format("MMM D")
                                                          .includes("May")
                                                          ? "MMM DD"
                                                          : "MMM. DD"
                                                      }
                                                    >
                                                      {res?.schedule_date}
                                                    </Moment>
                                                    <p className="px-1"> @ </p>
                                                    {moment(
                                                      new Date(
                                                        res?.schedule_time
                                                      )
                                                    ).format("hh:mm A")}
                                                  </span>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      {res?.schedule_date != null ? (
                                        <div
                                          onClick={() => {
                                            navigate(`/message/${res?._id}`);
                                            getGroupOfUser("");
                                            getcurrentID(res?._id);
                                            setSelectedChat(null);
                                          }}
                                          className="cursor-pointer md:ml-3"
                                        >
                                          <FaFacebookMessenger />
                                        </div>
                                      ) : res?.is_score_update === true ? (
                                        ""
                                      ) : res.propose_dates.length != 0 ? (
                                        <div
                                          onClick={() => {
                                            navigate(`/message/${res?._id}`);
                                            getGroupOfUser("");
                                            getcurrentID(res?._id);
                                            setSelectedChat(null);
                                          }}
                                          className="cursor-pointer md:ml-3"
                                        >
                                          <FaFacebookMessenger />
                                        </div>
                                      ) : null}
                                    </div>
                                  </td>
                                )}

                                <td
                                  title="View Map"
                                  onClick={() => {
                                    setmapshow(true);
                                    getlocation(
                                      res?.home_court?.latitude,
                                      res?.home_court?.longitude
                                    );
                                  }}
                                  className="py-3 text-left hover:text-green cursor-pointer"
                                >
                                  {res?.home_court?.name.length >= 25 ? (
                                    <div className="group relative flex justify-center">
                                      {`${res?.home_court?.name}`
                                        .substring(0, 25)
                                        .concat("...") ?? "N/A"}
                                      <span className="absolute bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">
                                        {" "}
                                        {res?.home_court?.name}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="text-center">
                                      {res?.home_court?.name ?? "N/A"}
                                    </div>
                                  )}
                                </td>

                                <td className="flex py-4 justify-center">
                                  <>
                                    {res?.is_score_update === false ? (
                                      <>
                                        {res?.schedule_date != null ? (
                                          <>
                                            {moment(
                                              new Date(res?.schedule_date),
                                              "DD/MM/YYYY"
                                            ).format("M/DD/YYYY") ===
                                              moment(
                                                new Date(),
                                                "DD/MM/YYYY"
                                              ).format("M/DD/YYYY") ||
                                            moment(
                                              new Date(),
                                              "DD/MM/YYYY"
                                            ).format("M/DD/YYYY") >=
                                              moment(
                                                new Date(res?.schedule_date),
                                                "DD/MM/YYYY"
                                              ).format("M/DD/YYYY") ? (
                                              <button
                                                className="font-medium text-sm text-green  border-2 border-green  2xl:px-9 px-5 py-1 md:w-auto  rounded-lg "
                                                type="button"
                                                onClick={() => {
                                                  setnewpopup(true);
                                                  opentable(false);
                                                  setIsHome(res?.home_court);
                                                  setMyhomecourt(
                                                    res?.home_court
                                                  );
                                                  setopenforfiet(false);
                                                  setopenwithdraw(false);
                                                  getMatchdetails(res?._id);
                                                  setclearvalue();
                                                }}
                                              >
                                                Click
                                              </button>
                                            ) : (
                                              <>
                                                <button
                                                  className="font-medium text-sm text-green  border-2 border-green  2xl:px-9 px-5 py-1 md:w-auto  rounded-lg "
                                                  type="button"
                                                  onClick={() => {
                                                    setnewpopup(true);
                                                    opentable(false);
                                                    setIsHome(
                                                      res?.home_court?._id
                                                    );
                                                    setopenforfiet(true);
                                                    setopenwithdraw(false);
                                                    getMatchdetails(res?._id);
                                                    setMyhomecourt(
                                                      res?.home_court
                                                    );
                                                    setclearvalue();
                                                    // setforfietvalue("Forfieted");
                                                  }}
                                                >
                                                  Click
                                                </button>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              className="font-medium text-sm text-green  border-2 border-green  2xl:px-9 px-5 py-1 md:w-auto  rounded-lg "
                                              type="button"
                                              onClick={() => {
                                                setnewpopup(true);
                                                opentable(false);
                                                setIsHome(res?.home_court?._id);
                                                setopenforfiet(true);
                                                setMyhomecourt(res?.home_court);
                                                setopenwithdraw(false);
                                                getMatchdetails(res?._id);
                                                setclearvalue();
                                                // setforfietvalue("Forfieted");
                                              }}
                                            >
                                              Click
                                            </button>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <p className="text-green whitespace-nowrap text md:text-sm text-xs">
                                          {res?.result === "score" ? (
                                            <>
                                              <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                                {res?.team_first_score?.set_one}
                                                -
                                                {
                                                  res?.team_second_score
                                                    ?.set_one
                                                }
                                              </span>
                                              <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                                {res?.team_first_score?.set_two}
                                                -
                                                {
                                                  res?.team_second_score
                                                    ?.set_two
                                                }
                                              </span>
                                              {res?.team_first_score
                                                ?.set_three ||
                                              res?.team_second_score
                                                ?.set_three != null ? (
                                                <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                                  {
                                                    res?.team_first_score
                                                      ?.set_three
                                                  }
                                                  -
                                                  {
                                                    res?.team_second_score
                                                      ?.set_three
                                                  }
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            <span className="md:text-sm capitalize">
                                              {res?.result === "Forfieted" ? (
                                                <span>
                                                  Forfeited By {res?.forfiet_by}
                                                </span>
                                              ) : (
                                                <span>
                                                  Withdrew By {res?.Withdraw_by}
                                                </span>
                                              )}
                                            </span>
                                          )}
                                        </p>
                                      </>
                                    )}
                                  </>
                                </td>
                              </tr>
                            ))} */}

                          {/* <tr>
                             <td className="py-5">Raj</td>
                             <td className="flex justify-center">
                               <div className="flex items-center ">
                                 <div className="avatar">
                                   <div
                                     onClick={() => {
                                       setpopisOpen(true);
                                     }}
                                     className="flex cursor-pointer items-center "
                                   >
                                     Jan, 6 - Jan, 31
                                   </div>
                                 </div>
                               </div>
                             </td>
                             <td>
                               <div
                                 className="cursor-pointer"
                                 onClick={() => {
                                   setmapshow(true);
                                 }}
                               >
                                 Atlanta
                               </div>
                             </td>
                             <td className="flex justify-center">
                               <button
                                 className="font-medium lg:text-[20px] text-lg text-green  border-2 border-green  md:px-16 px-5 md:w-auto  py-2 rounded-lg "
                                 type="button"
                                 onClick={() => {
                                   setnewpopup(true);
                                   opentable(false);
                                 }}
                               >
                                 Click
                               </button>
                             </td>
                           </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="lg:w-1/3 w-full py-11 flex flex-col">
                  <p className=" text-center text-[#fff] text-xl md:text-xl capitalize">
                    Division Standings
                    <br />
                  </p>
                  <div
                    id="style-1"
                    className=" bg-[#6C6C6C] flex-grow flex-shrink-0  border-2 border-[#fff] bg-opacity-40 rounded-lg my-5 min-h-[450px] overflow-auto"
                  >
                    <div id="partner">
                      <table className="w-full text-[#fff]" id="partnertable">
                        <thead>
                          <tr className="bg-green sticky top-0 ">
                            {leaguetype == "singles" ? (
                              <th className="md:py-3 text-[black] text-left px-3 ">
                                Player
                              </th>
                            ) : (
                              <th className="md:py-3 text-[black] text-left px-3 ">Team</th>
                            )}
                            <th className="text-[black] px-3">Win</th>
                            <th className="text-[black] px-3">Loss</th>
                            {/* <th className="px-3">Points</th> */}
                            {/* <th>Ranking</th> */}
                          </tr>
                        </thead>
                        <tbody className="text-xs lg:text-sm">
                          {standing.map((res, index) => (
                            <tr
                              key={index}
                              className="hover:bg-[#39fd13] hover:bg-opacity-5"
                            >
                              <td className="text-sm px-3 py-[.70rem] ">
                                {/* {res?.firstName} {res?.lastName} */}
                                {res?.names?.length === 0 ? (
                                  <span>-</span>
                                ) : (
                                  res?.names?.map((name, index) => (
                                    <span
                                    title="View Match History"
                                    onClick={(e) => {
                                      // RedirectChanged(`/mymatch/${id}`, true);
                                      setRoute(result);
                                      navigate(
                                        `/matchhistory/${name?._id}/${id}/${leaguesiddata}/${name?.firstName + " " +  name?.lastName}/${leaguetype}`
                                      );
                                    }}
                                    >
                                    <span
                                     
                                     
                                      className="capitalize cursor-pointer  hover:text-green"
                                    >
                                       </span>
                                     <span className="" > {(index ? " & " : "") }</span>
                                      <span key={index}  className="capitalize cursor-pointer  hover:text-green" >
                                        <>
                                          {
                                            name?.firstName +
                                            " " +
                                            name?.lastName}
                                        </>
                                      </span>
                                   
                                    </span>
                                  ))
                                )}
                              </td>
                              <td className="py-[.70rem] text-base text-center">
                                <p>{res?.win}</p>
                              </td>
                              <td className=" py-[.70rem] text-base text-center">
                                <p>{res?.loss}</p>
                              </td>
                              {/* <td className=" py-[.65rem] text-base text-center">
                          <p>{res?.total}</p>
                        </td> */}
                            </tr>
                          ))}
                          {/* {nolist && (
                               <tr>
                                 <td colSpan={6} className="py-20 ">
                                   <p className=" text-center font-semibold text-xl">
                                     No Matches Found
                                   </p>
                                 </td>
                               </tr>
                             )} */}
                          {/* <tr>
                             <td className="py-5">Raj</td>
                             <td className="flex justify-center">
                               <div className="flex items-center ">
                                 <div className="avatar">
                                   <div
                                     onClick={() => {
                                       setpopisOpen(true);
                                     }}
                                     className="flex cursor-pointer items-center "
                                   >
                                     Jan, 6 - Jan, 31
                                   </div>
                                 </div>
                               </div>
                             </td>
                             <td>
                               <div
                                 className="cursor-pointer"
                                 onClick={() => {
                                   setmapshow(true);
                                 }}
                               >
                                 Atlanta
                               </div>
                             </td>
                             <td className="flex justify-center">
                               <button
                                 className="font-medium lg:text-[20px] text-lg text-green  border-2 border-green  md:px-16 px-5 md:w-auto  py-2 rounded-lg "
                                 type="button"
                                 onClick={() => {
                                   setnewpopup(true);
                                   opentable(false);
                                 }}
                               >
                                 Click
                               </button>
                             </td>
                           </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="xl:px-35 lg:px-25 md:px-30 flex flex-col   lg:flex-row lg:space-x-5 px-4 justify-center">
                <div className=" w-full py-11">
                  <p className=" text-center text-[#fff] text-xl md:text-xl capitalize">
                    Match Schedule
                    <br />
                  </p>
                  <div
                    id="style-1"
                    className=" bg-[#6C6C6C] border-2 border-[#fff] bg-opacity-40 rounded-lg my-5 max-h-[450px] overflow-auto"
                  >
                    <div id="partner">
                      <table className="w-full whitespace-nowrap text-[#fff]" id="partnertable">
                        <thead>
                          <tr className="bg-green sticky top-0   ">
                            <th className="md:py-3 text-[black] text-left px-5">Opponent</th>
                            {/* {leaguetype != "singles" ? <th className="text-[black]" >Partner</th> : ""} */}
                            <th className="md:px-0 text-[black] px-5" >Match Period</th>
                            <th className="md:px-0 text-[black] px-5" >Match Date</th>
                            <th className="text-left text-[black] md:px-0 px-5 ">Home Court</th>
                            <th className="text-left text-[black] md:px-0 px-5 "  >Result</th>
                            {/* <th>Ranking</th> */}
                          </tr>
                        </thead>
                        <tbody className="text-xs lg:text-sm">
                          <tr>
                            <td colSpan={6} className="py-20 ">
                              <p className=" text-center font-semibold text-xl">
                                Schedule Has Not Been Released Yet.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : Champions === true ? (
            <div className="xl:px-35 lg:px-25 md:px-30 flex flex-col lg:flex-row lg:space-x-5 px-4 justify-center">
              <div className=" w-full">
                <PlayersTree Heading={leaguesiddata} />
              </div>
            </div>
          ) : null}
          {/* {Object.keys(playoffObj).map((key) => ( */}
      
           
              
              {Champions !== true ? (
              
                
                
                
           
                  <>
                  
                   
                  
              {/* {Object.keys(playoffObj).map((key) => (  */}
               
              {listplay  && 
                <div className="xl:px-35 lg:px-25 md:px-30 flex flex-col  lg:flex-row lg:space-x-5 px-4 justify-center">
                    <div className=" w-full py-11 flex flex-col">
                      <p className=" text-center text-[#fff] text-xl md:text-xl capitalize">
                        {/* {key} */}
                        Playoff
                      </p>
        
                      <div
                        id="style-1"
                        className=" bg-[#6C6C6C] whitespace-nowrap flex-grow flex-shrink-0 border-2 border-[#fff] bg-opacity-40 rounded-lg my-5 max-h-[450px] overflow-auto"
                      >
                        <div id="partner">
                          <table
                            className="w-full text-[#fff]"
                            id="partnertable"
                            
                          >
                            <thead>
                              <tr className="bg-green sticky top-0 ">
                              
                                <th className="md:py-3 text-[black] text-left px-5">
                                  Opponent
                                </th>
                                {/* {leaguetype != "singles" ? (
                                  <th className="text-left text-[black] ">Partner</th>
                                ) : (
                                  ""
                                )} */}
                                <th className="text-left text-[black] ">Match Period</th>
                                <th className="text-left md:px-0 text-[black] px-5 ">Match Date</th>
                                <th className="text-left text-[black] ">Home Court</th>
                                <th className="text-left text-[black] md:px-0 px-3 ">Result</th>
                                <th className="md:py-3 text-[black] text-left px-5">
                                  Round
                                </th>
                                {/* <th>Ranking</th> */}
                              </tr>
                            </thead>
                            <tbody className=" text-xs lg:text-sm">
                            {Object.keys(playoffObj).map((key,index) => (
                             < React.Fragment >
                             {/* <tr className="hover:bg-[#39fd13] hover:bg-opacity-5 text-center">
                              <td
                                colSpan={6}
                                className="px-5 py-3 text-xl md:text-xl capitalize text-[#000] bg-green"
                              >
                                {key}
                              </td>
                            </tr> */}
                             
                            {  playoffObj[key] === "BYE" ? (
                              <tr>
                               
                                <td className="text-sm px-5 py-3 ">
                                  {/* <p className=" text-center font-semibold text-xl">
                                    <span className="text-green">
                                      Congratulations
                                    </span>
                                    , You Are Qualified For Next Round Using{" "} */}
                                    <span className="text-green">Bye</span>
                                  {/* </p> */}
                                </td>
                                {/* {leaguetype != "singles" ? (
                                  <td className="text-sm  py-3 text-left ">-</td>
                                ) : (
                                  ""
                                )} */}
                                <td className="text-sm px-5 py-3  ">-</td>
                                <td className="text-sm px-5 py-3  ">-</td>
                                <td className="text-sm px-5 py-3 ">-</td>
                                <td className="text-sm  py-3  text-green">Won By Bye</td>
                                <td className="text-sm px-5 py-3 capitalize">{key}</td>
                             
                              </tr>
                              )
                              :
                              playoffObj[key]?.opponent !== undefined ? (
                                <tr className="hover:bg-[#39fd13] hover:bg-opacity-5 ">
                                 

                                <td className="text-sm px-5 py-3">
                               <div className="flex items-center" >
                                <span>
                                {playoffObj[key]?.opponent?.map(
                                      (playername, index) => (
                                        <span
                                        title="View Match History"
                                        onClick={(e) => {
                                          RedirectChanged(
                                            `/mymatch/${id}`,
                                            true
                                          );
                                          setRoutes("");
                                          setRoute(result);
                                          navigate(
                                            `/playerdetail/${playername?._id} `
                                          );
                                        }}
                                        >
                                        <span
                                        
                                          key={index}
                                       
                                          className="capitalize cursor-pointer  hover:text-green"
                                        >
                                          </span>
                                          <span className="" > {(index ? " & " : "") }</span>
                                          <span  key={index}  className="capitalize cursor-pointer  hover:text-green">
                                          {
                                            playername?.firstName}{" "}
                                          {playername?.lastName}
                                          </span>
                                        
                                        </span>
                                      )
                                    )}
                                </span>
                              
                                    <span className="flex justify-center">
                                    {!playoffObj[key]?.is_score_update === true && 
                                    playoffObj[key]?.schedule_date !=
                                      null ? (
                                        <div
                                          onClick={() => {
                                            navigate(
                                              `/message/${playoffObj[key]?._id}`
                                            );
                                            getGroupOfUser("");
                                            getcurrentID(playoffObj[key]?._id);
                                            setSelectedChat(null);
                                          }}
                                          className="cursor-pointer text-green md:ml-3 group relative "
                                        >
                                          <FaFacebookMessenger />
                                          <span className="absolute z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">Chat</span>                                                
                                        </div>
                                      ) : playoffObj[key]?.is_score_update ===
                                        true ? (
                                        ""
                                      ) : playoffObj[key].propose_dates
                                          ?.length != 0 ? (
                                        <div
                                          onClick={() => {
                                            navigate(
                                              `/message/${playoffObj[key]?._id}`
                                            );
                                            getGroupOfUser("");
                                            getcurrentID(playoffObj[key]?._id);
                                            setSelectedChat(null);
                                          }}
                                          className="cursor-pointer text-green md:ml-3 group relative "
                                        >
                                          <FaFacebookMessenger />
                                          <span className="absolute z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">Chat</span>                                                
                                        </div>
                                      ) : null
                                        }
                                    </span>
                               </div>
                                   
                                    
                                 
                                  
                                
                                
                                  {/* {res.opponent.firstName} {res.opponent.lastName} */}
                                </td>
                                <td className="whitespace-nowrap">
                                <Moment
                                                    format={
                                                      moment(
                                                        playoffObj[key]
                                                          ?.schedule_from_date
                                                      )
                                                        .format("MMM D")
                                                        ?.includes("May")
                                                        ? "MMM DD"
                                                        : "MMM. DD"
                                                    }
                                                  >
                                                    {
                                                      playoffObj[key]
                                                        ?.schedule_from_date
                                                    }
                                                  </Moment>
                                                  <span className="px-1"> - </span>
                                                  <Moment
                                                    format={
                                                      moment(
                                                        playoffObj[key]
                                                          ?.schedule_to_date
                                                      )
                                                        .format("MMM D")
                                                        ?.includes("May")
                                                        ? "MMM DD"
                                                        : "MMM. DD"
                                                    }
                                                  >
                                                    {
                                                      playoffObj[key]
                                                        ?.schedule_to_date
                                                    }
                                                  </Moment>
                                </td>
                             
                                {/* {playername?._id != user?._id ? <>{playername.firstName} {playername.lastName},</>  :"" }  */}
                                {/* {leaguetype != "singles" ? (
                                  <td>
                                    <span
                                      title="View Match History"
                                      className="capitalize cursor-pointer  hover:text-green"
                                      onClick={(e) => {
                                        setRoute(result);
                                        navigate(
                                          `/matchhistory/${playoffObj[key]?.mate?._id}/${id}/${leaguesiddata}/${playoffObj[key]?.mate?.firstName + " " + playoffObj[key]?.mate?.lastName}/${leaguetype}`
                                        );
                                      }}
                                    >
                                      {playoffObj[key]?.mate?.firstName}{" "}
                                      {playoffObj[key]?.mate?.lastName}
                                    </span>
                                  </td>
                                ) : (
                                  ""
                                )} */}
                                

                                {playoffObj[key]?.is_score_update === true ? (
                                  <td className=" py-3 md:px-0 px-5  ">
                                    <div className=" items-left ">
                                      <div className="avatar">
                                        <div  className="flex cursor-pointer items-left ">
                                          {playoffObj[key]?.schedule_date ===
                                          null ? (
                                            <span
                                              onClick={() => {
                                                setDefaultMessageSent();
                                         

                                                dropMessages();
                                                SetMaxDate(
                                                  playoffObj[key]
                                                    ?.date_range_end
                                                );
                                              }}
                                            >
                                              N/A
                                              {/* {
                                                <Moment
                                                  format={
                                                    moment(
                                                      playoffObj[key]
                                                        ?.schedule_from_date
                                                    )
                                                      .format("MMM D")
                                                      ?.includes("May")
                                                      ? "MMM DD"
                                                      : "MMM. DD"
                                                  }
                                                >
                                                  {
                                                    playoffObj[key]
                                                      ?.schedule_from_date
                                                  }
                                                </Moment>
                                              }{" "}
                                              -{" "}
                                              {
                                                <Moment
                                                  format={
                                                    moment(
                                                      playoffObj[key]
                                                        ?.schedule_to_date
                                                    )
                                                      .format("MMM D")
                                                      ?.includes("May")
                                                      ? "MMM DD"
                                                      : "MMM. DD"
                                                  }
                                                >
                                                  {
                                                    playoffObj[key]
                                                      ?.schedule_to_date
                                                  }
                                                </Moment>
                                              } */}
                                            </span>
                                          ) : (
                                            <>
                                              <span
                                                className="flex"
                                                title="Schedule Dates"
                                                onClick={() => {
                                                
                                                  SetMaxDate(
                                                    playoffObj[key]
                                                      ?.date_range_end
                                                  );
                                                }}
                                                
                                              >
                                                <Moment
                                                  format={
                                                    moment(
                                                      playoffObj[key]
                                                        ?.schedule_date
                                                    )
                                                      .format("MMM D")
                                                      ?.includes("May")
                                                      ? "MMM DD"
                                                      : "MMM. DD"
                                                  }
                                                >
                                                  {
                                                    playoffObj[key]
                                                      ?.schedule_date
                                                  }
                                                </Moment>
                                                <p className="px-1"> @ </p>
                                                {/* { playoffObj[key]?.schedule_time} */}
                                                {moment(
                                                 momentsss(    playoffObj[key]?.schedule_time).tz(TIMEZONE_URL) ).format("hh:mm A")}
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </div>
        
                                      {playoffObj[key]?.schedule_date !=
                                      null ? (
                                        ""
                                      ) : playoffObj[key]?.is_score_update ===
                                        true ? (
                                        ""
                                      ) : (
                                        <div className="cursor-pointer md:ml-3 group relative ">
                                          <FaFacebookMessenger />
                                          <span className="absolute z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">Chat</span>                                                
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                ) : (
                                  <td className=" md:px-0 px-5  py-3">
                                    <div className="flex items-center ">
                                      <div className="avatar">
                                        <div
                                          onClick={() => {
                                            setmatchid(playoffObj[key]?._id);
                                            setTeamId(playoffObj[key]?.my_team_id)
                                            // GetPurposed(
                                            //   playoffObj[key].propose_dates
                                            //     .length
                                            // );
                                            // setpopisOpen(true);
                                            // getMatchdetails(
                                            //   playoffObj[key]?._id
                                            // );
                                            gettym(
                                              playoffObj[key]?.schedule_time
                                            );
                                            setSelectedTime(
                                              moment().set({
                                                hour: 19,
                                                minute: 0,
                                              }).tz(TIMEZONE_URL)
                                            )
                                            // if (
                                            //   playoffObj[key]?.schedule_time ===
                                            //   null
                                            // ) {
                                            //   setSelectedTime(
                                            //     moment().set({
                                            //       hour: 19,
                                            //       minute: 0,
                                            //     })
                                            //   );
                                            // } else {
                                            //   setSelectedTime(
                                            //     moment(
                                            //       playoffObj[key]?.schedule_time
                                            //     )
                                            //   );
                                            // }
                                            // getPurposeDate(
                                            //   playoffObj[key]?._id
                                            // );
                                            setdatefixed(
                                              playoffObj[key]?.schedule_date
                                            );
                                            gethomeID(
                                              playoffObj[key]?.home_court?._id
                                            );
                                            setIsHome(
                                              playoffObj[key]?.home_court
                                            );
                                            setMyhomecourt(
                                              playoffObj[key]?.home_court
                                            );
                                            gethomecourt(
                                              playoffObj[key]?.home_court?.name
                                            );
                                            // setChange(res?.schedule_date);
                                            getuserID(playoffObj[key]?.players);
        
                                            getDateRange(
                                              new Date(
                                                playoffObj[
                                                  key
                                                ]?.schedule_from_date
                                              ),
                                              new Date(
                                                playoffObj[
                                                  key
                                                ]?.schedule_to_date
                                              )
                                            );
                                            // getMatchDate(
                                            //   new Date(
                                            //     playoffObj[
                                            //       key
                                            //     ]?.schedule_date === null
                                            //       ?  playoffObj[
                                            //         key
                                            //       ]?.schedule_from_date
                                            //       :  playoffObj[
                                            //         key
                                            //       ]?.schedule_date
                                            //   ).getFullYear(),
                                            //   playoffObj[
                                            //     key
                                            //   ]?.schedule_date === null?
                                            //   new Date(
                                            //     playoffObj[
                                            //       key
                                            //     ]?.schedule_from_date
                                            //   ).getMonth()-1
                                            //   : 
                                            //   new Date(
                                            //     playoffObj[
                                            //       key
                                            //     ]?.schedule_date
                                            //   ).getMonth()-1
                                            // );
        
                                            // getMatchDate(
                                            //   new Date(
                                            //     playoffObj[key]
                                            //       ?.schedule_date === null
                                            //       ? playoffObj[key]
                                            //           ?.schedule_from_date
                                            //       : playoffObj[key]
                                            //           ?.schedule_date
                                            //   ).getFullYear(),
                                            //   playoffObj[key]
                                            //       ?.schedule_date === null ?
                                            //   new Date(
                                              
                                            //   ).getMonth()+1
                                            //   :
                                            //   new Date(
                                            //     playoffObj[key]
                                            //           ?.schedule_date
                                            //   ).getMonth()+1

                                            // );
                                            setnewdate(
                                              new Date(
                                                playoffObj[key]?.propose_dates?.length !== 0 &&
                                                playoffObj[key]?.schedule_date === null ?
                                                (playoffObj[key]?.propose_dates[0]?.date)
                                                :
                                                (playoffObj[key]?.schedule_date === null
                                                  ? playoffObj[key]?.schedule_from_date
                                                    : playoffObj[key]?.schedule_date)

                                                // res?.schedule_date === null
                                                //   ? res?.schedule_from_date
                                                //   : res?.schedule_date
                                              )
                                            );
                                            setschedhule(
                                              playoffObj[key]?.schedule_date
                                            );
                                            // getdatefunctio(new Date(res?.metch_date).getFullYear())
                                            // setChange(new Date(res?.schedule_from_date));
                                          }}
                                          className="flex "
                                        >
                                          {playoffObj[key]?.propose_dates
                                            ?.length != 0 &&
                                          playoffObj[key]?.schedule_date ===
                                            null ? (
                                            <div className="flex  ">
                                            <span className="flex flex-col">
                                              <span>
                                              {playoffObj[key]?.propose_dates
                                                ?.slice() // Create a copy of the array
                                                .sort(
                                                  (a, b) =>
                                                    new Date(a.date) -
                                                    new Date(b.date)
                                                )
                                                .map((purpose, index) => (
                                                  <span
                                                    className="px-1"
                                                    onClick={() => {
                                                      setMessage(true);
                                                      SetMaxDate(
                                                        playoffObj[key]
                                                          ?.date_range_end
                                                      );
                                                      // toggleWidget(true);
                                                      setID(
                                                        playoffObj[key]?._id
                                                      );
                                                    }}
                                                    key={index}
                                                  >
                                                    {index ? " / " : ""}
                                                    <Moment
                                                      format={
                                                        moment(purpose?.date)
                                                          .format("MMM D")
                                                          ?.includes("May")
                                                          ? "MMM DD"
                                                          : "MMM. DD"
                                                      }
                                                    >
                                                      {purpose?.date}
                                                    </Moment>
                                                  </span>
                                                ))}
                                              </span>
                                              <span className="text-[11px]"> { playoffObj[key]?.propose_dates?.map((time, index)=>(
                                                    <span key={index}>
                                                        {index ? (
                                                        <span
                                                          style={{
                                                            paddingLeft: "5px",
                                                            paddingRight:"5px"
                                                          }}
                                                        >
                                                          /
                                                        </span>
                                                      ) : (
                                                        ""
                                                      )}
                                                       {time?.time}
                                                    </span>
                                                  )
                                                    
                                                  )}   </span> 
                                          
                                                   <span className="text-[9px] text-green">{playoffObj[key]?.my_team_id == playoffObj[key]?.proposedBy ? "Awaiting opponent to accept date": <div className="flex "> Click <FaCheck className="mt-1 mx-1 text-center"/>  to accept a date </div> }</span>                                                
                                            </span>
                                             
                                                 {" "}
                                                 <span className="flex">
                                                  
                                                 {!(playoffObj[key]?.my_team_id == playoffObj[key]?.proposedBy) && 
                                                 <button
                                                    // onClick={() => {
                                                    
                                                    // }}
                                                    className="flex mb-9 items-center ml-1 hover:text-green text-green group relative"
                                                    onClick={() => {
                                                      setplayername(playoffObj[key]?.opponent)
                                                      setMessage(true);
                                                        getPurposeDate(
                                                      playoffObj[key]?._id
                                                           );
                                                           getMatchdetails(
                                                            playoffObj[key]?._id
                                                          );
                                                      setCheckCalender(true)
                                                      SetMaxDate(
                                                        playoffObj[key]?.date_range_end
                                                         );
                                                      // toggleWidget(true);
                                                      setID(playoffObj[key]?._id);
                                                       setpopisOpen(
                                                        playoffObj[key]?.status == "void"
                                                        ? false
                                                        : true
                                          );

                                                   
                                                    }}
                                                    key={index}
                                                  >
                                                <FaCheck/>
                                                <span className="absolute whitespace-nowrap z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">Accept Date & Time</span>                                                
                                                </button>
                                              }
                                              
                                                <button
                                                    // onClick={() => {
                                                    
                                                    // }}
                                                    className="flex ml-2 mb-9 items-center cursor-pointer hover:text-green group relative "
                                                    onClick={() => {
                                                      setMessage(true);
                                                      setCheckCalender(false)
                                                      SetMaxDate(
                                                        playoffObj[key]?.date_range_end
                                                         );
                                                      // toggleWidget(true);
                                                      setID(playoffObj[key]?._id);

                                                      setmatchid(playoffObj[key]?._id);
                                                      GetPurposed(
                                                        playoffObj[key].propose_dates.length
                                                      );
                                                      setpopisOpen(
                                                        playoffObj[key]?.status == "void"
                                                          ? false
                                                          : true
                                                      );
                                                      getMatchdetails(playoffObj[key]?._id);
                                                      gettym(playoffObj[key]?.schedule_time);
                                                      // if (playoffObj[key]?.schedule_time === null) {
                                                        setSelectedTime(
                                                          moment().set({
                                                            hour: 19,
                                                            minute: 0,
                                                          }).tz(TIMEZONE_URL)
                                                        )
                                                      // } else {
                                                      //   setSelectedTime(
                                                      //     moment(playoffObj[key]?.schedule_time)
                                                      //   );
                                                      // }
                                                      getPurposeDate(playoffObj[key]?._id);
                                                      setdatefixed(playoffObj[key]?.schedule_date);
                                                      gethomeID(playoffObj[key]?.home_court?._id);
                                                      setIsHome(playoffObj[key]?.home_court);
                                                      setMyhomecourt(playoffObj[key]?.home_court);
                                                      gethomecourt(playoffObj[key]?.home_court?.name);
                                                      // setChange(res?.schedule_date);
                                                      getuserID(playoffObj[key]?.players);
          
                                                      getDateRange(
                                                        new Date(playoffObj[key]?.schedule_from_date),
                                                        new Date(playoffObj[key]?.schedule_to_date)
                                                      );
          
                                                       getMatchDate(
                                                    new Date(
                                                      playoffObj[key]?.schedule_date === null
                                                        ? playoffObj[key]?.schedule_from_date
                                                        : playoffObj[key]?.schedule_date
                                                    ).getFullYear(),
                                                    playoffObj[key]?.schedule_date === null?
                                                    new Date(
                                                      playoffObj[key]?.schedule_from_date
                                                    ).getMonth() < 
                                                    new Date(
                                                    ).getMonth()
                                                    && 
                                                    new Date(
                                                      playoffObj[key]?.schedule_from_date
                                                    ).getFullYear() ==
                                                    new Date(
                                                    ).getFullYear()
                                                    ?
                                                    new Date(
                                                      ).getMonth()+1  
                                                    :
                                                    new Date(
                                                      playoffObj[key]?.schedule_from_date
                                                    ).getMonth()
                                                    : 
                                                    new Date(
                                                      playoffObj[key]?.schedule_date
                                                    ).getMonth()+1
                                                  );
                                                  
                                                      setplayername(playoffObj[key]?.opponent)
                                                      setnewdate(
                                                        new Date(
                                                          playoffObj[key]?.propose_dates?.length !== 0 &&
                                                          playoffObj[key]?.schedule_date === null ?
                                                          (playoffObj[key]?.propose_dates[0]?.date)
                                                          :
                                                          (playoffObj[key]?.schedule_date === null
                                                            ? playoffObj[key]?.schedule_from_date
                                                              : playoffObj[key]?.schedule_date)
          
                                                          // res?.schedule_date === null
                                                          //   ? res?.schedule_from_date
                                                          //   : res?.schedule_date
                                                        )
                                                      );
                                                      // setschedhule(res?.schedule_date);
                                                      // getdatefunctio(new Date(res?.metch_date).getFullYear())
                                                      // setChange(new Date(res?.schedule_from_date));
                                                    }}
                                                    key={index}
                                                  >
                                                <SlCalender/>
                                                <span className="absolute z-[3] whitespace-nowrap bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">{  playoffObj[key].propose_dates.length != 0 || playoffObj[key]?.schedule_date != null  ? "Propose New Dates":"Propose Dates"  }</span>                                                
                                                </button>

                                                 </span>
                                               
                                            </div>
                                          ) : (
                                            <>
                                              {" "}
                                              {playoffObj[key]
                                                ?.schedule_date === null &&
                                              playoffObj[key]?.propose_dates
                                                .length === 0 ? (
                                            <div
                                            className="flex items-center cursor-pointer"

                                                  disabled={
                                                    playoffObj[key]?.status == "void"
                                                       ? true
                                                       : false
                                                   }

                                                   onClick={() => {
                                                    setMessage(true);
                                                    dropMessages();
                                                    setCheckCalender(false)
                                                    SetMaxDate(
                                                      playoffObj[key]?.date_range_end
                                                    );
                                                    setDefaultMessageSent(
                                                      false
                                                    );
                                                    // dropMessages();
                                                    setmatchid(playoffObj[key]?._id);
                                                    GetPurposed(
                                                      playoffObj[key].propose_dates.length
                                                    );
                                                    setpopisOpen(
                                                      playoffObj[key]?.status == "void"
                                                        ? false
                                                        : true
                                                    );
                                                    getMatchdetails(playoffObj[key]?._id);
                                                    gettym(playoffObj[key]?.schedule_time);
                                                    setSelectedTime(
                                                      moment().set({
                                                        hour: 19,
                                                        minute: 0,
                                                      }).tz(TIMEZONE_URL)
                                                    )
                                                    // if (playoffObj[key]?.schedule_time === null) {
                                                    //   setSelectedTime(
                                                    //     moment().set({
                                                    //       hour: 19,
                                                    //       minute: 0,
                                                    //     })
                                                    //   );
                                                    // } else {
                                                    //   setSelectedTime(
                                                    //     moment(playoffObj[key]?.schedule_time)
                                                    //   );
                                                    // }
                                                    getPurposeDate(playoffObj[key]?._id);
                                                    setdatefixed(playoffObj[key]?.schedule_date);
                                                    gethomeID(playoffObj[key]?.home_court?._id);
                                                    setIsHome(playoffObj[key]?.home_court);
                                                    setMyhomecourt(playoffObj[key]?.home_court);
                                                    gethomecourt(playoffObj[key]?.home_court?.name);
                                                    // setChange(res?.schedule_date);
                                                    getuserID(playoffObj[key]?.players);
        
                                                    getDateRange(
                                                      new Date(playoffObj[key]?.schedule_from_date),
                                                      new Date(playoffObj[key]?.schedule_to_date)
                                                    );
        
                                                    getMatchDate(
                                                      new Date(
                                                        playoffObj[key]?.schedule_date === null
                                                          ? playoffObj[key]?.schedule_from_date
                                                          : playoffObj[key]?.schedule_date
                                                      ).getFullYear(),
                                                      playoffObj[key]?.schedule_date === null?
                                                      new Date(
                                                        playoffObj[key]?.schedule_from_date
                                                      ).getMonth() < 
                                                      new Date(
                                                      ).getMonth()
                                                      && 
                                                      new Date(
                                                        playoffObj[key]?.schedule_from_date
                                                      ).getFullYear() ==
                                                      new Date(
                                                      ).getFullYear()
                                                      ?
                                                      new Date(
                                                        ).getMonth()+1  
                                                      :
                                                      new Date(
                                                        playoffObj[key]?.schedule_from_date
                                                      ).getMonth()
                                                      : 
                                                      new Date(
                                                        playoffObj[key]?.schedule_date
                                                      ).getMonth()+1
                                                    );
                                                    setplayername(playoffObj[key]?.opponent)
                                                    setnewdate(
                                                      new Date(
                                                        playoffObj[key]?.propose_dates?.length !== 0 &&
                                                        playoffObj[key]?.schedule_date === null ?
                                                        (playoffObj[key]?.propose_dates[0]?.date)
                                                        :
                                                        (playoffObj[key]?.schedule_date === null
                                                          ? playoffObj[key]?.schedule_from_date
                                                            : playoffObj[key]?.schedule_date)
        
                                                        // res?.schedule_date === null
                                                        //   ? res?.schedule_from_date
                                                        //   : res?.schedule_date
                                                      )
                                                    );
                                                    // setschedhule(res?.schedule_date);
                                                    // getdatefunctio(new Date(res?.metch_date).getFullYear())
                                                    // setChange(new Date(res?.schedule_from_date));
                                                  }}
                                            >
                                              
                                                  {  playoffObj[key]?.schedule_from_date?
                                                  <>
                                                  { playoffObj[key]?.status == "void" ? 
                                                  "N/A" 
                                                  :
                                                  "Propose date to play"
                                                }
                                                 </>
                                                  :
                                                  "-"
                                                  }

                                                  {/* <Moment
                                                    format={
                                                      moment(
                                                        playoffObj[key]
                                                          ?.schedule_from_date
                                                      )
                                                        .format("MMM D")
                                                        ?.includes("May")
                                                        ? "MMM DD"
                                                        : "MMM. DD"
                                                    }
                                                  >
                                                    {
                                                      playoffObj[key]
                                                        ?.schedule_from_date
                                                    }
                                                  </Moment>
                                                  <p className="px-1"> - </p>
                                                  <Moment
                                                    format={
                                                      moment(
                                                        playoffObj[key]
                                                          ?.schedule_to_date
                                                      )
                                                        .format("MMM D")
                                                        ?.includes("May")
                                                        ? "MMM DD"
                                                        : "MMM. DD"
                                                    }
                                                  >
                                                    {
                                                      playoffObj[key]
                                                        ?.schedule_to_date
                                                    }
                                                  </Moment> */}
                                               
                                                <button
                                                 className="flex ml-2 items-center cursor-pointer hover:text-green group relative "
                                                //  onClick={() => {
                                                  
                                                //  }}
                                                
                                               >
                                            {playoffObj[key]?.status == "void" ? null : <SlCalender/> } 
                                                <span className="absolute whitespace-nowrap z-[3] bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">{  playoffObj[key].propose_dates.length != 0 || playoffObj[key]?.schedule_date != null  ? "Propose New Dates":"Propose Dates"  }</span>                                                
                                                </button>
                                                </div>
                                              ) : (
                                                <>
                                                  <span
                                                    className="flex"
                                                    onClick={() => {
                                                      // toggleWidget();
                                                      setID(
                                                        playoffObj[key]?._id
                                                      );
                                                      SetMaxDate(
                                                        playoffObj[key]
                                                          ?.date_range_end
                                                      );
                                                      setMessage(true);
                                                    }}
                                                  
                                                    title="Schedule Dates"
                                                  >
                                                    {" "}
                                                    <Moment
                                                      format={
                                                        moment(
                                                          playoffObj[key]
                                                            ?.schedule_date
                                                        )
                                                          .format("MMM D")
                                                          ?.includes("May")
                                                          ? "MMM DD"
                                                          : "MMM. DD"
                                                      }
                                                    >
                                                      {
                                                        playoffObj[key]
                                                          ?.schedule_date
                                                      }
                                                    </Moment>
                                                    <p className="px-1"> @ </p>
                                                  {/* {  playoffObj[key]?.schedule_time} */}
                                                  {moment(
                                                 momentsss(playoffObj[key]
                                                  ?.schedule_time).tz(TIMEZONE_URL) ).format("hh:mm A")}
                                                  </span>
                                                  <span>
                                                  <button
                                                    // onClick={() => {
                                                    
                                                    // }}
                                                    className="flex ml-2 mt-[3px] items-center cursor-pointer hover:text-green group relative "
                                                    onClick={() => {
                                                      setMessage(true);
                                                      
                                                      setCheckCalender(false)
                                                      SetMaxDate(
                                                        playoffObj[key]?.date_range_end
                                                         );
                                                      // toggleWidget(true);
                                                      setID(  playoffObj[key]?._id);

                                                      setmatchid(  playoffObj[key]?._id);
                                                      GetPurposed(
                                                        playoffObj[key].propose_dates.length
                                                      );
                                                      setpopisOpen(
                                                        playoffObj[key]?.status == "void"
                                                          ? false
                                                          : true
                                                      );
                                                      getMatchdetails(  playoffObj[key]?._id);
                                                      gettym(  playoffObj[key]?.schedule_time);
                                                      setSelectedTime(
                                                        moment().set({
                                                          hour: 19,
                                                          minute: 0,
                                                        }).tz(TIMEZONE_URL)
                                                      )
                                                      // if (  playoffObj[key]?.schedule_time === null) {
                                                      //   setSelectedTime(
                                                      //     moment().set({
                                                      //       hour: 19,
                                                      //       minute: 0,
                                                      //     })
                                                      //   );
                                                      // } else {
                                                      //   setSelectedTime(
                                                      //     moment(  playoffObj[key]?.schedule_time)
                                                      //   );
                                                      // }
                                                      getPurposeDate(  playoffObj[key]?._id);
                                                      setdatefixed(  playoffObj[key]?.schedule_date);
                                                      gethomeID(  playoffObj[key]?.home_court?._id);
                                                      setIsHome(  playoffObj[key]?.home_court);
                                                      setMyhomecourt(  playoffObj[key]?.home_court);
                                                      gethomecourt(  playoffObj[key]?.home_court?.name);
                                                      // setChange(res?.schedule_date);
                                                      getuserID(  playoffObj[key]?.players);
          
                                                      getDateRange(
                                                        new Date(  playoffObj[key]?.schedule_from_date),
                                                        new Date(  playoffObj[key]?.schedule_to_date)
                                                      );
          
                                                      getMatchDate(
                                                        new Date(
                                                          playoffObj[key]?.schedule_date === null
                                                            ? playoffObj[key]?.schedule_from_date
                                                            : playoffObj[key]?.schedule_date
                                                        ).getFullYear(),
                                                        playoffObj[key]?.schedule_date === null?
                                                        new Date(
                                                          playoffObj[key]?.schedule_from_date
                                                        ).getMonth() < 
                                                        new Date(
                                                        ).getMonth()
                                                        && 
                                                        new Date(
                                                          playoffObj[key]?.schedule_from_date
                                                        ).getFullYear() ==
                                                        new Date(
                                                        ).getFullYear()
                                                        ?
                                                        new Date(
                                                          ).getMonth()+1  
                                                        :
                                                        new Date(
                                                          playoffObj[key]?.schedule_from_date
                                                        ).getMonth()
                                                        : 
                                                        new Date(
                                                          playoffObj[key]?.schedule_date
                                                        ).getMonth()+1
                                                      );
                                                      setplayername(playoffObj[key]?.opponent)
                                                      setnewdate(
                                                        new Date(
                                                          playoffObj[key]?.propose_dates?.length !== 0 &&
                                                          playoffObj[key]?.schedule_date === null ?
                                                          ( playoffObj[key]?.propose_dates[0]?.date)
                                                          :
                                                          ( playoffObj[key]?.schedule_date === null
                                                            ?  playoffObj[key]?.schedule_from_date
                                                              :  playoffObj[key]?.schedule_date)
          
                                                          // res?.schedule_date === null
                                                          //   ? res?.schedule_from_date
                                                          //   : res?.schedule_date
                                                        )
                                                      );
                                                      // setschedhule(res?.schedule_date);
                                                      // getdatefunctio(new Date(res?.metch_date).getFullYear())
                                                      // setChange(new Date(res?.schedule_from_date));
                                                    }}
                                                    key={index}
                                                  >
                                                <SlCalender/>
                                                <span className="absolute z-[3] whitespace-nowrap bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">{  playoffObj[key].propose_dates.length != 0 || playoffObj[key]?.schedule_date != null  ? "Propose New Dates":"Propose Dates"  }</span>                                                
                                                </button>
                                                  </span>
                                                </>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                     
                                    </div>
                                  </td>
                                )}
        
                                <td
                                  title="View Map"
                                  onClick={() => {
                                    setmapshow(true);
                                    getlocation(
                                      playoffObj[key]?.home_court?.latitude,
                                      playoffObj[key]?.home_court?.longitude
                                    );
                                  }}
                                  className="py-3 text-left hover:text-green cursor-pointer"
                                >
                                  {/* {PlayOffs?.home_court?.name} */}
                               
                                  {leaguetype != "singles" &&  playoffObj[key]?.home_court?.displayName?.length >= 20   ? (
                                    <div className="group relative flex ">
                                      {`${playoffObj[key]?.home_court?.displayName}`
                                        .substring(0, 20)
                                        .concat("...") ?? "N/A"}
                                      <span className="absolute bottom-5 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 bg-[#000]">
                                        {" "}
                                        {playoffObj[key]?.home_court?.displayName}
                                      </span>
                                    </div>
                                  ) : (
                                    <div >
                                      {playoffObj[key]?.home_court?.displayName ??
                                        "N/A"}
                                    </div>
                                  )}
                                </td>
        
                                <td className="md:px-0 px-3 md:py-3">
                                  <>
                                    {playoffObj[key]?.is_score_update ===
                                    false ? (
                                      <>
                                        {playoffObj[key]?.schedule_date !=
                                        null ? (
                                          <>
                                            {moment(
                                              new Date(
                                                playoffObj[key]?.schedule_date
                                              ),
                                              "DD/MM/YYYY"
                                            ).format("M/DD/YYYY") ===
                                              moment(
                                                new Date(),
                                                "DD/MM/YYYY"
                                              ).format("M/DD/YYYY") ||
                                            moment(
                                              new Date(),
                                              "DD/MM/YYYY"
                                            ).format("M/DD/YYYY") >=
                                              moment(
                                                new Date(
                                                  playoffObj[key]?.schedule_date
                                                ),
                                                "DD/MM/YYYY"
                                              ).format("M/DD/YYYY") ? (
                                              <button
                                                className="font-medium text-sm text-green  border-2 border-green  2xl:px-9 px-5 py-1 md:w-auto  rounded-lg "
                                                type="button"
                                                onClick={() => {
                                                  setnewpopup(true);
                                                  opentable(false);
                                                  setMyhomecourt(
                                                    playoffObj[key]?.home_court
                                                  );
                                                  setIsHome(
                                                    playoffObj[key]?.home_court
                                                  );
                                                  // setIsHome(PlayOffs?.home_court);
                                                  setopenforfiet(false);
                                                  setopenwithdraw(false);
                                                  getMatchdetails(
                                                    playoffObj[key]?._id
                                                  );
                                                  setclearvalue();
                                                }}
                                              >
                                                Click
                                              </button>
                                            ) : (
                                              <>
                                                <button
                                                  className="font-medium text-sm text-green  border-2 border-green  2xl:px-9 px-5 py-1 md:w-auto  rounded-lg "
                                                  type="button"
                                                  onClick={() => {
                                                    setnewpopup(true);
                                                    opentable(false);
                                                    setIsHome(
                                                      playoffObj[key]
                                                        ?.home_court?._id
                                                    );
                                                    setMyhomecourt(
                                                      playoffObj[key]
                                                        ?.home_court
                                                    );
                                                    setopenforfiet(false);
                                                    setopenwithdraw(false);
                                                    getMatchdetails(
                                                      playoffObj[key]?._id
                                                    );
                                                    setclearvalue();
                                                    // setforfietvalue("Forfieted");
                                                  }}
                                                >
                                                  Click
                                                </button>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              className="font-medium text-sm text-green  border-2 border-green  2xl:px-9 px-5 py-1 md:w-auto  rounded-lg "
                                              type="button"
                                              onClick={() => {
                                                setnewpopup(true);
                                                opentable(false);
                                                setIsHome(
                                                  playoffObj[key]?.home_court
                                                    ?._id
                                                );
                                                setopenforfiet(false);
                                                setMyhomecourt(
                                                  playoffObj[key]?.home_court
                                                );
                                                setopenwithdraw(false);
                                                getMatchdetails(
                                                  playoffObj[key]?._id
                                                );
                                                setclearvalue();
                                                // setforfietvalue("Forfieted");
                                              }}
                                            >
                                              Click
                                            </button>
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <p className="text-green text md:text-sm text-xs">
                                          {playoffObj[key]?.result ===
                                          "score" ? (
                                            <>
                                              <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                                {
                                                  playoffObj[key]
                                                    ?.team_first_score?.set_one
                                                }
                                                -
                                                {
                                                  playoffObj[key]
                                                    ?.team_second_score?.set_one
                                                }
                                              </span>
                                              <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                                {
                                                  playoffObj[key]
                                                    ?.team_first_score?.set_two
                                                }
                                                -
                                                {
                                                  playoffObj[key]
                                                    ?.team_second_score?.set_two
                                                }
                                              </span>
                                              {playoffObj[key]?.team_first_score
                                                ?.set_three ||
                                              playoffObj[key]?.team_second_score
                                                ?.set_three != null ? (
                                                <span className="border 2xl:mx-2 mx-[1px] lg:p-1">
                                                  {
                                                    playoffObj[key]
                                                      ?.team_first_score
                                                      ?.set_three
                                                  }
                                                  -
                                                  {
                                                    playoffObj[key]
                                                      ?.team_second_score
                                                      ?.set_three
                                                  }
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            <span className="md:text-sm capitalize">
                                              {playoffObj[key]?.result ===
                                              "Forfieted" ? (
                                                <span>
                                                  Forfeited By{" "}
                                                  {playoffObj[key]?.forfiet_by}
                                                </span>
                                              ) : playoffObj[key]?.result ===
                                                "withdrew" ? (
                                                <span>
                                                  Withdrew By{" "}
                                                  {playoffObj[key]?.Withdraw_by}
                                                </span>
                                              ) : (
                                                <span>
                                                  {playoffObj[key]?.winByAdmin}
                                                </span>
                                              )}
                                            </span>
                                          )}
                                        </p>
                                      </>
                                    )}
                                  </>
                                </td>
                                <td className="text-sm px-5 py-3 capitalize">
                                    {key}
                                   </td>
                              </tr>
                              )
                          :null}
                          </React.Fragment>
                              ))}
        
                              {/* <tr>
                  <td className="py-5">Raj</td>
                  <td className="flex justify-center">
                    <div className="flex items-center ">
                      <div className="avatar">
                        <div
                          onClick={() => {
                            setpopisOpen(true);
                          }}
                          className="flex cursor-pointer items-center "
                        >
                          Jan, 6 - Jan, 31
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setmapshow(true);
                      }}
                    >
                      Atlanta
                    </div>
                  </td>
                  <td className="flex justify-center">
                    <button
                      className="font-medium lg:text-[20px] text-lg text-green  border-2 border-green  md:px-16 px-5 md:w-auto  py-2 rounded-lg "
                      type="button"
                      onClick={() => {
                        setnewpopup(true);
                        opentable(false);
                      }}
                    >
                      Click
                    </button>
                  </td>
                </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
        
                    {/* <div className="lg:w-1/3 w-full py-11 flex flex-col">
          <p className=" text-center text-[#fff] text-xl md:text-xl capitalize">
            {Round} Standings
            <br />
          </p>
          <div
            id="style-1"
            className=" bg-[#6C6C6C] flex-grow flex-shrink-0  border-2 border-[#fff] bg-opacity-40 rounded-lg my-5 max-h-[450px] overflow-auto"
          >
            <div id="partner">
              <table className="w-full text-[#fff]" id="partnertable">
                <thead>
                  <tr className="bg-[#282B2D] sticky top-0  ">
                    {console.log(leaguesiddata)}
                    {leaguesiddata.includes("Singles") ? (
                      <th className="md:py-5 text-left px-3 ">Player</th>
                    ) : (
                      <th className="md:py-5 text-left px-3 ">Team</th>
                    )}
                    <th className="px-3">Win</th>
                    <th className="px-3">Loss</th>
            
                  </tr>
                </thead>
                <tbody className="text-xs lg:text-sm">
                  {PlayOffs?.standings?.result.map((res, index) => (
                    <tr
                      key={index}
                      className="hover:bg-[#39fd13] hover:bg-opacity-5"
                    >
                      <td className="text-sm px-3 py-[.70rem] ">
                        {res?.names?.length === 0 ? (
                          <span>-</span>
                        ) : (
                          res?.names?.map((name, index) => (
                            <span
                              title="View Profile"
                              onClick={(e) => {
                                RedirectChanged(`/mymatch/${id}`, true);
                                setRoute(result);
                                navigate(
                                  `/matchhistory/${name?._id}/${id}/${leaguesiddata}/${name?.firstName}`
                                );
                              }}
                              className="capitalize cursor-pointer  hover:text-green"
                            >
                              <span key={index}>
                                <>
                                  {(index ? " , " : "") +
                                    name?.firstName +
                                    " " +
                                    name?.lastName}
                                </>
                              </span>
                            </span>
                          ))
                        )}
                      </td>
                      <td className="py-[.70rem] text-base text-center">
                        <p>{res?.win}</p>
                      </td>
                      <td className=" py-[.70rem] text-base text-center">
                        <p>{res?.loss}</p>
                      </td>
                      
                    </tr>
                  ))}
                
                
                </tbody>
              </table>
            </div>
          </div>
        </div> */}
                  </div>
              }
                    
         
                  
                

              {/* )} */}
               
                  </>
                
                
              ) : null}
           
          {/* // ))} */}
        </div>
      </div>
      <div></div>

      <Popup
        open={popisOpen}
        id="round"
        contentStyle={{
          width: isMobileView ? "100%" : "70%",/* Default width for larger screens */
          background: "none",
          border: "none",
          padding:  isMobileView ? "15px" : "0px"
       
        }}
        closeOnDocumentClick={false}
        onClose={() => {
          setpopisOpen(false);
        }}
      >
        <div
          id="style-1"
          className="md:px-1 overflow-auto bg-[#ffffff] rounded relative"
        >
          <div className="float-right   ">
            <button
              className="  text-[#000]  font-extralight lg:text-2xl text-lg   md:text-2xl absolute md:right-[10px] right-[0px] md:top-[5px] "
              onClick={() => {
                setpopisOpen(false);
                // setAddMonth(false)
                handleButtonClick();
                Removevalidate();
                setdatetym(null);
                // setChange(null);
                SettymOnClick(null);
                removedate();
                setButtonText("Purposed Dates");
              }}
            >
              <FaTimes />
            </button>
          </div>
          <div className="flex rounded py-1 md:px-0 px-3 flex-wrap">
            <div className="md:w-5/12 md:block hidden rounded bg-[#000000]">
              <div className="rounded-full flex  justify-center py-5  ">
                <img
                alt=""
                  crossOrigin="anonymous"
                  className="rounded-full aspect-[1] border-2 w-[31%] border-[#ffffff]"
                  src={BASE_URL + `/image/${user?.image}`}
                />
              </div>
              <p className="text-xl text-[#ffffff] flex justify-center capitalize">
                {user?.firstName} {user?.lastName}
              </p>

              <p className="text-[#ffffff] px-5    mt-9">
                {leaguetype != "singles" ? (
                  <>
                    The right side calendar shows the booked dates for you, your
                    partner{" "}
                    {mate.map((name, index) => (
                      <span key={index} className="text-green capitalize">
                        {" "}
                        {user.firstName !== name.firstName
                          ? name.firstName
                          : ""}
                      </span>
                    ))}{" "}
                    and opponent team{" "}
                    {opponent.map((name, index) => (
                      <span className="text-green capitalize" key={index}>
                        {(index ?  ` &` : "") + " "} {name?.firstName}
                      </span>
                    ))}{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    The right side calendar shows the booked dates for
                    <span className="text-green capitalize"> you</span> and your
                    opponent{" "}
                    {opponent.map((name, index) => (
                      <span className="text-green capitalize" key={index}>
                        {(index ? ` &` : "") + " "} {name?.firstName}
                      </span>
                    ))}{" "}
                  </>
                )}
              </p>
              <div className="md:px-7 md:mt-7 ">
                <div className="flex items-center space-x-3">
                  <span className="w-3 h-3 bg-[#FF0000] rounded-full"></span>{" "}
                  <p className=" text-[11px] text-[white] leading-[29.3px]">
                  Match Already Booked
                  </p>
                </div>
                <div className="flex items-center space-x-3">
                  <span className="w-3 h-3 bg-[blue] rounded-full"></span>{" "}
                  <p className=" text-[11px] text-[white] leading-[29.3px]">
                    Proposed Dates For This Match
                  </p>
                </div>
                {datefixed === null && pusrposearray.length === 0 ? (
                  <div className="flex items-center space-x-3">
                    <span className="w-3 h-3 bg-green rounded-full"></span>{" "}
                    <p className=" text-[11px] text-[white] leading-[29.3px]">
                      Match Play Period
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center space-x-3">
                    <span className="w-3 h-3 bg-green rounded-full"></span>{" "}
                    <p className=" text-[11px] text-[white] leading-[29.3px]">
                      Date For This Match
                    </p>
                  </div>
                )}
              </div>

              {datefixed === null ? (
                <></>
              ) : (
                <p className="text-[white]  md:px-7 md:mt-7 ">
                  {" "}
                  <span className="text-green">
                    {<Moment    format={
                                moment(datefixed).format("MMM D")?.includes("May")
                                  ? "MMM   D"
                                  : "MMM.  D "
                              }>{datefixed}</Moment>}{" "} @{" "}
                              {/* {MatchTym} */}
                              {moment(
                                                 momentsss(MatchTym).tz(TIMEZONE_URL) ).format("hh:mm A")}
                    {/* {moment(MatchTym).format("hh:mm A")} */}
                  </span>{" "}
                  is Planned For this Match{" "}
                </p>
              )}

              <div className="m-[7%]"></div>
            </div>
            <div className="md:w-7/12 w-full  md:pr-0  ">
              <div className="md:mt-11 relative  md:px-7 disableDate ">
                {loadDates &&
                    <div className=" bg-[gray]   w-[-webkit-fill-available] bg-opacity-20  absolute h-[85%] z-10   ">
                    <img alt="" className="  w-[15%] absolute left-[45%] top-[50%]   " src={imageLoader}/>
                    </div>
                }
            
                
                <Calendar
                  className = "effect"
                  tileContent={PurposeContent}
                  tileDisabled={({ date }) =>  CheckCalender === true ? !isDateEnabled(date): BookingDateEnabled(date)}
                  onClickDay={(e) =>{ CheckCalender ? 
                  handleDateChange(e)                 
                 :setDaytime(true);
                 byDefault(e);
                }}
                
                showNeighboringMonth={false}
                  onActiveStartDateChange={handleMonthClick}
                  minDate={new Date()}
                  maxDate={maxDate}
                  onClose={() => {
                    setpopisOpen(false);
                  }}
                  tileClassName={tileClassName}
                  value={newdate}
                  onChange={(e) => {
                    setChange(e);
                    SettymOnClick(e);
                    setcurrentDate(e);
                    // handleDateChange(e);
                  }}
                />

                <div className={`flex flex-col  space-y-3 justify-end ${schedhule !== null ? "mt-16": "mt-3" } `}>
                  {getpurpose.length === 0 ? (
                    ""
                  ) :  lastSelectedDate?.date == undefined   ? (
                    <>
                    
                    {loaderstartsetdate ? 
                    <>
                     <div className=" bg-[gray]   w-[-webkit-fill-available] bg-opacity-20  absolute h-[85%] z-10   ">
                        <img alt="" className="  w-[15%] absolute left-[45%] top-[30%]   " src={imageLoader}/>
                        </div>
                        <button
                        className="font-medium   lg:text-[17px] text-lg text-[#ffffff]   bg-[black] md:px-9 px-5 md:w-auto  py-2 rounded-lg "
                        type="button"
                      >
                        <span className="flex items-center justify-center">
                        Processing...{" "}
                     
                        </span>
                      </button>
                    </>

                   
                      : 
                      CheckCalender  && 
                         <button
                         className="font-medium cursor-default   lg:text-[17px] text-lg text-[#ffffff]   bg-[black] md:px-9 px-5 md:w-auto  py-2 rounded-lg "
                         type="button"
                         onClick={() => {
                           setdate();
                           Removevalidate();
                         }}
                       >
                         Set Date
                       </button>
                      
                      }
                   

                      {setDate ? (
                        <div className="text-[red]">
                          Please select one date*
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    
                    CheckCalender  && 
                    <button
                      className="font-medium   lg:text-[17px] text-lg text-[#ffffff]   bg-[black] md:px-9 px-5 md:w-auto  py-2 rounded-lg "
                      type="button"
                      onClick={() => {
                        // PostDate(value);
                        grpPlayer(playername)
                        setDefaultMessageSent(true);
                        ChatWindowMessage();
                        purposeChatWidget();
                        PostDate(lastSelectedDate?.date, selectedTime , hoveredTime);
                        // settime(true);
                        setdate(false);
                        handleButtonClick();
                        setChatWidget();
                        NewSetMessage();
                        getGroupDeatails(matchid);
                        getGroupOfUser("");
                        setdatetym(null);
                        // setChange(null);
                        SettymOnClick(null);
                        removedate();
                        setButtonText("Purposed Dates");
                        removetym();
                        setDateEx4("00:00");
                      }}
                    >
                      Set{" "}
                      {
                        <span className="text-green">
                          {
                            <Moment
                              format={
                                moment(lastSelectedDate?.date).format("MMM D")?.includes("May")
                                  ? "MMM   D"
                                  : "MMM.  D "
                              }
                            >
                              {lastSelectedDate?.date }
                            </Moment>
                          }
                          <span className="text-[white]">{" "} @ {" "}</span>{" "}
                          { TimeCheck == true ? hoveredTime: lastSelectedDate?.time }
                        </span>
                      }{" "}
                    </button>
                  )}
                  {     selectedDates.length === 0 ? (
                    <>
                      {loaderstart ? (
                        <button
                          className="font-medium  lg:text-[17px] text-lg text-[#ffffff]   bg-[black] md:px-9  md:w-auto  py-2 rounded-lg "
                          type="button"
                        >
                          <span className="text-green flex items-center justify-center">
                          Processing......{" "}
                            <svg
                              className="animate-spin mr-1 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      ) : (
                        !CheckCalender  &&     <button
                          className="font-medium  lg:text-[17px] text-lg text-[#ffffff]   bg-[black] md:px-9  md:w-auto  py-2 rounded-lg "
                          type="button"
                          onClick={() => {
                            validate();
                            removedate();
                          }}
                        >
                          <span className="text-green">{buttonText}</span>
                        </button> 
                   
                      )}

                      {validateCheck ? (
                        <div className="text-[red]">
                          Please select at least 1 date*
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (

                    !CheckCalender  && 
                    <button
                      className="font-medium  lg:text-[17px] text-lg text-[#ffffff]   bg-[black] md:px-1  md:w-auto  py-2 rounded-lg "
                      type="button"
                      onClick={() => {
                        // ChatWindowMessage();
                        PurposeDate(selectedDates ,TeamId );
                        grpPlayer(playername)
                        // // PostDate(value);
                        // handleButtonClick();
                        // purposeChatWidget();
                        // DefaultMessage();
                        getGroupDeatails(matchid);
                        // navigate(`/message/${matchidslect}`);
                        getGroupOfUser("");
                        getpurposeddate(selectedDates);
                        setButtonText("Purposed Dates");
                        setdatetym(null);
                        // setChange(null);
                        SettymOnClick(null);
                        removedate();
                        // setDefaultMessageSent(true);
                      }}
                    >
                      Propose{" "}
                      {selectedDates.map((time, index) => (
                        <span key={index} className="text-green">
                          {(index ? ", " : "") +
                            moment(time?.date, "M/DD/YYYY").format(
                              moment(time?.date).format("MMM D")?.includes("May")
                                ? "MMM  D "
                                : "MMM.  D "
                            )}
                          <span className="text-[white]">@</span> {time?.time}
                        </span>
                      ))}{" "}
                    </button>
                    
                  )}
                
                  {selectedDates.length >= 3  && !CheckCalender ? (
                    <div className="text-[red]">
                      *You can propose max 3 dates at a time.
                    </div>
                  ) : (
                    ""
                  )}
                          {loaderstart && (
                        // <button
                        //   className="font-medium absolute  lg:text-[17px] text-lg text-[#ffffff]   bg-[black] md:px-9  md:w-auto  py-2 rounded-lg "
                        //   type="button"
                        // >
                          // <span className="text-green absolute left-[80%] bottom-[25%] flex items-center justify-center">
                          // {/* Processing......{" "} */}
                          //   <svg
                          //     className="animate-spin mr-1 h-5 w-5 text-white"
                          //     xmlns="http://www.w3.org/2000/svg"
                          //     fill="none"
                          //     viewBox="0 0 24 24"
                          //   >
                          //     <circle
                          //       className="opacity-25"
                          //       cx="12"
                          //       cy="12"
                          //       r="10"
                          //       stroke="currentColor"
                          //       strokeWidth="4"
                          //     ></circle>
                          //     <path
                          //       className="opacity-75"
                          //       fill="currentColor"
                          //       d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          //     ></path>
                          //   </svg>
                          // </span>
                        // </button>
                        <div className=" bg-[gray]   w-[-webkit-fill-available] bg-opacity-20  absolute h-[85%] z-10   ">
                        <img alt="" className="  w-[15%] absolute left-[45%] top-[30%]   " src={imageLoader}/>
                        </div>
                      ) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        open={newpop}
        closeOnDocumentClick={false}
        className=""
        contentStyle={{width: isMobileView ? "100%" : "70%", border: "none", background: "none",     padding:  isMobileView ? "15px" : "0px" }}
        onClose={() => {
          setnewpopup(false);
        }}
      >
        <div className={` md:w-full lg:w-full 2xl:w-full bg-[#31343B] w-[${isMobileView ? "100%":"90%" } ] move overflow-auto max-h-[610px] rounded  relative`}>
          <div className="float-right   ">
            <button
              className=" text-[white]  font-extralight md:text-2xl text-lg absolute md:right-[50px] right-3 lg:right-[50px]  font-poppins  md:leading-[2] top-[10px] md:top-[21px]"
              onClick={() => {
                setnewpopup(false);
                popfun();
              }}
            >
              <FaTimes />
            </button>
          </div>
          <p className=" text-center text-[#fff] font-poppins  text-3xl md:text-2xl mt-11">
            Add Your Result
            <br />
          </p>
          {/* <p className="my-3 text-center text-[#fff] capitalize">
            {leaguesiddata?.replace("(female)", "")?.replace("(male)", "")}
          </p> */}
          {/* {  Object.keys(ObjectPlayOff).map((key) => ( */}
          {Schedule.map((res, index) => (
            <React.Fragment key={index}>
              {res?._id == matchidslect ? (
                <>
                  {res?.schedule_date != null ? (
                    <React.Fragment>
                      {moment(
                        new Date(res?.schedule_date),
                        "DD/MM/YYYY"
                      ).format("M/DD/YYYY") ===
                        moment(new Date(), "DD/MM/YYYY").format("M/DD/YYYY") ||
                      moment(new Date(), "DD/MM/YYYY").format("M/DD/YYYY") >=
                        moment(
                          new Date(res?.schedule_date),
                          "DD/MM/YYYY"
                        ).format("M/DD/YYYY") ? (
                        <>
                          {/* <div
                            key={index}
                            className="items-center  flex justify-center pl-20"
                          >
                            <p className=" text-[white] flex justify-center space-x-1">
                              Choose Your home Court Where You Played :{" "}
                            </p>
                            <div className="px-3 w-5/12 ">
                              <Select
                                isRequired={true}
                                id="location"
                                options={homecourtdrop}
                                placeholder="Home Court"
                                onChange={(e) => {
                                  setIsHome(e);
                                  setMyhomecourt(e);
                                }}
                                getOptionLabel={(homecourtdrop) =>
                                  homecourtdrop.name
                                }
                                getOptionValue={(homecourtdrop) =>
                                  homecourtdrop._id
                                }
                                value={ishome}
                                // defaultInputValue={ishome}
                                isSearchable={true}
                                // isClearable
                                isMulti={false}
                                className=" bg-[#f1f1f1] text-[#837575] item w-8/12  rounded-lg focus:outline-none"
                                // menuIsOpen={}
                                onMenuOpen={() => {
                                  setIsHome(null);
                                }}
                              />
                            </div>
                          </div> */}
                          {/* {ishome === null ? (
                            <div className="pt-3 text-center text-[red]">
                              *Please Provide Homecourt
                            </div>
                          ) : null} */}
                        </>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </>
              ) : null}
            </React.Fragment>
          ))}

          {tiematch &&
            tiematch.map((res, index) => (
              <React.Fragment key={index}>
                {res?._id == matchidslect ? (
                  <>
                    {res?.schedule_date != null ? (
                      <React.Fragment>
                        {moment(
                          new Date(res?.schedule_date),
                          "DD/MM/YYYY"
                        ).format("M/DD/YYYY") ===
                          moment(new Date(), "DD/MM/YYYY").format(
                            "M/DD/YYYY"
                          ) ||
                        moment(new Date(), "DD/MM/YYYY").format("M/DD/YYYY") >=
                          moment(
                            new Date(res?.schedule_date),
                            "DD/MM/YYYY"
                          ).format("M/DD/YYYY") ? (
                          <>
                            {/* <div
                              key={index}
                              className="items-center  flex justify-center pl-20"
                            >
                              <p className=" text-[white] flex justify-center space-x-1">
                                Choose Your home Court Where You Played :{" "}
                              </p>
                              <div className="px-3 w-5/12 ">
                                <Select
                                  isRequired={true}
                                  id="location"
                                  options={homecourtdrop}
                                  placeholder="Home Court"
                                  onChange={(e) => {
                                    setIsHome(e);
                                    setMyhomecourt(e);
                                  }}
                                  getOptionLabel={(homecourtdrop) =>
                                    homecourtdrop.name
                                  }
                                  getOptionValue={(homecourtdrop) =>
                                    homecourtdrop._id
                                  }
                                  value={ishome}
                                  // defaultInputValue={ishome}
                                  isSearchable={true}
                                  // isClearable
                                  isMulti={false}
                                  className=" bg-[#f1f1f1] text-[#837575] item w-8/12  rounded-lg focus:outline-none"
                                  // menuIsOpen={}
                                  onMenuOpen={() => {
                                    setIsHome(null);
                                  }}
                                />
                              </div>
                            </div> */}
                            {/* {ishome === null ? (
                              <div className="pt-3 text-center text-[red]">
                                *Please Provide Homecourt
                              </div>
                            ) : null} */}
                          </>
                        ) : null}
                      </React.Fragment>
                    ) : null}
                  </>
                ) : null}
              </React.Fragment>
            ))}
          {/* ))} */}
          {Object.keys(playoffObj).map((key) => (
            <React.Fragment>
              {playoffObj[key]?._id == matchidslect ? (
                <>
                  {playoffObj[key]?.schedule_date != null ? (
                    <React.Fragment>
                      {moment(
                        new Date(playoffObj[key]?.schedule_date),
                        "DD/MM/YYYY"
                      ).format("M/DD/YYYY") ===
                        moment(new Date(), "DD/MM/YYYY").format("M/DD/YYYY") ||
                      moment(new Date(), "DD/MM/YYYY").format("M/DD/YYYY") >=
                        moment(
                          new Date(playoffObj[key]?.schedule_date),
                          "DD/MM/YYYY"
                        ).format("M/DD/YYYY") ? (
                        <>
                          {/* <div className="items-center  flex justify-center pl-20">
              <p className=" text-[white] flex justify-center space-x-1">
                Choose Your home Court Where You Played :{" "}
              </p>

              <div className="px-3 w-5/12 ">
                <Select
                  isRequired={true}
                  id="location"
                  options={homecourtdrop}
                  placeholder="Home Court"
                  onChange={(e) => {
                    setIsHome(e);
                    setMyhomecourt(e);
                  }}
                  getOptionLabel={(homecourtdrop) =>
                    homecourtdrop.name
                  }
                  getOptionValue={(homecourtdrop) =>
                    homecourtdrop._id
                  }
                  value={ishome}
                  // defaultInputValue={ishome}
                  isSearchable={true}
                  // isClearable
                  isMulti={false}
                  className=" bg-[#f1f1f1] text-[#837575] item w-8/12  rounded-lg focus:outline-none"
                  // menuIsOpen={}
                  onMenuOpen={() => {
                    setIsHome(null);
                  }}
                />
              </div>
            </div>
            {ishome === null ? (
              <div className="pt-3 text-center text-[red]">
                *Please Provide Homecourt
              </div>
            ) : null} */}
                        </>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </>
              ) : null}
            </React.Fragment>
          ))}

          <div className="flex my-10  md:mx-6 mx-0 justify-around ">
            {Schedule.map((res, index) => (
              <React.Fragment key={index}>
                {res?._id == matchidslect ? (
                  <>
                    {res?.schedule_date != null ? (
                      <>
                        {
                          new Date() >= new Date(res?.schedule_date) ? (
                          <div key={index} className="flex items-center">
                            <input
                              className=" md:h-[20px] radio  md:w-[20px] cursor-pointer"
                              //   id={index}
                              onClick={(e) => {
                                opentable(true);
                                setopenforfiet(false);
                                setopenwithdraw(false);
                                // setopensubmit(false);
                                setradiovalue(e.target.value);
                                setmyteamid(matchdetails?.my_team?._id);
                                setoppteamid(matchdetails?.opponent_team?._id);
                              }}
                              // onChange={(e)=>{  setradiovalue(e.target.value)}}
                              type="radio"
                              name="myCheckbox"
                              value="score"
                            />
                            <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                              Score
                            </p>
                          </div>
                        ) : (
                          <>
                            <div key={index} className="flex items-center">
                              <input
                                className=" md:h-[20px] radio opacity-20 md:w-[20px] "
                                // onChange={(e)=>{  setradiovalue(e.target.value)}}
                                type="radio"
                                name="myCheckbox"
                                value="score"
                                disabled
                              />
                              <p className="md:ml-3 2xl:text-[28px] md:text-[20px] opacity-20 text-[#d1cfcf] font-light font-poppins ">
                                Score
                              </p>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div key={index} className="flex items-center">
                          <input
                            className=" md:h-[20px] radio opacity-20 md:w-[20px] "
                            // onChange={(e)=>{  setradiovalue(e.target.value)}}
                            type="radio"
                            name="myCheckbox"
                            value="score"
                            disabled
                          />
                          <p className="md:ml-3 2xl:text-[28px] opacity-20 md:text-[20px] text-[#d1cfcf] font-light font-poppins ">
                            Score
                          </p>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
              </React.Fragment>
            ))}

            {tiematch &&
              tiematch.map((res, index) => (
                <React.Fragment key={index}>
                  {res?._id == matchidslect ? (
                    <>
                      {res?.schedule_date != null ? (
                        <>
                          { new Date() >= new Date(res?.schedule_date) ? (
                            <div key={index} className="flex items-center">
                              <input
                                className=" md:h-[20px] radio  md:w-[20px] cursor-pointer"
                                //   id={index}
                                onClick={(e) => {
                                  opentable(true);
                                  setopenforfiet(false);
                                  setopenwithdraw(false);
                                  // setopensubmit(false);
                                  setradiovalue(e.target.value);
                                  setmyteamid(matchdetails?.my_team?._id);
                                  setoppteamid(
                                    matchdetails?.opponent_team?._id
                                  );
                                }}
                                // onChange={(e)=>{  setradiovalue(e.target.value)}}
                                type="radio"
                                name="myCheckbox"
                                value="score"
                              />
                              <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                                Score
                              </p>
                            </div>
                          ) : (
                            <>
                              <div key={index} className="flex items-center">
                                <input
                                  className=" md:h-[20px] radio opacity-20 md:w-[20px] "
                                  // onChange={(e)=>{  setradiovalue(e.target.value)}}
                                  type="radio"
                                  name="myCheckbox"
                                  value="score"
                                  disabled
                                />
                                <p className="md:ml-3 2xl:text-[28px] md:text-[20px] opacity-20 text-[#d1cfcf] font-light font-poppins ">
                                  Score
                                </p>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div key={index} className="flex items-center">
                            <input
                              className=" md:h-[20px] radio opacity-20 md:w-[20px] "
                              // onChange={(e)=>{  setradiovalue(e.target.value)}}
                              type="radio"
                              name="myCheckbox"
                              value="score"
                              disabled
                            />
                            <p className="md:ml-3 2xl:text-[28px] opacity-20 md:text-[20px] text-[#d1cfcf] font-light font-poppins ">
                              Score
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </React.Fragment>
              ))}
            {Object.keys(playoffObj).map((key) => (
              <React.Fragment>
                {playoffObj[key]?._id == matchidslect ? (
                  <>
                    {playoffObj[key]?.schedule_date != null ? (
                      <>
                        {
                        
                        new Date() >= new Date(playoffObj[key]?.schedule_date)
                         ? (
                          <div className="flex items-center">
                            <input
                              className=" md:h-[20px] radio  md:w-[20px] cursor-pointer"
                              //   id={index}
                              onClick={(e) => {
                                opentable(true);
                                setopenforfiet(false);
                                setopenwithdraw(false);
                                // setopensubmit(false);
                                setradiovalue(e.target.value);
                                setmyteamid(matchdetails?.my_team?._id);
                                setoppteamid(matchdetails?.opponent_team?._id);
                              }}
                              // onChange={(e)=>{  setradiovalue(e.target.value)}}
                              type="radio"
                              name="myCheckbox"
                              value="score"
                            />
                            <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                              Score
                            </p>
                          </div>
                        ) : (
                          <>
                            <div className="flex items-center">
                              <input
                                className=" md:h-[20px] radio opacity-20 md:w-[20px] "
                                // onChange={(e)=>{  setradiovalue(e.target.value)}}
                                type="radio"
                                name="myCheckbox"
                                value="score"
                                disabled
                              />
                              <p className="md:ml-3 2xl:text-[28px] md:text-[20px] opacity-20 text-[#d1cfcf] font-light font-poppins ">
                                Score
                              </p>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="flex items-center">
                          <input
                            className=" md:h-[20px] radio opacity-20 md:w-[20px] "
                            // onChange={(e)=>{  setradiovalue(e.target.value)}}
                            type="radio"
                            name="myCheckbox"
                            value="score"
                            disabled
                          />
                          <p className="md:ml-3 2xl:text-[28px] opacity-20 md:text-[20px] text-[#d1cfcf] font-light font-poppins ">
                            Score
                          </p>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
              </React.Fragment>
            ))}

            {Schedule.map((res, index) => (
              <React.Fragment key={index}>
                {res?._id == matchidslect ? (
                  <>
                    {res?.schedule_date != null ? (
                      <>
                        {new Date() >= new Date(res?.schedule_date) ? (
                          <div className="flex md:ml-20 items-center">
                            <input
                              className=" md:h-[20px]  radio  md:w-[20px] cursor-pointer"
                              //   id={index}
                              onClick={(e) => {
                                setopenforfiet(true);
                                opentable(false);
                                setopenwithdraw(false);
                                setforfietvalue(e.target.value);
                              }}
                              // onChange={()=>{}}
                              type="radio"
                              name="myCheckbox"
                              value="Forfieted"
                            />
                            <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                              Forfeit
                            </p>
                          </div>
                        ) : (
                          <>
                            <div className="flex md:ml-20 items-center">
                              <input
                                className=" md:h-[20px] radio  md:w-[20px] cursor-pointer"
                                //   id={index}
                                onClick={(e) => {
                                  setopenforfiet(true);
                                  opentable(false);
                                  setopenwithdraw(false);
                                  setforfietvalue(e.target.value);
                                }}
                                // onChange={()=>{}}
                                // checked={true}
                                type="radio"
                                name="myCheckbox"
                                value="Forfieted"
                              />
                              <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                                Forfeit
                              </p>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="flex md:ml-20 items-center">
                          <input
                            className=" md:h-[20px] radio  md:w-[20px] cursor-pointer"
                            //   id={index}
                            onClick={(e) => {
                              setopenforfiet(true);
                              opentable(false);
                              setopenwithdraw(false);
                              setforfietvalue(e.target.value);
                            }}
                            // onChange={()=>{}}
                            // checked={true}
                            type="radio"
                            name="myCheckbox"
                            value="Forfieted"
                          />
                          <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                            Forfeit
                          </p>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
              </React.Fragment>
            ))}

            {tiematch &&
              tiematch.map((res, index) => (
                <React.Fragment key={index}>
                  {res?._id == matchidslect ? (
                    <>
                      {res?.schedule_date != null ? (
                        <>
                          {new Date() >= new Date(res?.schedule_date) ? (
                            <div className="flex md:ml-20 items-center">
                              <input
                                className=" md:h-[20px] radio  md:w-[20px] cursor-pointer"
                                //   id={index}
                                onClick={(e) => {
                                  setopenforfiet(true);
                                  opentable(false);
                                  setopenwithdraw(false);
                                  setforfietvalue(e.target.value);
                                }}
                                // onChange={()=>{}}
                                type="radio"
                                name="myCheckbox"
                                value="Forfieted"
                              />
                              <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                                Forfeit
                              </p>
                            </div>
                          ) : (
                            <>
                              <div className="flex md:ml-20 items-center">
                                <input
                                  className=" md:h-[20px] radio  md:w-[20px] cursor-pointer"
                                  //   id={index}
                                  onClick={(e) => {
                                    setopenforfiet(true);
                                    opentable(false);
                                    setopenwithdraw(false);
                                    setforfietvalue(e.target.value);
                                  }}
                                  // onChange={()=>{}}
                                  // checked={true}
                                  type="radio"
                                  name="myCheckbox"
                                  value="Forfieted"
                                />
                                <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                                  Forfeit
                                </p>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="flex md:ml-20 items-center">
                            <input
                              className=" md:h-[20px] radio  md:w-[20px] cursor-pointer"
                              //   id={index}
                              onClick={(e) => {
                                setopenforfiet(true);
                                opentable(false);
                                setopenwithdraw(false);
                                setforfietvalue(e.target.value);
                              }}
                              // onChange={()=>{}}
                              // checked={true}
                              type="radio"
                              name="myCheckbox"
                              value="Forfieted"
                            />
                            <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                              Forfeit
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </React.Fragment>
              ))}
            {Object.keys(playoffObj).map((key) => (
              <React.Fragment>
                {playoffObj[key]?._id == matchidslect ? (
                  <>
                    {playoffObj[key]?.schedule_date != null ? (
                      <>
                        {
                        
                        
                        
                        new Date() >= new Date(playoffObj[key]?.schedule_date) ? (
                          <div className="flex md:ml-20 items-center">
                            <input
                              className=" md:h-[20px] radio  md:w-[20px] cursor-pointer"
                              //   id={index}
                              onClick={(e) => {
                                setopenforfiet(true);
                                opentable(false);
                                setopenwithdraw(false);
                                setforfietvalue(e.target.value);
                              }}
                              // onChange={()=>{}}
                              type="radio"
                              name="myCheckbox"
                              value="Forfieted"
                            />
                            <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                              Forfeit
                            </p>
                          </div>
                        ) : (
                          <>
                            <div className="flex md:ml-20 items-center">
                              <input
                                className=" md:h-[20px] radio  md:w-[20px] cursor-pointer"
                                //   id={index}
                                onClick={(e) => {
                                  setopenforfiet(true);
                                  opentable(false);
                                  setopenwithdraw(false);
                                  setforfietvalue(e.target.value);
                                }}
                                // onChange={()=>{}}
                                // checked={true}
                                type="radio"
                                name="myCheckbox"
                                value="Forfieted"
                              />
                              <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                                Forfeit
                              </p>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="flex md:ml-20 items-center">
                          <input
                            className=" md:h-[20px] radio  md:w-[20px] cursor-pointer"
                            //   id={index}
                            onClick={(e) => {
                              setopenforfiet(true);
                              opentable(false);
                              setopenwithdraw(false);
                              setforfietvalue(e.target.value);
                            }}
                            // onChange={()=>{}}
                            // checked={true}
                            type="radio"
                            name="myCheckbox"
                            value="Forfieted"
                          />
                          <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                            Forfeit
                          </p>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
              </React.Fragment>
            ))}

            {Schedule.map((res, index) => (
              <React.Fragment key={index}>
                {res?._id == matchidslect ? (
                  <>
                    {res?.schedule_date != null ? (
                      <>
                        {new Date() >= new Date(res?.schedule_date) ? (
                          <div className="flex md:ml-20 items-center">
                            <input
                              className=" md:h-[20px] radio md:w-[20px] cursor-pointer"
                              //   id={index}
                              onClick={(e) => {
                                setopenwithdraw(true);
                                opentable(false);
                                setopenforfiet(false);
                                setwithdrawvalue(e.target.value);
                              }}
                              type="radio"
                              name="myCheckbox"
                              value="withdrew"
                            />
                            <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                              Withdraw
                            </p>
                          </div>
                        ) : (
                          <>
                            <div className="flex md:ml-20 items-center">
                              <input
                                className=" md:h-[20px] radio md:w-[20px] cursor-pointer"
                                //   id={index}
                                onClick={(e) => {
                                  setopenwithdraw(true);
                                  opentable(false);
                                  setopenforfiet(false);
                                  setwithdrawvalue(e.target.value);
                                }}
                                type="radio"
                                name="myCheckbox"
                                value="withdrew"
                              />
                              <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                                Withdraw
                              </p>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="flex md:ml-20 items-center">
                          <input
                            className=" md:h-[20px] radio md:w-[20px] cursor-pointer"
                            //   id={index}
                            onClick={(e) => {
                              setopenwithdraw(true);
                              opentable(false);
                              setopenforfiet(false);
                              setwithdrawvalue(e.target.value);
                            }}
                            type="radio"
                            name="myCheckbox"
                            value="withdrew"
                          />
                          <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                            Withdraw
                          </p>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
              </React.Fragment>
            ))}
{/* 
            {tiematch &&
              tiematch.map((res, index) => (
                <React.Fragment key={index}>
                  {res?._id == matchidslect ? (
                    <>
                      {res?.schedule_date != null ? (
                        <>
                          {moment(
                            new Date(res?.schedule_date),
                            "DD/MM/YYYY"
                          ).format("M/DD/YYYY") ===
                            moment(new Date(), "DD/MM/YYYY").format(
                              "M/DD/YYYY"
                            ) ||
                          moment(new Date(), "DD/MM/YYYY").format(
                            "M/DD/YYYY"
                          ) >=
                            moment(
                              new Date(res?.schedule_date),
                              "DD/MM/YYYY"
                            ).format("M/DD/YYYY") ? (
                            <div className="flex md:ml-20 items-center">
                              <input
                                className=" md:h-[20px] radio md:w-[20px] cursor-pointer"
                                //   id={index}
                                onClick={(e) => {
                                  setopenwithdraw(true);
                                  opentable(false);
                                  setopenforfiet(false);
                                  setwithdrawvalue(e.target.value);
                                }}
                                type="radio"
                                name="myCheckbox"
                                value="withdrew"
                              />
                              <p className="md:ml-3 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                                Withdraw
                              </p>
                            </div>
                          ) : (
                            <>
                              <div className="flex md:ml-20 items-center">
                                <input
                                  className=" md:h-[20px] radio md:w-[20px] cursor-pointer"
                                  //   id={index}
                                  onClick={(e) => {
                                    setopenwithdraw(true);
                                    opentable(false);
                                    setopenforfiet(false);
                                    setwithdrawvalue(e.target.value);
                                  }}
                                  type="radio"
                                  name="myCheckbox"
                                  value="withdrew"
                                />
                                <p className="md:ml-3 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                                  Withdraw
                                </p>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="flex md:ml-20 items-center">
                            <input
                              className=" md:h-[20px] radio md:w-[20px] cursor-pointer"
                              //   id={index}
                              onClick={(e) => {
                                setopenwithdraw(true);
                                opentable(false);
                                setopenforfiet(false);
                                setwithdrawvalue(e.target.value);
                              }}
                              type="radio"
                              name="myCheckbox"
                              value="withdrew"
                            />
                            <p className="md:ml-3 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                              Withdraw
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </React.Fragment>
              ))} */}
            {Object.keys(playoffObj).map((key) => (
              <React.Fragment>
                {playoffObj[key]?._id == matchidslect ? (
                  <>
                    {playoffObj[key]?.schedule_date != null ||
                    playoffObj[key]?.schedule_date === null ? (
                      <>
                        { new Date() >= new Date(playoffObj[key]?.schedule_date) ? (
                          Round === "division" ? (
                            <div className="flex md:ml-20 items-center">
                              <input
                                className=" md:h-[20px] radio md:w-[20px] cursor-pointer"
                                //   id={index}
                                onClick={(e) => {
                                  setopenwithdraw(true);
                                  opentable(false);
                                  setopenforfiet(false);
                                  setwithdrawvalue(e.target.value);
                                }}
                                type="radio"
                                name="myCheckbox"
                                value="withdrew"
                              />
                              <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                                Withdraw
                              </p>
                            </div>
                          ) : null
                        ) : (
                          <>
                            {/* <div className="flex md:ml-20 items-center">
                  <input
                    className=" md:h-[20px] radio md:w-[20px] cursor-pointer"
                    //   id={index}
                    onClick={(e) => {
                      setopenwithdraw(true);
                      opentable(false);
                      setopenforfiet(false);
                      setwithdrawvalue(e.target.value);
                    }}
                    type="radio"
                    name="myCheckbox"
                    value="withdrew"
                  />
                  <p className="md:ml-3 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                    Withdraw
                  </p>
                </div> */}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="flex md:ml-20 items-center">
                          <input
                            className=" md:h-[20px] radio md:w-[20px] cursor-pointer"
                            //   id={index}
                            onClick={(e) => {
                              setopenwithdraw(true);
                              opentable(false);
                              setopenforfiet(false);
                              setwithdrawvalue(e.target.value);
                            }}
                            type="radio"
                            name="myCheckbox"
                            value="withdrew"
                          />
                          <p className="md:ml-3 ml-1 2xl:text-[28px] md:text-[20px] text-[#fff] font-light font-poppins ">
                            Withdraw
                          </p>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
              </React.Fragment>
            ))}
          </div>
          {opentables ? (
            <>
              <form
                onSubmit={(e) => {
                  // PostMatchscore();
                  e.preventDefault();
                }}
              >
                <div className="">
                  {/* <div className="flex justify-end mb-3 ">
                  <p className="text-lg text-[#fff]">Player</p>
                  <p className="text-lg mx-12 text-[#fff]">Mate</p>
                  <p className="text-lg text-[#fff]">opp1</p>
                  <p className="text-lg mx-12 text-[#fff]">opp2</p>
                </div> */}

                  <ul className=" md:mx-10 lg:mx-10 ">
                    <li>
                      <div className="flex justify-end md:mr-0 mr-1 lg:space-x-10 space-x-3   md:space-x-10 2xl:space-x-10 my-10 text-[#fff] 2xl:text-2xl text-xl ">
                        {leaguetype != "singles" ? (
                          <p className="">My Team</p>
                        ) : (
                          <p className="">Myself</p>
                        )}
                        {leaguetype != "singles" ? (
                          <p className="  ">Opponents</p>
                        ) : (
                          <p className="  ">Opponent</p>
                        )}
                      </div>
                    </li>

                    <li className="flex md:px-10 px-3 mt-3 md:mt-0 justify-between md:mb-5 border-[#7E7777B5]  border-b-2">
                      <div className="2xl:text-[29.42px] md:text-[20px] text-green font-extralight font-[Poppins]">
                      First Set
                      </div>

                      <div className="flex justify-end space-x-14 2xl:space-x-20 mx-[10px] mb-3">
                        <input
                          name="setNo"
                          required
                          autoComplete="off"
                          // id="setNo"
                          className=" w-1/4 h-9 border-2 number border-[#fff] text-center font-[Poppins] p-2 px-3 relative"
                          type="number"
                          min="0"
                          id="input1"
                          maxLength="1"
                          max="99"
                          onInput={(e) => {
                            validateNo(e);
                            jumpToNextInput(e, "input2");
                          }}
                          onChange={(e) => {
                            setmyone(e.target.value);
                          }}
                          //  value={}
                        />

                        <input
                          name="setNo"
                          required
                          id="input2"
                          autoComplete="off"
                          maxLength="1"
                          // id="setNo"
                          className=" number w-1/4 h-9 border-2 border-[#fff] text-center font-[Poppins] p-2 px-3 relative"
                          type="number"
                          min="0"
                          max="99"
                          onInput={(e) => {
                            validateNo(e);
                            jumpToNextInput(e, "input3");
                          }}
                          onChange={(e) => {
                            setoppone(e.target.value);
                          }}
                        />
                      </div>
                    </li>
                    <li className="flex md:px-10 px-3 mt-3 md:mt-0 justify-between md:mb-5 border-[#7E7777B5]  border-b-2">
                      <div className="2xl:text-[29.42px] md:text-[20px] text-green font-extralight font-[Poppins]">
                        Second Set
                      </div>

                      <div className="flex justify-end space-x-14 2xl:space-x-20 mx-[10px] mb-3">
                        <input
                          name="setNo"
                          required
                          autoComplete="off"
                          id="input3"
                          className="w-1/4 h-9 number border-2 border-[#fff] text-center font-[Poppins] p-2 px-3 relative"
                          type="number"
                          min="0"
                          maxLength="1"
                          minLength={1}
                          max="99"
                          onInput={(e) => {
                            validateNo(e);
                            jumpToNextInput(e, "input4");
                          }}
                          onChange={(e) => {
                            setmytwo(e.target.value);
                          }}
                        />

                        <input
                          name="setNo"
                          autoComplete="off"
                          required
                          id="input4"
                          className="w-1/4 h-9 number border-2 border-[#fff] text-center font-[Poppins] p-2 px-3 relative"
                          type="number"
                          maxLength="1"
                          min="0"
                          max="99"
                          onInput={(e) => {
                            validateNo(e);
                            jumpToNextInput(e, "input5");
                          }}
                          onChange={(e) => {
                            setopptwo(e.target.value);
                          }}
                        />
                      </div>
                    </li>
                    <li className="flex md:px-10 mt-3 md:mt-0 px-3 justify-between md:mb-5 border-[#7E7777B5]  border-b-2">
                      <div className="2xl:text-[29.42px] md:text-[20px] text-green font-extralight font-[Poppins]">
                        Third Set
                      </div>

                      <div className="flex justify-end space-x-14 2xl:space-x-20 mx-[10px] mb-3">
                        <input
                          name="setNo"
                          readOnly={ThirdScoreCheck || (mysettwo && oppsetwo) == "" || (mysetone && oppsetone) == "" ? true : false }
                          autoComplete="off"
                          id="input5"
                          value={mysetthree}
                          maxLength="1"
                          className={`w-1/4 h-9 number border-2 border-[#fff] text-center font-[Poppins] p-2 px-3 relative ${ThirdScoreCheck || (mysettwo && oppsetwo) == "" || (mysetone && oppsetone) == "" ? "bg-[gray] cursor-not-allowed" : ""} `}
                          type="number"
                          min="0"
                          max="99"
                          onInput={(e) => {
                            validateNo(e);
                            jumpToNextInput(e, "input6");
                          }}
                          onChange={(e) => {
                            setmythree(e.target.value);
                          }}
                        />

                        <input
                          name="setNo"
                          readOnly={ThirdScoreCheck || (mysettwo && oppsetwo) == "" || (mysetone && oppsetone) == "" ? true : false }
                          autoComplete="off"
                          id="input6"
                          value={oppsetthree}
                          maxLength="1"
                          className={`w-1/4 h-9 number border-2 border-[#fff] text-center font-[Poppins] p-2 px-3 relative ${ThirdScoreCheck || (mysettwo && oppsetwo) == "" || (mysetone && oppsetone) == "" ? "bg-[gray] cursor-not-allowed" : ""} `}
                          type="number"
                          min="1"
                          max="99"
                          onInput={(e) => {
                            validateNo(e);
                          }}
                          onChange={(e) => {
                            setoppthree(e.target.value);
                          }}
                        />
                      </div>
                    </li>
                  </ul>

                  <div className="flex my-5 justify-center">
                    {/* {console.log("====>>>",(mysetone > oppsetone ? true : false) )} */}
                    {/* {console.log("====>>>",(mysettwo > oppsetwo ? true : false) )} */}

                    {(mysetone > oppsetone ? true : false) &&
                    (mysettwo > oppsetwo ? true : false) &&
                    mysetone !== "" &&
                    oppsetone !== "" &&
                    mysettwo !== "" &&
                    oppsetwo !== "" ? (
                      <>
                        {(mysetone === "6" ||
                          mysetone === "7" ||
                          oppsetone === "6" ||
                          oppsetone === "7") &&
                        (mysettwo === "6" ||
                          mysettwo === "7" ||
                          oppsetwo === "6" ||
                          oppsetwo === "7") &&
                        (mysetthree === "6" ||
                          mysetthree === "7" ||
                          mysetthree === "" ||
                          oppsetthree === "6" ||
                          oppsetthree === "" ||
                          oppsetthree === "7" ||
                          oppsetthree === "") &&
                        ((mysetone === "6" && parseInt(oppsetone) <= 4) ||
                          (mysetone === "7" && parseInt(oppsetone) >= 5) ||
                          oppsetone === "" ||
                          (oppsetone === "6" && parseInt(mysetone) <= 4) ||
                          (oppsetone === "7" && parseInt(mysetone) >= 5) ||
                          mysetone === "") &&
                        ((mysettwo === "6" && parseInt(oppsetwo) <= 4) ||
                          (mysettwo === "7" && parseInt(oppsetwo) >= 5) ||
                          oppsetwo === "" ||
                          (oppsetwo === "6" && parseInt(mysettwo) <= 4) ||
                          (oppsetwo === "7" && parseInt(mysettwo) >= 5) ||
                          mysettwo === "") &&
                        ((mysetthree === "6" && parseInt(oppsetthree) <= 4) ||
                          (mysetthree === "7" && parseInt(oppsetthree) >= 5) ||
                          oppsetthree === "" ||
                          (oppsetthree === "6" && parseInt(mysetthree) <= 4) ||
                          (oppsetthree === "7" && parseInt(mysetthree) >= 5) ||
                          mysetthree === "")
                          &&
                          !(mysetone == oppsetone )
                          ? (
                            mysetthree !== "" ||
                            oppsetthree !== ""  ?
                            mysetthree !== "" &&
                            oppsetthree !== "" ?
      
                              <button
                                className="font-medium bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                                //  type="submit"
                                onClick={() => {
                                  // setnewpopup(false);
                                  setopensubmit(true);
                                }}
                              >
                                Submit
                              </button>
                              :
                              <button
                              className="font-medium opacity-50 bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                              //  type="button"
                              disabled
                            >
                              Submit
                            </button>
                            :
                            <button
                            className="font-medium bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                            //  type="submit"
                            onClick={() => {
                              // setnewpopup(false);
                              setopensubmit(true);
                            }}
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            className="font-medium bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                            //  type="submit"
                            onClick={() => {
                              // setnewpopup(false);
                              toast.error(`Please Check Your Score.`);
                            }}
                          >
                            Submit
                          </button>
                        )}
                      </>
                    ) : (mysetone < oppsetone ? true : false) &&
                      (mysettwo < oppsetwo ? true : false) &&
                      mysetone !== "" &&
                      oppsetone !== "" &&
                      mysettwo !== "" &&
                      oppsetwo !== "" ||
                      mysetthree !== "" &&
                      oppsetthree !== ""  ? (
                      (mysetone === "6" ||
                        mysetone === "7" ||
                        oppsetone === "6" ||
                        oppsetone === "7") &&
                      (mysettwo === "6" ||
                        mysettwo === "7" ||
                        oppsetwo === "6" ||
                        oppsetwo === "7") &&
                      (mysetthree === "6" ||
                        mysetthree === "" ||
                        mysetthree === "7" ||
                        mysetthree === "" ||
                        oppsetthree === "6" ||
                        oppsetthree === "" ||
                        oppsetthree === "7" ||
                        oppsetthree === "") &&
                      ((mysetone === "6" && parseInt(oppsetone) <= 4) ||
                        (mysetone === "7" && parseInt(oppsetone) >= 5) ||
                        oppsetone === "" ||
                        (oppsetone === "6" && parseInt(mysetone) <= 4) ||
                        (oppsetone === "7" && parseInt(mysetone) >= 5) ||
                        mysetone === "") &&
                      ((mysettwo === "6" && parseInt(oppsetwo) <= 4) ||
                        (mysettwo === "7" && parseInt(oppsetwo) >= 5) ||
                        oppsetwo === "" ||
                        (oppsetwo === "6" && parseInt(mysettwo) <= 4) ||
                        (oppsetwo === "7" && parseInt(mysettwo) >= 5) ||
                        mysettwo === "") &&
                      ((mysetthree === "6" && parseInt(oppsetthree) <= 4) ||
                        (mysetthree === "7" && parseInt(oppsetthree) >= 5) ||
                        oppsetthree === "" ||
                        (oppsetthree === "6" && parseInt(mysetthree) <= 4) ||
                        (oppsetthree === "7" && parseInt(mysetthree) >= 5) ||
                        mysetthree === "")
                        &&
                        !(mysetone == oppsetone)
                        &&
                        !(mysettwo == oppsetwo)
                        &&
                        !(mysetthree == "7" &&  oppsetthree == "7")
                        ? (
                          mysetthree !== ""  ?
                      mysetthree !== "" &&
                      oppsetthree !== "" ?

                        <button
                          className="font-medium bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                          //  type="submit"
                          onClick={() => {
                            // setnewpopup(false);
                            if((mysettwo && oppsetwo) == "" || (mysetone && oppsetone) == "" ){
                              setmythree("")
                              setoppthree("")
                            }
                            setopensubmit(true);
                          }}
                        >
                          Submit
                        </button>
                        :
                        <button
                        className="font-medium opacity-50 bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        //  type="button"
                        disabled
                      >
                        Submit
                      </button>
                      :
                      <button
                      className="font-medium bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                      //  type="submit"
                      onClick={() => {
                        // setnewpopup(false);
                        setopensubmit(true);
                      }}
                    >
                      Submit
                    </button>
                        
                      ) : (
                        <button
                          className="font-medium bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                          //  type="submit"
                          onClick={() => {
                            // setnewpopup(false);
                            !(mysetone == oppsetone)
                            &&
                            !(mysettwo == oppsetwo)
                            &&
                            !(mysetthree == "7" &&  oppsetthree == "7")
                            ?
                            toast.error(`Please Check Your Score.`)
                            :
                            toast.error(`Score cannot be equal.`)

                          }}
                        >
                          Submit
                        </button>
                      )
                    ) : mysetthree == "" ||
                      oppsetthree == "" ||
                      mysetone == "" ||
                      oppsetone == "" ||
                      mysettwo == "" ||
                      oppsetwo == "" ? (
                      <button
                        className="font-medium opacity-50 bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        //  type="button"
                        disabled
                      >
                        Submit
                      </button>
                    ) : mysetthree != oppsetthree &&
                      mysetone != oppsetone &&
                      mysettwo != oppsetwo ? (
                      (mysetone === "6" ||
                        mysetone === "7" ||
                        oppsetone === "6" ||
                        oppsetone === "7") &&
                      (mysettwo === "6" ||
                        mysettwo === "7" ||
                        oppsetwo === "6" ||
                        oppsetwo === "7") &&
                      (mysetthree === "6" ||
                        mysetthree === "" ||
                        mysetthree === "7" ||
                        mysetthree === "" ||
                        oppsetthree === "6" ||
                        oppsetthree === "" ||
                        oppsetthree === "7" ||
                        oppsetthree === "") &&
                      ((mysetone === "6" && parseInt(oppsetone) <= 4) ||
                        (mysetone === "7" && parseInt(oppsetone) >= 5) ||
                        oppsetone === "" ||
                        (oppsetone === "6" && parseInt(mysetone) <= 4) ||
                        (oppsetone === "7" && parseInt(mysetone) >= 5) ||
                        mysetone === "") &&
                      ((mysettwo === "6" && parseInt(oppsetwo) <= 4) ||
                        (mysettwo === "7" && parseInt(oppsetwo) >= 5) ||
                        oppsetwo === "" ||
                        (oppsetwo === "6" && parseInt(mysettwo) <= 4) ||
                        (oppsetwo === "7" && parseInt(mysettwo) >= 5) ||
                        mysettwo === "") &&
                      ((mysetthree === "6" && parseInt(oppsetthree) <= 4) ||
                        (mysetthree === "7" && parseInt(oppsetthree) >= 5) ||
                        oppsetthree === "" ||
                        (oppsetthree === "6" && parseInt(mysetthree) <= 4) ||
                        (oppsetthree === "7" && parseInt(mysetthree) >= 5) ||
                        mysetthree === "") ? (
                        <button
                          className="font-medium bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                          //  type="submit"
                          onClick={() => {
                            // setnewpopup(false);
                            if((mysettwo && oppsetwo) == "" || (mysetone && oppsetone) == "" ){
                              setmythree("")
                              setoppthree("")
                            }
                            setopensubmit(true);
                          }}
                        >
                          Submit
                        </button>
                      ) : (
                        <button
                          className="font-medium bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                          //  type="submit"
                          onClick={() => {
                            // setnewpopup(false);
                            toast.error(`Please Check Your Score.`);
                          }}
                        >
                          Submit
                        </button>
                      )
                    ) : (
                      <button
                        className="font-medium opacity-50 bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        //  type="button"
                        disabled
                      >
                        Submit
                      </button>
                    )}

                    {/* {mysetone == "" ||
                    mysettwo == "" ||
                    oppsetone == "" ||
                    oppsetwo == "" ||
                    (mysetthree && oppsetthree == "") ||
                    (oppsetthree && mysetthree == "") ? (
                      <button
                        className="font-medium opacity-50 bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        //  type="button"
                        disabled
                      >
                        Submit
                      </button>
                    ) : ishome === null ? (
                      <button
                        className="font-medium opacity-50 bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        //  type="button"
                        disabled
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        className="font-medium bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        //  type="submit"
                        onClick={() => {
                          // setnewpopup(false);
                          setopensubmit(true);
                        }}
                      >
                        Submit
                      </button>
                    )} */}
                  </div>

                  {/* <div className="flex my-5 justify-center">
                    <button
                      className="font-medium bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                      type="button"
                      onClick={() => {
                        // setnewpopup(false);
                        setopensubmit(true);
                      }}
                    >
                      Submit
                    </button>
                  </div> */}
                </div>
                <Popup
                  open={opensubmit}
                  closeOnDocumentClick={false}
                  className=""
                  contentStyle={{
                    width: isMobileView ? "100%" : "50%",
                    background: "none",
                    border: "none",
                  }}
                  onClose={() => {
                    setopensubmit(false);
                  }}
                >
                  <div className=" border-2 border-[white] px-1 w-3/4 m-auto bg-[#31343B] overflow-auto max-h-[600px] rounded  relative">
                    <div className="float-right   ">
                      <button
                        className=" text-[#fff] text-2xl absolute  font-poppins  md:leading-[2] top-[0px] right-[10px]"
                        // type="button"
                        onClick={() => {
                          // setopensubmit(false);
                          // setnewpopup(true)
                          setopensubmit(false);
                          getbuttondisable(false);
                        }}
                      >
                        X
                      </button>
                    </div>
                    <p className=" text-center text-[#fff] font-poppins  text-3xl md:text-2xl mt-16">
                      Please Confirm
                      <br />
                    </p>
                    <p className=" text-center text-[#fff] my-5">
                      Once submitted it cannot be changed.
                    </p>
                    <div className="flex justify-center text-lg  my-10 items-center">
                      <button
                        onClick={() => {
                          setopensubmit(false);
                          getbuttondisable(false);
                        }}
                        className="bg-[red] px-3 mx-5 rounded-md"
                      >
                        No
                      </button>
                      {!buttondisable ? (
                        <button
                          // type="submit"
                          className="bg-green px-3 mx-5 rounded-md"
                          onClick={() => {
                            PostMatchscore(id);
                            setDefaultMessageSent();
                          }}
                        >
                          Yes
                        </button>
                      ) : (
                        <button
                          // type="submit"
                          disabled
                          className="flex items-center bg-green px-3 mx-5 rounded-md"
                          // onClick={() => {
                          //   PostMatchscore();
                          // }}
                        >
                          Yes..
                          <svg
                            className="animate-spin mr-1 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </Popup>
              </form>
            </>
          ) : (
            ""
          )}
          {openforfiet ? (
            <>
              <form
                onSubmit={(e) => {
                  // PostMatchscore();
                  e.preventDefault();
                }}
              >
                <div>
                  <p className="text-[#fff] 2xl:text-4xl md:text-2xl text-center my-4">
                    Forfeited By
                  </p>
                  <div className="flex justify-around">
                    <div className="flex items-center">
                      <input
                        className=" md:h-[25px] radio cursor-pointer  md:w-[25px]"
                        type="radio"
                        name="Checkbox"
                        // value={matchdetails?.my_team?._id}
                        onClick={(e) => {
                          setmyforfietvalue(matchdetails?.my_team?._id);
                        }}
                      />
                      {leaguetype != "singles" ? (
                        <label className=" ml-2 text-[#fff] text-lg">
                          My Team
                        </label>
                      ) : (
                        <label className=" ml-2 text-[#fff] text-lg">
                          Myself
                        </label>
                      )}
                    </div>
                    <div className="flex items-center">
                      <input
                        className=" md:h-[25px] radio cursor-pointer  md:w-[25px]"
                        type="radio"
                        name="Checkbox"
                        // value={matchdetails?.opponent_team?._id}
                        onClick={(e) => {
                          setoppforfietvalue(matchdetails?.opponent_team?._id);
                        }}
                      />
                      {leaguetype != "singles" ? (
                        <label className="ml-2 text-[#fff] text-lg">
                          {" "}
                          Opponent Team
                        </label>
                      ) : (
                        <label className="ml-2 text-[#fff] text-lg">
                          {" "}
                          Opponent
                        </label>
                      )}
                    </div>
                  </div>
                  {/* <div className="flex md:ml-20 items-center my-4">
              <input
                className=" md:h-[25px] radio  md:w-[25px]"
                //   id={index}
                // onClick={() => {
                //   setopenforfiet(true);
                //   opentable(false);
                //   setopenwithdraw(false);
                // }}
                type="radio"
                name="Checkbox"
              />
              <p className="md:ml-3 text-[28px]  text-[#fff] font-light font-poppins ">
                player1  player2
              </p>
            </div>
            <div className="flex md:ml-20 items-center my-4">
              <input
                className=" md:h-[25px] radio  md:w-[25px]"
                //   id={index}
                // onClick={() => {
                //   setopenforfiet(true);
                //   opentable(false);
                //   setopenwithdraw(false);
                // }}
                type="radio"
                name="Checkbox"
              />
              <p className="md:ml-3 text-[28px]  text-[#fff] font-light font-poppins ">
                opponent1 opponent2
              </p>
              </div> */}
                  <div className="flex my-5 justify-center">
                    {(myforfietvalue == "" && oppforfietvalue == "") ||
                    forfietvalue == "" ? (
                      <button
                        // onClick={()=> }
                        className="font-medium opacity-50 bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        // type="button"
                        disabled
                      >
                        Submit
                      </button>
                    ) : ishome === null ? (
                      <button
                        className="font-medium opacity-50 bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        //  type="button"
                        disabled
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        className="font-medium bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        //  type="submit"
                        onClick={() => {
                          // setnewpopup(false);
                          setopenforfietpopup(true);
                        }}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
                <Popup
                  open={openforfietpopup}
                  closeOnDocumentClick={false}
                  className=""
                  contentStyle={{
                    width: isMobileView ? "100%" : "50%",
                    background: "none",
                    border: "none",
                  }}
                  onClose={() => {
                    setopenforfietpopup(false);
                  }}
                >
                  <div className="border-2 border-[white] px-1 w-3/4 m-auto bg-[#31343B] move overflow-auto max-h-[600px] rounded  relative">
                    <div className="float-right   ">
                      <button
                        className=" text-[#ffffff] text-2xl absolute font-poppins  md:leading-[2]  top-[0px] right-[10px]"
                        // type="button"
                        onClick={() => {
                          // setopensubmit(false);
                          // setnewpopup(true)
                          setopenforfietpopup(false);
                          getbuttondisable(false);
                        }}
                      >
                        X
                      </button>
                    </div>
                    <p className=" text-center text-[#fff] font-poppins  text-3xl md:text-2xl mt-16">
                       Please Confirm
                      <br />
                    </p>
                    <p className=" text-center text-[#fff] my-5">
                      Once submitted it cannot be changed.
                    </p>
                    <div className="flex justify-center text-lg  my-10 items-center">
                      <button
                        onClick={() => {
                          setopenforfietpopup(false);
                          getbuttondisable(false);
                        }}
                        className="bg-[red] px-3 mx-5 rounded-md"
                      >
                        No
                      </button>
                      {!buttondisable ? (
                        <button
                          // type="submit"
                          className="bg-green px-3 mx-5 rounded-md"
                          onClick={() => {
                            PostMatchscore(id);
                            setDefaultMessageSent();
                          }}
                        >
                          Yes
                        </button>
                      ) : (
                        <button
                          // type="submit"
                          disabled
                          className="flex items-center bg-green px-3 mx-5 rounded-md"
                          // onClick={() => {
                          //   PostMatchscore();
                          // }}
                        >
                          Yes..
                          <svg
                            className="animate-spin mr-1 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </Popup>
              </form>
            </>
          ) : (
            <></>
          )}
          {openwithdraw ? (
            // Round === "division" ? (
              <form
                onSubmit={(e) => {
                  // PostMatchscore();
                  e.preventDefault();
                }}
              >
                <div>
                  <p className="text-[#fff] 2xl:text-4xl md:text-2xl text-center my-4">
                    Withdrew By
                  </p>
                  <div className="flex justify-around">
                    <div className="flex items-center">
                      <input
                        className=" md:h-[25px] radio cursor-pointer  md:w-[25px]"
                        type="radio"
                        name="Checkbox"
                        onClick={(e) => {
                          setmywithdrawvalue(matchdetails?.my_team?._id);
                        }}
                      />
                      {leaguetype != "singles" ? (
                        <label className=" ml-2 text-[#fff] text-lg">
                          My Team
                        </label>
                      ) : (
                        <label className=" ml-2 text-[#fff] text-lg">
                          Myself
                        </label>
                      )}
                    </div>
                    <div className="flex items-center">
                      <input
                        className=" md:h-[25px] radio cursor-pointer  md:w-[25px]"
                        type="radio"
                        name="Checkbox"
                        onClick={(e) => {
                          setoppwithdrawvalue(matchdetails?.opponent_team?._id);
                        }}
                      />
                      {leaguetype != "singles" ? (
                        <label className="ml-2 text-[#fff] text-lg">
                          Opponent Team
                        </label>
                      ) : (
                        <label className="ml-2 text-[#fff] text-lg">
                          {" "}
                          Opponent
                        </label>
                      )}
                    </div>
                  </div>
                  {/* <div className="flex md:ml-20 items-center my-4">
              <input
                className=" md:h-[25px] radio  md:w-[25px]"
                //   id={index}
                // onClick={() => {
                //   setopenforfiet(true);
                //   opentable(false);
                //   setopenwithdraw(false);
                // }}
                type="radio"
                name="Checkbox"
              />
              <p className="md:ml-3 text-[28px]  text-[#fff] font-light font-poppins ">
                player1  player2
              </p>
            </div>
            <div className="flex md:ml-20 items-center my-4">
              <input
                className=" md:h-[25px] radio  md:w-[25px]"
                //   id={index}
                // onClick={() => {
                //   setopenforfiet(true);
                //   opentable(false);
                //   setopenwithdraw(false);
                // }}
                type="radio"
                name="Checkbox"
              />
              <p className="md:ml-3 text-[28px]  text-[#fff] font-light font-poppins ">
                opponent1 opponent2
              </p>
              </div> */}
                  <div className="flex my-5 justify-center">
                    {mywithdrawvalue == "" && oppwithdrawvalue == "" ? (
                      <button
                        className="font-medium opacity-50 bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        // type="button"
                        disabled
                      >
                        Submit
                      </button>
                    ) : ishome === null ? (
                      <button
                        className="font-medium opacity-50 bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        //  type="button"
                        disabled
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        className="font-medium bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                        //  type="submit"
                        onClick={() => {
                          // setnewpopup(false);
                          setopenwithdrawpopup(true);
                        }}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
                <Popup
                  open={openwithdrawpopup}
                  closeOnDocumentClick={false}
                  className=""
                  contentStyle={{
                    width: isMobileView ? "100%" : "50%",
                    background: "none",
                    border: "none",
                  }}
                  onClose={() => {
                    setopenwithdrawpopup(false);
                  }}
                >
                  <div className=" border-2 border-[white] px-1 w-3/4 m-auto bg-[#31343B] move overflow-auto max-h-[600px] rounded  relative">
                    <div className="float-right   ">
                      <button
                        className=" text-[#ffffff] text-2xl absolute font-poppins  md:leading-[2] top-[0px] right-[10px]"
                        // type="button"
                        onClick={() => {
                          // setopensubmit(false);
                          // setnewpopup(true)
                          setopenwithdrawpopup(false);
                          getbuttondisable(false);
                        }}
                      >
                        X
                      </button>
                    </div>
                    <p className=" text-center text-[#fff] font-poppins  text-3xl md:text-2xl mt-16">
                      Please Confirm
                      <br />
                    </p>
                    <p className=" text-center text-[#fff] my-5">
                      Once submitted it cannot be changed.
                    </p>
                    <div className="flex justify-center text-lg  my-10 items-center">
                      <button
                        onClick={() => {
                          setopenwithdrawpopup(false);
                          getbuttondisable(false);
                        }}
                        className="bg-[red] px-3 mx-5 rounded-md"
                      >
                        No
                      </button>

                      {!buttondisable ? (
                        <button
                          // type="submit"
                          className="bg-green px-3 mx-5 rounded-md"
                          onClick={() => {
                            PostMatchscore(id);
                            setDefaultMessageSent();
                          }}
                        >
                          Yes
                        </button>
                      ) : (
                        <button
                          // type="submit"
                          disabled
                          className="flex items-center bg-green px-3 mx-5 rounded-md"
                          // onClick={() => {
                          //   PostMatchscore();
                          // }}
                        >
                          Yes..
                          <svg
                            className="animate-spin mr-1 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </Popup>
              </form>
          
          ):null}
        </div>
      </Popup>

      <Popup
        open={mapshow}
        id="round"
        contentStyle={{ width: isMobileView ? "100%" : "50%", background: "none", border: "none",    padding:  isMobileView ? "15px" : "0px" }}
        closeOnDocumentClick
        onClose={() => {
          setmapshow(false);
        }}
      >
        <div className={ `h-[519px]   w-[${isMobileView ? "709px": "0px" } ]  overflow-auto   rounded  relative`}>
          {/* <button
            className=" text-[#000000] text-2xl absolute mx-11 z-10 right-[10px] top-[5px]"
            onClick={() => {
              setmapshow(false);
            }}
          >
            X
          </button> */}
          {lat === null ? (
            <div className="MapFull">
              <div className="relative">
                <img alt="" className="w-[709px] h-[519px]" src={mapblur} />
                <p className="text-[#000] flex justify-center items-center absolute top-[50%] left-[39%] text-xl">
                  Map Not Available
                </p>
              </div>
            </div>
          ) : (
            <div className="MapFull">
              <MapContainer
                mylat={parseFloat(lat)}
                mylng={parseFloat(lng)}
                style={mapStyles}
                Mapkey={MapKey}
              />
            </div>
          )}
        </div>
      </Popup>
      <Popup
        open={time}
        id="round"
        closeOnDocumentClick={false}
        contentStyle={{ width: isMobileView ? "100%" : "70%", background: "none", border: "none" ,    padding:  isMobileView ? "15px" : "0px" }}
        onClose={() => {
          settime(false);
        }}
      >
        <div className="md:ml-[47%]  h-[350px] right-0 left-0 flex flex-col   w-[430px] px-1 overflow-auto  bg-[#ffffff]  rounded  relative">
          <button
            className=" text-[#000000] text-lg absolute z-10 right-[10px] top-[5px]"
            onClick={() => {
              settime(false);
              setDateEx4("00:00");
              // setvalidatetym(false)
            }}
          >
            <FaTimes />
          </button>

          <div className="flex text-center text-black font-extrabold px-3 mt-7 justify-center">
            Choose Homecourt and Time For This Match.
          </div>
          <div className="flex justify-between mt-7 mx-4">
            <span className="mt-3">Set Time </span>
            <span className="mt-2 ">
              {/* <TimePicker
                value={selectedTime}
                use12Hours
                minuteStep={15}
                showSecond={false}
                onChange={handleChange}
              /> */}
              <div onClick={()=> {
              if(!isOpen){
                setIsOpen(true)
              }
            }}>
              <TimePicker
               className="new-dateinput cursor-pointer"
               value={selectedTime}
               placeholder="HH:mm"
               open={isOpen}
               use12Hours
               minuteStep={15}
               showSecond={false}
               onChange={handleChange}
               onOpen={()=>{setIsOpen(true)}}
              //  onClose={()=>{alert("ssssssssssss")}}
               onAmPmChange={()=>{setIsOpen(!isOpen)}}
               focusOnOpen={false}
               autoFocus={false}
               inputReadOnly={true}
             />   
             </div> 
            </span>
          </div>

          <div className=" items-center my-4 flex justify-between  mx-4 ">
            <span>Home Court </span>
            <Select
              isRequired={true}
              id="location"
              options={homecourtdrop}
              placeholder="Home Court"
              onChange={(e) => {
                setIsHome(e);
                setMyhomecourt(e);
              }}
              getOptionLabel={(homecourtdrop) =>   homecourtdrop?.displayName   + " - " + homecourtdrop?.zipcode}
              getOptionValue={(homecourtdrop) => homecourtdrop._id}
              value={ishome}
              // defaultValue={ishome}
              // defaultInputValue={ishome}
              isSearchable={true}
              // isClearable
              isMulti={false}
              className=" bg-[#f1f1f1] text-[#837575] w-3/5 rounded-lg focus:outline-none"
              // menuIsOpen={}
              onMenuOpen={() => {
                setIsHome(null);
              }}
            />
          </div>
          <div className=" justify-center flex my-7">
            {selectedTime === null ? (
              <button
                className="font-medium  bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                type="submit"
                onClick={() => {
                  setvalidatetym();
                }}
              >
                Set Time & Homecourt
              </button>
            ) : (
              <>
                {loaderstartsetdate ? (
                  <button
                    className="font-medium  bg-green md:px-16  px-3 md:w-auto  py-2 rounded-lg "
                    type="button"
                  >
                    <span className="flex items-center justify-center">
                      Set Time & Homecourt...{" "}
                      <svg
                        className="animate-spin mr-1 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </span>
                  </button>
                ) : (
                  <button
                    className="font-medium  bg-green md:px-16  px-3 md:w-auto  py-2 rounded-lg "
                    type="submit"
                    onClick={() => {
                      if (ishome == null) {
                        toast.error("Please Select Homecourt");
                      } else {
                        ChatWindowMessage();
                        PostDate(value, selectedTime);
                        Removevalidate();
                        setDefaultMessageSent(true);
                        handleButtonClick();
                        setChatWidget();
                        NewSetMessage();
                        getGroupDeatails(matchid);
                        getGroupOfUser("");
                        setdatetym(null);
                        // setChange(null);
                        SettymOnClick(null);
                        removedate();
                        setButtonText("Purposed Dates");
                        removetym();
                        setDateEx4("00:00");
                        // settime(false);
                      }
                    }}
                  >
                    Set Time & Homecourt
                  </button>
                )}
              </>
            )}
          </div>
          {validatetym ? (
            <div className=" text-center text-[red]">
              *Please Provide Match Timings
            </div>
          ) : (
            ""
          )}
        </div>
      </Popup>
    
      <Popup
        open={Daytime}
        id="round"
        closeOnDocumentClick={false}
        contentStyle={{ width: isMobileView ? "100%" : "70%", background: "none", border: "none" ,     padding:  isMobileView ? "15px" : "0px" }}
        onClose={() => {
          setDaytime(false);
        }}
      >
        <div
          id="style-1"
          className="md:ml-[47%] h-[180px] right-0 left-0 flex flex-col  md:w-[350px] px-1 overflow-auto  bg-[#ffffff]  rounded  relative"
        >
          <button
            className=" text-[#000000] text-lg absolute z-10 right-[10px] top-[5px]"
            onClick={() => {
              setDaytime(false);
              removetym(false);
              setDateEx4("00:00");
              setIsOpen(false);
              setIncorrectTym(false);
              setSelectedTime(
                moment().set({    
                   hour: 19,
                  minute: 0,}).tz(TIMEZONE_URL)
             );

            }}
          >
            <FaTimes />
          </button>
   
        
           <div className="flex flex-col text-center text-black font-extrabold px-3 mt-3 justify-center">
            <span>
            Choose A Match Time
            </span>
            {IncorrectTym &&
           <span className="flex text-center text-[9px] text-black font-extrabold px-3 mt-1 text-[red] justify-center">
           *You can book a time only after 4 hours from now.
           </span>
          }
          </div>
       
         
          <div className="flex justify-around">
            {/* <span className="mt-3">Set Time </span> */}
            <span className="mt-5 flex relative ">
           
            {/* {isOpen && ( */}
            <div onClick={()=> {
              if(!isOpen){
                setIsOpen(true)
              }
            }}>
               <TimePicker
        className="new-dateinput cursor-pointer"
        value={selectedTime}
        placeholder="HH:mm"
        open={isOpen}
        use12Hours
        minuteStep={15}
        showSecond={false}
        onChange={handleChange}
        onOpen={() => setIsOpen(true)}
        onAmPmChange={() => setIsOpen(!isOpen)}
        focusOnOpen={false}
        autoFocus={false}
        inputReadOnly={true}
      />
             </div>   
            {/* )} */}
             {/* <span > */}
            
              {/* </span> */}
             
              
            </span>

            {/* <TimeInput
              value={dateEx4}
              step={900}
              eachInputDropdown
              onChange={(dateString) => setDateEx4(dateString)}
            /> */}
          </div>
          <div className="flex justify-center my-5">
            {selectedTime === null ? (
              <button
                className="font-medium  bg-green md:px-16 px-3 md:w-auto  py-2 rounded-lg "
                type="submit"
                onClick={() => {
                  setvalidatetym();
                  setIsOpen(false)
                }}
              >
                 Propose time
              </button>
            ) : (
            IncorrectTym == true ?
              <button
              disabled
              className="font-medium  bg-[#729072] md:px-16 px-3 md:w-auto  py-1 rounded-lg "
              type="submit"
           
            >
             Propose time
            </button>
            :
             <button
                className="font-medium  bg-green md:px-16 px-3 md:w-auto  py-1 rounded-lg "
                type="submit"
                onClick={(e) => {
                  setDaytime(false);
                  setdatetym(value);
                  EmptySetDate();
                  setDefaultMessage(true);
                  removetym(false);
                  // SettymOnClick(true);
                  handleDateChange(tymOnClick);
                  tileClassName(tymOnClick);
                  setIsOpen(false);
                  setIncorrectTym(false);
                }}
              >
               Propose time
              </button>
            
             
            )}

            {/* {dateEx4 === "00:00" ? (
              <button
                className="font-medium  bg-green md:px-16 px-3 md:w-auto  py-1 rounded-lg "
                type="submit"
                onClick={() => {
                  setvalidatetym();
                }}
              >
                Set Date
              </button>
            ) : (
              <button
                className="font-medium  bg-green md:px-16 px-3 md:w-auto  py-1 rounded-lg "
                type="submit"
                onClick={() => {
                  PostDate(value, dateEx4);
                  removetym();
                  setDateEx4("00:00");
                  settime(false);
                }}
              >
                Set Date
              </button>
            )} */}
          </div>
          
   
         
          {validatetym ? (
            <div className=" text-center text-[red]">
              Please Provide Match Timings*
            </div>
          ) : (
            ""
          )}
        </div>
      </Popup>


      {/* {defaultMessageSent && (
        <ChatWindow
          defaultMessageSent={defaultMessageSent}
          title={grpPlayerName}
          id={grp_Id}
          Name={grpName}
          handleNewUserMessage={DefaultMessage}
          opponent={opponent}
          getpurpose={getpurpose}
        />
      )} */}
    </div>
  );
});
export default MyMatch;
