/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import "../MatchPage/style.css";
import Calendar from "react-calendar";
import { observer } from "mobx-react";
import bgroger from "../assets/image/matchpage.webp";
import "reactjs-popup/dist/index.css";
import "react-calendar/dist/Calendar.css";
import { useAuthStore } from "../Auth/store/Auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  MyMatchPageStore,
  useMyMatchPageStore,
  MyMatchPageStoreContext,
} from "./store/MyMatchPageStore";
import Moment from "react-moment";
import { format } from "date-fns";
import moment from "moment";
import imageLoader from "../assets/image/MessageLoader.gif"
const TIMEZONE_URL = process.env.REACT_APP_TIMEZONE_LIVE;
const MyCalender = observer((props) => {
 
  const { authapproved, user } = useAuthStore();
  const dateUpcoming = localStorage?.getItem("upcoming_date")
  const maxDate = new Date();
  const minDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 6); // add 6 months to the current date
  minDate.setMonth(minDate.getMonth() - 3); // add 6 months to the current date
  const myNormalArray = [];
  const Schedule_Time = [];
  const navigate = useNavigate();
  const {
    Matchdata,
    matchLeague,
    SelectedDate,
    datesss,
    MatchDate,
    time,
    setRoutes,
    getupcomingdate,
    upcoming_date,
    subcription_count,
    upcoming_match_date,
    upcomingLeague,
    loadMatch,
    Check_Upcoming_Dates,
    getSelectedDate,
    valueCheck,
    getmatchdata,
    gettoken,
    getuser,
    getDateData,
  } = useMyMatchPageStore();
  const location = useLocation();
  const pattern = /^\/\w+/;
  const result = location?.pathname?.match(pattern);
  const momentsss = require('moment-timezone');
  
  useEffect(() => {
    if (authapproved) {
      gettoken(localStorage.getItem("token"));
      getuser(JSON.parse(localStorage.getItem("user")));
    }
  }, [authapproved]);

  useEffect(() => {
    gettoken(localStorage.getItem("token"));
    getuser(JSON.parse(localStorage.getItem("user")));
    valueCheck(result);
  }, []);

  const targetDate = new Date(SelectedDate);

  useEffect(() => {
    getupcoming();
    handleDayClick();
  }, []);
  
  
  const [value, setChange] = useState(new Date());
  const [CurrentDate, setCurrentDate] = useState(false);
  const [CurrentMonth, setCurrentMonth] = useState(false);
  const [CurrentYear, setCurrentYear] = useState(false);


  const [demo, setDemo] = useState(new Date());

  for (const date of Matchdata) {
    myNormalArray.push(new Date(date));
  }
  for (const date of time) {
    Schedule_Time.push(date);
  }

  const tileContent = ({ date, view }) => {
    const dates = myNormalArray?.filter((d) => d.getTime() === date.getTime());
    const events = Schedule_Time.find(
      (event) =>
        new Date(event?.schedule_date).toDateString() === date.toDateString()
    );
    // const newtym = ["2023-04-12T12:14:06.157Z", "2023-04-13T09:17:27.590Z"]
    // console.log("date", events?.schedule_time);
    if (
      view === "month" &&
      dates.some((selected) => selected.toDateString() === date.toDateString())
    ) {
      if (events) {
        const tooltipRef = React.createRef();
        return (
          <>
            <div
              className="back-circle"
              ref={tooltipRef}
              style={{ backgroundColor: "#ff0000" }}
            ></div>
            <div className="tooltip">
            {moment(
          momentsss(events?.schedule_time).tz(TIMEZONE_URL) ).format("hh:mm A")}
            </div>
          </>
        );
      }
    } else {
      return null;
    }
  };

  const handleMonthClick = (date) => {
    const day =  new Date().toISOString().slice(0, 10);
    getSelectedDate("Invalid Date");
    getDateData(day, user?._id);

    if (date === undefined) {
      const month = new Date().getMonth();
      const year = new Date().getFullYear();
      getmatchdata(month, year);
    } else {
      const month = new Date(date.activeStartDate).getMonth();
      const year = new Date(date.activeStartDate).getFullYear();
      getmatchdata(month, year);
    }
  };
  const getupcoming = (date) => {
    const day =  new Date().toISOString().slice(0, 10);
    getSelectedDate("Invalid Date");
    getDateData(day, user?._id);

    if (date === undefined) {
      const month = new Date().getMonth();
      const year = new Date().getFullYear();
      getupcomingdate(month, year);
    } else {
      const month = new Date(date.activeStartDate).getMonth();
      const year = new Date(date.activeStartDate).getFullYear();
      getupcomingdate(month, year);
    }
  };

  const handleDayClick = (date) => {
    if (date != undefined) {
      console.log("CurrentDate",new Date(), date);

      setCurrentDate( new Date() > date);
      setCurrentMonth( new Date().getMonth() > date.getMonth())
      setCurrentYear(new Date().getFullYear() == date.getFullYear())
      const day = format(new Date(date), "yyyy-MM-dd");
      getDateData(day, user?._id); 
      setDemo(format(new Date(date), "dd"));
    }
    getSelectedDate(date);

  };
  console.log("CurrentDate", CurrentDate);
  // const times = matchLeague.map((timestamp) => {
  //   return (
  //     <scan>
  //       {timestamp?.schedule_time} Hrs{" "}
  //      { console.log(timestamp?.schedule_time)}
  //     </scan>
  //   );
  // });
useEffect(()=>{
 
  if(dateUpcoming == "null")
  {
    setChange(new Date());
    handleMonthClick();

  }
  else{
    const month = new Date( dateUpcoming)?.getMonth();
    const year = new Date( dateUpcoming)?.getFullYear();
    getmatchdata(month, year);
    setChange( dateUpcoming);
  }
  

},[ dateUpcoming])
// console.log("CurrentDate===========>>>>>>>>>>>>>>",CurrentMonth,CurrentDate);

  return (
    <div className="relative">
         {loadMatch &&
         
                    <div className=" bg-[gray]   w-[-webkit-fill-available] bg-opacity-20  absolute h-[85%] z-10   ">
                    <img alt="" className=" w-[10%] absolute left-[45%] top-[50%]   " src={imageLoader}/>
                    </div>}
                
      <Calendar
        maxDate={maxDate}
        onActiveStartDateChange={(e)=>{handleMonthClick(e);getupcoming(e)}}
        onClickDay={handleDayClick}
        minDate={minDate}
        showFixedNumberOfWeeks={true}
        tileContent={tileContent}
        onChange={(e) => {
          setChange(e);
        }}
        value={value}
      />

      <div className="border-[#8375751F]  border-b-[1.9px]"></div>
{/* {console.log(
  "Checkinggggggggggggggggggggg",
  datesss?.length,
  matchLeague?.length

)} */}
      <div className=" md:mt-3  md:mb-3">
        <div className=" space-y-3">
          {/* <span className="w-3 h-2 bg-green rounded-full"></span>{" "} */}
          {subcription_count != 0 ? (
            <>
          
             {datesss?.length === 0  ? (
                <div className=" bg-[#D9D9D9] flex justify-center border border-[#6C6C6C] bg-opacity-40 rounded-lg  md:px-1 px-1 ">
                  <p className=" py-2  text-[15px] leading-[29.3px]">
                    {upcoming_match_date != null ? (
                      <>
                        Your upcoming match is on{" "}
                        {
                          <Link to={`/mymatch/${upcomingLeague}`}>
                            <span className="underline">
                              <Moment  format={
                               upcoming_match_date?.includes('May')
                                  ? "MMM DD"
                                  : "MMM. DD"
                              }>
                                {upcoming_match_date}
                              </Moment>
                            </span>
                          </Link>
                        }
                      </>
                    ) : (
                      "No Upcoming Matches."
                    )}
                  </p>
                </div>
              ) : (
                <>
                  {targetDate == "Invalid Date" ? (
                    <>
                      {matchLeague?.length != 0 ? (
                        <>
                          <div className=" bg-[#D9D9D9] flex justify-center border border-[#6C6C6C] bg-opacity-40 rounded-lg  md:px-1 px-1 ">
                            <p className=" py-2  text-[15px] leading-[29.3px]">
                              {upcoming_match_date != null ? (
                                <>
                                  Your upcoming match is on{" "}
                                  {
                                    <Link to={`/mymatch/${upcomingLeague}`}>
                                      <span className="underline">
                                        <Moment format={
                               upcoming_match_date?.includes('May')
                                  ? "MMM DD"
                                  : "MMM. DD"
                              }>
                                          {upcoming_match_date}
                                        </Moment>
                                      </span>
                                    </Link>
                                  }
                                </>
                              ) : (
                                upcoming_match_date == null && MatchDate?.length == 0  ?
                                "No Upcoming Matches."
                                :
                                "You Have Matches Scheduled In this Month."
                              )}
                            </p>
                          </div>
                        </> 
                        
                      ) : (
                         <div className=" bg-[#D9D9D9] flex justify-center border border-[#6C6C6C] bg-opacity-40 rounded-lg  md:px-1 px-1 ">

                         <p className=" py-2  text-[15px] leading-[29.3px]">
                          {upcoming_match_date == null ? "No Upcoming Matches.":
                          <>
                          Your upcoming match is on{" "}
                          
                          <Link to={`/mymatch/${upcomingLeague}`}>
                            <span className="underline">
                              <Moment  format={
                               upcoming_match_date?.includes('May')
                                  ? "MMM DD"
                                  : "MMM. DD"
                              }>
                                {upcoming_match_date}
                              </Moment>
                            </span>
                          </Link>
                          </>
                  
                          
                          }
     
                    
                   
                  </p>
                  </div>
                      )}
                    </>
                  ) : myNormalArray.some(
                      (date) => date.getTime() == targetDate.getTime()
                    ) ? (
                    <>
                      {matchLeague.map((res, index) => (
                        <div
                          key={index}
                          className=" bg-[#D9D9D9] flex justify-center border border-[#6C6C6C] bg-opacity-40 rounded-lg  md:px-1 px-1 "
                        >
                       
                          
                          <p className="py-2 justify-center text-[15px] leading-[29.3px]">
                            {res?.league?.league_type === "singles" ? (
                              <>
{CurrentDate && !res?.is_score_update  ?
<>
You missed entering score for {" "} <Link to={`/mymatch/${res?.league?._id}`}>
                                  <span className="underline ">
                                    {res?.league?.name
                                      .replace("(Female)", "")
                                      .replace("(Male)", "")}
                                  </span>
                                  {" "}
                                </Link> {" "}match against {" "} {res.opponent.map((res, index) => (
                                    <Link onClick={()=>setRoutes("/home") } to={`/playerdetail/${res?._id}`}>
                            <span key={index} className="underline capitalize">
                            
                            {(index ? " & " : "") +
                                res?.firstName +
                                " " +
                                res?.lastName}
                            </span>
                            </Link>
                          ))}.
                                </>:
                                <>

                                  {" "}
                                  You have  
                                  {" "}
                                  {res?.is_score_update ? <span className="text-green font-semibold ">played</span>: "a"}
                                  {" "}
                                  
                                     <Link to={`/mymatch/${res?.league?._id}`}>
                                    <span className="underline ">
                                      {res?.league?.name
                                        .replace("(Female)", "")
                                        .replace("(Male)", "")}
                                    </span>
                                  </Link>{" "}
                                    match 
                                  {" "}
                                
                                  against  {" "}
                                  {res.opponent.map((res, index) => (
                                            <Link onClick={()=>setRoutes("/home") } to={`/playerdetail/${res?._id}`}>
                                    <span key={index} className="underline capitalize">
                                    
                                    {(index ? " & " : "") +
                                        res?.firstName +
                                        " " +
                                        res?.lastName}
                                    </span>
                                    </Link>
                                  ))}{" "}
                                  at{" "}
                                  {moment(
          momentsss(res?.schedule_time).tz(TIMEZONE_URL) ).format("hh:mm A")}
                                  </>
                                }
                              
                              </>
                            ) : (
                              CurrentDate   && !res?.is_score_update  ?
                                <>
                                You missed entering score for {" "} <Link to={`/mymatch/${res?.league?._id}`}>
                                                                  <span className="underline ">
                                                                    {res?.league?.name
                                                                      .replace("(Female)", "")
                                                                      .replace("(Male)", "")}
                                                                  </span>
                                                                  {" "}
                                                                </Link> {" "}match against {" "} {res.opponent.map((res, index) => (
                                                                    <Link onClick={()=>setRoutes("/home") } to={`/playerdetail/${res?._id}`}>
                                                            <span key={index} className="underline capitalize">
                                                            
                                                            {(index ? " & " : "") +
                                                                res?.firstName +
                                                                " " +
                                                                res?.lastName}
                                                            </span>
                                                            </Link>
                                                          ))}.
                                                                </>:
                              <>
                                {" "}
                                
                                You have {res?.is_score_update ? <span className="text-green font-semibold">played this</span>: <span>a</span> } 
                                {" "}
                                <Link to={`/mymatch/${res?.league?._id}`}>
                                  <span className="underline">
                                    {res?.league?.name
                                      ?.replace("(Female)", "")
                                      ?.replace("(Male)", "")}
                                  </span>
                                </Link>{" "}
                                
                                 match 
                                {" "}
                               
                                against {" "}
                                {res.opponent.map((res, index) => (
                                  <span key={index}  className="capitalize">
                                  
                                    {(index ? " & " : "") +
                                      res?.firstName +
                                      " " +
                                      res?.lastName}
                                  </span>
                                ))}{" "}
                                at{" "}
                                {moment(
          momentsss(res?.schedule_time).tz(TIMEZONE_URL) ).format("hh:mm A")}
                              </>
                          
                            )}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className=" bg-[#D9D9D9] flex justify-center border border-[#6C6C6C] bg-opacity-40 rounded-lg  md:px-1 px-1 ">
                      <p className=" py-2  text-[15px] leading-[29.3px]">
                        {/* {datesss.map((res, index) => (
                    <>{format(new Date(res?.schedule_date), "dd") != demo ? "You Have no Matches Scheduled on this Day":"You Have Matches Scheduled In this Month" }
                 {console.log(format(new Date(res?.schedule_date), "dd"),demo)}
                    </>
                    You Have Matches Scheduled In this Month
                    ))} */}
                        You Have No Matches Scheduled On this Day.
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <div className=" bg-[#D9D9D9] flex justify-center border border-[#6C6C6C] bg-opacity-40 rounded-lg  md:px-1 px-1 ">
              <p className=" py-2  text-[15px] leading-[29.3px]">
                {/* {datesss.map((res, index) => (
                    <>{format(new Date(res?.schedule_date), "dd") != demo ? "You Have no Matches Scheduled on this Day":"You Have Matches Scheduled In this Month" }
                 {console.log(format(new Date(res?.schedule_date), "dd"),demo)}
                    </>
                    You Have Matches Scheduled In this Month
                    ))} */}
                YOU ARE NOT REGISTERED FOR ANY LEAGUE YET.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* <div className="md:px-14 md:mt-7 ">
              <div className="flex items-center space-x-3">
                <span className="w-5 h-5 bg-[#FF0000] rounded-full"></span>{" "}
                <p className=" text-[16px] leading-[29.3px]">
                  Singles League Match
                </p>
              </div>
              <div className="flex items-center space-x-3">
                <span className="w-5 h-5 bg-[#2F88FF] rounded-full"></span>{" "}
                <p className=" text-[16px] leading-[29.3px]">
                  Doubles League Match
                </p>
              </div>
              <div className="flex items-center space-x-3">
                <span className="w-5 h-5 bg-[#127100] rounded-full"></span>{" "}
                <p className=" text-[16px] leading-[29.3px]">
                  Mixed League Match
                </p>
              </div>
            </div> */}
    </div>
  );
});
export { MyCalender };
const MatchPage = observer(() => {
  const navigate = useNavigate();
  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 6); // add 6 months to the current date
  const myNormalArray = [];
  const {
    Matchdata,
    datesss,
    nolist,
    upcoming_match_date,
    upcomingLeague,
    gettoken,
    getmatchdata,
    subcription_count,
  } = useMyMatchPageStore();
  // console.log("====>>>>",datesss)
  useEffect(() => {
    gettoken(localStorage.getItem("token"));
  }, []);
  const momentsss = require('moment-timezone');

  const { user, authapproved } = useAuthStore();
  function gettime(dates) {
    const date = new Date(dates * 1000);
    return (
      <span>
        {date.getHours()}
        {date.getMinutes()} Hrs{" "}
      </span>
    );
  }
  // const times = datesss.map((timestamp) => {
  //   const date = new Date(timestamp.schedule_time * 1000);
  //   return (
  //     <scan>
  //       {date.getHours()}
  //       {date.getMinutes()} Hrs{" "}
  //     </scan>
  //   );
  // });
  // console.log(times);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    if (!authapproved) {
      navigate("/");
      return;
    }
  }, [authapproved]);

  const [value, setChange] = useState(new Date());
  for (const date of Matchdata) {
    myNormalArray.push(new Date(date));
  }

  const tileContent = ({ date, view }) => {
    const dates = myNormalArray?.filter((d) => d.getTime() === date.getTime());

    if (
      view === "month" &&
      dates.some((selected) => selected.toDateString() === date.toDateString())
    ) {
      // If the date is in the selectedDateArray, show a red circle.
      return <div className="back-circle" style={{ backgroundColor: "#ff0000" }} />;
    } else {
      return null;
    }
  };
  const handleMonthClick = (date) => {
    if (date === undefined) {
      const month = new Date().getMonth();
      const year = new Date().getFullYear();
      getmatchdata(month, year);
    } else {
      const month = new Date(date.activeStartDate).getMonth();
      const year = new Date(date.activeStartDate).getFullYear();
      getmatchdata(month, year);
    }
  };
  const handleDayClick = (date) => {
    if (date === undefined) {
      const day = format(new Date(), "yyyy-MM-dd");
    } else {
      const day = format(new Date(date), "yyyy-MM-dd");
    }
  };
  useEffect(() => {
    // handleMonthClick();
    handleDayClick();
  }, []);

  // const tileDisabled = ({ date }) => {
  //   return (
  //     <>
  //       {date < new Date()} {date > new Date()}
  //     </>
  //   );
  // };
  // console.log( leaguesiddata, "==>dataid")

  return (
    <>
      <div className="relative">
        <img
        alt=""
          className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top brightness-50"
          src={bgroger}
        />
        <div className="relative pt-3 md:pt-5 pb-3 md:pb-7 left-0  right-0">
          <p className=" text-center text-[#fff] text-[24px] font-[Poppins] md:leading-[0] md:text-3xl py-7">
            My Matches Calendar
            <br />
          </p>
        </div>
        <div className="flex items-start justify-center md:flex-row flex-col-reverse  2xl:px-28 lg:px-20 md:px-10 px-4 relative z-10 ">
          <div className=" md:w-6/12 w-full mb-5 md:mb-7 h-auto bg-[#fff] rounded-md ">
            <div className="p-3 md:mb-3 ">
              <MyCalender />
            </div>
          </div>
          <div className=" md:w-8/12 w-full  relative md:px-5 px-0">
            <div   className=" lg:w-full pb-3 md:pb-0 md:h-[570px] h-auto md:w-[450px] w-full 2xl:max-w-[90%]">
              <div id="style-1" className=" bg-[#6C6C6C] overflow-auto h-[395px]  border border-[#fff] bg-opacity-40 rounded-lg  px-1 ">
                {/* <p className=" text-center text-[#fff]  md:mt-[75px] my-6 md:text-[20px] text-[14px] font-[Poppins]">
                  Hii,{"   "} {user?.firstName} {user?.lastName} your matches
                  schedule is here!
                </p> */}
                <div className="flex px-3 overflow-auto justify-center">
                  <div className=" my-6 md:mb-[70px] md:mt-[45px]">
                    {datesss?.slice()?.sort((a, b) => {
                      
                       const dateA = new Date(a?.schedule_date);
                       const dateB = new Date(b?.schedule_date);
                     
                       return dateA - dateB;
                       
                     })?.map((res, index) => (
                      <div key={index} className="flex space-x-5 my-3">
                        <div className=" text-[17px] whitespace-nowrap leading-5 md:leading-[25px]">
                          <span className="text-green">
                            <Moment
                              format={
                                res?.schedule_date?.includes('May')
                                  ? "MMM DD"
                                  : "MMM. DD"
                              }
                            >
                              {res?.schedule_date}
                            </Moment>{" "}
                            @{" "}
                          </span>
                          <span className="pl-[2px] text-[17px]  text-green">
                          {moment(
          momentsss(res?.schedule_time).tz(TIMEZONE_URL) ).format("hh:mm A")}
                            {/* {moment(res?.schedule_time).format("hh:mm A")} */}
                          </span>
                        </div>
                        <div
                          key={index}
                          className="text-[16px] leading-5  md:leading-[25px] font-[Poppins] text-[#fff]"
                        >
                          {res?.league?.league_type === "singles" ? (
                            <>
                              {" "}
                              <Link to={`/mymatch/${res?.league?._id}`}>
                                <span className="underline">
                                  {res?.league?.name
                                    ?.replace("(Female)", "")
                                    ?.replace("(Male)", "")}
                                </span>
                              </Link>{" "}
                              match against 
                              {" "}
                              {res.opponent.map((res, index) => (
                                <span key={index} className="capitalize">
                                  {(index ? " & " : "") +
                                    res?.firstName +
                                    " " +
                                    res?.lastName}
                                </span>
                              ))}
                              {" "}
                              <span>at</span>
                              <span className="text-green"> {res?.home_court_name}</span>
                              {/* (Opponent) */}
                            </>
                          ) : (
                            <>
                              {" "}
                              <Link to={`/mymatch/${res?.league?._id}`}>
                                <span className="underline">
                                  {res?.league?.name
                                    ?.replace("(Female)", "")
                                    ?.replace("(Male)", "")}
                                </span>
                              </Link>{" "}
                              match against 
                              {/* {res?.mate?.firstName}{" "}
                              (Partner) &{" "} */}
                              {" "}
                              {res.opponent.map((res, index) => (
                                <span key={index} className="capitalize">
                                
                                  {(index ? " & " : "") +
                                    res?.firstName +
                                    " " +
                                    res?.lastName}
                                </span>
                              ))}{" "}
                              {/* (Opponent) */}
                              <span>at</span>
                              <span className="text-green"> {res?.home_court_name}</span>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                    {/* <li className="text-[15px] leading-[70.73px] font-[Poppins] text-[#fff]">
                        Winter 2023 Singles with Leo
                      </li>
                      <li className="text-[15px] leading-[70.73px] font-[Poppins] text-[#fff]">
                        Winter 2023 Doubles with Leo & Oliver
                      </li>
                      <li className="text-[15px] leading-[70.73px] font-[Poppins] text-[#fff]">
                        Winter 2023 Doubles with Lina & Oliver
                      </li>
                      <li className="text-[15px]  leading-[70.73px] font-[Poppins] text-[#fff]">
                        Winter 2023 Doubles with Lina & Oliver
                      </li> */}
                  </div>
                </div>
                <div className="flex justify-center">
                  {nolist && (
                    <table>
                      <tbody>
                        <tr>
                          <td
                            colSpan={6}
                            className="md:h-[165px] align-top h-0 pb-5 md:pb-12 "
                          >
                            {subcription_count === 0 ? (
                              <p className=" text-center text-green font-thin text-xl">
                               YOU ARE NOT REGISTERED FOR ANY LEAGUE YET.
                              </p>
                            ) : (
                              <p className=" text-center text-green font-thin text-xl">
                              {upcoming_match_date != null ? (
                      <>
                       <span className="text-[white]">Your upcoming match is on{" "}</span> 
                        {
                          <Link to={`/mymatch/${upcomingLeague}`}>
                            <span className="underline">
                              <Moment  format={
                               upcoming_match_date?.includes('May')
                                  ? "MMM DD"
                                  : "MMM. DD"
                              }>
                                {upcoming_match_date}
                              </Moment>
                            </span>
                          </Link>
                        }
                      </>
                    ) : (
                      "No Upcoming Matches."
                    )}
                              </p>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="w-6/12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
export default MatchPage;
