import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRegisterStore } from "../store/RegisterStore";
import { useAuthStore } from '../../Auth/store/Auth';

import Select from 'react-select';
import Facility from "./Facility";

const Skill = observer(() => {
  const {leaguetype,changeStep, Registerpost, onSubmit,leaguedata,contactUs,getName,getEmail,getDescription,getmaxLevel,levelmax,setpopisOpen, gethomecourtdropdown} =  useRegisterStore();
  const {user} = useAuthStore();
  // const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getmaxLevel()

    gethomecourtdropdown(user?.city?._id,user?.homeCourt?.latitude,user?.homeCourt?.longitude)

    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  }, [])

  const {
    setValue,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  // const onSubmit = async (data) => { 
  //   console.log(data)
  //   // changeStep("2")
  //  };

  return (
    <div className="w-full 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-1 py-3">
      <div className="flex flex-wrap justify-center">
        <div className="2xl:w-[80%] lg:w-[80%] md:w-[90%] w-full 2xl:max-w-[90%]">
          <div className=" bg-[#6C6C6C] border border-[#fff] bg-opacity-40 rounded-lg lg:px-5 md:px-3 px-2 md:pt-5 py-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className=" text-center">
                <p className=" text-[#fff] font-medium md:text-2xl text-xl relative">
                  Your Skill Level
                </p>
              </div>
              <div>
                {/* <p className=" py-6 text-[#fff] font-thin text-center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p> */}
              </div>
              <div className="flex py-3 justify-around items-center flex-wrap">
                {/* <p className="text-[#fff] text-lg">
                  Click the box to set your skill level
                </p> */}

                <div className=" flex  mt-4">
                  <input 
                  name="lavel" 
                  {...register("lavel", { required: true })} 
                  
                  defaultValue={leaguetype === "mixed" ? user?.mixed_level?._id : leaguetype === "doubles" ?  user?.doubles_level?._id : user?.level?._id} 
                  className="bg-green text-[#000] opacity-0 absolute left-0 right-0 rounded-md border border-[#C0C2C0] md:text-[22px] h-[52px] aspect-[1]"/>
                  <input 
                  name="lavel" 
                  //  {...register("lavel", { required: true })} 
                  type="button"
                  defaultValue={leaguetype === "mixed" ? user?.mixed_level?.name?.toFixed(1): leaguetype === "doubles" ?  user?.doubles_level?.name?.toFixed(1) : user?.level?.name?.toFixed(1)} 
                  className="bg-green text-[#000] text-center rounded-md border border-[#C0C2C0] md:text-[22px] h-[52px] aspect-[1]"/>
                    {/* {user?.level?.name}
                  </button> */}
                  {/* {levelmax === 6?  
                  <> */}
                  {/* <p className="self-center">◽</p>
                  <input type="button" className=" bg-opacity-0 text-[#000] text-center rounded-md border border-[#C0C2C0] md:text-[22px] h-[52px] aspect-[1] "
                    defaultValue={user?.level?.name+".5"<levelmax ? user?.level?.name+".5" : user?.level?.name  }
                  /> */}
                </div>
              </div>
              <div className="flex flex-wrap  justify-center items-center ">
                <div className="flex flex-col" >
                <div className="flex space-x-4 md:my-6 my-4 md:w-auto w-full">
                  <button
                    className=" font-medium lg:text-[20px] text-lg text-[#000] bg-[#fff] md:px-11 px-4 md:w-auto w-6/12 py-2 rounded-lg"
                    type="button"
                    onClick={()=>{navigate("/league")}}
                  >
                    Cancel
                  </button>
                    <button
                      className="font-medium lg:text-[20px] text-lg text-[#000] bg-[#39FD13] md:px-11 px-4 md:w-auto w-6/12 py-2 rounded-lg "
                      type="submit"
                      disabled={leaguetype==""}
                      //  onClick={() => {leaguetype != "singles"? changeStep("2"): changeStep("3")}}
                      disabled={leaguetype==""}
                    >
                    Continue
                    </button>
                </div>
                <div className="flex justify-center">
                  <p className="text-[#fff]">
                    Need help ?
                    <span className="text-[#39FD13] cursor-pointer ml-2" 
                    onClick={() => {setpopisOpen(true)}}>
                     Contact us
                     </span>
                  </p>
                </div>
                </div>
               
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    
  );
})

export default Skill;
