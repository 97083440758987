import { createContext, useContext, useState } from "react";
import {
  makeAutoObservable,
  useLocalObservable,
  observable,
  runInAction,
} from "mobx";
import {
  postLogin,
  postMethodData,
  patchMethodData,
  getMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";
// import { LeagueStore } from "../../Leaguespage/store/Leaguestore";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../ui/utils";

import { AuthStore } from "../../Auth/store/Auth";
import { loadStripe } from "@stripe/stripe-js";
export class RegisterStore {
  step = "1";
  keysss="";
  stripeKey="";
  Token = null;
  lat = "";
  lng = "";
  errloadplayer = "";
  loadplayer = false;
  saved = false;
  nolist = false;

  state = [];
  city = [];
  statteid = "";

  player = [];
  fName = "";
  lName = "";
  level = "";
  homecourtdrop = [];
  homecourtid = "";
  user = {};

  // gender =this.user.gender;

  code = "";
  amount = "";
  coupondata = {};
  couponStatus = false;
  autocouponstatus = false

  // name = "";
  email = "";
  description = "";

  showtable = false;
  showmap = false;

  biling_first_name = "";
  biling_last_name = "";

  levelmax = "";

  playerid = "";
  playerfname = "";
  playerlname = "";

  leaguesiddata = "";
  leaguetype = "";
  fees = "";
  leaugesfalse = false;

  leaguedata = {};
  captainid = "";
  idleague = "";
  // leagueStore ="";

  ispayment = false;
  errispayment = "";
  paymentid = "";

  cvc = "";
  expiry = "";
  name = "";
  number = "";

  paymentdata = {};
  paymentstatus ="";
  transactionid="";
  subcriptionid="";
  transactionStatus = {};

  mixedgender = "";

  paymentload = false;
  paymenterror = "";

  popisOpen = false;
  popupload = false;

  expiryError = false;

  contactmobile = "";
  ischecked = false;
  ischeckedid = "";


  expiryMonth = ""
  expiryYear = "";
  notificationNo = false;

  moveforwad=false;
  alreadyregister=false;
  erralreadyregister="";

  paymentwithcode = false;




  constructor(isloading = false) {
    makeAutoObservable(this);
    // this.captainid = this.user?._id;
    // console.log("sdfdsfdsf",localStorage.getItem("token"))
    this.Token = localStorage.getItem("token");
    this.user = JSON.parse(localStorage.getItem("user"));
  // console.log(this.stripeKey);
    // this.leagueStore = new LeagueStore(this);
    //  this.stores = {league: this.leagueStore};
    // console.log("leagueStore",this.leagueStore)
    if (isloading.isloading) {
      this.getPlayer();
      this.getState();
      // this.leaugesfalse = true;
      // this.getmaxLevel()
      return;
    }
  }

  getContactMobile = (e) => {
    this.contactmobile = e;
  };

  setmoveforwad= (e)=> {
    this.moveforwad = e
  }

  setpopisOpen = (e) => {
    // console.log(e)
    this.popisOpen = e;
    this.description = "";
  };

  getleaugesfalse = (e) => {
    this.leaugesfalse = e;
  };

  gettoken = (e) => {
    this.Token = e;
  };
  getuser = (e) => {
    this.user = e;
    // console.log(this.user)
    this.captainid = e?._id;
  };
  getcardnumber = (e) => {
    this.number = formatCreditCardNumber(e);
  };
  getcardcvc = (e) => {
    this.cvc = formatCVC(e);
  };
  getcardexpiry = (e) => {
    this.expiry = formatExpirationDate(e);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Add 1 to get month from 1-12 instead of 0-11

    // Get user-entered expiry month and year
    this.expiryMonth = parseInt(e?.slice(0, 2));
    this.expiryYear = parseInt("20" + e?.slice(3, 5));
    // console.log("expiryYear",this.expiryYear)
    // console.log("expiryMonth",this.expiryMonth)
    // Compare expiry date with current date
    if (
      this.expiryYear < currentYear ||
      (this.expiryYear === currentYear && this.expiryMonth < currentMonth) ||
      this.expiryMonth > 12
    ) {
      // Expiry date is less than current date
      this.expiryError = true;
    } else {
      // Expiry date is valid
      this.expiryError = false;
    }
  };
  getcardname = (e) => {
    this.name = e;
  };

  // handleInputChange = (e) => {
  //   const { name, value } = e.target;

  //   this.setState({ [name]: value });

  //   console.log(this.state)
  // }

  // get first name
  clearLeaugedata = () => {
    this.leaguedata = {};
  };
  clearPaymentdata = () => {
    this.name = '';
    this.number = '';
    this.expiry = '';
    this.expiry = '';
    this.cvc = '';
  };
  

  // get first name
  getFirstname = (text) => {
    this.fName = text;
  };

  //get last name
  getLastname = (text) => {
    this.lName = text;
  };
  //homecourt id
  getHomecourtid = (text) => {

    this.homecourtid = text;
    this.leaguedata = { ...this.leaguedata, home_court: text };
    // console.log(this.leaguedata);
  };

  getShowtable = (text) => {
    this.showtable = text;
  };

  getShowmap = (text) => {
    this.showmap = text;
  };

  getStateid = (text) => {
    this.statteid = text;
  };

  getCode = (text) => {
    this.code = text;
  };

  getAmount = (text) => {
    this.amount = text;
  };

  togglebtn = () => {
    this.coupondata = {};
    this.couponStatus = false;
    this.code = "";
    // this.fees = "";
  };

  //contactus name

  getName = (text) => {
    this.name = text;
  };

  //contactus email

  getEmail = (text) => {
    this.email = text;
  };

  //contactus description

  getDescription = (text) => {
    this.description = text;
  };

  getCaption = (text) => {
    this.captainid = text;
    this.leaguedata = { ...this.leaguedata, captain: text };
    // console.log(this.leaguedata)
  };

  //get league id
  getidLeague = (text) => {
    this.idleague = text;
  };

  // onlyhomecourtadd
  onlyhomecourt = (HomeCourtID) => {
    this.leaguedata = { ...this.leaguedata, home_court: this.homecourtid === undefined ? HomeCourtID?._id : this.homecourtid };
    // if(this.homecourtid != ""){
    //   this.step = "4"
    // }
  };

  
  getStripe = async() => {
 
   await  getMethodData("/api/get/setting/STRIPE_PUBLISHABLE_KEY",this.Token)
     .then((resp) => {
       if (resp?.data?.status === true) {
         runInAction(() => {
      
           // this.status = true;
           this.stripeKey = (resp?.data?.data?.value);
         });
         return;
       }
     
     })
     .catch((error) => {
   
       return;
     });
 };



  //select data
  getPlayerdetails = (data) => {
    this.leaguedata = {
      ...this.leaguedata,
      mate: data._id,
      captain: this.captainid,
      home_court: this.homecourtid,
    };
    // console.log(this.leaguedata);
    this.playerid = data._id;
    this.playerfname = data.firstName;
    this.playerlname = data.lastName;
  };

  getselected = (data) => {
    this.ischeckedid = data;
  };

  allClear = () => {
    this.fName = "";
    this.lName = "";
    this.showtable = false;
    this.showmap = false;
  };

  // getlat=(text)=>{
  //   this.lat= text.latitude;
  // }
  getlocation = (text) => {
    this.lat = text?.latitude || this.user.homeCourt.latitude;
    this.lng = text?.longitude || this.user.homeCourt.longitude; ;
  };

  changeStep = (e) => {
    runInAction(() => {
      this.step = e;
    });
  };

  //get player for searching

  getPlayer = async() => {
    runInAction(() => {
      this.loadplayer = true;
      this.errloadplayer = "";
      this.saved = false;
      this.ischeckedid = "";
    });

    const searchLevel = (this.leaguetype === "mixed") ? `&filters[mixed_level]=${this.user?.mixed_level?._id}` : (this.leaguetype === "doubles") ? `&filters[doubles_level]=${this.user?.doubles_level?._id}` : ""; 

    await getMethodData(
      `/api/get/players?filters[isRegister]=1&filters[firstName]=${this.fName}&filters[lastName]=${this.lName}&filters[league]=${this.leagueid}&filters[gender]=${this.mixedgender}${searchLevel}`,
      this.Token
      
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadplayer = false;
            this.errloadplayer = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadplayer = false;
            this.errloadplayer = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.player = resp?.data?.data;
            this.showtable = true;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadplayer = false;
          this.errloadplayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadplayer = false;
          if (!error.response) {
            this.errloadplayer = error?.message;
            // toast.error(error?.message);
          }
          this.errloadplayer = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };

  getSetting = async() => {
  
    await  getMethodData("/api/get/setting/GOOGLE_MAP_API_KEY",this.Token)
      .then((resp) => {
        if (resp?.data?.status === true) {
          runInAction(() => {
       
            // this.status = true;
            this.keysss = resp?.data?.data?.value;
          });
          return;
        }
      
      })
      .catch((error) => {
    
        return;
      });
  };
  //get leagues by Id

  getLeaguesid = async(id) => {
    this.leagueid = id;
    runInAction(() => {
      this.loadplayer = true;
      this.errloadplayer = "";
      this.saved = false;
      this.leaguesiddata = "";
    });
    await getMethodData(`/api/get/league/${this.leagueid}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.leaugesfalse = false;
            this.loadplayer = false;
            this.leaguesiddata = "";
            this.errloadplayer = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            if (resp?.data?.data?.league_type === "mixed") {
              if (this.user?.gender === "female") {
                this.mixedgender = "male";
                // return
              } else {
                this.mixedgender = "female";
                // return
              }
            } else {
              this.mixedgender = this.user?.gender;
            }
            this.leaugesfalse = true;
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
            this.leaguetype = resp?.data?.data?.league_type;
            this.leaguesiddata = resp?.data?.data?.name;
            this.fees = resp?.data?.data?.fees_amount;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.saved = false;
            // this.status = true;
          });
          this.PostRegisterCheck(id)
          return;
        }
        // if (resp?.data?.status === true) {
        //   runInAction(() => {
        //     this.leaugesfalse = true;
        //     if(resp?.data?.data?.league_type === "mixed"){
        //       if(this.user.gender === "female"){
        //         this.mixedgender = "male"
        //         return
        //       }
        //       else{
        //         this.mixedgender = "female"
        //         return
        //       }
        //     }
        //     else{
        //       this.mixedgender = this.user.gender
        //     }
        //     this.loadplayer = false;
        //     this.errloadplayer = resp?.data?.message;
        //     this.leaguetype =resp?.data?.data?.league_type;
        //     //  this.leaguesiddata =resp?.data?.data?.name;
        //      this.fees =resp?.data?.data?.fees_amount
        //     if(resp?.data?.data.length === 0) {
        //       this.nolist = true;
        //     }else{
        //       this.nolist = false;
        //     }
        //     this.saved = false;
        //     // this.status = true;
        //   });
        //   return;
        // }
        runInAction(() => {
          this.leaugesfalse = false;
          this.loadplayer = false;
          this.errloadplayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.leaugesfalse = false;
          this.loadplayer = false;
          if (!error.response) {
            this.errloadplayer = error?.message;
            toast.error(error?.message);
          }
          this.errloadplayer = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

  //get homecourt drop down

  gethomecourtdropdown = async(cityID,lat,lng) => {
    runInAction(() => {
      // this.loaduser = true;
      this.errloadcourt = "";
      //   this.saved = false;
    });
    await getMethodData(`/api/get/home-courts/${this.user?.city?._id || cityID }`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.loaduser = false;
            this.errloadcourt = resp?.data?.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            // this.loaduser = false;
            this.errloadcourt = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.homecourtdrop = resp?.data?.data;
            this.homecourtid = this.user?.homeCourt._id;
            this.lat = this.user?.homeCourt?.latitude || lat;
            this.lng = this.user?.homeCourt?.longitude || lng;
            //  this.homecourtverified = resp?.data?.data?.isVerified
          });
          return;
        }
        runInAction(() => {
          //   this.loaduser = false;
          this.errloadcourt = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          //   this.loaduser = false;
          if (!error.response) {
            this.errloadcourt = error?.message;
            // toast.error(error?.message);
          }
          this.errloadcourt = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };

  // get state
  getState = async() => {
    runInAction(() => {
      this.loadplayer = true;
      this.errloadplayer = "";
      this.saved = false;
    });
    await getMethodData("/api/get/states", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadplayer = false;
            this.errloadplayer = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadplayer = false;
            this.errloadplayer = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.state = resp?.data?.data;

            // this.showtable =true
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadplayer = false;
          this.errloadplayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadplayer = false;
          if (!error.response) {
            this.errloadplayer = error?.message;
            toast.error(error?.message);
          }
          this.errloadplayer = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

  // get city by state
  getCity = async(e) => {
    runInAction(() => {
      this.loadplayer = true;
      this.errloadplayer = "";
      this.saved = false;
    });
    await getMethodData(`/api/get/cities?filters[state]=${e}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadplayer = false;
            this.errloadplayer = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadplayer = false;
            this.errloadplayer = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.city = resp?.data?.data;
            // this.showtable =true
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadplayer = false;
          this.errloadplayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadplayer = false;
          if (!error.response) {
            this.errloadplayer = error?.message;
            toast.error(error?.message);
          }
          this.errloadplayer = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

  //coupon code
  couponcode = async(fees) => {
    runInAction(() => {
      this.errloadplayer = "";
    });
    await postMethodData(
      "/api/user/apply-coupon-code",
      {
        amount: fees,
        code: this.code,
        league: this.leagueid,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadplayer = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadplayer = resp?.data?.message;
             toast.success(resp.data.message);
            this.coupondata = resp?.data?.data;
            this.couponStatus = true;
            this.totalamount = resp?.data?.data?.total_amount;
            //  this.fees =resp?.data?.data?.total_discounted_amount
            this.saved = true;
          });
          return;
        }
        runInAction(() => {
          this.errloadplayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadplayer = error?.message;
            toast.error(error?.message);
          }
          this.errloadplayer = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

   //auto applied coupon code 
   autocouponcode = async(fees) => {
    runInAction(() => {
      this.errloadplayer = "";
      this.autocouponstatus= false
    });
    await postMethodData(
      "/api/user/check-refund-code",
      {
        league: this.leagueid,
        type: this.leaguetype
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadplayer = resp?.data?.message;
            // toast.error(resp.data.message);
            this.autocouponstatus= false
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadplayer = resp?.data?.message;
            this.autocouponstatus= true;
            //  toast.success(resp.data.message);
            this.coupondata = resp?.data?.data;
            this.code =resp?.data?.data?.discount_code
            this.couponStatus = true;
            this.totalamount = resp?.data?.data?.total_amount;
            //  this.fees =resp?.data?.data?.total_discounted_amount
            this.saved = true;
          });
          return;
        }
        runInAction(() => {
          this.errloadplayer = resp?.data?.message;
          // this.autocouponstatus= false
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadplayer = error?.message;
            // toast.error(error?.message);
          }
          this.errloadplayer = error?.response?.data?.message;
          this.autocouponstatus= false
          // toast.error(error?.message);
        });
        return;
      });
  };

  //contactus api
  contactUs = async(data) => {
    runInAction(() => {
      this.popupload = true;
      this.errloadplayer = "";
    });
    await postMethodData(
      "/api/get/contact-us",
      {
        ...data,
        mobile: this.contactmobile || data.mobile,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.popisOpen = true;
            this.popupload = false;
            this.errloadplayer = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.description = "";
            this.contactmobile = "";
            this.popisOpen = false;
            this.popupload = false;
            this.errloadplayer = resp?.data?.message;
            toast.success(resp.data.message);
            this.saved = true;
          });
          return;
        }
        runInAction(() => {
          this.popisOpen = true;
          this.popupload = false;
          this.errloadplayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.popisOpen = true;
          this.popupload = false;
          if (!error.response) {
            this.errloadplayer = error?.message;
            toast.error(error?.message);
          }
          this.errloadplayer = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

  //maxlevel api
  getmaxLevel = async() => {
    runInAction(() => {
      this.loadplayer = true;
      this.errloadplayer = "";
      this.saved = false;
    });
    await getMethodData("/api/get/max-level", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadplayer = false;
            this.errloadplayer = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadplayer = false;
            this.errloadplayer = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.levelmax = resp?.data?.data?.name;
            // this.showtable =true
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadplayer = false;
          this.errloadplayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadplayer = false;
          if (!error.response) {
            this.errloadplayer = error?.message;
            toast.error(error?.message);
          }
          this.errloadplayer = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

  // league regiser work
  onSubmit = async (data) => {
    runInAction(() => {
      this.fName = "";
      this.lName = "";
      this.leaguedata = { ...this.leaguedata.data, ...data };
      this.leaguetype != "singles" ? (this.step = "2") : (this.step = "3");
      // console.log("leaguedata", this.leaguedata);
      // if(data){
      //   this.step = 2
      // }
    });
    return;
  };

  onSubmitsingle = async (data) => {
    runInAction(() => {
      // console.log("==>Sdata", data);
      this.leaguedata = { ...this.leaguedata, ...data };
      this.step = "3";
      // if(data){
      //   this.step = 2
      // }
    });
    return;
  };

  Registerpost = async(data) => {
    runInAction(() => {
      this.paymentwithcode = false;
      this.paymentload = true;
    });
    await postMethodData(
      "/api/user/create-league-subcription",
      {
        ...data,
        ...this.leaguedata,
        league: this.idleague,
        discount: this.coupondata.discount,
        discount_amount: this.coupondata.discount_amount,
        discount_code: this.coupondata.discount_code,
        discount_type: this.coupondata.discount_type,
        total_amount: this.fees,
      },
      this.Token
    )
      .then((resp) => {
        // console.log(resp);
        if (resp?.data?.status === true) {
          runInAction(() => {
            // this.step= "4"
            this.paymentload = false;
            this.ispayment = true;
            this.paymentid = resp?.data?.data?._id;
          
            if(this.coupondata?.total_discounted_amount == "0" ){
              
              this.step = '5';
              this.paymentdata = resp?.data?.data;
              this.paymentstatus = resp?.data?.data?.success
              this.transactionid = "Payment By Promo Code"  
              this.paymentwithcode = true
              this.subcriptionid = resp?.data?.data?.subcription?._id
              this.name = '';
              this.number = '';
              this.expiry = '';
              this.expiry = '';
              this.cvc = '';
              this.notificationNo = true;
            }
         
          });
          return false;
        }
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.ispayment = false;
            this.paymentwithcode = false
            // toast.error(resp?.data?.message);
            this.paymentid = "";
            this.paymentload = false;
          });
        }
      })
      .catch((error) => {
        this.paymentwithcode = false
        this.paymentload = false;
        toast.error(error?.message);
        this.ispayment = false;
        this.paymentid = "";
      });
  };

  paymentscreen = (text) => {
    this.ispayment = text;
  };

 
//payment api with stripe
  // paymentapi =  async() => {
  //   if (!this.expiryError) {
  //     runInAction(() => {
  //       this.paymentload = true;
  //       this.paymenterror = '';
  //       this.paymentwithcode = false
  //     });

  //           this.stripeKey?.tokens.create({
  //             card: {
  //           name:this.name,
  //           number: this.number.replaceAll(' ', ''),
  //           exp_month: this.expiryMonth,
  //           exp_year: this.expiryYear,
  //           cvc: this.cvc,
           
  //         }
  //       })  
  //       .then(async(result) => {
  //         // console.log(result)
  //         if (result.error) {
  //           runInAction(() => {
  //             this.paymentload = false;
              
  //             toast.error(result.error.message);
  //           });
  //         } else {
  //           await postMethodData(
  //             `/api/user/subcription-payment/${this.paymentid}`,
  //             {
  //               token: result?.id,
  //               payment_method: "card"
  //             },
  //             this.Token
  //           )
  //             .then((resp) => {
  //               // console.log(resp);
  //               if (resp?.data?.status === true) {
  //                 runInAction(() => {
  //                   this.paymentload = false;
  //                   this.step = '5';
  //                   this.paymentdata = resp?.data?.data;
  //                   this.paymentstatus = resp?.data?.data?.success
  //                   this.transactionid = resp?.data?.data?.subcription?.transection_id
  //                   this.subcriptionid = resp?.data?.data?.subcription?._id
  //                   this.paymentwithcode = false
  //                   this.name = '';
  //                   this.number = '';
  //                   this.expiry = '';
  //                   this.expiry = '';
  //                   this.cvc = '';
  //                   this.notificationNo = true;

  //                   // const authnotification = new AuthStore()
  //                   // authnotification.getNotification()
                   
  //                   // toast.success(resp.data.message);
  //                 });
                 
  //                 return;
  //               }
  //               if (resp?.data?.status === false) {
  //                 runInAction(() => {
  //                   this.paymentwithcode = false
  //                   this.notificationNo = false;
  //                   this.paymentload = false;
  //                   toast.error(resp?.data?.message);
  //                 });
  //               }
  //             })
  //             .catch((error) => {
  //               this.paymentload = false;
  //               toast.error(error?.message);
  //             });
  //         }
  //       })
  //       .catch((error) => {
  //         // console.log("=====>>>>",error)
  //         runInAction(() => {
  //           this.paymentload = false;
  //           toast.error(error.message);
  //         });
  //       });
  //   }
  // };


  //new payment with stripe

  paymentapi = (result) => {
    // if (!this.expiryError) {
      runInAction(() => {
        this.paymentload = true;
        this.paymenterror = '';
        this.paymentwithcode = false
      });
      // return
      postMethodData(
        `/api/user/subcription-payment/${this.paymentid}`,
        {
          token: result?.id,
          payment_method: "card"
        },
        this.Token
      )
        .then((resp) => {
       
          if (resp?.data?.status === true) {
            runInAction(() => {
              this.paymentload = false;
              this.step = '5';
              this.paymentdata = resp?.data?.data;
              this.paymentstatus = resp?.data?.data?.success
              this.transactionid = resp?.data?.data?.subcription?.transection_id
              this.subcriptionid = resp?.data?.data?.subcription?._id
              this.paymentwithcode = false
              this.name = '';
              this.number = '';
              this.expiry = '';
              this.expiry = '';
              this.cvc = '';
              this.notificationNo = true;

              // const authnotification = new AuthStore()
              // authnotification.getNotification()
             
              // toast.success(resp.data.message);
            });
          }
          if (resp?.data?.status === false) {
            runInAction(() => {
              this.paymentwithcode = false
              this.notificationNo = false;
              this.paymentload = false;
              toast.error(resp?.data?.message);
            });
          }
        })
        .catch((error) => {
          this.paymentload = false;
          toast.error(error?.message);
        });
    // }
  };
//transaction status

  postTransactionStatus = async() => {
    runInAction(() => {
      this.errloadplayer = "";
    });
    await postMethodData(
      "/api/user/check-transacton-status",
      {
        league_subcription : this.subcriptionid,
        transaction_id : this.transactionid
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.errloadplayer = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.errloadplayer = resp?.data?.message;
            //  toast.success(resp.data.message);
             this.transactionStatus =resp?.data?.data;
            this.saved = true;
          });
          return;
        }
        runInAction(() => {
          this.errloadplayer = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.errloadplayer = error?.message;
            toast.error(error?.message);
          }
          this.errloadplayer = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

// is already registerd
  PostRegisterCheck = async(id) => {
    runInAction(() => {
      this.moveforwad=false;
      this.alreadyregister=true;
      this.erralreadyregister=""
    });
    await postMethodData(
      "/api/user/chack-league-already-succeeded",
      {
        league : id
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.moveforwad=false;
            this.alreadyregister=false;
            this.erralreadyregister=resp.data.message
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.moveforwad=resp?.data?.data?.already_succeeded;
            this.alreadyregister=false;
            this.erralreadyregister=resp.data.message
          });
          return;
        }
        runInAction(() => {
          this.alreadyregister=false;
          this.erralreadyregister=resp.data.message
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.alreadyregister=false;
          if (!error.response) {
            this.erralreadyregister = error?.message;
            toast.error(error?.message);
          }
          this.erralreadyregister = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

 

  //craete subscription api
  // createsubscrption = () => {
  //   runInAction(() => {
  //     this.loadplayer = true;
  //     this.errloadplayer = "";
  //   });
  //   postMethodData("/api/user/create-league-subcription",{
  //     biling_first_name: this.billingfname,
  //     biling_last_name: this.billinglname,
  //     biling_address: this.billingaddress,
  //     biling_city: this.billingcity,
  //     biling_state: this.billingstate,
  //     biling_zip_code: this.billingzipcode,
  //     league: this.billingleague,
  //     mate: this.billingmate,
  //     city: this.city,
  //     home_court: this.billingcity,
  //     lavel: this.billingcity,
  //     captain: this.billingcity,
  //     discount_type: this.billingcity,
  //     discount_code: this.billingcity,
  //     discount: this.billingcity,
  //     discount_amount: this.billingcity,
  //     total_amount: this.billingcity,
  //     },this.Token).then((resp) => {
  //       if (resp?.data?.status === false) {
  //         runInAction(() => {
  //           this.loadplayer = false;
  //           this.errloadplayer = resp?.data?.message;
  //           toast.error(resp.data.message);
  //         });
  //         return;
  //       }
  //       if (resp?.data?.status === true) {
  //         runInAction(() => {
  //           this.loadplayer = false;
  //           this.errloadplayer = resp?.data?.message;
  //           toast.success(resp.data.message);
  //           this.saved = true;
  //         })
  //         return
  //       }
  //       runInAction(() => {
  //         this.loadplayer = false;
  //         this.errloadplayer = resp?.data?.message;
  //       });

  //     })
  //     .catch((error) => {
  //       runInAction(() => {
  //           this.loadplayer = false;
  //           if(!error.response){
  //               this.errloadplayer = error?.message;
  //               toast.error(error?.message);
  //           }
  //           this.errloadplayer = error?.response?.data?.message;
  //           toast.error(error?.message);
  //       })
  //       return;
  //   });
  // };
}

export const RegisterStoreContext = createContext(new RegisterStore());
export const useRegisterStore = () => useContext(RegisterStoreContext);
