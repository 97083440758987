import { createContext, useContext, useState } from "react";
import {
  makeAutoObservable,
  useLocalObservable,
  observable,
  runInAction,
} from "mobx";
import {
  postLogin,
  postMethodData,
  patchMethodData,
  getMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";


export class LeagueStore {

    Token = null;
    user = {};
    errloadseason = "";
    loadseason = false;
    loadseasonnew= false;
    nolist = false;
    nolistcurrent =false

    leagues = [];

    saved = false;

    leagueid = "";
    leaguesiddata = "";
    leaguetype = "";
    fees=""
    leaugesfalse = false;


    registrationleague=[];

    currentleague=[];
    nolistupcomingleague = false;
    

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("token");
    this.user = JSON.parse(localStorage.getItem("user"));
    if(isloading.isloading){
    this.getSeason()
    this.getRegistrationleague()
    this.getCurrentleague()
    this.leaugesfalse = true;
     return
    }
  }

  getleaugesfalse = (e) => {
    this.leaugesfalse = e
  }

  gettoken = (e) => {
    this.Token = e
  }
  getuser = (e) => {
    this.user = e
  }

  changeStep = (e) => {
    runInAction(() => {
        this.step = e
    })
  }

   //get last name
   getFees = (text) => {
    this.fees = text;
  }

  //get league id
  getidleague = (text) => {
    this.leagueid = text;
  }

  //get Seasons

//   getSeason=()=>{
//     runInAction(()=>{

//     })
//   }
// }

//get seasons 
getSeason = async() => {
    runInAction(() => {
      this.loadseason = true;
      this.errloadseason = "";
      this.leagues=[];
      this.saved = false;
    });
    await getMethodData("/api/user/season", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.leaugesfalse = false;
            this.loadseason = false;
            this.errloadseason = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.leaugesfalse = false;
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
            if(resp?.data?.data.length === 0) {
              this.nolist = true;
            }else{
              this.nolist = false;
            }
            this.leagues = resp?.data?.data;

            this.saved = false;
          });
          return;
        }
        runInAction(() => {
          this.leaugesfalse = false;
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
            this.loadseason = false;
            if(!error.response){
                this.errloadseason = error?.message;
                toast.error(error?.message);
            }
            this.errloadseason = error?.response?.data?.message;
            toast.error(error?.message);
        })
        return;
    });
  };

  //Get Upcoming League
getRegistrationleague = async() => {
  runInAction(() => {
    this.loadseasonnew = true;
    this.errloadseason = "";
    this.saved = false;
    this.registrationleague=[];
    this.nolistupcomingleague = false;
  });
  await getMethodData("/api/user/get-upcomming-leagues-without-season", this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.leaugesfalse = false;
          this.loadseasonnew = false;
          this.errloadseason = resp?.data?.message
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.leaugesfalse = false;
          this.loadseasonnew = false;
          this.errloadseason = resp?.data?.message;
          if(resp?.data?.data?.length === 0) {
            this.nolistupcomingleague = true;
          }else{
            this.nolistupcomingleague = false;
          }
          this.registrationleague = resp?.data?.data;

          this.saved = false;
        });
        return;
      }
      runInAction(() => {
        this.leaugesfalse = false;
        this.loadseasonnew = false;
        this.errloadseason = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
          this.loadseasonnew = false;
          if(!error.response){
              this.errloadseason = error?.message;
              toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
      })
      return;
  });
};

//Get Current League
getCurrentleague = async() => {
  runInAction(() => {
    this.loadseason = true;
    this.errloadseason = "";
    this.saved = false;
  });
  await getMethodData("/api/user/get-current-leagues-without-season", this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.leaugesfalse = false;
          this.loadseason = false;
          this.errloadseason = resp?.data?.message
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.leaugesfalse = false;
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
          if(resp?.data?.data?.length === 0) {
            this.nolistcurrent = true;
          }else{
            this.nolistcurrent = false;
          }
          this.currentleague = resp?.data?.data;

          this.saved = false;
        });
        return;
      }
      runInAction(() => {
        this.leaugesfalse = false;
        this.loadseason = false;
        this.errloadseason = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
          this.loadseason = false;
          if(!error.response){
              this.errloadseason = error?.message;
              toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
      })
      return;
  });
};

  //get leagues by Id
  
  // getLeaguesid = (id) => {
  //   this.leagueid = id
  //   runInAction(() => {
  //     this.loadseason = true;
  //     this.errloadseason = "";
  //     this.saved = false;
  //   });
  //   getMethodData(`/api/get/league/${this.leagueid}`, this.Token)
  //     .then((resp) => {
  //       if (resp?.data?.status === false) {
  //         runInAction(() => {
  //           this.leaugesfalse = false;
  //           this.loadseason = false;
  //           this.errloadseason = resp?.data?.message
  //         });
  //         return;
  //       }
  //       if (resp?.data?.status === true) {
  //         runInAction(() => {
  //           this.leaugesfalse = true;
  //           this.loadseason = false;
  //           this.errloadseason = resp?.data?.message;
  //           this.leaguetype =resp?.data?.data?.league_type;
  //           this.leaguesiddata =resp?.data?.data?.name;
  //           this.fees =resp?.data?.data?.fees_amount
  //           if(resp?.data?.data.length === 0) {
  //             this.nolist = true;
  //           }else{
  //             this.nolist = false;
  //           }
  //           this.saved = false;
  //           // this.status = true;
  //         });
  //         return;
  //       }
  //       runInAction(() => {
  //         this.leaugesfalse = false;
  //         this.loadseason = false;
  //         this.errloadseason = resp?.data?.message;
  //       });
  //     })
  //     .catch((error) => {
  //       runInAction(() => {
  //         this.leaugesfalse = false;
  //           this.loadseason = false;
  //           if(!error.response){
  //               this.errloadseason = error?.message;
  //               toast.error(error?.message);
  //           }
  //           this.errloadseason = error?.response?.data?.message;
  //           toast.error(error?.message);
  //       })
  //       return;
  //   });
  // };

}
export const LeagueStoreContext = createContext(new LeagueStore());
export const useLeagueStore = () => useContext(LeagueStoreContext);
