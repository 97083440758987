import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { CommonStore , useCommonStore , CommonStoreContext } from "./Store/CommonStore";
const Benefits = observer(() => {
    return (
      <CommonStoreContext.Provider
        value={
          new CommonStore({
            Benifits: true,
            
          })
        }
      >
        <Screen />
      </CommonStoreContext.Provider>
    );
  });
  export default Benefits;

export const Screen = observer(() => {
    const { Benifits,loadcommon} = useCommonStore();
    useEffect(()=> {
      if(Benifits?.length === 0){
          document.getElementById("cmsdata").innerHTML="<h1 class='text-center'>NO BENEFITS DATA FOUND</h1>";
      }
      else{
        let data = Benifits[0]?.content.replace(/&quot;/g, '\"');
        document.getElementById("cmsdata").innerHTML=data
      }
    },[loadcommon])
    return(
        <div className="py-2 2xl:px-28 lg:px-20 md:px-10 px-4">
            <div className=" text-center">
                <p className=" font-semibold 2xl:text-2xl text-2xl after-line relative">Benefits</p>
            </div>
            <div className="my-10" id="cmsdata"></div>
        </div>
    )
});





