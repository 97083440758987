import { createContext, useContext, useState } from "react";
import {
  makeAutoObservable,
  useLocalObservable,
  observable,
  runInAction,
} from "mobx";
import {
  postLogin,
  postMethodData,
  patchMethodData,
  getMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";
import { format } from "date-fns";
// import { AuthStore } from "../../Auth/store/Auth";

export class MyMatchPageStore {
  Token = null;
  user = {};
  Check="";
  route="";
  Check_Upcoming_Dates=""
  upcoming_match_date = "";
  upcoming_date = "";
  matchLeague = [];
  upcomingLeague="";
  dateee = "";
  NoMatch = false;
  currentDate = "";
  loadPageMatch = false;
  errloadPageMatch = false;
  Matchdata = [];
  time = [];
  subcription_count = "";
  datesss = [];
  MatchDate =[];
  SelectedDate = "";
  nolist = false;
  loadMatch = false;

  constructor() {
    makeAutoObservable(this);
    // if (isloading) {
    //   // this.TokenUser = localStorage.getItem("token");
    // }
    this.Token = localStorage.getItem("token");
    this.user = JSON.parse(localStorage.getItem("user"));
  }
  setRoutes=(e)=>{
    this.route=e;
  }
  getSelectedDate = (e) => {
    this.SelectedDate = e;
  };
  getleagueid = (e) => {
    this.leagueID = e;
  };
  setmapshow = (e) => {
    this.mapshow = e;
  };
  setmatchid = (e) => {
    this.matchid = e;
  };
  setpopisOpen = (e) => {
    this.popisOpen = e;
    this.description = "";
  };
  setNoMatch = () => {
    this.NoMatch = !this.NoMatch;
  };
  gettoken = (e) => {
    this.Token = e;
  };
  getuser = (e) => {
    this.user = e;
  };
  setnewpopup = (e) => {
    this.newpop = e;
  };
  opentable = (e) => {
    this.opentables = e;
  };

  valueCheck = (e) => {
    this.Check = e;

  };
  
  SetCheck_Upcoming_Dates=(e)=>{
    this.Check_Upcoming_Dates=e;
  }

  //League Name
  getLeaguesid = async(id) => {
    this.leagueid = id;
    runInAction(() => {
      this.loadPageMatch = true;
      this.errloadPageMatch = "";
      this.saved = false;
      this.leaguesiddata = "";
    });
    await getMethodData(`/api/get/league/${this.leagueid}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.leaugesfalse = false;
            this.loadPageMatch = false;
            this.leaguesiddata = "";
            this.errloadPageMatch = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            if (resp?.data?.data?.league_type === "mixed") {
              if (this.user?.gender === "female") {
                this.mixedgender = "male";
                // return
              } else {
                this.mixedgender = "female";
                // return
              }
            } else {
              this.mixedgender = this.user?.gender;
            }
            this.leaugesfalse = true;
            this.errloadPageMatch = resp?.data?.message;
            this.leaguetype = resp?.data?.data?.league_type;
            this.leaguesiddata = resp?.data?.data?.name;
            this.fees = resp?.data?.data?.fees_amount;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.saved = false;
            // this.status = true;
          });
          return;
        }

        runInAction(() => {
          this.leaugesfalse = false;
          this.loadPageMatch = false;
          this.errloadPageMatch = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.leaugesfalse = false;
          this.loadPageMatch = false;
          if (!error.response) {
            this.errloadPageMatch = error?.message;
            toast.error(error?.message);
          }
          this.errloadMatch = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
  //Scheduled Match
  getmatchdata = async(month, year) => {
    runInAction(() => {
      this.loadPageMatch = true;
      this.errloadPageMatch = "";
      this.saved = false;
    });
    await getMethodData(
      `/api/user/my-matches?filters[year]=${year}&filters[month]=${month + 1}${this.Check?.length > 0  ? "" : "&filters[isFront]=true"}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadPageMatch = false;
            this.errloadPageMatch = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadPageMatch = false;
            this.errloadPageMatch = resp?.data?.message;
            if (resp?.data?.data.dates.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.Matchdata = [];
            this.time = [];
            this.matchLeague = resp?.data?.data?.metches;
            this.SetCheck_Upcoming_Dates(resp?.data?.data.dates.length)
            for (const date of resp.data.data?.metches) {
              this.Matchdata.push(
                format(new Date(date.schedule_date), `yyyy,M,d`)
              );
              this.time.push(date);
            }
            this.subcription_count = resp?.data?.data?.subcription_count;
            this.datesss = resp.data.data?.metches;
            this.MatchDate = resp.data.data?.dates;
            this.upcoming_match_date = resp?.data?.data?.upcomingDate;
            this.upcomingLeague= resp?.data?.data?.upcomingLeague;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadPageMatch = false;
          this.errloadPageMatch = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadPageMatch = false;
          if (!error.response) {
            this.errloadPageMatch = error?.message;
            toast.error(error?.message);
          }
          this.errloadPageMatch = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
  getupcomingdate = async(month, year) => {
    runInAction(() => {
      this.loadPageMatch = true;
      this.loadMatch = true;
      this.errloadPageMatch = "";
      this.saved = false;
    });
    await getMethodData(
      `/api/user/my-matches?filters[year]=${year}&filters[month]=${month + 1}${this.Check?.length > 0  ? "" : "&filters[isFront]=true"}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadPageMatch = false;
            this.loadMatch = true;
            this.errloadPageMatch = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadMatch = false;
            localStorage.setItem("upcoming_date", resp?.data?.data?.upcomingDate);
            this.upcoming_date = resp?.data?.data?.upcomingDate;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadPageMatch = false;
          this.errloadPageMatch = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadPageMatch = false;
          this.loadMatch = false;
          if (!error.response) {
            this.errloadPageMatch = error?.message;
            toast.error(error?.message);
          }
          this.errloadPageMatch = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
  //Date Clickable
  getDateData = async (day, id) => {
    // console.log("===>>", year,month)
    // return
    runInAction(() => {
      // this.loadMatch = true;
      this.errloadMatch = "";
      this.saved = false;
    });
    await getMethodData(
      `/api/user/get-matches-by-date?filters[user_id]=${id}&filters[date]=${day}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.loadMatch = true;
            this.errloadMatch = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          // console.log(d);
          runInAction(() => {
            // this.loadMatch = false;
            this.errloadMatch = resp?.data?.message;
            // if (resp?.data?.data.length === 0) {
            //   this.nolist = true;
            // } else {
            //   this.nolist = false;
            // }
            this.matchLeague = resp?.data?.data?.metches;
            this.SetCheck_Upcoming_Dates(resp?.data?.data.dates.length)
            // this.calenderdate = {...resp.data.data}

            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          // this.loadMatch = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
}

export const MyMatchPageStoreContext = createContext(new MyMatchPageStore());
export const useMyMatchPageStore = () => useContext(MyMatchPageStoreContext);
