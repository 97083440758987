/* global google */
import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { useAuthStore } from '../../Auth/store/Auth';
import { RegisterStore, useRegisterStore,RegisterStoreContext } from "../store/RegisterStore";
// import Select from 'react-select';
// import mapblur from "../../assets/image/mapblur.webp";
// import MapContainer from "../MapContainer/MapContainer";
// import { useLeagueStore } from "../../Leaguespage/store/Leaguestore";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useForm } from "react-hook-form";
// import { toast } from "react-toastify";

// const Facility = () => {
//     return(
//       <RegisterStoreContext.Provider value={new RegisterStore({
//       isloading: false,
//       loadfacility: true,
//       })}>
//         <Screen/>
//       </RegisterStoreContext.Provider>
//     )
//   }
  

const Partner = observer(() => {
  // const {} = useRegisterStore()
  const {leaguetype,leaguesiddata,onlyhomecourt,homecourtid, leaguedata,playerid,getHomecourtid,getCaption,changeStep,player,getPlayer,getLeaguesid, getFirstname,fName,lName,ischecked,getselected,ischeckedid,getPlayerdetails ,contactUs,getName,getEmail,getDescription ,getLastname,playerfname ,playerlname,homecourtdrop,getState, getlocation ,showtable,lat,lng,nolist,allClear, getShowmap, showmap,onSubmitsingle,gethomecourtdropdown,setpopisOpen } = useRegisterStore();
  const {user} = useAuthStore();
  // console.log("===>>>>",leaguedata)
  // const { } = useLeagueStore();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(()=> {
    gethomecourtdropdown()
    getState();
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  },[])
 
  const {
    setValue,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleClickScroll = () => {
    const element = document.getElementById('partner');
    if (element) {
      element.scrollIntoView({  top: 0, behavior: 'smooth' });
    }
  };

  const handleClickScrollpartner = () => {
    const element = document.getElementById('partnertable');
    if (element) {
      element.scrollIntoView({ behavior:'smooth' });
    }
  };
  // const selectOnlyThis = (e) => {
  //   console.log(e.target?.id)
  //   const myCheckbox = document.getElementsByName("myCheckbox");
  //   myCheckbox.forEach((m)=> {
  //     console.log('>>>>>>>>>>Tttttt',e.target.id, m.id);
  //     if(e.target.id === m.id){
  //       e.checked = true;
  //     }else{
  //       m.checked = false;
  //     }
  //   })
  //   // id.checked = true;
  // }

  return (
    <div id="partnertable" className="w-full 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-1 py-3">
      <div className="flex flex-wrap justify-center">
        <div className="2xl:w-[80%] lg:w-[80%] md:w-[90%] w-full 2xl:max-w-[90%]">
      {/* {leaguetype==="singles" ? 
        <div className=" bg-[#6C6C6C] border border-[#fff] bg-opacity-40 rounded-lg lg:px-10 md:px-5 px-4 md:pt-10 py-10">
        <form onSubmit={(e)=> {onlyhomecourt(homecourtid);getCaption(user._id); e.preventDefault(); }}>
              <div className=" text-center">
                <p className=" text-[#fff] font-medium md:text-3xl text-2xl  relative">
                Choose Your Facility partner
                </p>
              </div>
          
              <div className="grid gap-8 relative md:grid-cols-2 my-10">
               
                {lat === null ?
                  <>
                <div className="relative">
                <img className="w-[400px] h-[200px]" src={mapblur}/>
                 <p className="text-[#000] flex justify-center items-center absolute top-[50%] left-[25%] text-xl">Map Not Available</p>
                 </div>
                 </>
                 :
                 <MapContainer mylat={parseFloat(lat)} mylng={parseFloat(lng)}/>}
                

                <div className="">
                <Select
                    id="location"
                    options={ homecourtdrop}
                    // onClick={() =>{ changeStep("1"); allClear()}}
                    onChange={(e)=>{getlocation(e);getHomecourtid(e._id);} }
                    getOptionLabel ={(homecourtdrop)=>homecourtdrop.name}
                    getOptionValue ={(homecourtdrop)=>homecourtdrop._id}
                    // value={user?.homeCourt?._id}
                    // defaultInputValue={user?.homeCourt?.name}
                    defaultValue={user.homeCourt.isVerified===true? user?.homeCourt: null}
                    isSearchable={true}
                    isMulti={false}
                    className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg focus:outline-none"
              
                  />
                  
                  

                 
                  <div className=" flex justify-between text-center my-10  relative">
                    <button
                      className=" font-medium lg:text-[20px] text-lg text-[#000] bg-[#FFFFFF] px-11 py-2 rounded-lg mx-auto"
                      type="button"
                      onClick={() =>{ changeStep("1"); allClear()}}
                    >
                      Cancel
                    </button>
                    <button
                       onClick={() =>{
      changeStep("4");

    //                     if (selectedOption === null) {
    //                       toast.error('Please Select HomeCourt.');
    // } else {
    //   changeStep("4");
    // }
                       }}
                      className=" font-medium lg:text-[20px] text-lg text-[#000] bg-[#39FD13] px-11 py-2 rounded-lg mx-auto"
                      type="submit"
                    >
                      Continue
                    </button>
                  </div>
                  <div className="flex justify-center relative ">
                    <p className="text-[#fff]">
                      Need help ?
                      <span className="text-[#39FD13] cursor-pointer ml-2"
                       onClick={() => {setIsOpen(true)}}
                      > Contact us</span>
                    </p>
                  </div>
                </div>
              
                
              </div> 
            </form>
        </div>
        : */}
        <div className=" bg-[#6C6C6C] border border-[#fff] bg-opacity-40 rounded-lg lg:px-10 md:px-5 px-4 md:pt-5 py-5">
            <form
              autoComplete="new-password"
              onSubmit={(e) => {
                getPlayer();
                handleClickScrollpartner();
                e.preventDefault();
              }}
            >
              <div className=" text-center">
                <p className=" text-[#fff] font-medium md:text-2xl text-xl relative">
                 Choose Your Partner
                </p>
              </div>
              <div className="grid gap-6 md:grid-cols-2 mt-5">
                <div className="col-span-1">
                  <input
                    value={fName}
                    name="firstName"
                    onChange={(e) => getFirstname(e.target.value)}
                    autoComplete="FirstName"
                    id="firstName"
                    // required
                    className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    placeholder="First Name"
                    type="text"
                  />
                </div>
                <div>
                  <input
                    value={lName}
                    name="lastName"
                    onChange={(e) => getLastname(e.target.value)}
                    autoComplete="LastName"
                    id="lastName"
                    className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    placeholder="Last Name"
                    type="text"
                  />
                </div>
                <div className="md:col-span-2 ">
                  <div className="text-center space-x-4 my-3">
                  <button
                    className=" font-medium lg:text-[20px] text-lg text-[#000] bg-[#fff] px-11 py-2 rounded-lg mx-auto"
                    type="button"
                    onClick={() =>{ changeStep("1"); allClear()}}
                  >
                    Cancel
                  </button>
                  {fName == "" && lName == "" ? 
                  <button
                  // onClick={()=> }
                  className=" font-medium opacity-80 lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto"
                  type="button"
                  disabled
                >
                  Search
                </button>
                 : 
                 <button
                    className=" font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto"
                    type="submit">
                    Search
                  </button>}
                    
                  </div>
                </div>
              </div>
              <div id="partnertable">
              {showtable===true ?
            <div id="style-1" className=" bg-[#6C6C6C] border-2 border-[#fff] bg-opacity-40 rounded-lg my-5 max-h-[350px] overflow-auto">  
              <div id="partner">
              <table className="w-full text-[#fff]">
              <thead >
                <tr className="bg-[#000] sticky top-0 ">
                  <th>Select</th>
                  {/* <th className="py-5">S. No.</th> */}
                  <th className="py-3 pl-2 text-left">Player</th>
                  <th className="text-left">Home Court</th>
                  <th className="text-left">Level</th>
                </tr>
              </thead>
              <tbody className="text-center" >
              {player.map((data,index)=>(
              <tr key={index} className="hover:bg-[#39fd13] hover:bg-opacity-5  ">
                <label className="w-full contents cursor-pointer">
                    <td className="align-middle"> 
                      <input 
                      className=" md:h-[20px] md:w-[20px] "
                      id={index}
                      type="radio" 
                      checked ={ischeckedid === index ? true : false}
                      name="myCheckbox" 
                      value={index}
                      onClick={(e)=>{getShowmap(true);getPlayerdetails(data);getselected(index);getFirstname("");getLastname("")}}
                      /> 
                    </td>
                    {/* <td>{index + 1}</td> */}
                    <td className="py-4"> 
                      <div className="flex items-center space-x-3">
                              <div className="avatar">
                                <div className="flex  items-center  w-10 h-6">
                                  <img
                                    crossOrigin="anonymous"
                                    src={
                                       BASE_URL + `/image/${data?.image}`
                                    }
                                    alt="Avatar Tailwind CSS Component"
                                  />
                                </div>
                              </div>
                              <div className="ml-3 capitalize">
                                {data?.firstName + " " + data?.lastName}
                              </div>
                            </div></td>
                    <td className="text-left align-middle">{data?.homeCourt?.displayName ?? "-"}</td>
                    <td className="align-middle text-left" >{leaguetype=="doubles"? (data?.doubles_level?.name?.toFixed(1)):(data?.mixed_level?.name?.toFixed(1))}</td>
                    {/* <td>{data?.ranking}</td> */}
                </label>
              </tr>
      
              ))}
              {nolist && (
                    <tr>
                      <td colSpan={6} className="py-20 ">
                        <p className=" text-center font-semibold text-lg">The searched player is either not registered with FLT or not matching your skill level.</p>
                      </td>
                    </tr>
                  )}
            
              </tbody>

              </table>
              </div>
            </div>
            :
            null
          }
              </div>
              {showmap ?
               <div className=" flex space-x-4 justify-center text-center my-5 relative">
                    <button
                      className=" font-medium lg:text-[20px] text-lg text-[#000] bg-[#FFFFFF] px-11 py-2 rounded-lg "
                      type="button"
                      onClick={() =>{ changeStep("1"); allClear()}}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => changeStep("3")}
                      className=" font-medium lg:text-[20px] text-lg text-[#000] bg-[#39FD13] px-11 py-2 rounded-lg "
                      type="submit"
                    >
                      Continue
                    </button>
                  </div>

                  :
          <></>
             }
    
              
            </form>
           
           
          </div>
        {/* } */}
          
        </div>
      </div>
    </div>
  );
});
export default Partner;

