import { createContext, useContext, useState } from "react";
import {
  makeAutoObservable,
  useLocalObservable,
  observable,
  runInAction,
} from "mobx";
import {
  postLogin,
  postMethodData,
  patchMethodData,
  getMethodData,
  deleteData,
  postFormData,
  
} from "../../ApiMethods/api";
import { toast } from "react-toastify";
import {authentication} from "../../firebase-config";
import {signOut,signInWithPopup,GoogleAuthProvider , FacebookAuthProvider, getAuth, OAuthProvider } from "firebase/auth";
// import { fcmToken } from "../../firebase-config";

export class AuthStore {
  loader=false;
  LatLng=[];
  FormatedAddress="";
  LongName="";
  CityName = this.user?.city?.city_name;
  addressCheck="";
  address=null;
  keysss= null;
  mylatitude="";
  mylongitude="";
  zipCode= null;
  selectedImage=null;
  errLoggingIn = "";
  errwrongemail = "";
  FCM =""
  noSeasonList=false;
  loginLoading = false;
  authapproved = false;
  loginNoEmail = false;
  passchangedsuccesfull = false;
  passwordConfirm = false;
  user = {};
  userlatest = {};
  Token = null;
  seasons="";
  //   social login var
  isEmailverfied = false;
  isSignupComplete = false;
  signResponse = {};
  signupemailonly = false;
  gender="";
  isChecked = false;
  SeasonYear="2024";
  email = "";
  password = "";
  seasondropdown=[];
  resetemail = "";
  resetpassword = "";
  confirmresetpas = "";
  errSigningIn = "";
  errSigningup = "";
  otpscreen = false;
  OTP = { otp: null };
  ForgotOTP = { forgototp: null };
  verifybtn = false;
  otpTimeStatus = false;
  SeasonMatch=[];
  data = {};
  signupemail = "";
  firstname = "";
  lastname = "";
  signuppassword = "";
  errSignup = "";
  Forgotpass = "";
  passwordLoading = false;
  errPassword = "";
  state = "";
  currentpassword = "";
  newpassword = "";
  confirmnewpassword = "";
  passwordpopup = false;
  strongRegex = new RegExp( "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
  RememberCheck =false;
  output= ""
  cityid =""

  // city
  loadhomecourt = false;
  errloadhomecourt = "";
  citydrop = [];

  // homecourt
  errloadcourt = "";
  homecourtdrop = [];
 
  getaddress =(text)=>{
    this.address = text;
    }
    SetaddressCheck =(text)=>{
      this.addressCheck = text;
      }

  // level
  leveldropdown = [];

  //state
  statedrop = [];

  socialType = "email";
  socialUiddata = {};
  
  noemailloginLoading = false;
  errNoemailsignin = "";
  
  auth = getAuth();

  //profile
  // profile = {}


  upcomingleauge = false;

  allowuser = false;

  // my account

  myDetails = {};
  myDetailserrr = "";
  myDetailsLoad = false;
  userimage = {}

  showinput =false;


  dobuser = ""
  dobusererr = false

  //get history of profile
  gethistory = []
  nolistHistory=false
  //notification
  notification = [];
  allnotification = [];
  notificationcount =""
  nolist = false;
  nolistAllNoti=false;
  loadnotification=false
  
  
  isRedirect= false;
  redirectURL=  "";

  devicetype =""
  
  deletepopup = false;
  deleteload =false;
  deletenav= false;

  upcomingcity = "";
  

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.loadUser();
    this.user = JSON.parse(localStorage.getItem("user"));
    this.keysss=localStorage.getItem("Mapkey");
    if (isloading.loadfacility) {
     this.gethomecourtdropdown()
    }
    // this.getToken()

    if(localStorage.getItem("email") && localStorage.getItem("password")){
        this.isChecked = true;
        this.email=  localStorage.getItem("email")
        this.password= localStorage.getItem("password")
    }
  }

  // async getToken() {
  //   const token = await fcmToken;
  //    this.FCM =token
  // }
  
  //Redirect
  RedirectChanged = (arr1,state) => {
    this.isRedirect = state;
    // if (arr1 !== undefined) {
      this.redirectURL = arr1;
    // }
  };

  setlatestuser = (text) => {
    this.userlatest = text 
  }

  setlatestuserapiii = (e) => {
    const { name, value } = e.target;
    if(name == "address_state" || name == "city"){
      this.userlatest =  {
        ...this.userlatest,
        [name]: {_id:value},
      }
    }
    else{
      this.userlatest =  {
        ...this.userlatest,
        [name]: value,
      } 
    }
  }

  setotpTimeStatus= (text) => {
    this.otpTimeStatus= text;
  };

  setupcomingleauge = (text) => {
    this.upcomingleauge = text;
  };

  setupcomingcity = (text) => {
    this.upcomingcity = text;
  };
  setsocialType = (text) => {
    this.socialType = text;
    this.homecourtdrop = [];
  };
  componentWillUnmount() {
    this.isEmailverfied = false;
  }

  isEmailverfiedchange = (text) => {
    this.isEmailverfied = text;
  };

  otpscreenremove = (text) => {
    this.otpscreen = text;
  };

  getinput =(text) =>{
    this.showinput = !this.showinput
  }

 // get latitude
getlatitude =(text)=>{
  this.mylatitude = text;
}

getLongName =(text)=>{
  this.LongName = text;
}
getFormatedAddress =(text)=>{
  this.FormatedAddress = text;
}

//get longitude
getlongitude=(text)=>{
this.mylongitude = text; 
}
getZipcode=(text)=>{
  this.zipCode = text; 
  }
  onChangeCheckbox = (event) => {
    this.isChecked = event;
    if (!event) {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
  };
  

  // get profile data
  // getprofile = (text) => {
  //   this.user = text;
  // };

  // get opt
  setOTP = (otp) => {
    this.OTP = { otp };
    this.errSigningup = "";
    if (otp.length === 4) {
      this.verifybtn = true;
    } else {
      this.verifybtn = false;
    }
  };
  // get opt
  setforgotOTP = (forgototp) => {
    this.ForgotOTP = { forgototp };
    this.errSigningup = "";
    if (forgototp.length === 4) {
      this.verifybtn = true;
    } else {
      this.verifybtn = false;
    }
  };

  // get email value
  emailChanged = (text) => {
    this.email = text;
  };

  // get password value
  passwordChanged = (text) => {
    this.password = text;
  };

  // get forgotemail value
  Confirmemail = (text) => {
    this.resetemail = text;
  };
getSeasonYear=(e)=>{
  this.SeasonYear=e;
}
  // get Forgot password value
  newpassw = (text) => {
    this.resetpassword = text;
  };
  // get Forgot password value
  Confirmpassw = (text) => {
    this.confirmresetpas = text;
  };

  // getcurrentpassword value
  getcurrentpassword = (text) => {
    this.currentpassword = text;
    this.errPassword = "";
  };

  // getnewpassword value
  getnewpassword = (text) => {
    this.newpassword = text;
    // console.log(text)
    this.errPassword = "";
  };

  // getconfirmnewpassword value
  getconfirmnewpassword = (text) => {
    this.confirmnewpassword = text;
  };
 getSeasons=(e)=>{
  this.seasons=e;
 }
  //email
  getsignupemail = (text) => {
    this.signupemail = text;
  };
  //Forgot email
  getforgotpassEmail = (text) => {
    this.Forgotpass = text;
  };
  //signup First Name
  getsignupfname = (text) => {
    this.firstname = text;
  };
  //signup Last Name
  getsignuplname = (text) => {
    this.lastname = text;
  };
  //signup password
  setSelectedImage = (text) => {
    this.selectedImage = text;
  };

  setLatLng = (text) => {
    this.LatLng= text;
  };

  setpasswordpopup = () => {
    this.passwordpopup = !this.passwordpopup;
    this.errPassword = "";
    // this.currentpassword = ""
    // this.newpassword = ""
    // this.confirmnewpassword = ""
  };

  setdeletepopup =(e) =>{
    this.deletepopup = e
  }

  //get devicetype

  getdevicetype = () => {

    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) {
      this.devicetype="Windows 10";
    }
    
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1) {
      this.devicetype="Windows 8.1";
    }
    
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) {
      this.devicetype="Windows 8";
    }
    
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) {
      this.devicetype="Windows 7";
    }
    
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) {
      this.devicetype="Windows Vista";
    }
    
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) {
      this.devicetype="Windows XP";
    }
    
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) {
      this.devicetype="Windows 2000";
    }
    
    if (window.navigator.userAgent.indexOf("Mac") != -1) {
      this.devicetype="Mac/iOS";
    }
    
    if (window.navigator.userAgent.indexOf("X11") != -1) {
      this.devicetype="UNIX";
    }
    
    if (window.navigator.userAgent.indexOf("Linux") != -1) {
      this.devicetype="Linux";
    }
    
  };

  // load user data
  loadUser = async () => {
    if (!localStorage.getItem("user")) {
      runInAction(() => {
        this.authapproved = false;
      });
      return;
    }
    runInAction(() => {
      this.Token = localStorage.getItem("token");
      this.user = JSON.parse(localStorage.getItem("user"));
      this.authapproved = true;
    });
  };


  //get profile
  getProfile = async () => {
    runInAction(() => {
      this.loadseason = true;
      this.errloadseason = "";
      this.saved = false;
      
    });
    await getMethodData("/api/user/profile", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            
            this.errloadseason = resp?.data?.message;
            // if(resp?.data?.data.length === 0) {
            //   this.nolist = true;
            // }else{
            //   this.nolist = false;
            // }
            
            this.userlatest = resp.data?.data;
            localStorage.setItem("user", JSON.stringify(resp.data.data));
            this.user = resp.data?.data;
            // this.profile =resp?.data?.data;
            // this.saved = false;
            // this.status = true;
            this.loadseason = false;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {

            this.loadseason = false;
            if(!error.response){
                this.errloadseason = error?.message;
                toast.error(error?.message);
            }
            this.errloadseason = error?.response?.data?.message;
            toast.error(error?.message);
        })
        return;
    });
  };

  // getuserimage = (e) => {
  //   this.userimage = e
  // }

  setdobuser = (e) => {
    this.dobuser = e
    // console.log(e)
    this.dobusererr = false
  }

  calculateAge(data) {
    const dob = new Date(data);
    const currentDate = new Date();
    const diff = Date.now() - dob.getTime();
    const ageDate = new Date(diff);
    return Math.floor((currentDate - dob) / (365.25 * 24 * 60 * 60 * 1000));
  }

// update profile after social login
  updateProfile = async (data) => {
    runInAction(() => {
      this.loginLoading = true;
    })
    // data.preventDefault();
    const age = this.calculateAge(data?.dob);
    if (age >= 18) {
    this.dobusererr = false
    const formData = new FormData();
    const appendedKeys = [];
    Object.keys(data).forEach((key) => {
     
      if (key === "mobile") {
        const mobileNumber = data[key].replace(/[^\d]/g, "");
        if (!formData.has("mobile") && !appendedKeys.includes("mobile")) {
          formData.append(key, mobileNumber);
          appendedKeys.push(key); // add to the appended keys list
        }
      } 
      if(key === "image"){
        if (data.image && data.image.length > 0) {
          formData.append("image", data.image[0]);
        } else {
          formData.delete("image");
        }
      }
    //   if(key === "homeCourt"){
    //     if(!data.homeCourt){
    //       console.log(data.homeCourt)
    //       formData.delete("homeCourt");
    //     }
    // }
    //   if(key === "homeCourt_name"){
    //     if(!data.homeCourt_name){
    //       console.log(data.homeCourt_name)
    //       formData.delete("homeCourt_name");
    //     }
    //   }
      else {
        if (!appendedKeys.includes(key)) {
          formData.append(key, data[key]);
          appendedKeys.push(key); // add to the appended keys list
        }
      }
    });

    if (
      this.newpassword != this.currentpassword &&
      (this.signResponse?.user?.socialType === "email")
    ) {
      // console.log("firstttt",this.signResponse?.user?.socialType)
      // console.log("2")
      runInAction(() => {
        this.loginLoading = false;
        this.errPassword =
          "The New password and confirmation password do not match.";
        // toast.error("The New password and confirmation password do not match.")
      });
      return;
    }
    // runInAction(() => {});
    if (
      this.strongRegex.test(this.newpassword) &&
      (this.signResponse?.user?.socialType === "email")
    ) {
      // console.log("3")
      // console.log("second",this.signResponse?.user?.socialType)
      // return
      
      // const formattedData = {
      //   ...data,
      //   mobile: data.mobile?.replace(/[^\d]/g, ""),
      //   // image: formData.append("image", data.image[0])
      // };
      
      await postFormData(
        "/api/user/update/profile",
        formData,
        this.signResponse.token ?? this.Token
      )
        .then((resp) => {
          // console.log(resp.data.status)
          if (resp.data.status === false) {
            runInAction(() => {
              this.loginLoading = false;
              toast.error(resp.data.message)
            });
            return;
          }
          if (resp.data.status === true) {
            window.scrollTo({
              top: 0, 
              behavior: 'smooth'
            })
            runInAction(() => {
              this.loginLoading = false;
              if(resp?.data?.data?.gender !== this.gender && this.gender !== "mixed"){
                // console.log(resp?.data?.data?.user?.gender)
                this.allowuser = true
                // console.log("inside",this.allowuser)
              }
              this.Token = this.signResponse.token ?? this.Token;
              // console.log(resp.data)
              this.loginLoading = false;
              this.authapproved = true;
              this.newpassword = "";
              this.currentpassword = "";
              
              // localStorage.setItem("user", JSON.stringify(resp.data.data));
              localStorage.setItem("token", this.signResponse.token ?? this.Token);
              this.getProfile();
              this.errLoggingIn = resp?.data.message;
              
              // this.user = resp.data.data;
              toast.success(resp.data.message);
            });
            return;
          }
        })
        .catch((err) => {
          runInAction(() => {
            // console.log(err.data)
            this.loginLoading = false;
            toast.error(err.data?.message);
            toast.error(err.response?.data?.message);
          });
        });
      return;
    }
    if (this.signResponse?.user?.socialType != "email") {
      // console.log("4")
      // console.log("third",this.signResponse?.user?.socialType)
      // return
      // const formattedData = {
      //   ...data,
      //   mobile: data.mobile?.replace(/[^\d]/g, ""),
      //   // image: formData.append("image", data.image[0])
      // };
      await postFormData(
        "/api/user/update/profile",
        formData,
        this.signResponse.token ?? this.Token
      )
        .then((resp) => {
          // console.log(resp.data.status)
          if (resp.data.status === false) {
            runInAction(() => {
              this.loginLoading = false;
              toast.error(resp.data.message)
            });
            return;
          }
          if (resp.data.status === true) {
            window.scrollTo({
              top: 0, 
              behavior: 'smooth'
            })
            runInAction(() => {
              this.loginLoading = false;
              if(resp?.data?.data?.gender !== this.gender && this.gender !== "mixed"){
                // console.log(resp?.data?.data?.user?.gender)
                this.allowuser = true
                // console.log("inside",this.allowuser)
              }
              this.Token = this.signResponse.token ?? this.Token;
              // console.log(resp.data)
              this.loginLoading = false;
              this.authapproved = true;
              this.newpassword = "";
              this.currentpassword = "";
              // localStorage.setItem("user", JSON.stringify(resp.data.data));
              localStorage.setItem("token", this.signResponse.token ?? this.Token);
              this.getProfile();
              this.errLoggingIn = resp?.data.message;
              
              // this.user = resp.data.data;
              toast.success(resp.data.message);
            });
            return;
          }
        })
        .catch((err) => {
          runInAction(() => {
            // console.log(err)
            this.loginLoading = false;
            toast.error(err.data?.message);
            toast.error(err.response?.data?.message);
          });
        });
    } else {
      // console.log(data)
      if(localStorage.getItem("token")){
        // console.log("5")
        await postFormData(
          "/api/user/update/profile",
          formData,
          this.signResponse.token ?? this.Token
        )
          .then((resp) => {
            // console.log(resp.data.status)
            if (resp.data.status === false) {
              runInAction(() => {
                this.loginLoading = false;
                toast.error(resp.data.message)
              });
              return;
            }
            if (resp.data.status === true) {
              window.scrollTo({
                top: 0, 
                behavior: 'smooth'
              })
              runInAction(() => {
                this.loginLoading = false;
                if(resp?.data?.data?.gender !== this.gender && this.gender !== "mixed"){
                  // console.log(resp?.data?.data?.user?.gender)
                  this.allowuser = true
                  // console.log("inside",this.allowuser)
                }
                this.Token = this.signResponse.token ?? this.Token;
                // console.log(resp.data)
                this.loginLoading = false;
                this.authapproved = true;
                this.newpassword = "";
                this.currentpassword = "";
                
                // localStorage.setItem("user", JSON.stringify(resp.data.data));
                localStorage.setItem("token", this.signResponse.token ?? this.Token);
                this.getProfile();
                this.errLoggingIn = resp?.data.message;
                
                // this.user = resp.data.data;
                toast.success(resp.data.message);
              });
              return;
            }
          })
          .catch((err) => {
            runInAction(() => {
              this.loginLoading = false;
              // console.log(err.data)
              toast.error(err.data?.message);
              toast.error(err.response?.data?.message);
            });
          });
        return;
      }
      // console.log("6")
      this.loginLoading = false;
      this.errPassword = "( 8 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter )";
      // toast.error("8 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter")
      // console.log("fourth",this.signResponse?.user?.socialType)
    }
  }
    else {
      // console.log("7")
      this.loginLoading = false;
      this.dobusererr= true;
      // alert('You are not 18 yet');
      // console.log("fifth",this.signResponse?.user?.socialType)
    }
  };
//Update Profile Image
updateProfileImage = async (data) => {
  // data.preventDefault();
  // console.log(data)
  this.dobusererr = false
  this.loader=true;
  const formData = new FormData();
  formData.append("image", data.image[0]);
    await postFormData(
      "/api/user/update/profile",
      formData,
      this.signResponse.token ?? this.Token
    )
      .then((resp) => {
        // console.log(resp.data.status)
        if (resp.data.status === false) {
          runInAction(() => {
            toast.error(resp.data.message)
            this.loader=true;

          });
          return;
        }
        if (resp.data.status === true) {
          window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          })
          runInAction(() => {
            if(resp?.data?.data?.gender !== this.gender && this.gender !== "mixed"){
              // console.log(resp?.data?.data?.user?.gender)
              this.allowuser = true
              // console.log("inside",this.allowuser)
            }
            this.Token = this.signResponse.token ?? this.Token;
             // console.log(resp.data)
            this.loader=false;
            this.loginLoading = false;
            this.authapproved = true;
            this.newpassword = "";
            this.currentpassword = "";
            this.setSelectedImage(null);
            // localStorage.setItem("user", JSON.stringify(resp.data.data));
            localStorage.setItem("token", this.signResponse.token ?? this.Token);
            this.getProfile();
            this.errLoggingIn = resp?.data.message;
            
            // this.user = resp.data.data;
            toast.success(resp.data.message);
          });
          return;
        }
      })
      .catch((err) => {
        runInAction(() => {
          // console.log(err.data)
          toast.error(err.data?.message);
          toast.error(err.response?.data?.message);
        });
      });
    return;

}
  //   google login
  signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    
    runInAction(() => {
      this.newpassword="";
      this.currentpassword="";
    })
    signInWithPopup(authentication,provider).then(async (res) => {
      
            runInAction(() => {
              this.loginNoEmail = false;
              this.socialUiddata =  res;
              this.socialType = "google";
              this.getdevicetype() 
            })
           
        // if(res.user.email != ""){
       await postLogin("/api/auth/signup", {
          firstName: res._tokenResponse.firstName,
          lastName: res._tokenResponse.lastName,
          mobile: res.user.phoneNumber,
          dob: "",
          gender: "",
          email: res.user.email || res.user.providerData[0].email,
          // email:"",
          uid: res.user.uid,
          loginType: "social",
          socialType: "google",
          isEmailVerified: res.user.email != "" ? true : false,
          deviceId: this.devicetype,
          deviceToken: "823749879823747",
        })
          .then((resp) => {
            if (resp?.data?.status === false) {
              runInAction(() => {
                this.signupemailonly = false;
                this.loginNoEmail = true;
                this.signResponse = resp?.data?.data;
                toast.error("We could not retrieve your email from Google. Please enter your e-mail to sign up or contact us at support@flexleaguetennis.com");

              });
              return;
            }
            if (resp?.data?.status === true) {
              runInAction(() => {
                this.signupemailonly = false;
                if (!resp?.data?.data?.profileComplete) {
                  this.isEmailverfied = true;
                  this.signResponse = resp?.data?.data;
                  return;
                }
                if (resp?.data?.data?.profileComplete) {
                  if(resp?.data?.data?.user?.gender !== this.gender && this.gender !== "mixed"){
                    // console.log(resp?.data?.data?.user?.gender)
                    this.allowuser = true
                    // console.log("inside",this.allowuser)
                  }
                  this.loginLoading = false;
                  this.authapproved = true;
                  this.errLoggingIn = resp?.data?.message;
                  this.Token = resp?.data?.data?.token;
                  // this.user = resp.data?.data.user;
                  // localStorage.setItem("user",JSON.stringify(resp.data.data.user));
                  this.getProfile();
                  localStorage.setItem("token", resp.data.data.token);
                  // toast.success(resp.data.message);
                }
                // signOut(authentication)
              });
              return;
            }
            runInAction(() => {});
          })
          .catch((err) => {
            console.log(err);
          });
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   facebook login
  signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    
    runInAction(() => {
      this.newpassword="";
      this.currentpassword="";
    })
    signInWithPopup(authentication,provider).then(async (res) => {
      console.log("res==>>",res)
      runInAction(() => {
        this.loginNoEmail = false;
        this.socialUiddata =  res;
        this.socialType = "facebook";
        this.getdevicetype() 
      })
      // console.log(res)
      // console.log(res.user.email)
        // if(res.user.email != ""){
        await postLogin("/api/auth/signup", {
          firstName: res._tokenResponse.firstName,
          lastName: res._tokenResponse.lastName,
          mobile: res.user.phoneNumber,
          dob: "",
          gender: "",
          email: res.user.email || res.user.providerData[0].email,
          uid: res.user.uid,
          loginType: "social",
          socialType: "facebook",
          isEmailVerified: res.user.email != "" ? true : false,
          deviceId: this.devicetype,
          deviceToken: "823749879823747",
        })
          .then((resp) => {
            if (resp?.data?.status === false) {
              runInAction(() => {
                this.signupemailonly = false;
                this.loginNoEmail = true;
                this.signResponse = resp?.data?.data;
                toast.error("We could not retrieve your email from Facebook. Please enter your e-mail to sign up or contact us at support@flexleaguetennis.com");
              });
              return;
            }
            if (resp?.data?.status === true) {
              runInAction(() => {
                this.signupemailonly = false;
                if (!resp?.data?.data?.profileComplete) {
                  this.isEmailverfied = true;
                  this.signResponse = resp?.data?.data;
                  return;
                }
                if (resp?.data?.data?.profileComplete) {
                  if(resp?.data?.data?.user?.gender !== this.gender && this.gender !== "mixed"){
                    // console.log(resp?.data?.data?.user?.gender)
                    this.allowuser = true
                    // console.log("inside",this.allowuser)
                  }
                  this.loginLoading = false;
                  this.authapproved = true;
                  this.errLoggingIn = resp?.data?.message;
                  this.Token = resp?.data?.data?.token;
                  // this.user = resp.data?.data.user;
                  // localStorage.setItem("user",JSON.stringify(resp.data.data.user));
                  this.getProfile();
                  localStorage.setItem("token", resp.data.data.token);
                  // toast.success(resp.data.message);
                }
                // signOut(authentication)
              });
              return;
            }
            runInAction(() => {});
          })
          .catch((err) => {
            console.log(err);
          });
        // }
      })
      .catch((err) => {
        console.log(err);
    })
  }

  

    //   apple login
    signInWithApple = async () => {
      const provider = new OAuthProvider('apple.com');;
      
      runInAction(() => {
        this.newpassword="";
        this.currentpassword="";
      })
      signInWithPopup(authentication,provider).then(async(res) => {
        runInAction(() => {
          this.loginNoEmail = false;
          this.socialUiddata =  res;
          this.socialType = "apple";
          this.getdevicetype() 
        })
        console.log(res)
          // if(res.user.email != ""){
              await postLogin("/api/auth/signup", {
                  firstName: res._tokenResponse.firstName,
                  lastName:res._tokenResponse.lastName,
                  mobile:res.user.phoneNumber,
                  dob:"",
                  gender:"",
                  email:res.user.email || res.user.providerData[0].email,
                  uid: res.user.uid,
                  loginType: "social",
                  socialType: "apple",
                  isEmailVerified: res.user.email != "" ? true : false,
                  deviceId:this.devicetype,
                  deviceToken: "823749879823747"
                }).then((resp) => {
                  if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.signupemailonly = false;
                        this.loginNoEmail = true;
                        this.signResponse = resp?.data?.data;
                        toast.error("We could not retrieve your email from Apple. Please enter your e-mail to sign up or contact us at support@flexleaguetennis.com");

                    })
                    return;
                }
                  if (resp?.data?.status === true) {
  
                      runInAction(() => {
                          this.signupemailonly = false;
                          if(!resp?.data?.data?.profileComplete){
                              this.isEmailverfied = true;
                              this.signResponse = resp?.data?.data
                              return
                          }
                          if(resp?.data?.data?.profileComplete){
                            if(resp?.data?.data?.user?.gender !== this.gender && this.gender !== "mixed"){
                              // console.log(resp?.data?.data?.user?.gender)
                              this.allowuser = true
                              // console.log("inside",this.allowuser)
                            }
                              this.loginLoading = false;
                              this.authapproved = true;
                              this.errLoggingIn = resp?.data?.message;
                              this.Token = resp?.data?.data?.token;
                              // this.user = resp.data?.data.user;
                              // localStorage.setItem("user", JSON.stringify(resp.data.data.user));
                              this.getProfile();
                              localStorage.setItem("token", resp.data.data.token);
                              // toast.success(resp.data.message);
                          }
                          // signOut(authentication)
                      })
                      return;
                  }
                  runInAction(() => {
          
                  })
  
                })
                .catch((err) => {
                  console.log(err)
                })
          // }
          
      }).catch((err)=>{
          console.log(err);
      })
    }


  // front login api
  loginuser = async () => {
    runInAction(() => {
      this.newpassword="";
      this.currentpassword="";
      this.loginLoading = true;
      this.errLoggingIn = "";
      this.getdevicetype();
    });
    await postLogin("/api/auth/login", {
      username: this.email,
      password: this.password,
      loginType: "user",
      deviceId:this.devicetype
      
    })
      .then((resp) => {
        if (resp?.data?.status === false) {
          // console.log("false");
          runInAction(() => {
            this.loginLoading = false;
            this.errLoggingIn = resp?.data?.message;
            toast.error(resp?.data?.message);
            
          });
          return;
        }
        if (resp?.data?.status === true) {
          // console.log("true");
          runInAction(() => {
            // console.log(resp?.data.data.user)
            // console.log("outside",this.allowuser)
            
            if (this.isChecked) {
              localStorage.setItem("email", this.email);
              localStorage.setItem("password", this.password);
            }
            if (!resp?.data?.data?.profileComplete) {
              this.loginLoading = false;
              this.isEmailverfied = true;
              this.signResponse = resp?.data?.data;
              return;
            }
            if(resp?.data?.data?.user?.gender !== this.gender && this.gender !== "mixed"){
              // console.log(resp?.data?.data?.user?.gender)
              this.allowuser = true
              // console.log("inside",this.allowuser)
            }
            this.loginLoading = false;
            this.authapproved = true;
            this.errLoggingIn = resp?.data?.message;
            this.Token = resp?.data?.data?.token;
           
            localStorage.setItem("token", resp.data.data.token);
            localStorage.setItem("user", JSON.stringify(resp.data.data.user));
            this.user = resp?.data?.data?.user;
            // toast.success(resp.data.message);
            this.getProfile()
          });
          return;
        }
        runInAction(() => {
          this.loginLoading = false;
          this.errLoggingIn = resp?.data?.message;
          toast.error(resp?.data?.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          // console.log("catch",error);
          toast.error(error?.message);
          this.loginLoading = false;
          // this.errLoggingIn = error?.response?.data?.message;
          // toast.error(error?.message);
        });
      });
  };

  // Reset password
  resetpass = async (event) => {
    if (this.password != this.confirmresetpas) {
      runInAction(() => {
        this.loginLoading = false;
        this.errPassword =
          "The New password and confirmation password do not match.";
      });
      return;
    }
    if (this.strongRegex.test(this.password)) {
      this.errPassword = "";
      runInAction(() => {
        this.loginLoading = true;
        this.errLoggingIn = "";
        this.authapproved = false;
        this.errPassword = "";
        this.passchangedsuccesfull = false;
      });
      await postLogin("/api/auth/reset/password", {
        email: this.Forgotpass,
        password: this.password,
      })
        .then((resp) => {
          if (resp?.data?.status === false) {
            runInAction(() => {
              this.loginLoading = false;
              this.errLoggingIn = resp?.data?.message;
              this.authapproved = false;
              this.passchangedsuccesfull = false;
              toast.error(resp?.data?.message);
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
              this.loginLoading = false;
              this.passchangedsuccesfull = true;
              this.errLoggingIn = resp?.data?.message;
              // toast.success(resp.data.message);
            });
            return;
          }
          runInAction(() => {
            this.loginLoading = false;
            this.errLoggingIn = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
        })
        .catch((error) => {
          runInAction(() => {
            this.loginLoading = false;
            this.errLoggingIn = error?.response?.data?.message;
            // toast.error(error?.message);
          });
        });
    } else {
      this.errPassword =
        "( 8 Digit, Alphanumeric with 1 Special Char and 1 Capital Letter )";
    }
  };

  setloginNoEmail = (text) => {
    this.loginNoEmail = text;
  };

  //SignUp with email api
  signUpWithEmail = async () => {
    runInAction(() => {
      this.newpassword="";
      this.currentpassword="";
      this.loginLoading = true;
      this.errSignup = "";
      this.otpscreen = false;
      this.OTP.otp = "";
      this.errSigningup = "";
      this.getdevicetype() 
    });
    await postLogin("/api/auth/signup", {
      email: this.signupemail,
      uid: this.socialUiddata.user?.uid,
      loginType: "social",
      socialType: this.socialType,
      firstName: this.socialUiddata._tokenResponse?.firstName,
      lastName: this.socialUiddata._tokenResponse?.lastName,
      mobile: this.socialUiddata.user?.phoneNumber,
      deviceId:this.devicetype,
      deviceToken: "823749879823747",
    })
      .then((resp) => {
        this.loginNoEmail = false;
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loginLoading = false;
            this.errSignup = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loginLoading = false;
            this.otpscreen = true;
            this.errSignup = resp?.data?.message;
            // toast.success(resp.data.message);
          });
          return;
        }
        runInAction(() => {
          this.loginLoading = false;
          this.errSignup = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loginLoading = false;
          this.errSignup = error?.response?.data?.message;
          toast.error(error?.message);
        });
      });
  };

  //Forgot password
  Forgotpassword = async () => {
    runInAction(() => {
      this.loginLoading = true;
      this.errSignup = "";
      this.otpscreen = false;
      this.errwrongemail = false;
    });
    await postLogin("/api/auth/forgot/password", {
      email: this.Forgotpass,
    })
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loginLoading = false;
            this.errSignup = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loginLoading = false;
            this.otpscreen = true;
            this.errSignup = resp?.data?.message;
            // toast.success(resp.data.message);
          });
          return;
        }
        runInAction(() => {
          this.loginLoading = false;
          this.errSignup = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loginLoading = false;
          this.errSignup = error?.response?.data?.message;
          toast.error(error?.message);
          this.errwrongemail = "User not found";
        });
      });
  };

  // resendOtp

  resendOtp = async () => {
    runInAction(() => {
      this.newpassword="";
      this.currentpassword="";
      this.otpTimeStatus = true;
      this.OTP.otp = "";
      this.errSigningup = "";
      this.verifybtn = false;
    });
    await postLogin("/api/auth/signup", {
      email: this.signupemail,
      uid: "",
      loginType: "social",
      socialType: "email",
    })
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            // toast.success(resp.data.message);
          });
          return;
        }
        runInAction(() => {
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          toast.error(error?.message);
        });
      });
  };
  // resendOtp forgotpass

  resendOtpForgotPass = async () => {
    runInAction(() => {
      this.otpTimeStatus = true;
      this.ForgotOTP.forgototp = "";
      this.errSigningup = "";
      this.verifybtn = false;
    });
    await postLogin("/api/auth/forgot/password", {
      email: this.Forgotpass,
    })
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            // toast.success(resp.data.message);
          });
          return;
        }
        runInAction(() => {
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          toast.error(error?.message);
        });
      });
  };

  genderCheck=(text)=>{
    this.gender = text;
    // console.log("=====>>>>>",this.user?.gender)
    // if(this.user?.gender !== text && text !== "mixed"){
    //   this.allowuser = true
    // }
   }

   setallowuser = (text) => {
    this.allowuser = text;
   }

setCityName=(text)=>{
  this.CityName =text
}
  // verify otp
  otpVerify = async () => {
    runInAction(() => {
      this.newpassword="";
      this.currentpassword="";
      this.loginLoading = true;
      this.errSigningup = "";
      this.getdevicetype()
    
    });
    await postLogin("/api/auth/verify/otp", {
      email: this.signupemail,
      otp: this.OTP.otp,
      deviceId: this.devicetype     
    })
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loginLoading = false;
            this.errSigningup = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            if (resp?.data?.data?.profileComplete === false) {
              this.loginLoading = false;
              // this.authapproved = true;
              this.showinput =false;
              this.isEmailverfied = true;
              this.otpscreen = false;
              this.OTP = { otp: null };
              this.errSigningup = "";
              this.Token = resp?.data?.data?.token;
              this.user = resp.data?.data;
             
              if (this.socialType === "email") {
                this.signupemailonly = true;
              }
              this.signResponse = resp?.data?.data;
              // localStorage.setItem("user", JSON.stringify(resp.data?.data));
              // localStorage.setItem("token",JSON.stringify(resp.data?.data?.token));
              // toast.success(resp.data.message);
            }
            if (resp?.data?.data?.profileComplete === true) {
              if(resp?.data?.data?.user?.gender !== this.gender && this.gender !== "mixed"){
                // console.log(resp?.data?.data?.user?.gender)
                this.allowuser = true
                // console.log("inside",this.allowuser)
              }
              this.loginLoading = false;
              this.authapproved = true;
              // this.isEmailverfied = true;
              this.otpscreen = false;
              this.OTP = { otp: null };
              this.errSigningup = "";
              // this.Token = resp?.data?.data?.token;
              // console.log(resp.data?.data)
              this.user = resp.data?.data?.user;
              // this.signupemailonly = true;
              this.signResponse = resp?.data?.data;
              // localStorage.setItem("user", JSON.stringify(resp.data?.data?.user));
              this.Token = resp?.data?.data?.token;
              localStorage.setItem("token", resp.data?.data?.token);
              this.getProfile();
              // toast.success(resp.data.message);
            }
          });
          return;
        }
        runInAction(() => {
          this.loginLoading = false;
          this.errSigningup = resp?.data?.message;
          toast.error(resp?.data?.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loginLoading = false;
          this.errSigningup = error?.response?.data?.message;
          toast.error(error?.message);
        });
      });
  };
  //verify forgot pass otp
  otpForgotpassVerify = async () => {
    runInAction(() => {
      this.newpassword="";
      this.currentpassword="";
      this.loginLoading = true;
      this.errSigningup = "";
    });
    await postLogin("/api/auth/verify/otp", {
      email: this.Forgotpass,
      otp: this.ForgotOTP.forgototp,
    })
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loginLoading = false;
            this.errSigningup = resp?.data?.message;
            toast.error(resp?.data?.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loginLoading = false;
            this.isEmailverfied = true;
            this.OTP = { otp: null };
            this.otpscreen = false;
            this.passwordConfirm = true;
            this.errSigningup = resp?.data?.message;
            // this.Token = resp?.data?.data?.token;
            // this.user = resp.data?.data;
            // localStorage.setItem("user",JSON.stringify(resp.data.data))
            // localStorage.setItem("token",JSON.stringify(resp.data.data.token))
            // toast.success(resp.data.message);
          });
          return;
        }
        runInAction(() => {
          this.loginLoading = false;
          this.errSigningup = resp?.data?.message;
          toast.error(resp?.data?.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loginLoading = false;
          this.errSigningup = error?.response?.data?.message;
          toast.error(error?.message);
        });
      });
  };

  // admin password change api
  passwordChange = async () => {
    if (this.newpassword != this.currentpassword) {
      runInAction(() => {
        this.newpassword="";
        this.currentpassword="";
        this.loginLoading = false;
        this.errPassword =
          "The New password and confirmation password do not match.";
      });
      return;
    }
    runInAction(() => {
      this.passwordLoading = true;
      this.errPassword = "";
    });
    await patchMethodData(
      "/api/Auth/change/password",
      {
        password: this.currentpassword,
        newPassword: this.newpassword,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.passwordLoading = false;
            this.errPassword = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        runInAction(() => {
          this.passwordLoading = false;
          this.setpasswordpopup();
          // toast.success(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.passwordLoading = false;
          this.errPassword = error?.response?.data?.message;
          toast.error(error?.message);
        });
      });
  };


// my account work 

getuserdetail = async (id) => {
  runInAction(() => {
    this.myDetailsLoad= true;
    this.myDetailserrr= "";
  });
  await getMethodData(`/api/get/player-details/${id}`, this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.myDetailsLoad= false;
          this.myDetailserrr= resp?.data?.message;
        });
        return;
      }
      if (resp?.data?.status === true) {
        // console.log("resp_data",resp.data.data)
        runInAction(() => {
          this.myDetailsLoad= false;
          this.myDetailserrr= resp?.data?.message;
          this.myDetails =  resp.data.data
        });
        return;
      }
      runInAction(() => {
        this.myDetailsLoad= false;
        this.myDetailserrr= resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
              this.myDetailsLoad= false;
              this.myDetailserrr= error?.message;
              toast.error(error?.message);
          }
          this.myDetailserrr= error?.response?.data?.message;
          toast.error(error?.message);
      })
      return;
  });
}

// history api of my profile

gethistorydetail = async (id,year) => {
  runInAction(() => {
    this.myDetailsLoad= true;
    // this.myDetailserrr= "";
  });
  await getMethodData(`/api/season/history/${id}?filters[year]=${year}`, this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.myDetailsLoad= false;
          // this.myDetailserrr= resp?.data?.message;
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.myDetailsLoad= false;
          if(resp?.data?.data?.length === 0) {
            this.nolistHistory = true;   
          }else{
            this.nolistHistory = false;
          }
          // this.myDetailserrr= resp?.data?.message;
          this.gethistory= resp?.data?.data
        });
        return;
      }
      runInAction(() => {
        this.myDetailsLoad= false;
        // this.myDetailserrr= resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
              this.myDetailsLoad= false;
              // this.myDetailserrr= error?.message;
              toast.error(error?.message);
          }
          this.myDetailserrr= error?.response?.data?.message;
          toast.error(error?.message);
      })
      return;
  });
}



 // state drop down
 getstatedropdown = async () => {
  runInAction(() => {
    this.loadhomecourt = true;
    // this.errloadhomecourt = "";
    // this.saved = false;
  });
  await getMethodData("/api/get/states", this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loadhomecourt = false;
          // this.errloadhomecourt = resp?.data?.message;
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.loadhomecourt = false;
          // this.errloadhomecourt = resp?.data?.message;
          // toast.success(resp?.data?.message);
          this.statedrop = resp?.data?.data;
        });
        return;
      }
      runInAction(() => {
        this.loadhomecourt = false;
        // this.errloadhomecourt = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
        this.loadhomecourt = false;
        if (!error.response) {
          // this.errloadhomecourt = error?.message;
          // toast.error(error?.message);
        }
        // this.errloadhomecourt = error?.response?.data?.message;
        // toast.error(error?.message);
      });
      return;
    });
};



  // city drop down
  getcitydropdown = async () => {
    runInAction(() => {
      this.loadhomecourt = true;
      this.errloadhomecourt = "";
      // this.saved = false;
    });
    await getMethodData(`/api/get/cities`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadhomecourt = false;
            this.errloadhomecourt = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadhomecourt = false;
            this.errloadhomecourt = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.citydrop = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadhomecourt = false;
          this.errloadhomecourt = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadhomecourt = false;
          if (!error.response) {
            this.errloadhomecourt = error?.message;
            // toast.error(error?.message);
          }
          this.errloadhomecourt = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };

  //get homecourt drop down

gethomecourtdropdown = async ( cityid,userid) =>{
    runInAction(() => {
      // this.loaduser = true;
      this.errloadcourt = "";
      //   this.saved = false;
    });
    await getMethodData(`/api/get/home-courts/${cityid}?filters[user]=${userid}`, this.Token)
    // getMethodData(`/api/get/home-courts/${cityid}?filters[user]=${this.user._id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.loaduser = false;
            this.errloadcourt = resp?.data?.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            // this.loaduser = false;
            this.errloadcourt = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.homecourtdrop = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          //   this.loaduser = false;
          this.errloadcourt = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          //   this.loaduser = false;
          if (!error.response) {
            this.errloadcourt = error?.message;
            // toast.error(error?.message);
          }
          this.errloadcourt = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };
    // get Matches 
    getMatchDetails = async (id,seasonID) => {
      runInAction(() => {
        //   this.loaduser = true;
        //   this.errloaduser = "";
        //   this.saved = false;
      });
      await getMethodData(`/api/season/matches/${id}?filters[season]=${this.seasons}`, this.Token)
        .then((resp) => {
          if (resp?.data?.status === false) {
            runInAction(() => {
              // this.loaduser = false;
              // this.errloaduser = resp?.data?.message;
              // toast.error(resp.data.message);
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
              if(resp?.data?.data?.length === 0) {
                this.noSeasonList = true;
              }else{
                this.noSeasonList = false;
              }
              this.SeasonMatch=resp?.data?.data;
            });
            return;
          }
          runInAction(() => {
            //   this.loaduser = false;
            //   this.errloaduser = resp?.data?.message;
          });
        })
        .catch((error) => {
          runInAction(() => {
            //   this.loaduser = false;
            if (!error.response) {
              //   this.errloaduser = error?.message;
              // toast.error(error?.message);
            }
            //  this.errloaduser = error?.response?.data?.message;
            // toast.error(error?.message);
          });
          return;
        });
    };
  // get level dropdown
  getleveldropdown = async () => {
    runInAction(() => {
      //   this.loaduser = true;
      //   this.errloaduser = "";
      //   this.saved = false;
    });
    await getMethodData("/api/get/lavels", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.loaduser = false;
            // this.errloaduser = resp?.data?.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            // this.loaduser = false;
            // this.errloaduser = resp?.data?.message;
            // toast.success(resp?.data?.message);
            //  this.homecourtdrop = resp?.data?.data;
            this.leveldropdown = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          //   this.loaduser = false;
          //   this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          //   this.loaduser = false;
          if (!error.response) {
            //   this.errloaduser = error?.message;
            // toast.error(error?.message);
          }
          //  this.errloaduser = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };
  // get season dropdown
  getseasondropdown = async () => {
    runInAction(() => {
      //   this.loaduser = true;
      //   this.errloaduser = "";
      //   this.saved = false;
    });
    await getMethodData("/api/get/seasons", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.loaduser = false;
            // this.errloaduser = resp?.data?.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.seasondropdown = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          //   this.loaduser = false;
          //   this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          //   this.loaduser = false;
          if (!error.response) {
            //   this.errloaduser = error?.message;
            // toast.error(error?.message);
          }
          //  this.errloaduser = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };
  //api logout
  apilogout = async () => {
    if(this.Token){
      await getMethodData("/api/auth/user/logout", this.Token).then((resp) => {
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.Token = null;
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.removeItem("upcoming_date");
            // localStorage.clear()
          });
          return;
        }
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {

          }
        });
        return;
      });
    }
  }; 
  

  // user logout
  logout = () => {
    this.apilogout();
    signOut(authentication)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
    runInAction(() => {
      this.devicetype=""
      this.showinput =false
      this.authapproved = false;
      this.otpscreen = false;
      this.isEmailverfied = false;
      this.otpscreen = false;
      this.allowuser = false;
      this.user = {};
      this.errLoggingIn = "";
      this.Token = "";
      this.homecourtdrop = [];
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      // toast.success("Logout Successfully.");
    });
  };


   //get notification
   getNotification = async () => {
    runInAction(() => {
      // this.loadseason = true;
      // this.errloadseason = "";
      // this.saved = false;
    });
    await getMethodData("/api/user/get/notification/count", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.loadseason = false;
            // this.errloadseason = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            // this.loadseason = false;
            // this.errloadseason = resp?.data?.message;
            if(resp?.data?.data?.notifications?.length === 0) {
              this.nolist = true;
            }else{
              this.nolist = false;
            }
             this.notification = resp?.data?.data?.notifications;
             this.notificationcount = resp?.data?.data?.count;
            // localStorage.setItem("user", JSON.stringify(resp.data.data));
            // this.profile =resp?.data?.data;
            // this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          // this.loadseason = false;
          // this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
            // this.loadseason = false;
            if(!error.response){
                // this.errloadseason = error?.message;
                toast.error(error?.message);
            }
            // this.errloadseason = error?.response?.data?.message;
            toast.error(error?.message);
        })
        return;
    });
  }; 

  //delete notification
  deleteNotification = async() => {
    runInAction(() => {
      // this.loadseason = true;
      // this.errloadseason = "";
      // this.saved = false;
    });
    await deleteData("/api/user/notifications", this.Token)
      .then((resp) => {
        
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.loadseason = false;
            // this.errloadseason = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
          this.getAllNotification();
            // this.loadseason = false;
            // this.errloadseason = resp?.data?.message;
            // if(resp?.data?.length === 0) {
            //   this.nolist = true;
            // }else{
            //   this.nolist = false;
            // }
            //  this.notification = resp.data?.data;
            // localStorage.setItem("user", JSON.stringify(resp.data.data));
            // this.profile =resp?.data?.data;
            // this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          // this.loadseason = false;
          // this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
            // this.loadseason = false;
            if(!error.response){
                // this.errloadseason = error?.message;
                toast.error(error?.message);
            }
            // this.errloadseason = error?.response?.data?.message;
            toast.error(error?.message);
        })
        return;
    });
  };

//Get Map Key
getApiKey = async() => {
  
  await  getMethodData("/api/get/setting/GOOGLE_MAP_API_KEY",this.signResponse.token ?? this.Token)
    .then((resp) => {
      if (resp?.data?.status === true) {
        runInAction(() => {
     
          localStorage.setItem("Mapkey", resp?.data?.data?.value)
          
        });
        return;
      }
    
    })
    .catch((error) => {
      return;
    });
};


    //get All notification
    getAllNotification = async() => {
      runInAction(() => {
        this.loadnotification = true;
        // this.errloadnotification = "";
        // this.saved = false;
      });
      await getMethodData("/api/user/notifications", this.Token)
        .then((resp) => {
          if (resp?.data?.status === false) {
            runInAction(() => {
              this.loadnotification = false;
              // this.errloadnotification = resp?.data?.message
            });
            return;
          }
          if (resp?.data?.status === true) {
            runInAction(() => {
              this.loadnotification = false;
              // this.errloadnotification = resp?.data?.message;
              if(resp?.data?.data?.length === 0) {
                this.nolistAllNoti = true;
                this.loadnotification = false
              }else{
                this.nolistAllNoti = false;
              }
               this.allnotification = resp?.data?.data;
               this.getNotification()
              // localStorage.setItem("user", JSON.stringify(resp.data.data));
              // this.profile =resp?.data?.data;
              // this.saved = false;
              // this.status = true;
            });
            return;
          }
          runInAction(() => {
            this.loadnotification = false;
            // this.errloadnotification = resp?.data?.message;
          });
        })
        .catch((error) => {
          runInAction(() => {
              this.loadnotification = false;
              if(!error.response){
                  // this.errloadnotification = error?.message;
                  toast.error(error?.message);
              }
              // this.errloadnotification = error?.response?.data?.message;
              toast.error(error?.message);
          })
          return;
      });
    }; 


 // delete user
deleteUser = async () => {
  runInAction(() => {
    this.deleteload= true;
    this.deletenav= false
    // this.myDetailserrr= "";
  });
  await getMethodData(`/api/user/delete/account`, this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.deleteload= false;
          this.deletenav =false
          // this.myDetailserrr= resp?.data?.message;
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.deleteload= false;
          this.deletenav =true;
          this.deletepopup = false;
          // this.logout();
          this.apilogout();
          this.devicetype=""
          this.showinput =false
          this.authapproved = false;
          this.otpscreen = false;
          this.isEmailverfied = false;
          this.otpscreen = false;
          this.allowuser = false;
          this.user = {};
          this.errLoggingIn = "";
          this.Token = "";
          this.homecourtdrop = [];
          localStorage.removeItem("user");
          localStorage.removeItem("token");
            // toast.success("Logout Successfully.");
          // if(resp?.data?.data?.length === 0) {
          //   this.nolistHistory = true;   
          // }else{
          //   this.nolistHistory = false;
          // }
          // this.myDetailserrr= resp?.data?.message;
          // this.gethistory= resp?.data?.data
        });
        return;
      }
      runInAction(() => {
        this.deleteload= false;
        this.deletenav =false;
        // this.myDetailserrr= resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
          if(!error.response){
              this.deleteload= false;
              this.deletenav =false;
              // this.myDetailserrr= error?.message;
              toast.error(error?.message);
          }
          // this.myDetailserrr= error?.response?.data?.message;
          toast.error(error?.message);
      })
      return;
  });
}

}

export const AuthStoreContext = createContext(new AuthStore());
export const useAuthStore = () => useContext(AuthStoreContext);
