/* eslint-disable jsx-a11y/alt-text */
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import "reactjs-popup/dist/index.css";
import { CommonStore, useCommonStore, CommonStoreContext } from "../Common/Store/CommonStore";
import { Link } from "react-router-dom";


const GettheApp = observer(() => {
  return (
    <CommonStoreContext.Provider
      value={
        new CommonStore({
          GettheApp: true
        })
      }
    >
      <Screen />
    </CommonStoreContext.Provider>
  );
});
export default GettheApp;


const Screen = observer(() => {
  const { gettheappp,loadcommon } = useCommonStore();
  useEffect(()=> {
    if(gettheappp?.length === 0){
      if (document.getElementById("cmsdata")) {
        document.getElementById("cmsdata").innerHTML="<h1 class='text-center'>NO GET THE APP US DATA FOUND</h1>";
      }
    }
    else{
      let data = gettheappp[0]?.content.replace(/&quot;/g, '\"');
      document.getElementById("cmsdata").innerHTML=data
    }
  },[loadcommon])
  return (
    <>
    <div className=" 2xl:pl-64"></div>
      <div>
      {loadcommon ?
        <div className="flex justify-center items-center min-h-[80vh]">
          <svg
            className="animate-spin mt-11  h-16 w-16 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="green"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="green"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>

        :
        <div id="cmsdata"></div>
        }
        
        
        {/* <div class="w-full 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-1 md:mb-12 mb-4">
       <div class="md:mt-12 mt-4">
           <p class="font-nunito font-bold xl:text-[54px] md:text-[40px] text-[34px] xl:leading-[58px] leading-[38px] text-center">
               Premier League Tennis <br class="md:block hidden"/>
               Mobile App
           </p>
           <p class=" md:text-base text-sm text-center md:mt-6 mt-4 text-[#5D6265]">
               Scan the QR code to start registering for leagues , scheduling
               matches , and <br class="lg:block hidden"/>
               with opponents from your phone
           </p>
       </div>
   </div>

   <div class="relative">
       <div class=" flex md:flex-row flex-col">
           <div class="md:w-4/12 w-full my-10 flex justify-end">
               <img class="m-auto object-contain object-top md:scale-[1.1] h-full 2xl:pl-28 lg:pl-20 md:pl-10" src="./image/tennisPhone.webp"/>
           </div>
           <div class=" 
              2xl:pr-28 
              2xl:pl-32 
              lg:pl-28  
              lg:pr-20 
              md:pr-10 
              md:pl-20 
              px-4 
              z-1
              flex 
              md:py-10
              py-4
              items-center 
              flex-wrap 
              md:w-8/12 
              w-full 
              relative 
              clippath 
              box-border 
              bg-[#282B2D]">
              <div class="text-center m-auto ">
                <p class="text-[#fff] text-2xl my-1">Scan the QR code or go to</p>
                <Link class="text-green">plt/tennis</Link>
              </div>
              
              <div>

              </div>

               <div class=" w-full ">
                   <div class="bg-[#6C6C6C] bg-opacity-10 ">
                     
                    <div class=" bg-[#282B2D] flex ">
                               <img class="md:w-full  max-w-[200px]" src="./image/playstoreee.webp"/>
                     <div class=" md:mt-9 mt-4">
                       <a href="https://play.google.com" target="_blank" rel="noopener noreferrer">
                           <img class="m-auto" src="./image/googleplay.webp"/>
                       </a>
                           </div>
                           </div>
                
                   </div>

               </div> 
               <div class=" w-full ">
                   <div class="bg-[#6C6C6C] bg-opacity-10 ">
                   
                    <div class=" bg-[#282B2D] flex flex-row-reverse  ">
                               <img class="md:w-full  max-w-[150px] mx-6  " src="./image/applestore.webp"/>
                     <div class=" md:mt-9 mt-4 ">
                       <a href="https://www.apple.com" target="_blank" rel="noopener noreferrer">
                           <img class="m-auto" src="./image/appstore.webp"/>
                       </a>
                           </div>
                           </div>
                     
                   </div>

               </div> 
           </div>
       </div>
   </div>
   <div class="relative">
       <div class=" flex md:flex-row flex-col-reverse">
           <div class="
              
              2xl:pr-28 
              2xl:pl-32 
              lg:pl-28  
              lg:pr-20 
              md:pr-10 
              md:pl-10
              px-4 
              z-1
              flex 
              md:py-20 
              py-4
              items-center 
              flex-wrap 
              md:w-8/12 
              w-full 
              relative 
              new_clippath 
              box-border 
              bg-[#282B2D]">
              <div class=" m-auto text-center w-3/4 ">
                <p class="text-[#fff] text-5xl ">
                Lorem ipsum dolor sit amet, consectetur
                </p>
                <p class="text-[#fff] text-sm my-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              </div>
           </div>
           <div class="md:w-4/12 w-full flex justify-center my-10">
               <img class=" object-contain object-top  h-full 2xl:pl-28 " src="./image/tennisMobile.webp"/>
           </div>
       </div>
   </div> */}
      </div>
    </>
  );
});

