import React, { useEffect } from 'react'
import { observer } from 'mobx-react';
import img1 from "../assets/image/img1.webp";
import img2 from "../assets/image/img2.webp";
import img3 from "../assets/image/img3.webp";
import img4 from "../assets/image/img4.webp";
import img5 from "../assets/image/img5.webp";
import { TransactionStore,useTransactionStore,TransactionStoreContext } from './store/TransactionStore';
import { useAuthStore } from '../Auth/store/Auth';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
// import { useLeagueStore } from '../Leaguespage/store/Leaguestore';




const background =[
    img1,
    img2,
    img3,
    img4,
    img5,
  ]
const random = Math.floor(Math.random() * background.length);

const MyTransaction = observer(() => {
  const { gettoken,getuser,user,getTransaction,transaction,nolist } = useTransactionStore();
  const { authapproved,setallowuser,allowuser} = useAuthStore();

// console.log("transaction",transaction)

  const navigate = useNavigate()

  useEffect(()=> {
    gettoken(localStorage.getItem("token"))
    getuser(JSON.parse(localStorage.getItem("user")))
    if (!authapproved){
      navigate("/");
    }
    else{
      getTransaction()
    }
    
  },[authapproved])

  return (
    <div className="relative py-5 flex h-full bg-no-repeat bg-fixed w-full object-cover object-top"
    style={{
      backgroundImage: `url(${background[random]})`,
       backgroundSize: 'cover',
       }}>

<div className="w-full 2xl:px-28 lg:px-24 md:px-14 px-4 relative z-1  ">
        <div className="flex flex-wrap justify-center">
          <div className=" w-full ">
            <div className="w-full bg-[#6C6C6C] bg-opacity-40 rounded-lg lg:px-10 md:px-5 md:pt-5 pb-20">
            <div>
                <div className='flex justify-between items-center md:whitespace-nowrap'>
                <p className=" text-[#fff] mb-5 md:text-3xl text-2xl relative flex items-center ">
                  My Transaction
                  <span className="h-[2px] w-[39.05px] block bg-green ml-3">
                  </span>
                </p>
                </div>
              </div>
              <div id="table"> 
            <div id="style-1" className=" 2xl:w-[100%] border rounded-lg max-h-[450px] border-[#ffffff] lg:w-[100%] md:w-[100%] w-full 2xl:max-w-[100%] overflow-auto  "> 
            <table cellPadding={0} cellSpacing={0} className="w-full text-[#fff]" id="partnertable">

            <thead className='text-left' >
                <tr className="bg-[#000] sticky top-0 ">
                  <th className='md:pl-5 py-5'>League Name</th>
                  {/* <th className="py-5">S. No.</th> */}
                  {/* <th className="py-5">DEADLINE</th> */}
                  <th>Transaction Id</th>
                  <th>Date</th>
                  <th >Status</th>
                  <th>Amount</th>
                  {/* <th className='text-center'>REGISTRATION</th> */}
                  {/* <th>Ranking</th> */}
                </tr>
              </thead>
              <tbody className="text-[15px] 2xl:text-[20px] text-left " >
              {transaction.map((data,index)=>(
                <tr key={index} className="hover:bg-[#39fd13] hover:bg-opacity-5 ">
              <td className="capitalize py-3 md:pl-5">{data?.league?.name ?? "N/A"}</td>
              <td className="py-3">{data?.transaction_id ?? "N/A"}</td>
              <td className="capitalize py-3 ">
              <Moment format="D, MMM yyyy ">
              {data?.createdAt ?? "N/A"}
             </Moment>
              </td>
              <td className="capitalize py-3 ">{data?.payment_status ?? "N/A"}</td>
              <td className="capitalize py-3 ">$ {data?.amount ?? "N/A"}</td>
             
              </tr>
              ))}
              {nolist && (
                    <tr>
                      <td colSpan={5} className="py-20 ">
                        <p className=" text-center font-semibold text-xl">No Transaction Found</p>
                      </td>
                    </tr>
                  )}
              
              </tbody>
            </table> 
            </div>
            </div>




            </div>
            </div>
            </div>
            </div>
       </div>
  )
})

export default MyTransaction