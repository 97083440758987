import { observer } from "mobx-react";
import React, { useEffect } from "react";
import bannerimage from "../assets/image/bannerimage.webp";
import { useAuthStore } from "../Auth/store/Auth";
import Moment from "react-moment";
import { Link, useFetcher, useNavigate, useParams } from "react-router-dom";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../assets/image/img1.webp";
import img2 from "../assets/image/img2.webp";
// import img3 from "../assets/image/img3.webp";
import img4 from "../assets/image/img4.webp";
import img5 from "../assets/image/img5.webp";
import img6 from "../assets/image/img6.webp"
import img7 from "../assets/image/img7.webp"
// import bgleague from "../assets/image/bgleague.webp";
// import cardimage from "../assets/image/cardimage.webp";
// import doublesimg from "../assets/image/doublesimg.webp";
// import mixedimg from "../assets/image/mixedimg.webp";
import { toast } from "react-toastify";
// import moment from "moment";

// import { FaAngleRight } from "react-icons/fa";
import {
  LeagueStore,
  useLeagueStore,
  LeagueStoreContext,
} from "../Leaguespage/store/Leaguestore";
import { useRegisterStore } from "../Register/store/RegisterStore";
import moment from "moment";

// const Leagues = observer(() => {
//   const {authapproved} = useAuthStore();
//   const { id } = useParams();
//   const store = new LeagueStore({
//     isloading: authapproved,
//     id:id
//     // loadseason: true,
//   });
//   return (
//     <LeagueStoreContext.Provider value={store}>
//       <Screen />
//     </LeagueStoreContext.Provider>
//   );
// });

// export default Leagues

const Leagues = observer(() => {
  const background = [ img2, img4, img5,img6,img7];
  const random = Math.floor(Math.random() * background.length);
  // console.log(random, background[random]);
  const { user, authapproved, setallowuser, allowuser } = useAuthStore();
  const {
    leagues,
    getSeason,
    gettoken,
    getuser,
    getRegistrationleague,
    registrationleague,
    nolist,
    loadseason,
    loadseasonnew,
    nolistupcomingleague
  } = useLeagueStore();
  const {
    changeStep,
    togglebtn,
    getShowtable,
    getShowmap,
    clearLeaugedata,
    getleaugesfalse,
    clearPaymentdata,
    setmoveforwad,
  } = useRegisterStore();

  const navigate = useNavigate();
  const { paymentscreen } = useRegisterStore();
  useEffect(() => {
    gettoken(localStorage.getItem("token"));
    getuser(JSON.parse(localStorage.getItem("user")));
    if (!authapproved) {
      navigate("/");
    } else {
      // getSeason()
      getRegistrationleague();
    }
  }, [authapproved, allowuser]);

  return (
    <>
      {authapproved ? (
        <>
          <div
            className="relative py-5 flex h-full min-h-[50vh] bg-no-repeat bg-fixed w-full object-cover object-top"
            style={{
              backgroundImage: `url(${img6})`,
              backgroundSize: "cover",
            }}
          >
            {/* <img
        className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top"
        src={bgleague}
      /> */}
            <div className="w-full 2xl:px-28 lg:px-24 md:px-14 px-4 relative z-1  ">
              <div className="flex flex-wrap justify-center">
                <div className=" w-full ">
                  <div className="w-full bg-[#6C6C6C] bg-opacity-40 rounded-lg lg:px-10 md:px-5 md:pt-5 pb-1">
                    {/* <p className="  text-[#fff] font-medium md:text-2xl text-xl relative">
                    {user?.city?.city_name}
                    </p>  */}
                    <div>
                      <div className="flex justify-between items-center md:whitespace-nowrap">
                        <p className=" text-[#fff] md:mx-0 mx-3 md:mt-0 mb-5  mt-3 md:text-3xl text-2xl relative flex items-center ">
                          Upcoming leagues in {user?.city?.city_name}
                          <span className="h-[2px] w-[39.05px] block bg-green  ml-3"></span>
                        </p>
                        {/* <button className='text-green  text-lg'>{index === 1 ? "View All" : null}</button>  */}
                      </div>
                    </div>

                    <div id="partnertable">
                      <div className=" bg-[#6C6C6C] border-2 border-[#fff] bg-opacity-40 rounded-lg mb-10 overflow-auto  ">
                        {/* <div id="partner"> */}
                        <table className="w-full text-[#fff] " id="partnertable">
                          <thead className="text-left">
                            <tr className="bg-[#000] sticky top-0 md:text-base text-sm ">
                              <th className="md:pl-5 pl-3">LEAGUE</th>
                              <th className="md:pl-5 pl-3">CATEGORY</th>
                              {/* <th className="py-5">S. No.</th> */}
                              <th className="py-3">DEADLINE</th>
                              {/* <th className="py-5">DEADLINE</th> */}
                              <th className="md:text-start text-center">SEASON</th>
                              <th className="md:text-start text-center px-3">PRICE</th>
                              <th className="text-center md:px-0 px-3">REGISTRATION</th>
                              {/* <th>Ranking</th> */}
                            </tr>
                          </thead>
                          <tbody className="text-[15px] 2xl:text-[20px]">
                         
                            {registrationleague.map((res, index) => {
                              const startDate = new Date(res.start_date);
                              startDate.setDate(startDate.getDate() + 5);
                              const today = new Date();
                              today.setDate(today.getDate(new Date()));
                              if (startDate > today) {
                                return (
                                  <tr
                                    key={index}
                                    className="hover:bg-[#39fd13] hover:bg-opacity-5 "
                                  >
                                    {/* {moment(res.start_date).add(5, 'days').format('DD-MM-YYYY') < moment(new Date().format('DD-MM-YYYY'))?
              <></>
              :
              null
              } */}

                                    <>
                                      <td className="align-middle whitespace-nowrap capitalize py-4 md:pl-5 pl-3">
                                      {res?.name?.replace("(Female)","")?.replace("(Male)","") }
                                      </td>
                                      <td className="align-middle whitespace-nowrap capitalize py-4 md:pl-5 pl-3">
                                      {res?.league_type}
                                      </td>
                                      <td className="align-middle capitalize whitespace-nowrap py-4">
                                        {/* {console.log("start_registration_date",res?.start_registration_date)} */}
                                      {(
                                          <p className="px-3">
                                            {(
                                              <Moment
                                                format={
                                                  moment(res?.last_registration_date).format("MMM D").includes('May')

                                                    ? "MMM DD"
                                                    : "MMM. DD"
                                                }
                                              >
                                                { res?.last_registration_date ?? "N/A"}
                                              </Moment>
                                            ) ?? "N/A"}
                                          </p>
                                        )}
                                      </td>
                                      {/* <td className="align-middle" >{<Moment format=" MMM, D ">{res?.last_registration_date}</Moment> ?? "N/A"}</td> */}
                                      <td className="align-middle whitespace-nowrap">
                                        {(
                                          <Moment
                                            format={
                                              moment(res?.start_date).format("MMM D").includes('May')
                                              
                                                ? "MMM DD"
                                                : "MMM. DD"
                                            }
                                          >
                                            {res?.start_date}
                                          </Moment>
                                        ) ?? "N/A"} - {(
                                          <Moment
                                            format={
                                              moment( res?.end_date).format("MMM D").includes('May')

                                                ? "MMM DD"
                                                : "MMM. DD"
                                            }
                                          >
                                            {res?.end_date}
                                          </Moment>
                                        ) ?? "N/A"}
                                      </td>
                                      <td className="px-3 align-middle whitespace-nowrap">
                                        $
                                        {res?.league_type === "singles" ? (
                                          <>
                                          {" "}
                                            {res?.fees_amount}
                                            <span className="text-xs">
                                              /Person
                                            </span>{" "}
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            {res?.fees_amount * 2}
                                            <span className="text-xs">
                                              /Team
                                            </span>{" "}
                                          </>
                                        )}{" "}
                                      </td>
                                      <td className="align-middle text-center">
                                        {new Date().toISOString() <
                                        res?.start_registration_date ? (
                                          <p className="text-sm">
                                            Opens On - {(
                                              <Moment
                                                format={
                                              moment( res?.start_registration_date).format("MMM D").includes('May')

                                                    ? "MMM DD"
                                                    : "MMM. DD"
                                                }
                                              >
                                                {res?.start_registration_date}
                                              </Moment>
                                            ) ?? "N/A"}
                                          </p>
                                        ) : moment(new Date().toISOString()).format("YYYY-MM-DD") >
                                        moment( res?.last_registration_date).format("YYYY-MM-DD")
                                          ? (
                                          <button
                                            disabled
                                            className="font-medium 2xl:text-[20px] text-sm text-[#000] bg-[red] 2xl:px-9 md:px-2 md:py-1 md:w-auto px-2 rounded-lg "
                                          >
                                            
                                            Closed
                                          </button>
                                        ) : (
                                          <button
                                            className="font-medium 2xl:text-[20px] text-sm text-[#000] bg-green 2xl:px-9 md:px-2 md:py-1 md:w-auto rounded-lg "
                                            type="button"
                                            onClick={() => {
                                              paymentscreen(false);
                                              if (
                                                new Date().toISOString() <
                                                res?.start_registration_date
                                              ) {
                                                toast.error(
                                                  "Registration Not Open Yet."
                                                );
                                              } else if (
                                                
                                                moment(new Date().toISOString()).format("YYYY-MM-DD")
                                                 >
                                                 moment( res?.last_registration_date).format("YYYY-MM-DD")
                                                
                                              ) {
                                                toast.error(
                                                  "Registration is Closed."
                                                );
                                              } else {
                                                setmoveforwad(false)
                                                navigate(
                                                  `/register/${res?._id}`
                                                );
                                                setallowuser(false);
                                                getleaugesfalse(true);
                                                changeStep("1");
                                                clearLeaugedata();
                                                clearPaymentdata();
                                                togglebtn();
                                                getShowtable(false);
                                                getShowmap(false);
                                              }
                                            }}
                                          >
                                            Register
                                          </button>
                                        )}
                                      </td>
                                    </>

                                    {/* <td>{index + 1}</td> */}
                                    {/* <td  className="py-5"> 
                      <div className="flex items-center space-x-3">
                              <div className="avatar">
                                <div className="flex  items-center  w-10 h-6">
                                  <img
                                    crossOrigin="anonymous"
                                    src={
                                       BASE_URL + `/image/${data?.image}`
                                    }
                                    alt="Avatar Tailwind CSS Component"
                                  />
                                </div>
                              </div>
                              <div className="ml-3">
                                {data?.firstName + " " + data?.lastName}
                              </div>
                            </div></td> */}
                                  </tr>
                                );
                              } else {
                                return (
                                  <tr
                                    key={index}
                                    style={{ display: "none" }}
                                  ></tr>
                                );
                              }
                            })}
                             {loadseasonnew &&
                            <>
              <tr>
                <td colSpan={6} className="animate-pulse py-6 bg-[#ffffff21]"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6 bg-[#ffffff36]"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6 bg-[#ffffff21]"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6 bg-[#ffffff36]"></td>
              </tr>
              <tr>
                <td colSpan={6} className="animate-pulse py-6 bg-[#ffffff21]"></td>
              </tr>
              </>

                           }
                            {nolistupcomingleague && (
                              <tr>
                                <td colSpan={5} className="py-20 ">
                                  <p className=" text-center font-semibold text-xl">
                                    No Upcoming Leagues Found
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="text-center">
            <img alt="" className=" w-full" src={bannerimage} />
          </div>
        </>
      )}
    </>
  );
});

export default Leagues;
