/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import bannerimage from "../assets/image/MessageUI.webp";
import { useAuthStore } from "../Auth/store/Auth";
import sender from "../assets/image/sender.webp";
import Double from "../assets/image/three.webp";
// import Loader from "../assets/image/MessageLoader.gif";
import { useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";

import { FaAngleLeft, FaArrowCircleDown } from "react-icons/fa";
import { useRef } from "react";
import { MessageStore } from "./store/MessageStore";
import moment from "moment";
import { getMethodData } from "../ApiMethods/api";
import { useCommonStore } from "../Common/Store/CommonStore";

const Message = observer((props) => {
  const { user, authapproved } = useAuthStore();
  const {
    setSelectedChat,
    getcurrentID,
    getgroupname,
    getGroupOfUser,
    getImage,
    GroupList,
    gettoken,
    getuser,
    ChatImage,
    selectedChat,
    GroupOfUser,
    purposedDate,
    groupName,
    grouplist,
    currentID,
  } = MessageStore();
  const { MessageCount } = useCommonStore();

  const [toggle, setToggle] = useState(false);
  const [ChatClose, setChatClose] = useState();
  const [visible, setVisible] = useState(false);
  const chatContainerRef = useRef(null);
  const location = useLocation();

  const toggleVisible = () => {
    const container = chatContainerRef.current;
    if (container) {
      const atBottom =
        container.scrollHeight - container.scrollTop === container.clientHeight;
      if (atBottom) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    }
  };

  const scrollToBottom = () => {
    chatContainerRef.current?.scrollTo({
      // use optional chaining operator
      top: chatContainerRef.current.scrollHeight,
      behavior: "auto",
    });
  };

  useEffect(() => {
    chatContainerRef.current?.addEventListener("scroll", toggleVisible); // use optional chaining operator
    return () => {
      chatContainerRef.current?.removeEventListener("scroll", toggleVisible); // use optional chaining operator
    };
  }, []);
  //  console.log("TTTTTTTTTTTTTTTTTTTTTTTT",Token);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [message, setMessage] = useState("");
  const [GroupImage, setGroupImage] = useState({});
  const [grpLastName, setgrpLastName] = useState({});

  const [time, settime] = useState("");
  // const [Msgtime, setMsgtime] = useState("");
  const [messages, setMessages] = useState([]);
  const [localMessages, setLocalMessages] = useState([]);
  const [loader, setloader] = useState(false);
  const [BigScreenView, setIsBigScreenView] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [Image, setImage] = useState({});
  const [userChat, setuserChat] = useState("");
  const [userprofile, setuserprofile] = useState([]);
  const [CheckGroup, setCheckGroup] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const isMessageEmpty = message.trim().length === 0;
  const isSendButtonDisabled = isMessageEmpty;

  // const [selectedChat, setSelectedChat] = useState(null);
  // scroll to bottom on mount and when new messages arrive

  // Get the .rcw-close-widget-container element
  const closeButton = document?.querySelector(".rcw-widget-container");
  const widget = document?.querySelector(".rcw-conversation-container");

  const pattern = /^\/\w+/;
  const result = location.pathname.match(pattern)[0];
  useEffect(() => {
    if (authapproved) {
      gettoken(localStorage.getItem("token"));
      getuser(JSON.parse(localStorage.getItem("user")));
    }
 
  }, [authapproved]);
  useEffect(()=>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  },[])

  if (closeButton !== null) {
    if (result === "/message") {
      if (widget !== null) {
        widget.style.display = "none";
      }
      closeButton.style.display = "none";
    } else {
      closeButton.style.display = "block";
      if (widget !== null) {
        widget.style.display = "block";
      }
    }
  }

  useEffect(() => {
    if (!authapproved) {
      navigate("/");
      return;
    }
    gettoken(localStorage.getItem("token"));
    getuser(JSON.parse(localStorage.getItem("user")));
  }, [authapproved]);
  useEffect(() => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [messages]);

 useEffect(() => {
    if (user?._id !== undefined) {
      const socket = socketIOClient(BASE_URL);

      socket.emit("joinRoom", { roomId: currentID || id });
      socket.on("recieve_message", (data) => {
        setLocalMessages((prevLocalMessages) => [...prevLocalMessages, data]);
        fetchData(currentID);
        GroupList();
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [user?._id, currentID, id]);


  useEffect(() => {
    if (user?._id != undefined) {
    // Update the state with the localMessages when component re-renders
    setMessages((prevMessages) => [...prevMessages, ...localMessages]);
    }
  }, [localMessages]);

  const fetchData = async (Listid) => {
    try {
      setLocalMessages([]);
      setloader(true);

      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        return;
      }
      const resp = await getMethodData(`/api/user/chat/${Listid || id}`, storedToken);

      if (resp?.data?.status === true) {
        setMessages(resp.data.data.chat);
        setuserprofile(resp.data.data.users);
        setloader(false);
        MessageCount();
      } else {
        setCheckGroup(resp.data.message);
        setloader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filteredChatList = grouplist.filter(
    (chat) =>
      chat?.users
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      chat?.name.toString().toLowerCase().includes(searchQuery.toLowerCase())
  );
  useEffect(() => {
    MessageCount();
  }, []);

  useEffect(() => {
    GroupList();
  }, [CheckGroup]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [CheckGroup]);
  useEffect(() => {
    GroupList();
  }, [messages]);
  
  useEffect(() => {
    if (user?._id != undefined) {
      const socket = socketIOClient(BASE_URL);

      socket.emit("joinRoom", { roomId: currentID || id });
      socket.on("recieve_message", (data) => {
        setLocalMessages((prevLocalMessages) => [...prevLocalMessages, data]);
        fetchData(currentID);
        GroupList();
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [messages]);

  function getMessageFromTime(time) {
    const date = new Date(time);
    const timeDiff = Date.now() - date.getTime();

    const seconds = timeDiff / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;

    if (seconds < 60) {
      return ` ${Math.round(seconds)} second ago`;
    } else if (minutes < 60) {
      return ` ${Math.round(minutes)} minute ago`;
    } else if (hours < 24) {
      return `${Math.round(hours)} hour ago`;
    } else if (days === 1) {
      return "1 day ago";
    } else {
      return `${Math.round(days)} day ago`;
    }
  }

  useEffect(() => {
    const GroupTime = [];
    const GroupImage = {};
    const demo = {};
    for (const GroupList of grouplist) {
      const time = getMessageFromTime(GroupList?.lastMessageTime);
      demo[GroupList?.roomId] = GroupList?.users;
      GroupImage[GroupList?.roomId] = GroupList?.users.split(",").length;
      const names = GroupList?.users.split(", ");
      const abbreviatedNames = names.map((name) => {
        const lastName = name.split(" ")[1];
        return `${name.split(" ")[0]} ${lastName.charAt(0)}`;
      });
      demo[GroupList?.roomId] = abbreviatedNames.join(", ");

      const list = GroupList?.roomId;
      setGroupImage(GroupImage);
      setgrpLastName(demo);

      GroupTime.push(time);
      if (id === GroupList.roomId) {
        getgroupname(GroupList?.name);
        setuserChat(GroupList?.users);
        setSelectedChat(list);
      }
    }
    settime(GroupTime);
  }, [grouplist]);

  for (const GroupList of grouplist) {
    if (currentID === GroupList.roomId) {
      setSelectedChat(GroupList?.roomId);
      getImage(GroupList?.image);
    }
  }

  useEffect(() => {
    if (id != undefined) {
      const socket = socketIOClient(BASE_URL);

      if (CheckGroup === "Room not found.") {
        const mappedData = purposedDate.map((item) => {
          return {
            id: item.date,
          };
        });

        // socket.emit("send_message", {
        //   name: "System",
        //   user_id: user?._id,
        //   roomId: id || currentID,
        //   message: `Hi, I want to propose  ${mappedData.map((date) =>
        //     moment(date.id, "M/DD/YYYY").format("MMM D")
        //   )} for this match. What you guys say?`,
        //   dateTime: new Date(),
        // });
        // setMessages((prevMessages) => [
        //   ...prevMessages,
        //   {
        //     name: user?.firstName + " " + user?.lastName,
        //     user_id: user?._id,
        //     roomId: currentID || id,
        //     message: message,
        //     dateTime: new Date(),
        //   },
        // ]);

        fetchData();
        GroupList();
      }
    }
  }, [id, CheckGroup]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    const updatedImages = {};
    for (const UserName of messages) {
      for (const DP of userprofile) {
        if (UserName.user_id === DP._id) {
          updatedImages[DP._id] = DP.image;
          break;
        }
      }
    }
    setImage(updatedImages);
  }, [userprofile]);
  const handleSend = (e) => {
    e.preventDefault();
    const mess = e.target.text_message.value;
    const socket = socketIOClient(BASE_URL);

    if (mess.length === 0) {
      socket.emit("send_message", {
        name: user?.firstName + " " + user?.lastName,
        user_id: user?._id,
        roomId: id,
        message: mess.trim(),
        dateTime: new Date(),
        // });
      });

      setLocalMessages((prevMessages) => [
        ...prevMessages,
        {
          name: user?.firstName + " " + user?.lastName,
          user_id: user?._id,
          roomId: id,
          message: mess.trim(),
          dateTime: new Date(),
        },
      ]);
    } else {
      socket.emit("send_message", {
        name: user?.firstName + " " + user?.lastName,
        user_id: user?._id,
        roomId: currentID || id,
        message: mess.trim(),
        dateTime: new Date(),
      });

      setLocalMessages((prevMessages) => [
        ...prevMessages,
        {
          name: user?.firstName + " " + user?.lastName,
          user_id: user?._id,
          roomId: currentID || id,
          message: mess.trim(),
          dateTime: new Date(),
        },
      ]);
    }
    setMessage("");

    document.getElementsByName("text_message")[0].value = null;
  };
    useEffect(() => {
    // Update isMobileView based on the viewport width
    const handleResize = () => {
      setIsBigScreenView(window?.innerWidth >= 2366); // Adjust the breakpoint as needed for mobile view
    };

    // Call the handleResize function on initial load and window resize
    handleResize();
    window?.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <div className="relative flex items-center">
        <img
        alt=""
          className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top"
          src={bannerimage}
        />
        <div className="w-full px-0 relative z-1 ">
          <div className="flex min-h-[600px] bg-[#6C6C6C] border-[#fff] bg-opacity-40 rounded-lg overflow-hidden ">
            {grouplist.length !== 0 ? (
              <>
                <div
                 id="style-1"
                  className={` overflow-auto ${BigScreenView ? "": "max-h-[600px]" }  ${
                    toggle ? "lg:block hidden" : ""
                  }  lg:w-4/12 w-full bg-[#000000] pb-10`}
                >
                  <div className="flex items-center mt-5 px-7">
                    <div className="relative w-full">
                      <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        id="voice-search"
                        className="bg-[black] border border-[white] text-[#959494] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search"
                        required
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5 text-[#959494] dark:text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div id="style-1" className="mt-2"  >
                
                    {filteredChatList.map((group, index) => (
                      <div
                        onClick={() => {
                          fetchData(group.roomId);
                          getGroupOfUser(group?.users);
                          getImage(group?.image);
                          setSelectedChat(group.roomId);
                          getgroupname(group?.name);
                          getcurrentID(group.roomId);
                          setChatClose(group.isComplete)
                          if (window.innerWidth <= 1023) {
                            setToggle(true);
                          }
                        }}
                        key={index}
                        className={` ${
                          selectedChat === group.roomId
                            ? "bg-[#2B2E30A1] flex hover:bg-[#2B2E30A1] cursor-pointer px-5 relative flex-wrap  py-3   border-[#7E7777B5]  border-b-[1.5px]"
                            : " flex hover:bg-[#2B2E30A1] cursor-pointer  relative flex-wrap px-5  py-3   border-[#7E7777B5]  border-b-[1.5px]"
                        }`}
                      >
                        <div className="flex capitalize relative w-full">
                        
                          <div className=" cursor-pointer self-center rounded-full w-[35px] h-[35px] aspect-[1]  overflow-hidden ">
                            <img
                            alt=""
                              crossOrigin="anonymous"
                              src={
                                GroupImage[group.roomId] === 1
                                  ? BASE_URL + `/image/${group?.image}`
                                  : Double
                              }
                            />
                          </div>

                          <p className="text-[#ffffff] ml-5 mt-1 font-[Poppins] p-0 lg:text-[15px] ">
                            {GroupImage[group.roomId] === 1
                              ? group?.users
                              : grpLastName[group.roomId]}{" "}
                            <br />
                            <span
                              className="text-[13px] text-[#959494] font-[100]  flex"
                              style={{
                                maxWidth: "200px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {/* {group?.name} */}
                              {group?.name?.replace("(Female)","")?.replace("(Male)","") }
                            </span>
                          </p>

                          {!group.allSeen && (
                            <span className="w-2 mt-2 h-2 absolute right-0 bg-green rounded-full"></span>
                          )}
                          <span className="absolute right-0 mt-7 ml-16 text-[9px] text-[#bababa] ">
                            {time[index]}
                          </span>
                        </div>
                      </div>
                    ))}
                  {filteredChatList?.length == 0 &&
                          <span className="text-[white] flex justify-center mt-[50%]">
                              No PLayer Found
                          </span>
                  }
                  </div>
                </div>
                {currentID === undefined ? (
                  <div
                    className={`${
                      toggle ? "" : "lg:block hidden"
                    }  relative lg:w-8/12 w-full `}
                  >
                    <div
                      id="style-1"
                      className="flex justify-center items-center my-[10%]"
                      style={{ height: "415px", overflow: "auto" }}
                      ref={chatContainerRef}
                    >
                      <span
                        className="text-[#fff] cursor-pointer lg:hidden block"
                        onClick={() => {
                          if (window.innerWidth <= 1023) {
                            setToggle(false);
                          }
                        }}
                      >
                        <FaAngleLeft />
                      </span>

                      <p className="text-[white] lg:block hidden">
                        Please Select The Group From the Left List
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`${
                      toggle ? "" : "lg:block hidden"
                    }  relative lg:w-8/12 w-full  `}
                  >
                    <div className="bg-[black]  lg:border-l-2 border-[#7E7777B5]  ">
                      <div className="flex items-center ml-5 space-x-3 py-3 relative">
                        <span
                          className="text-[#fff] text-3xl cursor-pointer lg:hidden block"
                          onClick={() => {
                            if (window.innerWidth <= 1023) {
                              setToggle(false);
                            }
                          }}
                        >
                          <FaAngleLeft />
                        </span>
                        <div className=" cursor-pointer rounded-full w-[36px] aspect-[1] border  border-[#fff] overflow-hidden ">
                          <img
                          alt=""
                            crossOrigin="anonymous"
                            src={
                              GroupImage[selectedChat] === 1
                                ? BASE_URL + `/image/${ChatImage}`
                                : Double
                            }
                          />
                        </div>
                        <div className="flex flex-col capitalize">
                          <p className=" text-[white] ">
                            {GroupOfUser || userChat}
                          </p>
                          <p className="text-[#958c8cb5] text-[13px]">
                            {groupName?.replace("(Female)","")?.replace("(Male)","") }
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      id="style-1"
                      className={`overflow-auto ${BigScreenView ? "h-[800px] mb-20 ": "h-[430px]" }  `}
                    
                      ref={chatContainerRef}
                    >
                      {/* <div className="ml-[10%]  mt-5 flex items-center">
                      <div className="h-[1px] bg-[#5e5e5e] w-[39%]"></div>
                              <p className=" text-[#a7a7a7] font-medium text-xs  "> Two Days Ago </p>
                            <div className="h-[1px] bg-[#5e5e5e] w-[39%]"></div>
                      </div> */}

                      {messages.map((message, index) => {
                        const isSentByUser = message?.user_id === user?._id;

                        return (
                          <div
                            key={index}
                            className={` ${
                              isSentByUser
                                ? "mt-5 px-5 space-x-1 items-end   flex justify-end chat-right"
                                : "mt-5 px-5 space-x-1 items-end  flex  chat-left"
                            } `}
                          >
                            <div className="relative inline-block ">
                              {user._id != message?.user_id ? (
                                <>
                                  <img
                                  alt=""
                                    className="absolute top-0 left-0 h-7 w-7  rounded-full"
                                    crossOrigin="anonymous"
                                    src={
                                      BASE_URL +
                                      `/image/${Image[message.user_id]}`
                                    }
                                  />
                                </>
                              ) : (
                                ""
                              )}

                              <p
                                className={`bg-[black]  text-[#959494] text-[px] left-1 whitespace-pre-wrap break-words ${
                                  isSentByUser
                                    ? "text-[12px] text-green max-w-[535px] break-words ml-9 px-3 p-1 rounded-xl"
                                    : "text-[12px]   message-box max-w-[535px] break-words ml-9 px-3  p-1 rounded-xl  text-[white]"
                                }`}
                              >
                                {isSentByUser ? (
                                  ""
                                ) : (
                                  <span className="block mt-0 mb-1 capitalize text-[#959494] text-[px] left-1 ">
                                    {message?.name}
                                  </span>
                                )}
                                {message?.message}
                                <span
                                  className={`text-[#a7a7a7b5] ${
                                    isSentByUser
                                      ? "block text-right text-[10px] "
                                      : "block text-right text-[10px] "
                                  }`}
                                >
                                  {/* {console.log(
                                    "TODAY DATE",
                                    new Date(message?.dateTime),
                                    new Date().getDay()
                                  )} */}
                                  {new Date().getDate() ===
                                  new Date(message?.dateTime).getDate()
                                    ? moment(message?.dateTime).format(
                                        "hh:mm A"
                                      )
                                    : `${moment(message?.dateTime).format(
                                        "MMM D"
                                      )} ${moment(message?.dateTime).format(
                                        "hh:mm A"
                                      )} `}
                                </span>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <form
                      onSubmit={(e) => {
                        handleSend(e);
                        GroupList();
                      }}
                    >
                      {visible && (
                        <button
                          className="absolute text-2xl right-5 text-[#fff] "
                          onClick={scrollToBottom}
                          type="button"
                        >
                          <FaArrowCircleDown />
                        </button>
                      )}
                      <div className="absolute px-7 bottom-0 w-full">
                        <button
                          type="button"
                          className="absolute text-green  ml-3 top-6 "
                        >
                          {/* <FaPlus /> */}
                        </button>
                        {!ChatClose ?   <input
                          type="text"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          id="voice-search"
                          name="text_message"
                          className=" border border-[white] mb-1 text-[#837575] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Type your message here..."
                          required
                          // autoComplete="off"
                        />:
                        <input
                        type="text"
                        disabled
                        id="voice-search"
                        name="text_message"
                        className=" border border-[white] mb-1 text-[#837575] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Chat is disabled"
                        required
                        // autoComplete="off"
                      /> 
                        
                        }
                      

                        <button type="submit" disabled={isSendButtonDisabled}>
                          <img
                          alt=""
                            crossOrigin="anonymous"
                            className="absolute w-[30px] none right-11 h-[27px] top-[0.4rem]"
                            src={sender}
                          />
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </>
            ) : (
              <div
                className={` relative w-full `}
              >
                <div
                  id="style-1"
                  className="flex justify-center md:px-0 md:text-[15px] text-[11px]  items-center my-[7%]"
                  style={{ height: "415px", overflow: "auto" }}
                  ref={chatContainerRef}
                >
                  
                  <p className="text-[white]">
                    Chat is only available when you participate in any League.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default Message;
