import { createContext, useContext, useState } from "react";
import {
  makeAutoObservable,
  useLocalObservable,
  observable,
  runInAction,
} from "mobx";
import {
  postLogin,
  postMethodData,
  patchMethodData,
  getMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";

export class UpcomingLeagueStore {
  Token = null;
  UpcomingLeagueid = "";
  errloadseason = "";
  loadseason = false;
  nolist = false;
  logincheck= false;
  Upcomingleaguesss = [];
  citydrop=[];
  upcommingcitydrop=[];
  saved = false;

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("token");
    // if(this.Token != null){
    //   this.getcitydropdown()
    // }
    
    // if(this.Token === null){
    // this.getupcomingcitydropdown()
    // }
    // if (isloading.isloading) {
    //   this.getUpcomingLeague();
    //   return;
    // }
  }
  getcitydropdown =async() =>{
    runInAction(() => {
     this.loadhomecourt = true;
     this.errloadhomecourt = "";
     // this.saved = false;
    });
    await getMethodData("/api/get/cities", this.Token)
   .then((resp) => {
     if (resp?.data?.status === false) {
       runInAction(() => {
         this.loadhomecourt = false;
         this.errloadhomecourt = resp?.data?.message
       });
       return;
     }
     if (resp?.data?.status === true) {
       runInAction(() => {
         this.loadhomecourt = false;
         this.errloadhomecourt = resp?.data?.message;
         // toast.success(resp?.data?.message);
         this.citydrop = resp?.data?.data;
        
       });
       return;
     }
     runInAction(() => {
       this.loadhomecourt = false;
       this.errloadhomecourt = resp?.data?.message;
     });
   })
   .catch((error) => {
     runInAction(() => {
         this.loadhomecourt = false;
         if(!error.response){
             this.errloadhomecourt = error?.message;
             // toast.error(error?.message);
         }
         this.errloadhomecourt = error?.response?.data?.message;
         // toast.error(error?.message);
     })
     return;
 });
 }


 getupcomingcitydropdown =async() =>{
  runInAction(() => {
   this.loadhomecourt = true;
   this.errloadhomecourt = "";
   // this.saved = false;
  });
  await getMethodData("/api/get/cities", this.Token)
 .then((resp) => {
   if (resp?.data?.status === false) {
     runInAction(() => {
       this.loadhomecourt = false;
       this.errloadhomecourt = resp?.data?.message
     });
     return;
   }
   if (resp?.data?.status === true) {
     runInAction(() => {
       this.loadhomecourt = false;
       this.errloadhomecourt = resp?.data?.message;
       // toast.success(resp?.data?.message);
       this.upcommingcitydrop = resp?.data?.data;
       
      
     });
     return;
   }
   runInAction(() => {
     this.loadhomecourt = false;
     this.errloadhomecourt = resp?.data?.message;
   });
 })
 .catch((error) => {
   runInAction(() => {
       this.loadhomecourt = false;
       if(!error.response){
           this.errloadhomecourt = error?.message;
           // toast.error(error?.message);
       }
       this.errloadhomecourt = error?.response?.data?.message;
       // toast.error(error?.message);
   })
   return;
});
}

  getLeaguesid = (text) => {
    this.UpcomingLeagueid=text;
    // localStorage.setItem("upcomingid", this.UpcomingLeagueid);
  };

  getUpcomingLeague = async(id) => {
    runInAction(() => {
      this.loadseason = true;
      this.errloadseason = "";
      this.saved = false;
    });
    
    await getMethodData(`/api/get/upcoming-leagues?filters[city]=${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.Upcomingleaguesss = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
}
export const UpcomingLeagueStoreContext = createContext(
  new UpcomingLeagueStore()
);
export const useUpcomingLeagueStore = () =>
  useContext(UpcomingLeagueStoreContext);
