import axios from 'axios'
import { AuthStoreContext } from "../Auth/store/Auth";
import { useContext } from "react";
import { toast } from 'react-toastify';

const BASE_URL = process.env.REACT_APP_BASE_URL;
let errorToastDisplayed = false;

// get and post method api front

// export const postLogin = async (API_URL, Data) => {
//     try {
//       const response = await axios.post(`${BASE_URL}${API_URL}`, Data);
//       return response;
//     } catch (error) {
//       // Handle the error here
//     //   console.log('An error occurred while trying to post login:', error);
//       throw error; // Rethrow the error so that the caller can handle it
//     }
//   };

export const postLogin = async (API_URL,Data) => {
    try {
      const response = await fetch(`${BASE_URL}${API_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(Data),
      });
    const responseData = await response.text();
    const data = responseData && JSON.parse(responseData);
    if (response.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
    }
        if (!response.ok || !data) {
            throw new Error(data.message);
        }
    // console.log(data)
    const newData = {
        data:data
    }
    return newData;
    } catch (error) {
      // Handle the error here
      // console.log('An error occurred while trying to post login:', error);
      throw error; // Rethrow the error so that the caller can handle it
    }
  };



// export const getMethodData = async (API_URL,Token) => {
//     try {
//         const response = await axios.get(`${BASE_URL}${API_URL}`,{ headers: { 
//             authorization: `Bearer ${Token}`,
//             "Content-Type":"application/json" 
//         } });
//         console.log(response)
//         return response;
//       } catch (error) {
//         // Handle the error here
//       //   console.log('An error occurred while trying to post login:', error);
//         throw error; // Rethrow the error so that the caller can handle it
//     }
// }

export const getMethodData = async (API_URL,Token) => {
    try {
      const response = await fetch(`${BASE_URL}${API_URL}`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${Token}`,
        //   "Content-Type": "application/json"
        },
      });
    const responseData = await response.text();
    const data = responseData && JSON.parse(responseData);

    if (response.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
    }

    if (!response.ok || !data) {
        throw new Error(data.message);
    }
    // console.log(data)
    const newData = {
        data:data
    }
    return newData;
    } catch (error) {
      if(error =="TypeError: Failed to fetch"  ){
        if (!errorToastDisplayed) {
          errorToastDisplayed = true;
          toast.error("We have difficulties in connecting to the server at the moment, please try after some time.");
        }

        // toast.error("We have difficulties in connecting to server at the minute, please try after some time.")
      }
      else{
        throw error;
      }
      // Handle the error here
      // console.log('An error occurred while trying to post login:', error);
  
    }
  };

// export const putMethod = async (API_URL,data,Token) => {
//     try {
//         const response = await axios.put(`${BASE_URL}${API_URL}`,data,{ headers: { authorization: `Bearer ${Token}` } });
//         return response;
//       } catch (error) {
//         // Handle the error here
//       //   console.log('An error occurred while trying to post login:', error);
//         throw error; // Rethrow the error so that the caller can handle it
//     }
// }

export const putMethod = async (API_URL,data,Token) => {
    try {
      const response = await fetch(`${BASE_URL}${API_URL}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${Token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.text();
        const data = responseData && JSON.parse(responseData);
        if (response.status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          window.location.reload();
        }
            if (!response.ok || !data) {
                throw new Error(data.message);
            }
            
        const newData = {
            data:data
        }
        return newData;
    } catch (error) {
      // Handle the error here
      throw error; // Rethrow the error so that the caller can handle it
    }
  }

// export const postMethodData = async (API_URL,data,Token) => {
//     try {
//         const response = await axios.post(`${BASE_URL}${API_URL}`,data,{ headers: { authorization: `Bearer ${Token}` } });
//         return response;
//       } catch (error) {
//         // Handle the error here
//       //   console.log('An error occurred while trying to post login:', error);
//         throw error; // Rethrow the error so that the caller can handle it
//     }
// }

export const postMethodData = async (API_URL, datanew, Token) => {
    try {
      const response = await fetch(`${BASE_URL}${API_URL}`, {
        method: 'POST',
        headers: {
          'authorization': `Bearer ${Token}`,
          'Content-Type': 'application/json'
        },
        body:  JSON.stringify(datanew),
      });
      const responseData = await response.text();
        const data = responseData && JSON.parse(responseData);
        if (response.status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          window.location.reload();
        }
            if (!response.ok || !data) {
                throw new Error(data.message);
            }
        // console.log(data)
        const newData = {
            data:data
        }
        return newData;
    } catch (error) {
      // Handle the error here
      // console.log('An error occurred while trying to post login:', error);
      throw error; // Rethrow the error so that the caller can handle it
    }
  };


  export const postFormData = async (API_URL, formData, Token) => {
    try {
      const response = await fetch(`${BASE_URL}${API_URL}`, {
        method: 'POST',
        headers: {
          'authorization': `Bearer ${Token}`,
        },
        body: formData,
      });
      const responseData = await response.text();
      const data = responseData && JSON.parse(responseData);
      if (response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      if (!response.ok || !data) {
        throw new Error(data.message);
      }
      const newData = {
        data: data
      }
      return newData;
    } catch (error) {
      // Handle the error here
      // console.log('An error occurred while trying to post login:', error);
      throw error; // Rethrow the error so that the caller can handle it
    }
  };

// export const patchMethodData = async (API_URL,data,Token) => {
//     try {
//         const response = await axios.patch(`${BASE_URL}${API_URL}`,data,{ headers: { authorization: `Bearer ${Token}`,"Content-Type":"application/json" } });
//         return response;
//       } catch (error) {
//         // Handle the error here
//       //   console.log('An error occurred while trying to post login:', error);
//         throw error; // Rethrow the error so that the caller can handle it
//     }
// }

export const patchMethodData = async (API_URL, data, Token) => {
    try {
      const response = await fetch(`${BASE_URL}${API_URL}`, {
        method: 'PATCH',
        headers: {
          authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.text();
        const data = responseData && JSON.parse(responseData);
        if (response.status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          window.location.reload();
        }
            if (!response.ok || !data) {
                throw new Error(data.message);
            }
        // console.log(data)
        const newData = {
            data:data
        }
        return newData;
    } catch (error) {
      // Handle the error here
      // console.log('An error occurred while trying to post login:', error);
      throw error; // Rethrow the error so that the caller can handle it
    }
  }


// export const getSubmitData = async (API_URL,Token) => {
//     try {
//         const response = await axios.get(`${BASE_URL}${API_URL}`,{ headers: { authorization: `Bearer ${Token}`,"Content-Type":"application/json" } });
//         return response;
//       } catch (error) {
//         // Handle the error here
//       //   console.log('An error occurred while trying to post login:', error);
//         throw error; // Rethrow the error so that the caller can handle it
//     }
// }

export const getSubmitData = async (API_URL, Token) => {
    try {
      const response = await fetch(`${BASE_URL}${API_URL}`, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${Token}`,
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.text();
        const data = responseData && JSON.parse(responseData);
        if (response.status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          window.location.reload();
        }
            if (!response.ok || !data) {
                throw new Error(data.message);
            }
        // console.log(data)
        const newData = {
            data:data
        }
        return newData;
    } catch (error) {
      // Handle the error here
      // console.log('An error occurred while trying to post login:', error);

      throw error; // Rethrow the error so that the caller can handle it
    }
  };



// export const deleteData = async (API_URL,Token) => {
//     try {
//         const response = await axios.delete(`${BASE_URL}${API_URL}`,{ headers: { authorization: `Bearer ${Token}` } });
//         return response;
//       } catch (error) {
//         // Handle the error here
//       //   console.log('An error occurred while trying to post login:', error);
//         throw error; // Rethrow the error so that the caller can handle it
//     }
// }

export const deleteData = async (API_URL, Token) => {
    try {
      const response = await fetch(`${BASE_URL}${API_URL}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.text();
      const data = responseData && JSON.parse(responseData);
      if (response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
          if (!response.ok || !data) {
              throw new Error(data.message);
          }
      // console.log(data)
      const newData = {
          data:data
      }
      return newData;
    } catch (error) {
      // Handle the error here
      // console.log('An error occurred while trying to post login:', error);
      throw error; // Rethrow the error so that the caller can handle it
    }
  };