import { observer } from "mobx-react";
import React from 'react';
import { useEffect } from "react";
import { useRef } from "react";
import moment from "moment";

import trophy from "../assets/image/trophy.webp"
import { usePlayersTreeStore } from "./Store/PlayersTreeStore";
import { useParams } from "react-router-dom";
import Moment from "react-moment";

const PlayersTreeMobile = observer(() => {
    const {playertreenolist,playertreedata,totalRounds,getPlayersTree} = usePlayersTreeStore()
    const divRef = useRef([]);
    const divRefone = useRef(null);


    const {leagueid,levelid,userToken} = useParams()

    useEffect(()=> {
        if(leagueid && levelid && userToken){
            getPlayersTree(leagueid,levelid,userToken)
        }
    },[])

      useEffect(() => {
        if (divRefone.current) {
            const childDivs = Array.from(divRefone.current.childNodes).filter(
              (node) => node.nodeName === 'DIV'
            );
            if(childDivs.length == 1){
                divRefone?.current?.classList.add('has-ul');
            }
          }
      }, [divRefone]);

    return(
        <div className="w-full 2xl:px-10 lg:px-5 md:px-3 px-4 relative z-1  bg-[#000] overflow-auto min-h-screen ">
          

        <div>
            {!playertreenolist &&
            <>
            
            <div className="webkitdisplay my-10 w-full">
            {totalRounds && totalRounds.map((res,index)=> (
                <div key={index} className="text-[white] border-r border-green last:border-r-0 w-64 pl-4 first:pl-0">
                    <p className="text-green  font-semibold text-xl capitalize">{res?.round_name}</p>
                    {res?.roundStartDate && (
                      <p className="">
                        {" "}
                        <Moment
                          format={
                            moment(new Date(res?.roundStartDate))
                              .format("MMM D")
                              .includes("May")
                              ? "MMM DD"
                              : "MMM. DD"
                          }
                        >
                          {res?.roundStartDate}
                        </Moment>
                        {res?.roundEndDate &&
                        <>
                         { " - " }
                          <Moment
                          format={
                            moment(new Date(res?.roundEndDate))
                              .format("MMM D")
                              .includes("May")
                              ? "MMM DD"
                              : "MMM. DD"
                          }
                        >
                          {res?.roundEndDate}
                        </Moment>
                        </>
                        }
                      </p>
               
                    )}
                    
                </div>
             ))}
            </div>
           
            

            <div  className={`text-[white] flex gap-0 tree-container d-flex first-div ${playertreedata.length == 1 && "justify-center"}`} ref={divRefone}>
                {playertreedata && playertreedata.map((resone,index)=> (
                    <div key={index} className="grid gap-x-5 gap-y-5 h-auto relative w-fit" ref={(ref) => divRef.current[index] = ref}>
                            {
                                resone.map((res,index)=>(
                                    <ul key={index}   className={`w-full grid border-after relative ${playertreedata.length != 1 && "pr-12"} lastcond`}>
                                        <li className="capitalize py-2 text-[12px] px-4 border border-green h-fit m-auto relative z-10 bg-[#000] w-full min-w-[13rem] text-center">
                                            <p className="whitespace-nowrap text-overnew">
                                            {res?.winner && 
                                            <img alt="" className=" h-full m-auto" src={trophy}/>}
                                            {res?.team?.name}{res?.team?.mate != "" && ", "+res?.team?.mate }
                                            </p>
                                            {res?.score && <p className="text-green absolute left-[50%] right-[50%] top-[110%] translate-x-[-50%] w-max">{res.score}</p>}
                                        </li>
                                        {res?.opponent 
                                        && 
                                        <li className="capitalize text-[12px] py-2 px-4 border border-green whitespace-nowrap relative z-10 bg-[#000] text-overnew w-full min-w-[13rem] text-center">
                                            {res?.opponent?.name}{res?.opponent?.mate != "" && (", "+res?.opponent?.mate) }
                                        </li>}
                                    </ul>
                                ))
                            }
                    </div>
                ))}
            </div>
            </>}

            {playertreenolist && 
                <div className="my-10 text-center">
                  <p className=" font-semibold text-2xl text-[white] text-center">No Data Found</p>
                </div>
            }
        </div>
        </div>
    )
})

export default PlayersTreeMobile;