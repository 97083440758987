import React, { useEffect } from 'react';
import { useAuthStore } from "../Auth/store/Auth";
import { SlBell } from "react-icons/sl";
import img1 from "../assets/image/img1.webp";
import img2 from "../assets/image/img2.webp";
import img3 from "../assets/image/img3.webp";
import img4 from "../assets/image/img4.webp";
import img5 from "../assets/image/img5.webp";
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';

const background = [
  img1,
  img2,
  img3,
  img4,
  img5,
]
const random = Math.floor(Math.random() * background.length);

const AllNotification = observer(() => {
  const navigate = useNavigate()

  const {
    user,
    authapproved,
    logout,
    otpscreenremove,
    setloginNoEmail,
    setsocialType,
    setupcomingleauge,
    getNotification,
    notification,
    nolistAllNoti,
    deleteNotification,
    allnotification,
    getAllNotification,
    loadnotification,
  } = useAuthStore();
  useEffect(() => {
    // gettoken(localStorage.getItem("token"))
    // getuser(JSON.parse(localStorage.getItem("user")))
    if (!authapproved) {
      navigate("/");
    }
    else {
      getAllNotification()
    }

  }, [authapproved])

  return (
    <div className="relative py-5 flex h-full bg-no-repeat bg-fixed w-full object-cover object-top"
      style={{
        backgroundImage: `url(${background[random]})`,
        backgroundSize: 'cover',
      }}>

      <div className="w-full 2xl:px-28 lg:px-24 md:px-14 px-4 relative z-1  ">
        <div className="flex flex-wrap justify-center">
          <div className=" w-full ">
            <div className="w-full bg-[#6C6C6C] bg-opacity-40 rounded-lg lg:px-10 md:px-5 md:pt-5 pb-5">
              <div>
                <div className='flex justify-between items-center md:whitespace-nowrap'>
                  <p className=" text-[#fff] mb-5 md:text-3xl text-2xl relative flex items-center ">
                    Notification List
                    <span className="h-[2px] w-[39.05px] block bg-green ml-3">
                    </span>
                  </p>
                  {!nolistAllNoti && (
                    <button
                      onClick={() => { deleteNotification(); }}
                      className=" text-[20px]  text-[#000] mb-5 bg-green 2xl:px-9 md:px-2 md:py-1 md:w-auto rounded-lg "
                    >
                      Clear All
                    </button>
                  )}

                </div>
              </div>
              <div id="style-1" className=" 2xl:w-[100%]  max-h-[600px] border-t border-t-[#ffffff] lg:w-[100%] md:w-[100%] w-full 2xl:max-w-[100%] overflow-auto  ">
                {allnotification.map((data, index) => (
                  <div key={index} className=' rounded-lg w-full py-2 px-5 my-5 bg-[#fff]'>
                    <div className='flex items-center w-full'>
                      <SlBell className='text-3xl font-bold mx-3' />
                      <p className='w-full text-[15px]'>{data?.body}</p>

                    </div>
                    <div className=' flex justify-end md:mt-[-5px]'>
                      <p className='text-[12px] text-end whitespace-nowrap'>
                        <Moment fromNow>
                          {data?.createdAt ?? "N/A"}
                        </Moment>
                      </p>
                    </div>
                  </div>

                ))}

                {loadnotification &&
                  <div className="flex items-center justify-center py-10">
                    <svg
                      className=" animate-spin mr-1 h-14 w-14 text-white "
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                }
                {nolistAllNoti && (
                  <div className="shadow-lg py-5">
                    <p className=" font-semibold text-[#fff] text-3xl text-center">
                      No Notification Found
                    </p>
                  </div>
                )}




              </div>




            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default AllNotification