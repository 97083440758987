import { createContext, useContext, useState } from "react";
import { format } from "date-fns";
import {
  makeAutoObservable,
  useLocalObservable,
  observable,
  runInAction,
} from "mobx";
import { getMethodData, postMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";

export class PlayerSearchStore {
  Token = null;
  searchList = false;
  playerFirstname = "";
  playerLastname = "";
  city = "";
  cityid = "";
  homeCourtid = "";
  password = "";
  formcheck = false;
  usercheck = false;
  career_win_loss = "";
  current_ranking = "";
  homeCourt = "";
  level = "";
  tempfirstName = "";
  templastName = "";
  dob = "";
  mobile = "+1";
  gender = "";
  user = {};
  userId = "";
  email = "";
  errloadseason = "";
  newcityname = "";
  tablehide = false;
  levelid = "";
  loadseason = false;
  nolist = false;
  homeCourtDropdown = [];
  cityDropdown = [];
  SearchPlayer = [];
  levels = [];
  fName = "";
  lName = "";
  saved = false;

  playerDetails = [];

  constructor(isloading = false, playerData = false) {
    makeAutoObservable(this);
    this.user = JSON.parse(localStorage.getItem("user"));

    // if (this.cityid === "" || null) {
    //   this.Token = localStorage.getItem("token");
    // } else {
    //   this.cityid = JSON.parse(localStorage.getItem("cityid"));
    //   this.Token = localStorage.getItem("token");
    //   return;
    // }

    this.userId = this.user?._id;
    this.Token = localStorage.getItem("token");
    // if(isloading.isloading){
    // // this.getSearchPlayer();
    // this.gethomecourt();
    // this.getcity();
    // this.getlevel();
    //  return
    // }
    if (isloading.editloading) {
      // this.getplayerdetail(isloading.id);
      // this.getuserdetail(isloading.id)
      // this.gethomecourt();
      // this.getcity();
      // this.getlevel();

      return;
    }
  }
  ListTrue = (e) => {
    this.searchList = e;
  };
  gettoken = (e) => {
    this.Token = e;
  };
  reset = () => {
    if (this.searchList) {
      this.getfirstname("");
      this.getlastname("");
      this.getcityid("");
      this.gettablehide(false);
      this.clearsearechlist();
    }
  };

  getformcheck = () => {
    this.formcheck = true;
  };
  getdetail = () => {
    this.formcheck = false;
  };
  gettablehide = (e) => {
    // console.log("====>>>", e)
    this.tablehide = e;
  };
  // tabelreset=()=>{
  //   this.tablehide = false;
  // }
  getcityid = (text) => {
    this.cityid = text;
    // if(this.cityid === null ){
    //   localStorage.setItem("cityid","");
    // }
    // else{
    //   localStorage.setItem("cityid", JSON.stringify(text));
    // }
  };

  changeStep = (e) => {
    runInAction(() => {
      this.step = e;
    });
  };
  getusernumber = (text) => {
    this.mobile = (text || "").toString();
  };
  getfirstname = (text) => {
    this.fName = text;
    if (text?.split(" ").length == 1) {
      this.tempfirstName = text;
      this.templastName = text;
    } else {
      this.tempfirstName = text?.substring(0, text.indexOf(" ")).trim();
      this.templastName = text?.substring(text.indexOf(" ") + 1).trim();
    }
  };

  updatefname = (text) => {
    this.playerFirstname = text;
  };
  updateLname = (text) => {
    this.playerLastname = text;
  };
  getemail = (text) => {
    this.email = text;
  };
  getgender = (text) => {
    this.gender = text;
  };
  getdob = (text) => {
    this.dob = text;
  };
  getlastname = (text) => {
    this.lName = text;
  };
  // getcityid=(text)=>{
  //   this.newcityname=text;
  // }
  gethomeid = (text) => {
    this.homeCourtid = text;
  };
  getlevelid = (text) => {
    this.levelid = text;
  };
  getlevels = (text) => {
    this.level = text;
  };
  clearsearechlist = (text) => {
    this.SearchPlayer = [];
  };

  getSearchPlayer = async () => {
    runInAction(() => {
      this.loadseason = true;
      this.errloadseason = "";
      this.saved = false;
      this.tablehide = true;
    });
    await getMethodData("/api/get/players?filters[isRegister]=0", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }

            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
  gethomecourt = async () => {
    runInAction(() => {
      this.loadseason = true;
      this.errloadseason = "";
      this.saved = false;
    });
    await getMethodData("/api/get/home-courts", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.homeCourtDropdown = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
  getcity = async () => {
    runInAction(() => {
      this.loadseason = true;
      this.errloadseason = "";
      this.saved = false;
    });
    await getMethodData("/api/get/cities", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.cityDropdown = resp?.data?.data;
            this.saved = false;

            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
  gethomecourtdropdown = async (cityid) => {
    runInAction(() => {
      // this.loaduser = true;
      this.errloadcourt = "";
      //   this.saved = false;
    });
    await getMethodData(`/api/get/home-courts/${cityid}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.loaduser = false;
            this.errloadcourt = resp?.data?.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            // this.loaduser = false;
            this.errloadcourt = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.homecourtdrop = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          //   this.loaduser = false;
          this.errloadcourt = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          //   this.loaduser = false;
          if (!error.response) {
            this.errloadcourt = error?.message;
            // toast.error(error?.message);
          }
          this.errloadcourt = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };
  getlevel = async () => {
    runInAction(() => {
      this.loadseason = true;
      this.errloadseason = "";
      this.saved = false;
    });
    await getMethodData("/api/get/lavels", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.levels = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
  getplayerdetail = async (id) => {
    runInAction(() => {});
    await getMethodData(`/api/get/player-details/${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {});
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.playerDetails = resp?.data?.data;
            // this.playerFirstname= resp.data.data.user.firstName
            // this.playerLastname= resp.data.data.user.lastName
            // this.city= resp.data.data.user.city.city_name
            // this.cityid = resp.data.data.user.city._id
            // this.homeCourt= resp.data.data.user.homeCourt.name
            // this.homeCourtid = resp.data.data.user.homeCourt._id
            // this.level = resp.data.data.user.level.name
            // // this.levelid = resp.data.data.user.level._id
            // this.career_win_loss = resp.data.data.career_win_loss
            // this.current_ranking= resp.data.data.current_ranking
            // this.loadseason = false;
            // this.errloadseason = resp?.data?.message;
            // if(resp?.data?.data.length === 0) {
            //   this.nolist = true;
            // }else{
            //   this.nolist = false;
            // }

            // this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          // this.loadseason = false;
          // this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          // this.loadseason = false;
          if (!error.response) {
            // this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          // this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

  getuserdetail = async (id) => {
    runInAction(() => {
      this.loadseason = true;
      this.errloadseason = "";
      this.saved = false;
    });
    await getMethodData(`/api/get/player-details/${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.usercheck = true;
            this.playerFirstname = resp.data.data.user.firstName;
            this.playerLastname = resp.data.data.user.lastName;
            this.city = resp.data.data.user.city.city_name;
            this.homeCourt = resp.data.data.user.homeCourt.name;
            this.level = resp.data.data.user.level.name;
            this.dob = format(new Date(resp.data.data.user.dob), "yyyy-MM-dd");
            this.email = resp.data.data.user.email;

            this.levelid = resp.data.data.user.level._id;
            this.mobile = resp.data.data.user.mobile.toString();
            this.gender = resp.data.data.user.gender;
            this.career_win_loss = resp.data.data.career_win_loss;
            this.current_ranking = resp.data.data.current_ranking;
            this.loadseason = false;
            this.errloadseason = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }

            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
  //filter user api

  getFilterPlayer = async () => {
    runInAction(() => {
      this.loaduser = true;
      this.errloaduser = "";
      this.saved = false;
    });
    await getMethodData(
      `/api/get/players?filters[isRegister]=&filters[firstName]=${this.tempfirstName}&filters[lastName]=${this.templastName}&filters[city]=${this.cityid}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaduser = false;
            this.errloaduser = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.SearchPlayer = resp?.data?.data;

            this.saved = false;
            this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loaduser = false;
          this.errloaduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaduser = false;
          if (!error.response) {
            this.errloaduser = error?.message;
            toast.error(error?.message);
          }
          this.errloaduser = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
  edituser = async (id) => {
    runInAction(() => {
      this.addloaduser = true;
      this.erradduser = "";
    });
    await postMethodData(
      "/api/user/update/profile",
      {
        firstName: this.playerFirstname,
        lastName: this.playerLastname,
        email: this.email,
        gender: this.gender,
        mobile: this.mobile,
        homeCourt: this.homeCourtid,
        city: this.cityid,
        dob: this.dob,
        level: this.levelid,
        newsletterSubscription: true,

        // city: this.newcityname,
        // name: this.homename,
        // zipcode: this.zipcode,
        // isActive: this.status,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            toast.success(resp.data.message);
            this.getuserdetail(id);
            this.saved = true;
          });
          return;
        }
        runInAction(() => {
          this.addloaduser = false;
          this.erradduser = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.addloaduser = false;
            this.erradduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.message);
          this.addloaduser = false;
          this.erradduser = error?.response?.data?.message;
        });
        return;
      });
  };
}
export const PlayerSearchStoreContext = createContext(new PlayerSearchStore());
export const usePlayerSearchStore = () => useContext(PlayerSearchStoreContext);
