import React,{useEffect} from "react";
import axios from 'axios';
import { useLocation, useNavigate} from "react-router-dom";
import { toast,ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Header from "./Common/Header";
import Routess from "./Routes";
import {useAuthStore} from "./Auth/store/Auth"
import { observer } from "mobx-react";
import Footer from "./Common/Footer";
import { useRegisterStore } from "./Register/store/RegisterStore";

import { Route, Routes } from "react-router-dom";
import PlayersTreeMobile from "./PlayersTree/PlayersTreeMobile";


const App = observer(() => {
  const location = useLocation();
  const {logout,setupcomingleauge,Token} = useAuthStore();
  const { getleaugesfalse,changeStep,allClear } = useRegisterStore();
  useEffect(()=>{
    axios.interceptors.request.use(function (config) {
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
      switch (response.status) {
          case 400:
            break;
          case 401:
          logout();
          setupcomingleauge(false);
          getleaugesfalse(false);
          changeStep("1");
          allClear();
            break;
          case 500:
            break;
          case 504:
            break;
          default:
      }
      return response;
    }, function (error) {
      switch (error.response.status) {
        case 400:
          break;
        case 401:
        logout();
        setupcomingleauge(false);
        getleaugesfalse(false);
        changeStep("1");
        allClear();
          break;
        case 500:
          break;
        case 504:
          break;
        default:
      }
      return Promise.reject(error);
    });
  },[])

  const hiddenUrls = ['/treeview'];
  // Check if the current URL is in the hiddenUrls array
  const isHidden = hiddenUrls.some(url => location.pathname.startsWith(url));
  const toastConfig = {
    autoClose: 1000, // 1 seconds
  };
  return (
    <>
      <Routes>
          <Route element={<PlayersTreeMobile/>} path="/treeview/:leagueid/:levelid/:userToken" />
      </Routes>
      {!isHidden && 
      <>
        <div className="flex">
          <main className="w-full bg-[#ffffff] block">
          <Header/>
            <section className="">
              <Routess />
            </section>
          <Footer/>
          </main> 
        </div>
        <ToastContainer {...toastConfig}/>

      </>}
    </>
   
  );
})

export default App;
