import { createContext, useContext } from "react";
import {
  makeAutoObservable,
 
  runInAction,
} from "mobx";
import {

  getMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";

export class CommonStore {
  Token = null;
  loadcommon =false
  UpcomingLeagueid = "";
  errcommon = "";
  count = "0";
  loadseason = false;
  nolist = false;
  logincheck = false;
  Aboutus=[];
  Scheduling=[];
  RatingSystem=[];
  PlayoffStructure=[];
  Benifits=[];
  contact=[]
  gettheappp=[];
  privacy=[];
  terms=[];
  Upcomingleaguesss = [];
  citydrop = [];
  upcommingcitydrop = [];
  saved = false;
  grpName=""
  grpPlayerName=""
  constructor(Aboutus = false ) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("token");
  if(Aboutus.about === true){
    this.getaboutpage()
  }
  if(Aboutus.Benifits === true ){
    this.getbenifitspage()
  }
  if(Aboutus.contact){
    this.getcontactus();
  }
  if(Aboutus.GettheApp === true){
    this.gettheapp()
  }
  if(Aboutus.terms === true){
    this.getTerms()
  }
  if(Aboutus.privacy === true){
    this.getPrivacy()
  }
  if(Aboutus.scheduling === true){
    this.getScheduling()
  }
  if(Aboutus.ratingSystem === true){
    this.getRatingSystem()
  }
  if(Aboutus.playoffStructure === true){
    this.getPlayoffStructure()
  }
  }
  
  gettoken = (e) => {
    this.Token = e;
  };
  getaboutpage = async() => {
    runInAction(() => {
      this.loadcommon = true;
      this.errcommon = "";
      // this.saved = false;
    });
    await getMethodData("/api/get/cms-page/about", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadcommon = false;
            this.errcommon = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadcommon = false;
            this.errcommon = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.Aboutus = resp?.data?.data;
            // console.log( this.Aboutus)
          });
          return;
        }
        runInAction(() => {
          this.loadcommon = false;
          this.errcommon = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadcommon = false;
          if (!error.response) {
            this.errcommon = error?.message;
            // toast.error(error?.message);
          }
          this.errcommon = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };

  setCount = (e) => {
    this.count = e;
  };

  getbenifitspage = async() => {
    runInAction(() => {
      this.loadcommon = true;
      this.errcommon = "";
      // this.saved = false;
    });
    await getMethodData("/api/get/cms-page/benefits", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadcommon = false;
            this.errcommon = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadcommon = false;
            this.errcommon = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.Benifits = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadcommon = false;
          this.errcommon = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadcommon = false;
          if (!error.response) {
            this.errcommon = error?.message;
            // toast.error(error?.message);
          }
          this.errcommon = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };

  gettheapp = async () => {
    runInAction(() => {
      this.loadcommon = true;
      this.errcommon = "";
      // this.saved = false;
    });
    await getMethodData("/api/get/cms-page/get-the-app", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadcommon = false;
            this.errcommon = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadcommon = false;
            this.errcommon = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.gettheappp = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadcommon = false;
          this.errcommon = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadcommon = false;
          if (!error.response) {
            this.errcommon = error?.message;
            // toast.error(error?.message);
          }
          this.errcommon = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };
  
  getcontactus = async() => {
    runInAction(() => {
      this.loadcommon = true;
      this.errcommon = "";
      // this.saved = false;
    });
    await getMethodData("/api/get/cms-page/contact-us", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadcommon = false;
            this.errcommon = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadcommon = false;
            this.errcommon = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.contact = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadcommon = false;
          this.errcommon = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadcommon = false;
          if (!error.response) {
            this.errcommon = error?.message;
            // toast.error(error?.message);
          }
          this.errcommon = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };

  getPrivacy = async() => {
    runInAction(() => {
      this.loadcommon = true;
      this.errcommon = "";
      // this.saved = false;
    });
    await getMethodData("/api/get/cms-page/privacy-policy", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadcommon = false;
            this.errcommon = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadcommon = false;
            this.errcommon = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.privacy = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadcommon = false;
          this.errcommon = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadcommon = false;
          if (!error.response) {
            this.errcommon = error?.message;
            // toast.error(error?.message);
          }
          this.errcommon = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };

  getTerms = async() => {
    runInAction(() => {
      this.loadcommon = true;
      this.errcommon = "";
      // this.saved = false;
    });
    await getMethodData("/api/get/cms-page/terms-and-conditions", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadcommon = false;
            this.errcommon = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadcommon = false;
            this.errcommon = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.terms = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          this.loadcommon = false;
          this.errcommon = resp?.data?.message;
        });
        
      })
      .catch((error) => {
        runInAction(() => {
          this.loadcommon = false;
          if (!error.response) {
            // console.log(error.response);
            this.errcommon = error?.message;
            toast.error(error?.message);
          }
          this.errcommon = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
//Group Data
getGroupDeatails = async(id) => {
  runInAction(() => {
    this.loadcommon = true;
    this.errcommon = "";
  });
  await getMethodData(`/api/user/chat-details/${id}`,this.Token).then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loadcommon = false;
          this.errcommon = resp?.data?.message;
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
         this.grpName=resp?.data?.data?.name;
         this.grpPlayerName= resp?.data?.data?.users;
        });
        return;
      }
      runInAction(() => {
        this.loadcommon = false;
        this.errcommon = resp?.data?.message;
      });
      
    })
    .catch((error) => {
      runInAction(() => {
        this.loadcommon = false;
        if (!error.response) {
          this.errcommon = error?.message;
          toast.error(error?.message);
        }
        this.errcommon = error?.response?.data?.message;
        toast.error(error?.message);
      });
      return;
    });
};
MessageCount = async() => {
  runInAction(() => {
    this.loadMessage = true;
    this.errloadMessage = "";
    this.saved = false;
  });
  await getMethodData(`/api/user/chat-count`, this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loadcommon = true;
          this.errcommon = "";
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.loadcommon = true;
          this.errcommon = "";
          if (resp?.data?.data.length === 0) {
            this.nolist = true;
          } else {
            this.nolist = false;
          }
          this.setCount(resp.data.data);
          // this.status = true;
        });
        return;
      }
      runInAction(() => {
        this.loadcommon = true;
        this.errcommon = "";
      });
    })
    .catch((error) => {
      runInAction(() => {
        this.loadcommon = true;
       
        if (!error.response) {
          this.errcommon = "";
        }
        this.errcommon = "";
      });
      return;
    });
};

getScheduling= async() => {
  runInAction(() => {
    this.loadcommon = true;
    this.errcommon = "";
    // this.saved = false;
  });
  await getMethodData("/api/get/cms-page/scheduling", this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loadcommon = false;
          this.errcommon = resp?.data?.message;
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.loadcommon = false;
          this.errcommon = resp?.data?.message;
          // toast.success(resp?.data?.message);
          this.Scheduling = resp?.data?.data;
          // console.log( this.Aboutus)
        });
        return;
      }
      runInAction(() => {
        this.loadcommon = false;
        this.errcommon = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
        this.loadcommon = false;
        if (!error.response) {
          this.errcommon = error?.message;
          // toast.error(error?.message);
        }
        this.errcommon = error?.response?.data?.message;
        // toast.error(error?.message);
      });
      return;
    });
};
 
getRatingSystem= async() => {
  runInAction(() => {
    this.loadcommon = true;
    this.errcommon = "";
    // this.saved = false;
  });
  await getMethodData("/api/get/cms-page/rating_system", this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loadcommon = false;
          this.errcommon = resp?.data?.message;
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.loadcommon = false;
          this.errcommon = resp?.data?.message;
          // toast.success(resp?.data?.message);
          this.RatingSystem = resp?.data?.data;
          // console.log( this.Aboutus)
        });
        return;
      }
      runInAction(() => {
        this.loadcommon = false;
        this.errcommon = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
        this.loadcommon = false;
        if (!error.response) {
          this.errcommon = error?.message;
          // toast.error(error?.message);
        }
        this.errcommon = error?.response?.data?.message;
        // toast.error(error?.message);
      });
      return;
    });
};

getPlayoffStructure= async() => {
  runInAction(() => {
    this.loadcommon = true;
    this.errcommon = "";
    // this.saved = false;
  });
  await getMethodData("/api/get/cms-page/playoff_structure", this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.loadcommon = false;
          this.errcommon = resp?.data?.message;
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.loadcommon = false;
          this.errcommon = resp?.data?.message;
          // toast.success(resp?.data?.message);
          this.PlayoffStructure = resp?.data?.data;
          // console.log( this.Aboutus)
        });
        return;
      }
      runInAction(() => {
        this.loadcommon = false;
        this.errcommon = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
        this.loadcommon = false;
        if (!error.response) {
          this.errcommon = error?.message;
          // toast.error(error?.message);
        }
        this.errcommon = error?.response?.data?.message;
        // toast.error(error?.message);
      });
      return;
    });
};

}
export const CommonStoreContext = createContext(
  new CommonStore()
);
export const useCommonStore = () =>
  useContext(CommonStoreContext);
