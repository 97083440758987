import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import 'webstorage-polyfill';
import "./index.css";
import "./App.css"
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthStoreContext,AuthStore } from "./Auth/store/Auth";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  
    <AuthStoreContext.Provider value={new AuthStore()}>
      <BrowserRouter> 
        <App />
      </BrowserRouter>
    </AuthStoreContext.Provider>
);
reportWebVitals();
