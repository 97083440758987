import { createContext, useContext, useState } from "react";
import {
  makeAutoObservable,
  useLocalObservable,
  observable,
  runInAction,
  action,
} from "mobx";
import {
  postLogin,
  postMethodData,
  patchMethodData,
  getMethodData,
  getMessageData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { AuthStore } from "../../Auth/store/Auth";

export class MessagePageStore {
  Token = null;
  loadMessage = "";
  ChatImage="";
  demo = [];
  time = [];
  matchId = "";
  count = "0";
  selectedChat = null;
  mymessageID = "";
  currentID = undefined;
  GroupOfUser = "";
  purposedDate = [];
  errloadMessage = "";
  messages = [];
  groupName = "";
  grouplist = [];
  nolist = false;
  reciver = "";

  constructor() {
    makeAutoObservable(this);
    // if (isloading) {
    //   // this.TokenUser = localStorage.getItem("token");
    // }
    this.Token = localStorage.getItem("token");
    this.user = JSON.parse(localStorage.getItem("user"));
  }
  getGroupOfUser = (e) => {
    this.GroupOfUser = e;
  };
  setCount = (e) => {
    this.count = e;
  };
  
  getImage=(e)=>{
    this.ChatImage=e
  }
  getMessageId = (e) => {
    this.matchId = e;
  };
  gettoken = (e) => {
    this.Token =e;
  };
  getuser = (e) => {
    this.user = e;
  };
  getmymessageID = (e) => {
    this.mymessageID = e;
  };
  getcurrentID = (e) => {
    this.currentID = e;
  };
  // Action to set messages
  setMessages = (messages) => {
    this.messages.push(messages);
  };
  getgroupname = (e) => {
    this.groupName = e;
  };
  getpurposeddate = (e) => {
    this.purposedDate = e;
  };

  setSelectedChat = (e) => {
    this.selectedChat = e;
  };
  settime = (e) => {
    this.time = e;
  };
  // //MessageList
  // MessageList = (matchid) => {
  //   runInAction(() => {
  //     this.loadMessage = true;
  //     this.errloadMessage = "";
  //     this.saved = false;
  //   });
  //   getMethodData(
  //     `/api/user/chat/${matchid}`,
  //     this.Token
  //   )
  //     .then((resp) => {
  //       if (resp?.data?.status === false) {
  //         runInAction(() => {
  //           this.loadMatch = false;
  //           this.errloadMatch = resp?.data?.message;
  //         });
  //         return;
  //       }
  //       if (resp?.data?.status === true) {
  //         runInAction(() => {
  //           this.loadMessage = false;
  //           this.errloadMessage = resp?.data?.message;
  //           if (resp?.data?.data.length === 0) {
  //             this.nolist = true;
  //           } else {
  //             this.nolist = false;
  //           }
  //           this.messages = resp.data.data;
  //           this.demo = resp.data.data[0];
  //           // this.status = true;
  //         });
  //         return;
  //       }
  //       runInAction(() => {
  //         this.loadMessage = false;
  //         this.errloadMessage = resp?.data?.message;
  //       });
  //     })
  //     .catch((error) => {
  //       runInAction(() => {
  //         this.loadseason = false;
  //         if (!error.response) {
  //           this.errloadMessage = error?.message;

  //         }
  //         this.errloadMessage = error?.response?.data?.message;

  //       });
  //       return;
  //     });
  // };
  //GroupList
  GroupList = async() => {
    runInAction(() => {
      this.loadMessage = true;
      this.errloadMessage = "";
      this.saved = false;
    });
    await getMethodData(`/api/user/chats/list`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadMessage = false;
            this.errloadMessage = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadMessage = false;
            this.errloadMessage = resp?.data?.message;
            if (resp?.data?.data.length === 0) {
              this.nolist = true;
            } else {
              this.nolist = false;
            }
            this.grouplist = resp.data.data;
            this.settime(resp.data.data);
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadMessage = false;
          this.errloadMessage = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadMessage = false;
          if (!error.response) {
            this.errloadMessage = error?.message;
          }
          this.errloadMessage = error?.response?.data?.message;
        });
        return;
      });
  };

}

export const MessageContext = createContext(new MessagePageStore());
export const MessageStore = () => useContext(MessageContext);
