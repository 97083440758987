/* eslint-disable jsx-a11y/alt-text */
import React, { useState,useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
// import bgroger from "../assets/image/bgroger.webp";
import img1 from "../assets/image/img1.webp";
import img2 from "../assets/image/img2.webp";
import img3 from "../assets/image/img3.webp";
import img4 from "../assets/image/img4.webp";
import img5 from "../assets/image/img5.webp";
import img6 from "../assets/image/img6.webp";
import Skill from "../Register/ui/Skill.js";
import Facility from "./ui/Facility";
import { useRegisterStore } from "./store/RegisterStore";
import Payment from "./ui/Payment";
import Receipt from "./ui/Receipt";
// import { useLeagueStore } from "../Leaguespage/store/Leaguestore";
import { useAuthStore } from "../Auth/store/Auth";
// import { getIdToken } from "firebase/auth";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import ContactForm from "../Common/ContactForm";
import Partner from "./ui/Partner";
import { FaAngleLeft } from "react-icons/fa";
import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import { loadStripe } from '@stripe/stripe-js/pure';
const stripeJs = async () => await import("@stripe/stripe-js/pure");
const { loadStripe } = await stripeJs();

const Register = observer(() => {
  
  const {id} =  useParams()
  const {PostRegisterCheck,moveforwad,leaguetype,step,getidLeague,gettoken,getuser,getLeaguesid,leaguesiddata,leaugesfalse,popisOpen,setpopisOpen,contactUs,popupload , stripeKey} =  useRegisterStore()
  const { getProfile,authapproved,user , gender,allowuser } = useAuthStore();
 
  const navigate = useNavigate();
  const background =[
    img1,
    img2,
    img3,
    img4,
    img5,
  ]
  const random = Math.floor(Math.random() * background.length);

  useEffect(() => {
    if(moveforwad){
      navigate(`/mymatch/${id}`);
      return
    }
  },[moveforwad])

  useEffect(() => {
    if(user){
      getuser(user || JSON.parse(localStorage.getItem("user")))
    }
  },[user])

  useEffect(()=> {
    if(authapproved){
       gettoken(localStorage.getItem("token"))
      //  if(!moveforwad){
      //   PostRegisterCheck(id)
      //  }
      
       getLeaguesid(id)
       getidLeague(id)
      if (allowuser){
        navigate("/league");
      }
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'
      })
    }
    else{
      navigate("/")
      return
    }
    },[authapproved,allowuser])
    

    // useEffect(() => {
    //   if(!authapproved){
    //     navigate("/")
    //     return
    //   }
    // },[authapproved])

 


    const {
      register: register2,
      reset:reset2,
      handleSubmit: handleSubmit2,
      watch: watch2,
      control:control2,
      formState: { errors: errors2,isSubmitSuccessful },
     } = useForm();

     

     useEffect(() => {
      if (isSubmitSuccessful) {
        reset2();
      }
    }, [isSubmitSuccessful]);

    loadStripe.setLoadParameters({advancedFraudSignals: false})
    const stripePromise =  stripeKey ? loadStripe(stripeKey) : null;

  return (
    <div className="relative min-h-[100vh] ">
      <img
      alt=""
        className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top "
        src={img6}
      />
      <div className=" w-full relative z-1 py-3">
      <Link to="/league">
              <p className="flex items-center md:pl-24 pl-4 top-5 text-[20px] text-[#ffffff]  ">
                <FaAngleLeft /> Back to List
              </p>
            </Link>
          <div className="w-full text-center md:mt-[-50px] text-[#fff] text-2xl md:text-3xl py-3">
          Registration 
          <br/>
          <p className="text-[#fff] text-base capitalize mt-2">
           {/* {leaguesiddata.replace("mixed","(Mixed)")} */}
          {leaguesiddata?.replace("(Female)","")?.replace("(Male)","") }
          </p>
        </div>

        <div className="flex justify-center md:px-0 px-2 pr-8">
          <div className="flex items-center">
            <div className="flex relative items-center justify-center border border-dashed md:w-28 w-20 p-2 border-[#39FD13] rounded-full  ">
              <div className={` ${step === "1" ? "bg-green ": "bg-[#fff]" } bg-[#fff] flex w-32 aspect-[1] rounded-full items-center justify-center`}>
                <p className="md:text-sm text-[10px]">Skill Level </p>
              </div>
            </div>
            <span className="h-[2px] lg:w-[110px] md:w-[40px] w-[0.5] block bg-[#fff]">
              {" "}
            </span>
          </div>

          {leaguetype != "singles"?
          <div className="flex items-center">
            <div className="flex items-center justify-center border border-dashed md:w-28 w-20 p-2 border-[#39FD13] rounded-full  ">
              <div className={` ${step === "2" ? "bg-green ": "bg-[#fff]" } flex w-32 aspect-[1] bg-[#fff] rounded-full items-center justify-center`}>
                <p className="md:text-sm text-[10px]">Partner</p>
              </div>
            </div>
            <span className="h-[2px] lg:w-[110px] md:w-[40px] w-[0.5] block bg-[#fff]">
            </span>
          </div>
          :
          null
          }

          <div className="flex items-center">
            <div className="flex items-center justify-center border border-dashed md:w-28 w-20 p-2 border-[#39FD13] rounded-full  ">
              <div className={` ${step === "3" ? "bg-green ": "bg-[#fff]" } flex w-32 aspect-[1] bg-[#fff] rounded-full items-center justify-center`}>
                <p className="md:text-sm text-[10px]">Facility</p>
              </div>
            </div>
            <span className="h-[2px] lg:w-[110px] md:w-[40px] w-[0.5] block bg-[#fff]">
              {" "}
            </span>
          </div>

          <div className="flex items-center">
            <div className="flex items-center justify-center border border-dashed md:w-28 w-20 p-2 border-[#39FD13] rounded-full  ">
              <div className={`${step === "4" ? "bg-green ": "bg-[#fff]" } flex w-32 aspect-[1] bg-[#fff] rounded-full items-center justify-center`}>
                <p className="md:text-sm text-[10px]">Payment</p>
              </div>
            </div>
            <span className="h-[2px] lg:w-[110px] md:w-[40px] w-[0.5] block bg-[#fff]">
              {" "}
            </span>
          </div>

          <div className="flex items-center justify-center border border-dashed md:w-28 w-20 p-2 border-[#39FD13] rounded-full  ">
            <div className={`${step === "5" ? "bg-green ": "bg-[#fff]" } flex w-32 aspect-[1] bg-[#fff] rounded-full items-center justify-center`}>
              <p className="md:text-sm text-[10px]">Finish</p>
            </div>
          </div>
        </div>
      </div>

      {step  === "1" &&  <Skill /> }
      {step  === "2" &&  <Partner /> }
      {step  === "3" &&  <Facility/> }
      {step  === "4" && <Elements stripe={stripePromise} > <Payment/> </Elements> }
      {step  === "5" &&  <Receipt/> }
      


      <Popup
        open={popisOpen}
        closeOnDocumentClick
        onClose={() =>{setpopisOpen(false)}}
      >
        <div className=" px-4 overflow-auto max-h-[600px] bg-[#202020] relative">
        <div className="float-right  ">
        <button className=" text-[#fff] text-2xl absolute right-[10px] top-[5px]" onClick={() =>{setpopisOpen(false)}}>
          X
          </button>
          </div>
          <div className="my-5 text-center">
            <p className="text-[#fff] 2xl:text-3xl text-center text-2xl">
            Contact Us
            </p>
          </div>
          <div className=" text-center">
            <p className="text-[#fff] 2xl:text-2xl text-center text-xl">
            Write us at
            </p>
            <p  className="text-[#fff] 2xl:text-xl text-center text-base">
            support@flexleaguetennis.com
            </p>
          </div>
          <ContactForm/>
        </div>
      </Popup>

    </div>
  );
});

export default Register;
