import { createContext, useContext, useState } from "react";
import {
  makeAutoObservable,
  useLocalObservable,
  observable,
  runInAction,
} from "mobx";
import {
  postLogin,
  postMethodData,
  patchMethodData,
  getMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";

export class PlayersTreeStore {
  Token = null;

  playertreeload = false;
  errplayertreeload = "";
  playertreedata=[];
  playertreenolist= false;
  totalRounds=[];
 

  constructor(isloading = false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("token");
  }
 
  getPlayersTree = async(leagueid,levelid,userToken) => {
    runInAction(() => {
      this.playertreeload = true;
      this.errplayertreeload = "";
    });
    
    await getMethodData(`/api/playoff/matches/chart?filters[league]=${leagueid}&filters[level]=${levelid}`, userToken)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.playertreeload = false;
            this.errplayertreeload = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.playertreeload = false;
            this.errplayertreeload = resp?.data?.message;
            if (resp?.data?.data?.data?.length === 0) {
              this.playertreenolist = true;
            } else {
              this.playertreenolist = false;
            }
            this.playertreedata = resp?.data?.data?.data;
            this.totalRounds = resp?.data?.data?.totalRounds;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
            this.playertreeload = false;
            this.errplayertreeload = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.playertreeload = false;
          if (!error.response) {
            this.errplayertreeload = error?.message;
            toast.error(error?.message);
          }
          this.errplayertreeload = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };


}
export const PlayersTreeStoreContext = createContext(
  new PlayersTreeStore()
);
export const usePlayersTreeStore = () =>
  useContext(PlayersTreeStoreContext);
