import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link,useParams } from 'react-router-dom';
import { RegisterStore, RegisterStoreContext, useRegisterStore } from '../store/RegisterStore';
import { AiOutlineCreditCard } from "react-icons/ai";
import visa from "../../assets/image/visa.webp";
import mastercard from "../../assets/image/mastercard.webp";
import amex from "../../assets/image/Amex.webp";
import paypal from "../../assets/image/paypal.webp";
import { useLeagueStore } from '../../Leaguespage/store/Leaguestore';
import { useAuthStore } from '../../Auth/store/Auth';
import { useForm } from 'react-hook-form';
import Cards  from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement, Elements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// const Payment = () => {
//     return(
//       <RegisterStoreContext.Provider value={new RegisterStore({
//       isloading: true,
//       // loadfacility: true,
//       })}>
//         <Screen/>
//       </RegisterStoreContext.Provider>
//     )
//   }
// export default Payment




const Payment = observer(() => {
  // const {id} =  useParams()
const [vname, setvname] = useState("")
const [verror, setverror] = useState("")
const [cardnoerror, setcardnoerror] = useState("")
const [expiryverror, setexpiryverror] = useState("")
const [cvcerror, setcvcerror] = useState("")
const [paymentContinueLoader, setPaymentContinueLoader] = useState(false);
// console.log(paymentContinueLoader,"paymentContinueLoader")


  const stripe = useStripe();
  const elements = useElements();

  // const handleSubmit5 = async (event) => {
  //   // event.preventDefault();

  //   if (!stripe || !elements) {
  //     // Stripe.js has not yet loaded.
  //     return;
  //   }

  //   const cardElement = elements.getElement(CardNumberElement);

  //   try {
  //     const { token } = await stripe.createToken(cardElement);
  //     // Now you can send the token to your server
     
  //     console.log(token?.id);
  //      paymentapi(token?.id)
  //     // Send the token to your server for further processing
  //   } catch (error) {
  //     // Handle error
  //     console.error(error.message);
  //   }
  // };

  // const handleSubmit5 = async (event) => {
  //   if (!stripe || !elements) {
  //     return;
  //   }
  
  //   const cardElement = elements.getElement(CardNumberElement);
  
  //   try {
  //     const { token } = await stripe.createToken(cardElement);
  //     if(token){
  //       console.log(token);
  //     paymentapi(token);
  //     }
      
  //   } catch (error) {
  //     console.error(error.message);
  //   }
  // };

  const handleNameChange = (event) => {
    const cardholderName = event?.target?.value;
   

    if (!cardholderName.trim()) {
      setvname("Cardholder's Name is required");
    } else {
      setvname("");
    }
  };

  //handel stripe payment submit method
  const handleSubmit5 = async (event) => {
    event?.preventDefault();
    setPaymentContinueLoader(true);
    if (!stripe || !elements) {
      return;
    }
   

    const cardElement = elements.getElement(CardNumberElement);
    const cardholderName = event?.target?.cardholder_name?.value;

    if (!cardholderName.trim()) {
    
      setvname("Cardholder's Name is required");
      return;
    }else {
      setvname("");
    }
    try {
     
      // Confirm the card payment
      const { paymentMethod,error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardholderName,
        },
      });
      if (error) {
        // Handle card field error
        setverror(error.message)
        setPaymentContinueLoader(false);
        console.error(error.message);
        // You can display the error message to the user or update your UI accordingly
        // For example, you could set an error state in your component
        // setErrorState(error.message);
        return;
      }
      if (paymentMethod) {
        paymentapi(paymentMethod);
       
        // Use paymentMethod.id in your paymentapi function
       
      }
    } catch (error) {
      setPaymentContinueLoader(false);
      const errorss = error.message
    }
  };

  const {expiryError,leaguesiddata,leaguetype,fees,getLeaguesid,name,number,cvc,expiry,getcardname,getcardexpiry,getcardcvc,getcardnumber,paymentapi,paymentload,ispayment,paymentscreen, Registerpost, changeStep, state, statteid, getCity, city, getState, getCode, getAmount, amount, togglebtn, getStateid, couponcode, coupondata, couponStatus, code,getStripe,stripeKey,handleSubmitpayment,autocouponcode,autocouponstatus } = useRegisterStore()
  // const {  } = useLeagueStore();
  const { user } = useAuthStore();
  // console.log(user)
  // console.log(paymentload, "paymentload")
  // console.log("leaguetype",leaguetype)
  const {
    setValue,
    register,
    handleSubmit,
    watch,

    control,
    formState: { errors },
  } = useForm();

  const {
    
    setValue:setValue2,
    register:register2,
    handleSubmit:handleSubmit2,
    watch:watch2,

    control:control2,
    formState: { errors:errors2 },
  } = useForm();

  const customRegister = (name, options) => {
    register2(name, options);
    return { name, ...options };
  };
  
 
  

  useEffect(() => {
    autocouponcode();
    getState();
    getStripe();
      // getLeaguesid(id)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
 
  }, [ispayment]);

  
  const handleKeyPress = (e) => {
    if (e.key >= '0' && e.key <= '9') {
      e.preventDefault(); // Prevent input of numbers
    }
  };


  /**
   * payment to register for a league match
   */
  return (
   
    <div className="w-full 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-1 py-3">
      
      <div className="flex flex-wrap justify-center">
        <div className="2xl:w-[80%] lg:w-[80%] md:w-[90%] w-full 2xl:max-w-[90%]">
          <div className=" bg-[#6C6C6C] border border-[#fff] bg-opacity-40 rounded-lg lg:px-10 md:px-5 px-4 md:pt-5 py-3">
           {!ispayment
           ?
            <form
              onSubmit={handleSubmit(Registerpost)}
            >
              <div className=" text-center">
                <p className=" text-[#fff] font-medium  md:text-2xl text-xl relative">
                   Billing Address
                </p>
              </div>
              <div className="grid gap-4 md:grid-cols-2 mt-5">
               <div className="col-span-1">
                  <input
                    // defaultValue={signResponse?.user?.firstName}
                    {...register("biling_first_name", { required: true })}
                    name="biling_first_name"
                    id="biling_first_name"
                    defaultValue={user?.firstName ?? ""}
                    // required
                    className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    placeholder="First Name"
                    type="text"
                  />
                  {errors.biling_first_name && (
                    <span className=" text-[red] text-xs">
                      This field is required
                    </span>
                  )}
                </div>
                <div>
                  <input
                    // defaultValue={signResponse?.user?.lastName}
                    {...register("biling_last_name", { required: true })}
                    name="biling_last_name"
                    id="biling_last_name"
                    defaultValue={user?.lastName ?? ""}
                    // required
                    className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    placeholder="Last Name"
                    type="text"
                  />
                  {errors.biling_last_name && (
                    <span className=" text-[red] text-xs">
                      This field is required
                    </span>
                  )}
                </div>
                <div>
                  <input
                    // defaultValue={signResponse?.user?.lastName}
                    {...register("biling_address", { required: true })}
                    name="biling_address"
                    id="biling_address"
                    defaultValue={user?.address_line1 ?? ""}
                    // required
                    className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    placeholder="Addresss"
                    type="text"
                  />
                  {errors.biling_address && (
                    <span className=" text-[red] text-xs">
                      This field is required
                    </span>
                  )}
                </div>
                <div>
                  <input
              
                    {...register("biling_city", { required: true })}
                    name="biling_city"
                    id="biling_city"
                    // required
                    // onChange={(e) => {getStateid(e.target.value);}}
                    defaultValue={user?.address_city ?? ""}
                    className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    placeholder="City"
                    type="text"
                  />
                    {/* <option value="" className='disable hidden'>City</option>
                    {city.map((data, index) => (
                      <option value={data?._id} key={index}>{data?.city_name}</option>

                    ))}

                  </select> */}
                  {errors.biling_city && (
                    <span className=" text-[red] text-xs">
                      This field is required
                    </span>
                  )}
                </div>
                <div>
                  <select
                    {...register("biling_state", { required: true })}
                    name="biling_state"
                    id="biling_state"
                    defaultValue={user?.address_state?.name ?? ""}
                    // required
                    className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                  >
                    <option value={user?.address_state?.name} className="hidden">
                    {user?.address_state?.name}
                    </option>
                    {state.map((data, index) => (
                        <option value= {data?.name} key={index}>
                          {data?.name}
                        </option>
                    ))}
                  </select>
                  {errors.biling_state && (
                    <span className=" text-[red] text-xs">
                      This field is required
                    </span>
                  )}
                </div>
                <div>
                  <input
                    // defaultValue={signResponse?.user?.lastName}
                    {...register("biling_zip_code", { required: true })}
                    name="biling_zip_code"
                    id="biling_zip_code"
                    maxLength={5}
                    minLength={5}
                    defaultValue={user?.address_zip ?? ""}
                    // required
                    className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    placeholder="Zip Code"
                    type="text"
                  />
                  {errors.biling_zip_code && (
                    <span className=" text-[red] text-xs">
                      This field is required
                    </span>
                  )}
                </div>
              
                {/* <div>
                  <input
               
                    {...register("biling_state", { required: true })}
                    name="biling_state"
                    id="biling_state"
                    // required
                    // onClick={(e) => { getCity(e.target.value); }}

                    className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    placeholder="State"
                    type="text"
                  />
             
                  {errors.biling_state && (
                    <span className=" text-[red] text-xs">
                      This field is required
                    </span>
                  )}
                </div> */}
            

                <div className="md:col-span-2">
                    <hr className=' border-hidden bg-[#6C6C6C] h-[2px]' />
                  <div className='grid gap-8 md:grid-cols-2 mt-2'>
                    <div className=' md:col-span-2 grid gap-8 md:grid-cols-2 col-start-1'>
                      <div className=''>
                        <label
                          htmlFor="couponcode"
                          className='text-[#fff] text-2xl my-5'
                        >
                          Promo Code
                        </label>
                      
                        <div className='relative'>        
                          <input
                            // defaultValue={signResponse?.user?.lastName}
                            // {...register("lastName", { required: true })}
                            name="couponcode"
                            autoComplete="couponcode"
                            id="couponcode"
                            //  value={fees}
                            onChange={(e) => { getCode(e.target.value); }}
                            value={code}
                            disabled={couponStatus == true ? true : false}
                            // required 
                            className="bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                            placeholder="Promo Code"
                            type="text"
                          />
                          <span className=" cursor-pointer absolute right-0 top-0 h-full">


                            {couponStatus === true ?
                            <>
                            {autocouponstatus == true ? 
                            <button type="button" className=' rounded-md bg-[#39FD13] px-5 h-full'>Applied</button>
                            :
                            <button onClick={() => { togglebtn() }} type="button" className=' rounded-md bg-[#39FD13] px-5 h-full'> Remove</button>

                           }
                           </>
                              :
                              <button onClick={() => { couponcode(leaguetype === "singles" ? fees : fees * 2) }} type="button" disabled={code == "" ? true : false} className=' rounded-md bg-[#39FD13] px-5 h-full'>Apply</button>
                            }
                          </span>
                        </div>
                       {autocouponstatus == true && <div className='text-[#fff]'>The credit of ${coupondata?.discount_amount} is applied on this league registration fees.</div> } 
                      </div>
                      <div className=' md:flex items-end flex-wrap'>
                      <div className=' md:mt-5 w-full'>
                        <p className='text-[#fff] text-2xl'>League</p>
                        <p className='text-[#fff] capitalize'>{leaguesiddata?.replace("(Female)","")?.replace("(Male)","") } Skill level<span className='text-[#39FD13]'> {" " + leaguetype === "mixed" ? user?.mixed_level.name.toFixed(1): leaguetype === "doubles" ?  user?.doubles_level.name.toFixed(1) : user?.level?.name.toFixed(1) } </span> </p>
                      </div>

                      <div className='text-[#fff] w-full row-span-3 space-y-4 mt-5'>
                        <div className='flex justify-between '>
                          <p className='text-lg '>Fees Amount</p>
                          <p className='text-xl '>${leaguetype === "singles" ? fees : fees * 2}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p className='text-lg '>Discount</p>
                          <p className='text-xl '>${couponStatus === true ? coupondata?.discount_amount : "0"}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p className='text-lg'>Total Amount</p>
                          <p className='text-xl text-green'>${(couponStatus === true ? (coupondata?.total_discounted_amount  ): (leaguetype === "singles" ? fees : fees*2 ))}</p>
                        </div>
                      </div>
                      <div className='h-[52px] flex md:justify-end justify-around mt-4 w-full'>
                        <button
                          className=" font-medium lg:text-[20px] text-lg text-[#000] bg-[#fff] px-11 py-2 rounded-lg mr-4 "
                          type="button"
                          onClick={() => changeStep("3")}
                        >
                          Cancel
                        </button>
                        {!paymentload
                        ?
                          <button
                            className=" font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg "
                            type="submit"
                          //  onClick={()=> changeStep("4")}
                          >
                            Continue
                        </button>
                          :
                          <button 
                            className="flex justify-center items-center font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg "
                            type="button"
                          //  onClick={()=> changeStep("4")}
                          >
                          <svg
                              className="animate-spin mr-1 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              >
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                          </svg>
                          Processing..
                          </button>
                        }
                      </div>

                    </div>
                    </div>
                    
                  </div>
               
                </div>
              </div>

            </form>

            :
            <form
              onSubmit={(e)=> handleSubmit5(e)}
            >
               
              <div className=" text-center">
                <p className="scrolltop text-[#fff] font-medium  md:text-2xl text-xl relative">
                  Payment
                </p>
              </div>
              <div className="grid gap-8 md:grid-cols-2 ">

                <div className="md:col-span-2">
                  <div className='grid gap-8 md:grid-cols-2'>
                    <div className='grid gap-4 grid-cols-1'>

                      <div className='flex flex-col space-y-4 md:mt-2'>
                        <div className='flex items-end space-x-4'>
                          <AiOutlineCreditCard className='text-[#fff] text-5xl relative top-1' />
                          <p className='text-[#fff] text-xl'>Pay with card</p>
                        </div>
                        <div className='flex space-x-2'>

                          <div className='object-contain p-1 w-[82px] bg-[#fff] '>
                            <img alt="" className=' h-[33px] ' src={visa} />
                          </div>
                          <div className='object-contain p-1 w-[82px] bg-[#fff] ' >
                            <img alt="" className=' h-[33px] ' src={mastercard} />
                          </div>
                          <div className='object-contain  w-[82px] bg-[#fff]' >
                            <img alt="" className=' h-[45px] ' src={amex} />
                          </div>

                        </div>
                      </div>

                      {/* <div>
                      <input
        type="text"
        name="cardholder_name"
        placeholder="Cardholder's Name"
        onChange={(e) => getcardname(e.target.value)}
        required
      />
      <CardElement
        options={{
          style: {
            base: {
              color: '#837575',
              fontFamily: 'Arial, sans-serif',
              fontSize: '16px',
              '::placeholder': {
                color: '#837575',
              },
            },
          },
          hidePostalCode: true,
          billingDetails: {
            name: name,
          },
        }}
      />
                      </div> */}

                   <div>

                   <input
                          // defaultValue={signResponse?.user?.lastName}
                          // {...register2("name", {onChange:(e)=>getcardname(e.target.value), required: true })}
                          onChange={(e)=>handleNameChange(e)}
                          type="text"
                          name="cardholder_name"
                          placeholder="Cardholder's Name"  
                          id="name"
                          onKeyDown={handleKeyPress}
                          // required
                          className=" bg-[#f1f1f1] font-thin text-[15px] w-full border border-[#7E7777] rounded-lg px-6 py-2 focus:outline-none"
                      
                        />
                       {vname && (
                          <span className=" relative text-[red] text-xs">
                            {vname}
                          </span>
                        )}


  </div>
  <div>
                      <CardNumberElement
                        // {...customRegister("number", {onChange:(e)=>getcardnumber(e?.target?.value), required: true, min: 19, max: 19 })}
                        // onChange={(e)=> (getcardnumber(e.target.value))}
                        // value={number || ''}
                        // name="number"
                        displayName="card"
                        onChange={(event) => {
                          if (event.error) {
                            // Handle error state
                            setcardnoerror(event?.error?.message);
                          }else{
                            setcardnoerror("")
                          }
                        }}
                        pattern='[\d| ]{16,22}'
                        maxLength='19'
                        minLength='19'
                        autoComplete="cardnumber"
                        id="cardnumber"
                        // required
                        className=" bg-[#f1f1f1] font-thin text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                        placeholder="Card Number"
                        options={{
                          placeholder: "Card Number",
                        }}
                       />
                        {cardnoerror && (
                          <span className=" relative text-[red] text-xs">
                            {cardnoerror}
                          </span>
                        )}
                      </div>

                    <div>
                      <CardExpiryElement 
                      // {...customRegister("expiry", {onChange:(e)=>getcardexpiry(e?.target?.value),required: true })}
                      // onBlur={handleExpiryBlur}
                      // onChange={(e)=> (getcardexpiry(e.target.value))}
                      // value={expiry || ''}
                      // name="expiry"
                      onChange={(event) => {
                        if (event.error) {
                          // Handle error state
                          setexpiryverror(event.error.message)
                          
                        }else{
                          setexpiryverror("")
                        }

                      }}
                      autoComplete="expirationdate"
                      id="expirationdate"
                      required
                      pattern="\d\d/\d\d"
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      placeholder="Expiration Date"
                      type="tel"
                      />
                   
               
                         {expiryverror && (
                          <span className=" relative text-[red] text-xs">
                            {expiryverror}
                          </span>
                        )}
                      </div>
                      <div>
                      <CardCvcElement 
                        // {...customRegister("cvc", {onChange:(e)=>getcardcvc(e?.target?.value), required: true })}
                        // onChange={(e)=> (getcardcvc(e.target.value))}
                        // value={cvc || ''}
                        // name="cvc"
                        onChange={(event) => {
                          if (event.error) {
                            // Handle error state
                            setcvcerror(event.error.message);
                          }else{
                            setcvcerror("")
                          }
                        }}
                        id="cvc"
                        // required
                        maxLength='3'
                        pattern='\d{3}'
                        className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                        placeholder="CVV (3 digits)"
                        type="tel"
                       />

                      {cvcerror && (
                          <span className=" relative text-[red] text-xs">
                            {cvcerror}
                          </span>
                        )}
                     </div>
                    </div>

                    <div className=' md:flex items-end flex-wrap'>
                      <div className=' md:mt-2 w-full'>
                        <p className='text-[#fff] text-2xl'>League</p>
                        <p className='text-[#fff] capitalize'>{leaguesiddata?.replace("(Female)","")?.replace("(Male)","") } Skill level<span className='text-[#39FD13]'> {" " + leaguetype === "mixed" ? user?.mixed_level.name.toFixed(1): leaguetype === "doubles" ?  user?.doubles_level.name.toFixed(1) : user?.level?.name.toFixed(1)} </span> </p>
                      </div>

                      <div className='text-[#fff] w-full row-span-3 space-y-4 mt-5'>
                        <div className='flex justify-between '>
                          <p className='text-lg '>Fees Amount</p>
                          <p className='text-xl '>${leaguetype === "singles" ? fees : fees * 2}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p className='text-lg '>Discount</p>
                          <p className='text-xl '>${couponStatus === true ? coupondata?.discount_amount : "0"}</p>
                        </div>
                        <div className='flex justify-between'>
                          <p className='text-lg'>Total Amount</p>
                          <p className='text-xl text-green'>${(couponStatus === true ? (coupondata?.total_discounted_amount  ): (leaguetype === "singles" ? fees : fees*2 ))}</p>
                        </div>
                      </div>

                      <div className='h-[52px] flex md:justify-end justify-around mt-4 w-full'>
                      {!paymentload
                      ? 
                      <button
                      className=" font-medium lg:text-[20px] text-lg text-[#000] bg-[#fff] px-11 py-2 rounded-lg mr-4 "
                      type="button"
                      onClick={() => {paymentscreen(false)}}
                    >
                      Cancel
                    </button>
                    :
                    <button
                    className=" font-medium lg:text-[20px] text-lg text-[#000] opacity-50 bg-[#fff] px-11 py-2 rounded-lg mr-4 "
                    type="button"
                    disabled
                    // onClick={() => {paymentscreen(false)}}
                  >
                    Cancel
                  </button>
                    } 
                       
                        {!paymentload && !paymentContinueLoader
                        ?
                          <button
                            className=" font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg "
                            type="submit"
                            disabled={!stripe || !elements}
                          //  onClick={()=> changeStep("4")}
                          >
                            Continue
                        </button>
                          :
                          <button 
                            className="flex justify-center items-center font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg "
                            type="button"
                          //  onClick={()=> changeStep("4")}
                          >
                          <svg
                              className="animate-spin mr-1 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              >
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                          </svg>
                          Processing..
                          </button>
                        }
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
            </form>
            }
          </div>
        </div>
      </div>
  
    </div>
  )
})
export default Payment
