import { Link } from "react-router-dom";
import nopage from "../assets/image/nopage.gif";

export const PageNotFound = () => {
    return(
        <div className="w-full bg-[#fff] flex items-center justify-center">
            <div className="text-center">
                <p className="text-8xl font-bold relative top-24">404</p>
                <img alt="" className="h-[400px] object-contain" src={nopage}/>
                <div className=" relative -top-16 bg-[#fff]">
                    <p className=" text-3xl font-semibold">Look like you're lost</p>
                    <p className=" text-base opacity-90">the page you are looking for not available!</p>
                    <Link to="/">
                        <button className="btn btn-md rounded-md px-4 py-2 bg-green text-[#000] text-sm hover:text-green hover:bg-[#000] mt-4">
                            Go TO HOMEPAGE
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}