import { createContext, useContext, useState } from "react";
import {
  makeAutoObservable,
  useLocalObservable,
  observable,
  runInAction,
} from "mobx";
import {
  postLogin,
  postMethodData,
  patchMethodData,
  getMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";
import { format } from "date-fns";
import moment from "moment";
const TIMEZONE_URL = process.env.REACT_APP_TIMEZONE_LIVE;

export class MyMatchStore {
  Token = null;
  NextMonth=false;
  messgae_initate= false;
   obj = {};
   playoffObj={};
   addMonth=false;
   TeamId="";
   ProposedBy=""
  playoffObj={};
  Playoff_obj={};
  play_round="";
  ThirdScoreCheck=true;
  play_level="";
  MaxDate=null;
  getPlayOfs="";
  MinDate=null;
  route="";
  MatchHistoryOfUser="";
  DynamicPlayOf=[];
  MatchHistory=[];
  user = {};
  demo="";
  QutarFinal=false;
  checkDemo=false;
  defaultMessageSent = "";
  opponent = [];
  playOF="";
  Round="";
  CheckHistory=false;
  mate = [];
  MapKey="";
  PlayOffs=[];
  ObjectPlayOff=[];
  grp_Id = "";
  grpPlayerName=[];
  Purposed = [];
  messsage = false;
  DefaultMes= false;
  Purposecheck = false;
  currentDate = "";
  getpurpose = [];
  getpurposetym = [];
  nolist = "";
  dateEx4 = "";
  validatetym = false;
  userID = [];
  loadMatch = false;
  loadMatchdata = false;
  loadMatchHistory = false;
  errloadMatch = false;
  popisOpen = false;
  popupload = false;
  newpop = false;
  grpName = "";
  calenderdate = [];
  leaguetype = "";
  opentables = false;
  openforfiet = false;
  openwithdraw = false;
  opensubmit = false;
  openforfietpopup = false;
  openwithdrawpopup = false;
  lat = "";
  lng = "";
  tymOnClick = "";
  SetDate = null;
  mapshow = false;
  time = false;
  Daytime = false;
  leaguesiddata = "";
  leagueID = "";
  matchid = "";
  Schedule = [];
  tiematch = [];
  loadtielist=false;
  errloadtielist="";
  standing = [];
  Schedulesss = "";
  myteamid = "";
  oppteamid = "";
  datefixed = "";
  matchdetails = {};
  metchid = "";
  homecourt = "";
  radiovalue = "";
  forfietvalue = "";
  withdrawvalue = "";
  validateCheck = false;
  setDate = false;
  getMyPropose = [];
  myforfietvalue = "";
  oppforfietvalue = "";
  Myhomecourt = "";
  mywithdrawvalue = "";
  oppwithdrawvalue = "";
  homecourtdrop = [];
  FromRange = "";
  ToRange = "";
  mysetone = "";
  mysettwo = "";
  mysetthree = "";
  oppsetone = "";
  oppsetwo = "";
  oppsetthree = "";
  homename = "";
  division= true;
  MatchTym = moment();
  matchidslect = "";
  PurposeBtnLoader=false;
  buttondisable = false;

  playertreeload = false;
  errplayertreeload = "";
  playertreedata=[];
  playertreenolist= false;
  totalRounds=[];


  loaderstart=false;
  loaderstartsetdate=false;

  playroundnolist = false

  nohistorylist= false

  notificationNo = false;

  hieracybtn=false
  loadDates = false;
  isloader=false;
  constructor() {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("token");
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  convertTimeToISOString(timeString) {
  const date = new Date(); // Create a new Date object


  const [time, meridiem] = timeString.split(' ');
  let [hours, minutes] = time.split(':');

  if (meridiem === 'PM' && hours !== '12') {
    hours = (parseInt(hours, 10) + 12).toString();
  } else if (meridiem === 'AM' && hours === '12') {
    hours = '00'; // Special case for 12:00 AM
  }

  date.setHours(hours, minutes, 0, 0);

  return date.toISOString();
}


  setProposedBy=(e)=>{
    this.ProposedBy =e;
    }

    setTeamId=(e)=>{
      this.TeamId =e;
      }
setmessgae_initate=(e)=>{
  this.messgae_initate = e;
}

setDivision=(e)=>{
this.division =e;
}
setQutarFinal=(e)=>{
this.QutarFinal=e;
}
  grpPlayer=(e)=>{
    this.grpPlayerName=e
  }
  GetgrpName=(e)=>{
    this.grpName=e
  }
  SetMaxDate=(e)=>{
    this.MaxDate=e;
  }
  SetMinDate=(e)=>{
    this.MinDate=e;
  }
  BackSchedule = (e) => {
    this.Schedulesss = e;
  };
  setvalidatetym = () => {
    this.validatetym = true;
  };
  removetym = () => {
    this.validatetym = false;
  };
  getleagueid = (e) => {
    this.leagueID = e;
  };
  getPlayOf=(e)=>{
    this.playOF =e;
  }
  setmapshow = (e) => {
    this.mapshow = e;
  };
  setmatchid = (e) => {
    this.matchid = e;
  };
  setpopisOpen = (e) => {
    this.popisOpen = e;
    this.description = "";
  };
  setnewpopup = (e) => {
    this.newpop = e;
  };
  settime = (e) => {
    this.time = e;
  };
  setopensubmit = (e) => {
    this.opensubmit = e;
  };
  setopenforfietpopup = (e) => {
    this.openforfietpopup = e;
  };
  setopenwithdrawpopup = (e) => {
    this.openwithdrawpopup = e;
  };

  opentable = (e) => {
    this.opentables = e;
  };
  setopenforfiet = (e) => {
    this.openforfiet = e;
  };
  setopenwithdraw = (e) => {
    this.openwithdraw = e;
  };
  gettoken = (e) => {
    this.Token = e;
  };
  setPlayOffs=(e)=>{
    this.getPlayOfs=e
  }
 
  getuser = (e) => {
    this.user = e;
  };
  gethomecourt = (e) => {
    this.Myhomecourt = e;
  };
  gethomeID = (e) => {
    this.homeID = e;
  };
  setDateEx4 = (e) => {
    this.dateEx4 = e;
  };
  GetPurposed = (e) => {
    this.Purposed = e;
  };
  validate = () => {
    this.validateCheck = true;
  };
  Removevalidate = () => {
    this.validateCheck = false;
  };
  setdate = () => {
    this.setDate = true;
  };
  setDefaultMessageSent = (e) => {
    this.defaultMessageSent = e;
  };
  setMyhomecourt = (e) => {
    this.homename = e?._id;
  };
  removedate = () => {
    this.setDate = false;
  };
  setdatefixed = (e) => {
    this.datefixed = e;
  };

  setMessage = (e) => {
    this.messsage = e;
  };
  setDefaultMessage = (e) => {
    this.DefaultMes = e;
  };
  setPlayoffObj = (e) => {
    this.playoffObj = e;
  };
setRound=(e)=>{
this.Round=e;
}
  gettym = (e) => {
    this.MatchTym = e;
  };
//button disable on close
  getbuttondisable = (e) => {
    this.buttondisable = e;
  };
 
  setcheckDemo=(e)=>{
    this.checkDemo = e
  }
  setThirdScoreCheck=(e)=>{
    this.ThirdScoreCheck = e
  }
  setradiovalue = (e) => {
    this.radiovalue = e;
    this.forfietvalue = "";
    this.withdrawvalue = "";

    this.myforfietvalue = "";
    this.oppforfietvalue = "";

    this.mywithdrawvalue = "";
    this.oppwithdrawvalue = "";

    // console.log("===>radio",this.radiovalue)
  };


  setforfietvalue = (e) => {
    this.forfietvalue = e;
    this.radiovalue = "";
    this.withdrawvalue = "";

    this.mysetone = "";
    this.mysettwo = "";
    this.mysetthree = "";
    this.oppsetone = "";
    this.oppsetwo = "";
    this.oppsetthree = "";

    this.mywithdrawvalue = "";
    this.oppwithdrawvalue = "";

    // this.myteamid = null;
    // this.oppteamid = null;
    // console.log("===>radio",this.radiovalue)
  };
  SettymOnClick = (e) => {
    this.tymOnClick = e;
  };
  setdatetym = (e) => {
    this.SetDate = e;
  };

  setwithdrawvalue = (e) => {
    this.withdrawvalue = e;
    this.forfietvalue = "";
    this.radiovalue = "";

    this.mysetone = "";
    this.mysettwo = "";
    this.mysetthree = "";
    this.oppsetone = "";
    this.oppsetwo = "";
    this.oppsetthree = "";

    this.myforfietvalue = "";
    this.oppforfietvalue = "";

    // this.myteamid = null;
    // this.oppteamid = null;
    // console.log("===>radio",this.radiovalue)
  };
  setclearvalue = (e) => {
    this.radiovalue = "";
    this.forfietvalue = "";
    this.withdrawvalue = "";

    this.mysetone = "";
    this.mysettwo = "";
    this.mysetthree = "";
    this.oppsetone = "";
    this.oppsetwo = "";
    this.oppsetthree = "";

    this.myforfietvalue = "";
    this.oppforfietvalue = "";

    this.mywithdrawvalue = "";
    this.oppwithdrawvalue = "";

    this.myteamid = null;
    this.oppteamid = null;
  };
  getDateRange = (e, b) => {
    this.FromRange = e;
    this.ToRange = b;
  };

  setmyforfietvalue = (e) => {
    this.myforfietvalue = e;
  };
  setoppforfietvalue = (e) => {
    this.oppforfietvalue = e;
    // console.log("===>radio",this.radiovalue)
  };
setdemo=(e)=>{
  this.demo=e;
}
  setmywithdrawvalue = (e) => {
    this.mywithdrawvalue = e;
    // console.log("===>radio",this.radiovalue)
  };
  setoppwithdrawvalue = (e) => {
    this.oppwithdrawvalue = e;
    // console.log("===>radio",this.radiovalue)
  };

  setmyone = (e) => {
    this.mysetone = e;
    // console.log("===>mysetone",this.mysetone)
  };
  getuserID = (e) => {
    this.userID = e;
  };

  setmytwo = (e) => {
    this.mysettwo = e;
    //  console.log("===>mysettwo",this.mysettwo)
  };
 
  setmythree = (e) => {
    this.mysetthree = e;
    //  console.log("===>mysetthree",this.mysetthree)
  };
setRoute=(e)=>{
  this.route=e;
}


  setoppone = (e) => {
    this.oppsetone = e;
    //  console.log("===>mysetthree",this.mysetthree)
  };
  setopptwo = (e) => {
    this.oppsetwo = e;
    //  console.log("===>mysetthree",this.mysetthree)
  };
  setoppthree = (e) => {
    this.oppsetthree = e;
    //  console.log("===>mysetthree",this.mysetthree)
  };
  setpurposecheck = () => {
    this.Purposecheck = false;
  };
  getlocation = (latitude, longitude) => {
    this.lat = latitude;
    this.lng = longitude;
  };
  getPlayOff_Obj=(round,level)=>{
    this.play_round= round;
    this.play_level=level;
  }
  setmyteamid = (e) => {
    this.myteamid = e;
    //  console.log("===>mysetthree",this.mysetthree)
  };
  setDaytime = (e) => {
    this.Daytime = e;
  };
  setoppteamid = (e) => {
    this.oppteamid = e;
    //  console.log("===>mysetthree",this.mysetthree)
  };

  setAddMonth=(e)=>{
    this.addMonth=e
  }
 
  //League Name
  getLeaguesid = async(id) => {
    this.leagueid = id;
    runInAction(() => {
      // this.loadMatch = true;
      this.errloadMatch = "";
      this.saved = false;
      // this.leaguesiddata = "";
    });
    await getMethodData(`/api/get/league/${this.leagueid}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.leaugesfalse = false;
            this.loadMatch = false;
            this.leaguesiddata = "";
            this.errloadMatch = resp?.data?.message;
          });
          return;
        }
   
        if (resp?.data?.status === true) {
          runInAction(() => {
            if (resp?.data?.data?.league_type === "mixed") {
              if (this.user?.gender === "female") {
                this.mixedgender = "male";
                // return
              } else {
                this.mixedgender = "female";
                // return
              }
            } else {
              this.mixedgender = this.user?.gender;
            }
            this.leaugesfalse = true;
            this.loadseason = false;
            this.errloadMatch = resp?.data?.message;
            this.leaguetype = resp?.data?.data?.league_type;
            this.leaguesiddata = resp?.data?.data?.name;
            this.fees = resp?.data?.data?.fees_amount;
           
            // this.saved = false;
            // this.status = true;
          });
          return;
        }

        runInAction(() => {
          this.leaugesfalse = false;
          this.loadMatch = false;
          this.errloadMatch = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.leaugesfalse = false;
          this.loadMatch = false;
          if (!error.response) {
            this.errloadMatch = error?.message;
            toast.error(error?.message);
          }
          this.errloadMatch = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
  getDynamicPlayOff = async(id) => {
    
    runInAction(() => {
      this.loadMatch = true;
      this.errloadMatch = "";
      this.saved = false;
      this.DynamicPlayOf=[];
      this.playroundnolist = false;
      // this.leaguesiddata = "";
    });
    await getMethodData(`/api/user/playoff/rounds/${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.leaugesfalse = false;
            this.loadMatch = false;
            this.playroundnolist = false;
            // this.leaguesiddata = "";
            this.errloadMatch = resp?.data?.message;
          });
          return;
        }
   
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.DynamicPlayOf=resp.data.data;
            // console.log(resp?.data?.data?.length)
            this.saved = false;
            if (resp?.data?.data?.length === 0) {
              this.playroundnolist = true;
            } else {
              this.playroundnolist = false;
            }
            // this.status = true;
          });
          return;
        }

        runInAction(() => {
          this.leaugesfalse = false;
          this.loadMatch = false;
          this.errloadMatch = resp?.data?.message;
          this.playroundnolist = false;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.leaugesfalse = false;
          this.loadMatch = false;
          if (!error.response) {
            this.errloadMatch = error?.message;
            toast.error(error?.message);
          }
          this.errloadMatch = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
  //Scheduled Match
  getScheduled = async() => {
    runInAction(() => {
      this.Schedule = [];
      this.loadMatchdata = true;
      this.errloadMatch = "";
      this.saved = false;
    });
    await getMethodData(
      `/api/user/my-schedule?filters[league]=${this.leagueID}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadMatchdata = false;
            this.errloadMatch = resp?.data?.message;
            this.notificationNo= false;
          });
          return;
        }
    
  
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.notificationNo= true
            this.nolist= resp?.data?.data?.result?.length 
            this.loadMatchdata = false;
            this.errloadMatch = resp?.data?.message;
            this.Schedule = resp?.data?.data?.result;
            this.hieracybtn =resp?.data?.data?.playoffs;
            this.currentDate = resp?.data;
            this.loadtielist=true;
            this.errloadtielist="";
          
            // this.status = true;
          });

          // getMethodData(
          //   `/api/user/my-schedule?filters[league]=${this.leagueID}&filters[matchType]=division_tie`,
          //   this.Token
          // ).then((resp)=> {
          //   if (resp?.data?.status === true) {
          //       runInAction(() => {
          //         this.tiematch = resp?.data?.data;
          //         this.loadtielist=false;
          //         this.errloadtielist=resp?.data?.message;
               
          //       })
          //       return
          //   }
          //   this.loadtielist=false;
          //   this.errloadtielist=resp?.data?.message;
          // })
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

  //Group Deatails
  getGroupDeatails = async(id) => {
    runInAction(() => {
      // this.loadMatch = true;
      this.errloadMatch = "";
      this.saved = false;
    });
    await getMethodData(`/api/user/chat-details/${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadMatch = false;
            this.errloadMatch = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.GetgrpName(resp?.data?.data?.name)
            // this.grpPlayerName = resp?.data?.data?.users;
            // this.grpPlayer(resp?.data?.data?.users)
            this.grp_Id = resp?.data?.data?.roomId;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

  //Match Standing
  getStanding = async() => {
    runInAction(() => {
      this.loadMatch = true;
      this.errloadMatch = "";
      this.saved = false;
    });
    await getMethodData(
      `/api/user/my-schedule-players-rank?filters[league]=${this.leagueID}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadMatch = false;
            this.errloadMatch = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loadMatch = false;
            this.errloadMatch = resp?.data?.message;
           
            this.standing = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };
  //PlayOff Matches
  getPlayOff = async(playOf,levelid) => {
    runInAction(() => {
      this.loadMatch = true;
      this.errloadMatch = "";
      this.saved = false;
    });
    await getMethodData(
      `/api/user/playoff/matches?filters[league]=${this.leagueID}&filters[round]=${playOf}&filters[level]=${levelid}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.notificationNo = false;
            this.loadMatch = false;
            this.errloadMatch = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.notificationNo = true ;
            this.loadMatch = false;
            this.errloadMatch = resp?.data?.message;
            this.ObjectPlayOff= resp?.data?.data;
            this.PlayOffs = resp?.data?.data;
            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            // toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };

  getMatchDate = async(fromyear, frommonth) => {
    // console.log("===>>", year,month)
    // return
    runInAction(() => {
      // this.loadMatch = true;
      this.loadDates = true;
      this.errloadMatch = "";
      this.saved = false;
    });
    await getMethodData(
      `/api/user/get-group-calendar-dates?filters[match]=${
        this.matchid
      }&filters[month]=${
        frommonth
      //  ` ${this.addMonth == true  ? frommonth  : frommonth  } `  
      }&filters[year]=${fromyear}&filters[users]=${JSON.stringify(
        this.userID
      )}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadMatch = false;
            this.loadDates = true;
            this.errloadMatch = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          // console.log(d);
          runInAction(() => {
            this.loadMatch = false;
            this.loadDates = false;
            this.errloadMatch = resp?.data?.message;
            this.calenderdate = [];
            for (const date of resp.data.data) {
              this.calenderdate.push(format(new Date(date), `yyyy,M,d`));
            }
            // this.calenderdate = {...resp.data.data}

            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.loadDates = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          this.loadDates = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

  
  // const isoString = ;
  // Post Date
  PostDate = async(date, time , settime) => {
    // console.log("puspose time",settime);
    const originalDate = new Date(date); 
    var d = new Date(date); 

    // console.log("originalDate",originalDate , "new date", d);

    const originalMoment = moment(originalDate);
    const t= d.toISOString('en-US', { timeZone: TIMEZONE_URL }); 
    // console.log("yash",t);
     // Convert the moment object to Etc/GMT timezone
  const etcGmtMoment = originalMoment.tz('Etc/GMT');
  const formattedDate = etcGmtMoment.format('YYYY-MM-DD HH:mm:ss');
  // console.log("raj",formattedDate);
    runInAction(() => {
      this.loaderstartsetdate = true;
      this.addloaduser = true;
      this.erradduser = "";
      this.notificationNo =false;

    });
    await postMethodData(
      "/api/user/create-match-schedule",
      {
        match: this.matchid,
        schedule_date: date,
        schedule_time: settime,
        home_court: this.homename,
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.time=false;
            this.loaderstartsetdate = false;
            this.notificationNo =false;
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.time=false;
            this.addMonth == false; 
            this.getpurpose = [];
            this.getpurposetym = [];
            this.loaderstartsetdate = false;
            this.notificationNo =true;
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            toast.success(resp.data.message);
            this.saved = true;
            this.popisOpen = false;
          });
          this.getScheduled();
         
          return;
        }
        runInAction(() => {
          this.time=false;
          this.loaderstartsetdate = false;
          this.addloaduser = false;
          this.erradduser = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.time=false;
          this.loaderstartsetdate = false;
          if (!error.response) {
            this.addloaduser = false;
            this.erradduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.message);
          this.addloaduser = false;
          this.erradduser = error?.response?.data?.message;
        });
        return;
      });
  };
  //Purpose Date

  PurposeDate = async(date,TeamId) => {
    console.log("date check",date);
    runInAction(() => {
      this.isloader=true;
      this.messgae_initate = false;
      this.loaderstart=true;
      this.addloaduser = true;
      this.PurposeBtnLoader=true;
      this.erradduser = "";
      this.Purposecheck = false;
    });
    await postMethodData(
      "/api/user/save-match-propose-dates",
      {
        match: this.matchid,
        dates: date,
        proposedBy: TeamId
        // schedule_time: moment().unix()
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loaderstart=false;
            this.isloader=false;
            this.notificationNo = false;
            this.addloaduser = false;
            this.PurposeBtnLoader=true;
            this.erradduser = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.loaderstart=false;
            this.isloader=false;
            this.messgae_initate=true;
            this.addMonth == false; 
            this.notificationNo = true;
            this.addloaduser = false;
            this.PurposeBtnLoader=false;
            this.erradduser = resp?.data?.message;
            this.Purposecheck = true;
            toast.success(resp.data.message);
            this.saved = true;
            this.popisOpen = false;
          });

          this.getScheduled();
   
          this.getPlayOff(this.getPlayOfs,this.Schedule[0]?.level);
          return;
        }
        runInAction(() => {
          this.messgae_initate=false;
          this.loaderstart=false;
          this.addloaduser = false;
          this.Purposecheck = false;
          this.erradduser = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loaderstart=false;
          this.isloader=false;

          this.messgae_initate=false;
          if (!error.response) {
            this.addloaduser = false;
            this.Purposecheck = false;
            this.erradduser = error?.message;
            // toast.error(error?.message);
          }
          toast.error(error?.message);
          this.addloaduser = false;
          this.erradduser = error?.response?.data?.message;
        });
        return;
      });
  };

  //get match details

  getMatchdetails = async(id) => {
    this.matchidslect = id;
    runInAction(() => {
      // this.loadMatch = true;
      this.errloadMatch = "";
      this.saved = false;
    });
    await getMethodData(`/api/user/get-match-details/${id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadMatch = false;
            this.loadMatchHistory= true;

            this.errloadMatch = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
             this.loadMatch = false;
             this.loadMatchHistory= false;
            this.errloadMatch = resp?.data?.message;
            this.matchdetails = resp?.data?.data;
            this.metchid = resp?.data?.data?._id;
            this.opponent = resp?.data?.data.opponent_team?.players;
            // this.grpPlayerName =resp?.data?.data.opponent_team?.players;
            this.mate = resp?.data?.data.my_team?.players;
            this.homecourt = resp?.data?.data?.home_court?._id;
            this.myteamid = resp?.data?.data?.my_team?._id;
            this.oppteamid = resp?.data?.data?.opponent_team?._id;

            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          this.loadMatchHistory= true;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

  // Post match score
  PostMatchscore = async(id) => {
    runInAction(() => {
      this.addloaduser = true;
      this.erradduser = "";
      this.buttondisable = true;
      this.notificationNo =false;
    });
    await postMethodData(
      "/api/user/save-match-score",
      {
        match: this.metchid,
        home_court: this.homename,
        result: this.radiovalue || this.forfietvalue || this.withdrawvalue,
        forfiet_by: this.myforfietvalue || this.oppforfietvalue,
        Withdraw_by: this.mywithdrawvalue || this.oppwithdrawvalue,
        team_first_score: {
          team: this.myteamid,
          set_one: parseInt(this.mysetone),
          set_two: parseInt(this.mysettwo),
          set_three: parseInt(this.mysetthree),
        },
        team_second_score: {
          team: this.oppteamid,
          set_one: parseInt(this.oppsetone),
          set_two: parseInt(this.oppsetwo),
          set_three: parseInt(this.oppsetthree),
        },
      },
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.notificationNo = false 
            this.buttondisable = false;
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            this.notificationNo = true;
            this.buttondisable = false;
            this.addloaduser = false;
            this.erradduser = resp?.data?.message;
            this.getScheduled();
           
            this.getLeaguesid(id);
            this.getDynamicPlayOff(this.leagueID);
            this.getStanding();
            this.getPlayOff(this.getPlayOfs,this.Schedule[0]?.level);
            toast.success(
              "Success! Standings have been updated."
             );
            this.saved = true;
            this.newpop = false;
            this.opensubmit = false;
            this.openforfietpopup = false;
            this.openwithdrawpopup = false;
          });
          return;
        }
        runInAction(() => {
          this.addloaduser = false;
          this.erradduser = resp?.data?.message;
          toast.error(resp.data.message);
        });
      })
      .catch((error) => {
        runInAction(() => {
          if (!error.response) {
            this.addloaduser = false;
            this.erradduser = error?.message;
            toast.error(error?.message);
          }
          toast.error(error?.message);
          this.addloaduser = false;
          this.erradduser = error?.response?.data?.message;
        });
        return;
      });
  };
  getPurposeDate = async(id) => {
    runInAction(() => {
      this.loadDates = true;
      this.errloadMatch = "";
      this.saved = false;
      this.notificationNo = false
    });
    await getMethodData(
      `/api/user/get-match-propose-dates?filters[match]=${id}`,
      this.Token
    )
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            this.loadMatch = false;
            this.loadDates = true;
            this.errloadMatch = resp?.data?.message;
          });
          return;
        }
        if (resp?.data?.status === true) {
          // console.log(d);
          runInAction(() => {
            this.loadMatch = false;
            this.loadDates = false;
            this.errloadMatch = resp?.data?.message;
            this.getpurpose = [];
            this.getpurposetym = [];

            for (const date of resp.data.data) {
              this.getpurpose.push(format(new Date(date?.date), `yyyy,M,d`));
              this.getpurposetym.push(date);
            }

            this.saved = false;
            // this.status = true;
          });
          return;
        }
        runInAction(() => {
          this.loadseason = false;
          this.loadDates = false;
          this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          this.loadseason = false;
          this.loadDates = false;
          if (!error.response) {
            this.errloadseason = error?.message;
            toast.error(error?.message);
          }
          this.errloadseason = error?.response?.data?.message;
          toast.error(error?.message);
        });
        return;
      });
  };

  getPlayoffData = async () => {

    runInAction(() => {
      this.obj = {};
      this.playoffObj = {};
      this.NextMonth=false;
    });
    for await (const v of this.DynamicPlayOf) {
      const getDetails = await getMethodData(
        `/api/user/playoff/matches?filters[league]=${v.league}&filters[round]=${v.round_name}&filters[level]=${v.level}`,
        localStorage.getItem("token")
      );
      const responseData = getDetails.data.data;
      this.NextMonth=false;
      if(getDetails.data.data){
         this.NextMonth=true;
      }
      this.playoffObj = getDetails.data.data 
      // this.setPlayoffObj(getDetails.data.data) 
      
      runInAction(() => {
        this.obj[v.round_name] = responseData;
      });
    }
  
    return this.obj;
  }
  

  gethomecourtdropdown = async() => {
    runInAction(() => {
      // this.loaduser = true;
      this.errloadMatch = "";
      //   this.saved = false;
    });
    await getMethodData(`/api/get/home-courts`, this.Token)
      // getMethodData(`/api/get/home-courts/${cityid}?filters[user]=${this.user._id}`, this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.loaduser = false;
            this.errloadMatch = resp?.data?.message;
            // toast.error(resp.data.message);
          });
          return;
        }
        if (resp?.data?.status === true) {
          runInAction(() => {
            // this.loaduser = false;
            this.errloadMatch = resp?.data?.message;
            // toast.success(resp?.data?.message);
            this.homecourtdrop = resp?.data?.data;
          });
          return;
        }
        runInAction(() => {
          //   this.loaduser = false;
          this.errloadMatch = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
          //   this.loaduser = false;
          if (!error.response) {
            this.errloadMatch = error?.message;
            // toast.error(error?.message);
          }
          this.errloadMatch = error?.response?.data?.message;
          // toast.error(error?.message);
        });
        return;
      });
  };

fetchData=(Listid)=>{
  runInAction(() => {
    // this.loaduser = true;
    this.errloadMatch = "";
    //   this.saved = false;
  });
  getMethodData(`/api/user/chat/${Listid}`, this.Token)
    // getMethodData(`/api/get/home-courts/${cityid}?filters[user]=${this.user._id}`, this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          // this.loaduser = false;
          this.errloadMatch = resp?.data?.message;
          this.CheckHistory=false;
          // toast.error(resp.data.message);
        });
        return;
      }
      
      if (resp?.data?.status === true) {
        // runInAction(() => {
          this.CheckHistory=true
        // });
        return;
      }
   
      runInAction(() => {
        //   this.loaduser = false;
        this.errloadMatch = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
        //   this.loaduser = false;
        if (!error.response) {
          this.errloadMatch = error?.message;
          // toast.error(error?.message);
        }
        this.errloadMatch = error?.response?.data?.message;
        // toast.error(error?.message);
      });
      return;
    });
}
//MapKey
getSetting = async() => {
  runInAction(() => {
    this.errloadMatch = "";
  });
  await  getMethodData("/api/get/setting/GOOGLE_MAP_API_KEY",this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.errloadMatch = resp?.data?.message;
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.errloadMatch = resp?.data?.message;
          // if (resp?.data?.data.length === 0) {
          //   this.nolist = true;
          // } else {
          //   this.nolist = false;
          // }
          // this.status = true;
          this.MapKey = resp.data.data.value;
        });
        return;
      }
      runInAction(() => {
        this.errloadMatch = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
        if (!error.response) {
          this.errloadMatch = error?.message;
          // toast.error(error?.message);
        }
        this.errloadMatch = error?.response?.data?.message;
        // toast.error(error?.message);
      });
      return;
    });
};

//Player Match History
GetMatchHistory = async(userid,leagueid) => {
  runInAction(() => {
    this.loadMatchHistory= true;
    this.errloadMatch = "";
    this.saved = false;
    this.nohistorylist = false;
    this.MatchHistory= [];
    // this.leaguesiddata = "";
  });
  await getMethodData(`/api/season/matches/${userid}?filters[league]=${leagueid}`, this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.leaugesfalse = false;
          this.loadMatch = false;
          this.loadMatchHistory= true;
          this.nohistorylist = false;
          // this.leaguesiddata = "";
          this.errloadMatch = resp?.data?.message;
        });
        return;
      }
 
      if (resp?.data?.status === true) {
        runInAction(() => {
         this.MatchHistory=resp?.data?.data;
         this.loadMatchHistory= false;
          if (resp?.data?.data.length === 0) {
            this.nohistorylist = true;
          } else {
            this.nohistorylist = false;
          }

        });
        return;
      }

      runInAction(() => {
        this.leaugesfalse = false;
        this.loadMatch = false;
        this.errloadMatch = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
        this.leaugesfalse = false;
        this.loadMatchHistory= true;
        this.loadMatch = false;
        if (!error.response) {
          this.errloadMatch = error?.message;
          toast.error(error?.message);
        }
        this.errloadMatch = error?.response?.data?.message;
        toast.error(error?.message);
      });
      return;
    });
};
// tree api

getPlayersTree = async(id,level) => {
  if(level == undefined){
    runInAction(()=> {
      this.playertreenolist = true;
    })
    return
  }
  runInAction(() => {
    this.playertreeload = true;
    this.errplayertreeload = "";
    this.playertreenolist = false;
  });
  
  await getMethodData(`/api/playoff/matches/chart?filters[league]=${id}&filters[level]=${level}`, this.Token)
    .then((resp) => {
      if (resp?.data?.status === false) {
        runInAction(() => {
          this.playertreeload = false;
          this.errplayertreeload = resp?.data?.message;
        });
        return;
      }
      if (resp?.data?.status === true) {
        runInAction(() => {
          this.playertreeload = false;
          this.errplayertreeload = resp?.data?.message;
          if (resp?.data?.data?.data?.length === 0) {
            this.playertreenolist = true;
          } else {
            this.playertreenolist = false;
          }
          this.playertreedata = resp?.data?.data?.data;
          this.totalRounds = resp?.data?.data?.totalRounds;
          // this.status = true;
        });
        return;
      }
      runInAction(() => {
          this.playertreeload = false;
          this.playertreenolist = false;
          this.errplayertreeload = resp?.data?.message;
      });
    })
    .catch((error) => {
      runInAction(() => {
        this.playertreeload = false;
        if (!error.response) {
          this.errplayertreeload = error?.message;
          toast.error(error?.message);
        }
        this.errplayertreeload = error?.response?.data?.message;
        toast.error(error?.message);
      });
      return;
    });
};

}

export const MyMatchStoreContext = createContext(new MyMatchStore());
export const useMyMatchStore = () => useContext(MyMatchStoreContext);
