/* eslint-disable jsx-a11y/alt-text */
import { observer } from "mobx-react";
import { useForm, Controller, useController } from "react-hook-form";
import { AuthStore, AuthStoreContext, useAuthStore } from "./store/Auth";
import { Link, useNavigate } from "react-router-dom";
import profilebg from "../assets/image/profilebg.webp";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import Input, { isValidPhoneNumber } from "react-phone-number-input/input";
import React, { useRef } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useUpcomingLeagueStore } from "../UpcomingLeague/Store/UpcomingLeagueStore";
import TermsCondition from "../Common/TermsCondition";
import PrivacyPolicy from "../Common/PrivacyPolicy";
import Select from "react-select";
import { usePlacesWidget } from "react-google-autocomplete";
import axios from "axios";
import { toast } from "react-toastify";

// import moment from "moment";
// import Moment from "moment";
// const ProfileComplete = observer(() => {
//     return(
//       <AuthStoreContext.Provider value={new AuthStore({

//       })}>
//         <Screen/>
//       </AuthStoreContext.Provider>
//     )
//   })
//   export default ProfileComplete;

const ProfileComplete = observer(() => {
  const {
    authapproved,
    LatLng,
    isEmailverfied,
    dobusererr,
    getaddress,
    SetaddressCheck,
    setdobuser,
    addressCheck,
    address,
    signResponse,
    errPassword,
    updateProfile,
    loginLoading,
    getcitydropdown,
    citydrop,
    upcomingleauge,
    gethomecourtdropdown,
    homecourtdrop,
    getleveldropdown,
    leveldropdown,
    signupemailonly,
    newpassword,
    emailChanged,
    isChecked,
    loginNoEmail,
    passwordChanged,
    currentpassword,
    getcurrentpassword,
    getnewpassword,
    isEmailverfiedchange,
    output,
    getinput,
    showinput,
    statedrop,
    getstatedropdown,
    getApiKey,
    getLongName,
    getFormatedAddress,
    getZipcode,
    getlatitude,
    getlongitude,
    LongName,
    FormatedAddress,
    zipCode,
    mylatitude,
    mylongitude,
    setCityName,
    setLatLng,
    CityName,
    keysss,
    upcomingcity,
    user
  } = useAuthStore();
  const {
    setValue,
    register,
    handleSubmit,
    watch,
    control,
    reset,
    resetField,
    formState,
    trigger,
    formState: { errors },
  } = useForm();
  const {
    field: {
      value: langValue,
      onChange: homeCourtnameChange,
      ...restLangField
    },
    fieldState: { error: langError },
  } = useController({
    name: "homeCourt",
    control,
    rules: {
      required: !showinput && "This field is required",
    },
  });
  const {
    field: {
      value: CoustomHomeCourtValue,
      onChange: CoustomHomeCourt,
      ...restCoustomHomeCourt
    },
    fieldState: { error: CoustomHomeCourtError },
  } = useController({
    name: "homeCourt_name",
    control,
    rules: {
      required: showinput && "This field is required",
    },
  });
  const {
    field: { onChange: SetHC_displayname },
  } = useController({ name: "displayName", control });
  const {
    field: { onChange: SethomeCourt_lat },
  } = useController({ name: "homeCourt_lat", control });
  const {
    field: { onChange: SethomeCourt_lng },
  } = useController({ name: "homeCourt_long", control });
  const {
    field: { onChange: SethomeCourt_zipcode },
  } = useController({ name: "homeCourt_zipcode", control });
  const {
    field: { onChange: SethomeCourt_address },
  } = useController({ name: "homeCourt_address", control });
  // const { errors } = formState;

  const [homeerror, sethomeerror] = useState("");

  const navigate = useNavigate();
  const [showpassword, setshowpassword] = useState(false);
  const [confirmpassword, setconfirmpassword] = useState(false);
  const { UpcomingLeagueid } = useUpcomingLeagueStore();
  const Mapkey = localStorage.getItem("Mapkey");
  const [charCheck, set3charCheck] = useState();
  const [charlength, setcharlength] = useState();
  const [HomeCourtValid, setHomeCourtValid] = useState();

  useEffect(()=>{
    const isRefresh = window?.performance && window?.performance?.navigation?.type === 1;
    if(isRefresh){
      navigate("/")   
    }

  },[])

  // console.log(showinput)
  useEffect(() => {
    
    if (authapproved) {
      if (upcomingleauge &&  upcomingcity == user?.city) {
        navigate(`/register/${UpcomingLeagueid}`);
      } else {
        navigate("/");
      }
    }
    if (isEmailverfied) {
      navigate("/verify");
    }
    if (!isChecked) {
      emailChanged("");
      passwordChanged("");
    }
    if (loginNoEmail) {
      navigate("/signup");
    }
  }, [authapproved, isChecked, isEmailverfied, loginNoEmail, UpcomingLeagueid]);
  useEffect(() => {
    getcitydropdown();
    getApiKey();
    getleveldropdown();
    getstatedropdown();
    isEmailverfiedchange(false);
    // if(!isEmailverfied){
    //     navigate("/login")
    // }
  }, [authapproved, isEmailverfied]);

  const handlescorll = () => {
    const element = document.querySelector(".passwordinput");
    if (element) {
      element.scrollIntoView({ top: 50, behavior: "smooth" });
    }
  };
  useEffect(() => {
    if (!errPassword == "") {
      handlescorll();
    }
  }, [errPassword]);

  const handlePlaceSelected = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${localStorage.getItem(
          "Mapkey"
        )}`
      );

      const { status, results } = response.data;
      // Extract the zip code from the results
      let zipCode = "";
      if (status === "OK" && results.length > 5) {
        set3charCheck(false);
        setHomeCourtValid(false);

      }
      else {
        // Address is invalid
        console.error("Error while geocoding:", "error");
        setHomeCourtValid(true);

        // Handle the invalid address as needed
      }
      for (let i = 0; i < results.length; i++) {
        const addressComponents = results[i].address_components;
        for (let j = 0; j < addressComponents.length; j++) {
          const component = addressComponents[j];
          if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
            getZipcode(zipCode);
            break;
          }
        }
        if (zipCode) {
          break;
        }
      }

      // You can use the zipCode variable as per your requirements
    } catch (error) {
      console.error("Error fetching zip code:", error);
      setHomeCourtValid(true);

    }
  };
  const AddressCheck = (address) => {
    // Perform address validation
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${Mapkey}`
      )
      .then((response) => {
        const { status, results } = response.data;
        set3charCheck(false);

        if (status === "OK" && results.length > 5) {
          setHomeCourtValid(false);

        } else {
          // Address is invalid
          console.error("Error while geocoding:", "error");
          setHomeCourtValid(true);

          // Handle the invalid address as needed
        }
      })
      .catch((error) => {
        console.error("Error while geocoding:", error);
        getZipcode(null);
        setHomeCourtValid(true);
      });
  };
  //   const [TextInputPhone,setTextInputPhone] = useState("")

  // const formatPhoneNumber = (value) => {
  //     setValue("mobile", value.replace(/[^\d]/g, ""));
  // };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isHcpopup, setIsHcpopup] = useState(false);
  // const placesService = new google.maps.places.PlacesService(
  //   document.createElement("div")
  // );
  const [ishome, setIsHome] = useState("");

  // const validateDate = (value) => {
  //   const selected = new Date(value).getFullYear();
  //   const now = new Date().getFullYear();
  //   return now - selected > 18;
  // };
  const [btndiable, setBtndiable] = useState("");
  const [homeAddress, sethomeAddress] = useState("");
  const [mobileno, setMobileno] = useState();
  const [newmobileno, setnewmobilenoMobileno] = useState();

  

  const handleMobileInput = (value) => {
    setnewmobilenoMobileno(value)
    if(value == "+1"){
      setMobileno(undefined)
    }
    // console.log("mobileno",value)
    // setnewmobilenoMobileno(value)
    // // console.log("seconddddddd",value)
    // if(mobileno == undefined){
    //     if(value == "+1" || value == "1"){
    //       console.log("value==============",value)
    //       setMobileno(undefined)
    //       return
    //     }
    //     else{
    //       setMobileno(value)
    //     }
    // }else if (mobileno == ""){
    //   if(value == "+1" || value == "1"){
    //     setMobileno(undefined)
    //     return
    //   }
    //   else{
    //     setMobileno(value)
    //   }
    // };
  }

  useEffect(() => {
    handleMobileInput(newmobileno)
  },[mobileno,newmobileno])

  const selectRef = useRef(null);
  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.blur();
    }
  }, []);

  useEffect(() => {
    handleInputChange(address);
  }, [address, zipCode]);
  const atlantaBounds = {
    south: 33.6471, // Southwest corner latitude of Atlanta
    west: -84.5493, // Southwest corner longitude of Atlanta
    north: 33.8879, // Northeast corner latitude of Atlanta
    east: -84.2895, // Northeast corner longitude of Atlanta
  };
  const inputRef = useRef(null);
  var ref = ""
  if (LatLng === undefined || LatLng?.length === 0 ||  LatLng === null || LatLng?.east === null || LatLng?.south === null ) {
    var { ref } = usePlacesWidget({
      apiKey: Mapkey,
      onPlaceSelected: (place) => {
        // Handle the selected place
        getlatitude(place.geometry.location.lat());
        getlongitude(place.geometry.location.lng());
        getLongName(place.address_components[0]?.long_name);
        getFormatedAddress(place.formatted_address);
        handlePlaceSelected(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
      },
      options: {
        types: ["geocode", "establishment"],
      },
      ref: inputRef,
    });
  }
  else{
    var { ref } = usePlacesWidget({
      apiKey: Mapkey,
      onPlaceSelected: (place) => {
        // Handle the selected place
        getlatitude(place.geometry.location.lat());
        getlongitude(place.geometry.location.lng());
        getLongName(place.address_components[0]?.long_name);
        getFormatedAddress(place.formatted_address);
        handlePlaceSelected(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );
      },
      options: {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: "US" }
        // bounds: new google.maps.LatLngBounds(
        //   new google.maps.LatLng(LatLng?.south, LatLng?.west), // Southwest corner of Atlanta
        //   new google.maps.LatLng(LatLng?.north, LatLng?.east) // Northeast corner of Atlanta
        // ),
        // strictBounds: true,
      },
      ref: inputRef,
    });
  }
 

  function getMaxDate() {
    const today = new Date();
    today.setDate(today.getDate() - 1);

    // Adjust the date if it falls on February 29 in a leap year
    if (isLeapYear(today.getFullYear()) && today.getMonth() === 1 && today.getDate() === 29) {
        today.setDate(28); // Move to February 28
    }

    const maxYear = today.getFullYear() - 18;
    const maxMonth = today.getMonth() + 1;
    const maxDay = today.getDate();
    return `${maxYear}-${maxMonth < 10 ? `0${maxMonth}` : maxMonth}-${
        maxDay < 10 ? `0${maxDay}` : maxDay
    }`;
}

function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

  function handleInputChange(e) {
    CoustomHomeCourt(e?.split(",")[0]?.trim());
    SetHC_displayname(e?.split(",")[0]?.trim());
    SethomeCourt_zipcode(zipCode);
    SethomeCourt_lat(mylatitude);
    SethomeCourt_lng(mylongitude);
    SethomeCourt_address(FormatedAddress);
  }
  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  const clearzip = () => {
    // getZipcode(null)
  };
  const handleKeyPress = (e) => {
    if (e.key === '0') {
      if (e.target.value.length === 0) {
        e.preventDefault(); // Prevent input of '0' as the first character
      }
    }
  };
  return (
    <div className="relative py-9 min-h-[100vh] border flex items-center">
      <img
      alt=""
        className="absolute h-full z-0 top-0 left-0  w-full object-cover object-top"
        src={profilebg}
      />
      <div className="w-full 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-1">
        <div className="flex flex-wrap justify-center">
          <div className="2xl:w-[80%] lg:w-[80%] md:w-[90%] w-full 2xl:max-w-[90%] ">
            <div className=" bg-[black] rounded-lg lg:px-10 md:px-5 px-4 md:pt-20 py-10">
              <form
                autoComplete="new-password"
                onSubmit={handleSubmit(updateProfile)}
              >
                <div className=" text-center">
                  <p className=" text-[#fff] font-medium md:text-3xl text-2xl  relative">
                    Complete your Profile to continue
                  </p>
                </div>
                <div className="grid gap-8 md:grid-cols-2 mt-20">
                  <div className="col-span-1">
                    <label htmlFor="firstName" className=" text-green text-xs">
                      First Name
                    </label>
                    <input
                      defaultValue={signResponse?.user?.firstName}
                      {...register("firstName", { required: true })}
                      name="firstName"
                      autoComplete="FirstName"
                      id="firstName"
                      // required
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none capitalize"
                      placeholder="First Name"
                      type="text"
                      onChange={(e) => {
                        const capitalizedValue =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1);
                        setValue("firstName", capitalizedValue);
                        trigger("firstName"); // Manually trigger validation
                      }}
                    />
                    {errors.firstName && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )}
                  </div>
                  <div>
                    <label htmlFor="lastName" className=" text-green text-xs">
                      Last Name
                    </label>
                    <input
                      defaultValue={signResponse?.user?.lastName}
                      {...register("lastName", { required: true })}
                      name="lastName"
                      autoComplete="LastName"
                      id="lastName"
                      // required
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none capitalize"
                      placeholder="Last Name"
                      type="text"
                      onChange={(e) => {
                        const capitalizedValue =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1);
                        setValue("lastName", capitalizedValue);
                        trigger("lastName"); // Manually trigger validation
                      }}
                    />
                    {errors.lastName && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )}
                  </div>

                  <div>
                    <label htmlFor="email" className=" text-green text-xs">
                      Email
                    </label>
                    <input
                      // value={signResponse.email}
                      // {...register("email", { required: true })}
                      defaultValue={signResponse?.user?.email}
                      name="email"
                      autoComplete="email"
                      // required
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      placeholder="Email"
                      id="email"
                      readOnly
                      type="email"
                    />
                  </div>
                  <div>
                    <label htmlFor="mobile" className=" text-green text-xs">
                      Phone Number
                    </label>
                    <Input
                      name="mobile"
                      inputProps={{
                        autoComplete: "off",
                      }}
                      // defaultValue=""
                      // onChange={(value) => setValue("mobile", value.replace(/[^\d]/g, ""))}
                      onKeyDown={handleKeyPress}
                      {...register("mobile", {
                        onChange: (e) => handleMobileInput,
                        required: true,
                        minLength:14,
                        maxLength:14
                      })}
                      id="mobile"
                      onChange={(e) => {handleMobileInput(e)}}
                      value={mobileno}
                      defaultCountry="US"
                      // onChange={()=>trigger("mobile")}
                      minLength={14}
                      maxLength={14}
                      placeholder="Phone Number"
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    />
                    {/* {console.log(mobileno)} */}
                    {mobileno && mobileno.length < 12 && errors.mobile && errors.mobile.type == "minLength" && 
                    <span className=" text-[red] text-xs">
                        Mobile Number Should be of 10 digits.
                      </span>
                    }
                    {errors.mobile && !mobileno && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )}
                  </div>

                  {signupemailonly && (
                    <>
                      <div>
                        <label
                          htmlFor="password"
                          className=" text-green text-xs passwordinput"
                        >
                          Password
                        </label>
                        <label className="relative">
                          <input
                            defaultValue={newpassword}
                            // defaultValue=""
                            {...register("password", {
                              onChange: (e) => getnewpassword(e.target.value),
                              required: signupemailonly ? false : true,
                            })}
                            name="password"
                            // onChange={(e) => {
                            //   getnewpassword(e.target.value);
                            // }}
                            autoComplete="new-password"
                            // required
                            id="password"
                            className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                            placeholder="Password"
                            type={showpassword ? "text" : "password"}
                          />
                          <span
                            className=" cursor-pointer absolute right-4 top-0"
                            onClick={() => setshowpassword(!showpassword)}
                          >
                            {showpassword ? (
                              <RxEyeOpen className=" text-[#837575]" />
                            ) : (
                              <RxEyeClosed className=" text-[#837575]" />
                            )}
                          </span>
                        </label>
                        {errors.password && (
                          <span className=" text-[red] text-xs">
                            This field is required
                          </span>
                        )}
                        {errPassword && (
                          <span className=" text-[red] text-xs">
                            {errPassword}
                          </span>
                        )}
                      </div>

                      <div>
                        <label
                          htmlFor="confirmpassword"
                          className=" text-green text-xs passwordinput"
                        >
                          Confirm Password
                        </label>
                        <label className=" relative">
                          <input
                            name="confirmpassword"
                            id="confirmpassword"
                            defaultValue={currentpassword}
                            {...register("confirmpassword", {
                              onChange: (e) =>
                                getcurrentpassword(e.target.value),
                              required: signupemailonly ? false : true,
                            })}
                            // onChange={(e) => {
                            //   getcurrentpassword(e.target.value);
                            // }}
                            autoComplete="confirm-password"
                            // required
                            className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                            placeholder="Confirm Password"
                            type={confirmpassword ? "text" : "password"}
                          />
                          <span
                            className=" cursor-pointer absolute right-4 top-0"
                            onClick={() => setconfirmpassword(!confirmpassword)}
                          >
                            {confirmpassword ? (
                              <RxEyeOpen className=" text-[#837575]" />
                            ) : (
                              <RxEyeClosed className=" text-[#837575]" />
                            )}
                          </span>
                        </label>
                        {errors.confirmpassword && (
                          <span className=" text-[red] text-xs">
                            This field is required
                          </span>
                        )}
                        {errPassword && (
                          <span className=" text-[red] text-xs">
                            {errPassword}
                          </span>
                        )}
                      </div>
                    </>
                  )}

                  <div>
                    <label htmlFor="dob" className=" text-green text-xs">
                      DOB
                    </label>
                    <input
                      defaultValue={signResponse?.user?.dob}
                      {...register("dob", {
                        onChange: (e) => {
                          setdobuser(e.target.value);
                        },
                        required: true,
                        // validate:validateDate
                      })}
                      name="dob"
                      id="dob"
                      autoComplete="dob"
                      placeholder="DOB"
                      max={getMaxDate()}
                      // min={"02-01-1855"}
                      // max={"02-02-2000"}
                      // max={new Date(Moment().subtract(18, "years"))}
                      // required
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      // placeholder="DOB"
                      type="date"
                    />
                    {errors?.dob?.type === "required" && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )}
                    {dobusererr && (
                      <span className=" text-[red] text-xs">
                        Age Should Be 18+
                      </span>
                    )}
                    {/* {errors?.dob?.type === "validate" &&(  <span className=" text-[#ffef00] text-xs">Age Should Be 18+ </span>  )} */}
                  </div>

                  <div>
                    <label htmlFor="gender" className=" text-green text-xs">
                      Gender
                    </label>
                    <select
                      defaultValue=""
                      {...register("gender", { required: true })}
                      name="gender"
                      id="gender"
                      // required
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    >
                      <option value="" className="hidden">
                        Gender
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {errors.gender && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="address_line1"
                      className=" text-green text-xs"
                    >
                      Street Address 1
                    </label>
                    <input
                      // defaultValue={signResponse?.user?.address_line1}
                      {...register("address_line1", { required: true })}
                      name="address_line1"
                      autoComplete="address_line1"
                      id="address_line1"
                      // required
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      placeholder="Street Address 1"
                      type="text"
                    />
                    {errors.address_line1 && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="address_line2"
                      className=" text-green text-xs"
                    >
                      Street Address 2
                    </label>
                    <input
                      // defaultValue={signResponse?.user?.address_line2}
                      {...register("address_line2")}
                      name="address_line2"
                      autoComplete="address_line2"
                      id="address_line2"
                      // required
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      placeholder="Street Address 2"
                      type="text"
                    />
                    {/* {errors.address_line2 && (
                      <span className=" text-[#ffef00] text-xs">
                        This field is required
                      </span>
                    )} */}
                  </div>

                 

                  <div>
                    <label
                      htmlFor="address_city"
                      className=" text-green text-xs"
                    >
                      City
                    </label>
                    <input
                      // defaultValue={signResponse?.user?.address_city}
                      {...register("address_city", { required: true })}
                      name="address_city"
                      autoComplete="address_city"
                      id="address_city"
                      // required
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      placeholder="City"
                      type="text"
                    />
                    {errors.address_city && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )}
                  </div>
                   <div>
                    <label
                      htmlFor="address_state"
                      className=" text-green text-xs"
                    >
                      State
                    </label>
                    <select
                      defaultValue=""
                      {...register("address_state", { required: true })}
                      // onChange={(e) => {
                      //   gethomecourtdropdown(e.target.value);
                      // }}
                      name="address_state"
                      id="address_state"
                      // required
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    >
                      <option value="" disabled className="hidden">
                        State
                      </option>
                      {statedrop.map((data, index) => (
                        <option value={data?._id} key={index}>
                          {data?.name}
                        </option>
                      ))}
                    </select>
                    {errors.address_state && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="address_zip"
                      className=" text-green text-xs"
                    >
                      Zipcode
                    </label>
                    <input
                      // defaultValue={signResponse?.user?.zip}
                      {...register("address_zip", { required: true })}
                      name="address_zip"
                      autoComplete="address_zip"
                      id="address_zip"
                      maxLength={5}
                      minLength={5}
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      placeholder="Zipcode"
                      type="text"
                    />
                    {errors.address_zip && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="address_country"
                      className=" text-green text-xs"
                    >
                      Country
                    </label>
                    <select
                      defaultValue=""
                      {...register("address_country", { required: true })}
                      // onChange={(e) => {
                      //   gethomecourtdropdown(e.target.value);
                      // }}
                      name="address_country"
                      id="address_country"
                      // required
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    >
                      <option value="USA">USA</option>
                    </select>

                    {errors.address_country && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )}
                  </div>

                  <div>
                    <label htmlFor="city" className=" text-green text-xs">
                      League City
                    </label>
                    <select
                      defaultValue=""
                      {...register("city", {
                        onChange: (e) => {
                          setBtndiable(e.target.value);
                          const selectedOption = e.target.options[e.target.selectedIndex];
                          const boundKey = selectedOption?.getAttribute("boundKey"); // Access the bound key attribute
                          const boundKeyArray = JSON.parse(boundKey); // Parse the bound key string back to an array
                          const content = selectedOption?.getAttribute("content");
                          setCityName(content);
                           setLatLng(boundKeyArray);
                          // console.log(boundKeyArray);
                          gethomecourtdropdown(e.target.value, "");
                        },
                        required: true,
                      })}
                      // onChange={(e) => {
                      //   gethomecourtdropdown(e.target.value);
                      // }}
                      name="city"
                      id="city"
                      // required
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    >
                      <option value="" disabled className="hidden">
                        League City
                      </option>
                      {citydrop.map((data, index) => (
                        <option
                          value={data?._id}
                          content={data?.city_name}
                          boundKey={JSON?.stringify(data?.bound)} 
                          key={index}
                        >
                          {data?.city_name}
                        </option>
                      ))}
                    </select>
                    {errors.city && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )}
                  </div>

                  <div>
                    <label htmlFor="homeCourt" className=" text-green text-xs">
                      Home Court {btndiable !== "" ? "In" + " " + CityName : ""}
                    </label>
                    <div className="flex items-center">
                      {/* {showinput 
                    && */}
                      <>
                        <input
                          {...restCoustomHomeCourt}
                          // {...register("homeCourt_name",{ required: true })}
                          disabled={btndiable !== "" ? false : true}
                          name="homeCourt_name"
                          id="homeCourt_name"
                          className={`bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none ${
                            showinput ? "block" : "hidden"
                          }`}
                          placeholder="Add Home Court By Name Or Address"
                          onChange={getaddress(ref?.current?.value)}
                          onChangeCapture={(e) => {
                            const inputValue = e.target.value;
                            setcharlength(inputValue.length)
                            if (
                              inputValue.length <= 3 &&
                              inputValue.length > 0
                            ) {
                              set3charCheck(true);
                            }
                            if (e.target.value !== ref?.current?.value) return;
                            inputValue.length > 3 &&
                              AddressCheck(e.target.value);
                          }}
                          // value={homeAddress}
                          type="text"
                          ref={ref}
                          // autoFocus
                        />
                        <button
                          type="button"
                          className={`text-[#38e680] text-3xl ${
                            showinput ? "block" : "hidden"
                          }`}
                          onClick={(e) => {
                            getinput(false);
                            getaddress("");
                            resetField("homeCourt");
                            resetField("homeCourt_name");
                            CoustomHomeCourt("");
                            SetHC_displayname("")
                            SethomeCourt_zipcode("");
                            SethomeCourt_lat("");
                            SethomeCourt_lng("");
                            SethomeCourt_address("");
                          }}
                        >
                          -
                        </button>
                      </>
                      {/* } */}

                      {!showinput && (
                        <>
                          <Select
                            // required
                            // {...restLangField("homeCourt", { required: true })}
                            {...restLangField}
                            ref={selectRef}
                            name="homeCourt"
                            id="homeCourt"
                            options={btndiable !== "" ? homecourtdrop : []}
                            placeholder="Type Your Home Court Name"
                            // onClick={() =>{ changeStep("1"); allClear()}}
                            // onClick={(e)=>{setIsHome("");getHomecourtid(""); getlocation("") }}
                            //  onChange={(e)=>{setIsHome(e);} }
                            //  onChange={option => homeCourtnameChange(option ? option.value : option)}
                            onChange={(e) => {
                              setIsHome(e);
                              homeCourtnameChange(e ? e._id : e);
                              sethomeerror("");
                            }}
                            getOptionLabel={(homecourtdrop) =>
                              homecourtdrop?.displayName + " - " + homecourtdrop?.zipcode
                            }
                            getOptionValue={(homecourtdrop) =>
                              homecourtdrop._id
                            }
                            // value={user?.homeCourt?._id}
                            // defaultInputValue={user?.homeCourt?.name}
                            // defaultValue={ishome}
                            // autoFocus={false}
                            // defaultValue={user.homeCourt.isVerified===true? user?.homeCourt: null}
                            // value={ishome}
                            value={
                              langValue
                                ? homecourtdrop.find(
                                    (x) => x.value === langValue
                                  )
                                : langValue
                            }
                            // defaultInputValue={ishome?.name}
                            isSearchable={true}
                            // isClearable
                            isMulti={false}
                            className=" react-select-input"
                            isDisabled={btndiable !== "" ? false : true}
                            // menuIsOpen={}
                            onMenuOpen={() => {
                              homeCourtnameChange(null);
                            }}
                          />
                          <button
                            type="button"
                            className="text-[#38e680] text-3xl"
                            onClick={(e) => {
                              getinput(true);
                              resetField("homeCourt_name");
                              resetField("homeCourt");
                              getaddress("");
                              clearInput();
                            }}
                          >
                            +
                          </button>

                         

                        </>
                      )}
                      {/* {btndiable 
                  &&    
                  <>
                  {showinput ? :
                  
                  }
                  
                  </>
                 }    */}
                      {/* <button type="button" className="text-[#38e680] text-3xl" onClick={(e)=>{getinput(true)}}>+</button> */}
                      <svg
                          onClick={() => setIsHcpopup(true)}
                          className="ml-2 cursor-pointer"
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          viewBox="0 0 43 43"
                          fill="none"
                        >
                          <path
                            d="M21.5 0.25C9.77 0.25 0.25 9.77 0.25 21.5C0.25 33.23 9.77 42.75 21.5 42.75C33.23 42.75 42.75 33.23 42.75 21.5C42.75 9.77 33.23 0.25 21.5 0.25ZM21.5 38.5C12.1287 38.5 4.5 30.8712 4.5 21.5C4.5 12.1287 12.1287 4.5 21.5 4.5C30.8712 4.5 38.5 12.1287 38.5 21.5C38.5 30.8712 30.8712 38.5 21.5 38.5ZM19.375 30H23.625V34.25H19.375V30ZM22.7962 8.835C18.4187 8.1975 14.5512 10.8962 13.3825 14.7637C13 15.9962 13.935 17.25 15.2312 17.25H15.6562C16.5275 17.25 17.2287 16.6337 17.5262 15.8262C18.2062 13.935 20.225 12.6387 22.4137 13.1062C24.4325 13.5312 25.92 15.5075 25.75 17.5687C25.5375 20.4162 22.3075 21.0325 20.5437 23.6887C20.5437 23.71 20.5225 23.71 20.5225 23.7312C20.5012 23.7737 20.48 23.795 20.4587 23.8375C20.2675 24.1562 20.0762 24.5175 19.9275 24.9C19.9062 24.9637 19.8637 25.0062 19.8425 25.07C19.8212 25.1125 19.8212 25.155 19.8 25.2187C19.545 25.9412 19.375 26.8125 19.375 27.875H23.625C23.625 26.9825 23.8587 26.2387 24.22 25.6012C24.2625 25.5375 24.2837 25.4737 24.3262 25.41C24.4962 25.1125 24.7087 24.8362 24.9212 24.5812C24.9425 24.56 24.9637 24.5175 24.985 24.4962C25.1975 24.2412 25.4312 24.0075 25.6862 23.7737C27.7262 21.84 30.4887 20.2675 29.915 16.2087C29.405 12.5112 26.4937 9.3875 22.7962 8.835Z"
                            fill="#39FD13"
                          />
                        </svg>
                    </div>
                    {!showinput && (
                      <p className=" text-[red] text-xs flex-wrap">
                        Click '+' to add your Home Court
                      </p>
                    )}
                    
                     {showinput && (
                      <p className=" text-[red] text-xs flex-wrap">
                        Click '-' to get your Home Court List
                      </p>
                    )}
                    {!showinput &&
                      (btndiable !== "" ? null : (
                        <span className=" text-[red] text-xs">
                          Please Select League City <br />
                        </span>
                      ))}
                    
                   

                    {showinput &&
                      (btndiable !== "" ? null : (
                        <span className=" text-[red] text-xs">
                          Please Select League City <br />
                        </span>
                      ))}
                     
                     {showinput && charlength != undefined &&  (zipCode === null || HomeCourtValid == true || charCheck === true )  ? (
                      <span className=" text-[red] text-xs">
                        Please Provide Valid Home Court
                      </span>
                    ) : (
                      ""
                    )}
                    {errors.homeCourt && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )}

                     {showinput && (errors.homeCourt_name && (
                      <p className=" text-[red] text-xs">
                        This field is required
                      </p>
                    ))}

                    {/* {!showinput && langError && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )} */}

                    {/* {btndiable && !showinput
                    && 
                    <p  className=" text-[#ffef00] text-xs flex-wrap">Click '+' to add your Home Court</p>
                    }
                    {langError && (
                      <span className=" text-[#ffef00] text-xs">
                        This field is required
                      </span>
                    )} */}
                  </div>

                  <div className="md:col-span-2 flex">
                    <div className="md:w-6/12 w-full mx-auto ">
                      <label htmlFor="level" className=" text-green text-xs">
                        Skill Level
                      </label>
                      <div className="flex items-center">
                        <select
                          defaultValue=""
                          {...register("level", { required: true })}
                          name="level"
                          id="level"
                          // required
                          className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none "
                        >
                          <option value="" className="hidden">
                            Skill Level
                          </option>
                          {leveldropdown?.map((data, index) => (
                            <option value={data?._id} key={index}>
                              {data?.name.toFixed(1)}
                            </option>
                          ))}
                        </select>
                        <svg
                          onClick={() => setIsOpen3(true)}
                          className="ml-2 cursor-pointer"
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          viewBox="0 0 43 43"
                          fill="none"
                        >
                          <path
                            d="M21.5 0.25C9.77 0.25 0.25 9.77 0.25 21.5C0.25 33.23 9.77 42.75 21.5 42.75C33.23 42.75 42.75 33.23 42.75 21.5C42.75 9.77 33.23 0.25 21.5 0.25ZM21.5 38.5C12.1287 38.5 4.5 30.8712 4.5 21.5C4.5 12.1287 12.1287 4.5 21.5 4.5C30.8712 4.5 38.5 12.1287 38.5 21.5C38.5 30.8712 30.8712 38.5 21.5 38.5ZM19.375 30H23.625V34.25H19.375V30ZM22.7962 8.835C18.4187 8.1975 14.5512 10.8962 13.3825 14.7637C13 15.9962 13.935 17.25 15.2312 17.25H15.6562C16.5275 17.25 17.2287 16.6337 17.5262 15.8262C18.2062 13.935 20.225 12.6387 22.4137 13.1062C24.4325 13.5312 25.92 15.5075 25.75 17.5687C25.5375 20.4162 22.3075 21.0325 20.5437 23.6887C20.5437 23.71 20.5225 23.71 20.5225 23.7312C20.5012 23.7737 20.48 23.795 20.4587 23.8375C20.2675 24.1562 20.0762 24.5175 19.9275 24.9C19.9062 24.9637 19.8637 25.0062 19.8425 25.07C19.8212 25.1125 19.8212 25.155 19.8 25.2187C19.545 25.9412 19.375 26.8125 19.375 27.875H23.625C23.625 26.9825 23.8587 26.2387 24.22 25.6012C24.2625 25.5375 24.2837 25.4737 24.3262 25.41C24.4962 25.1125 24.7087 24.8362 24.9212 24.5812C24.9425 24.56 24.9637 24.5175 24.985 24.4962C25.1975 24.2412 25.4312 24.0075 25.6862 23.7737C27.7262 21.84 30.4887 20.2675 29.915 16.2087C29.405 12.5112 26.4937 9.3875 22.7962 8.835Z"
                            fill="#39FD13"
                          />
                        </svg>
                      </div>
                      {errors.level && (
                        <span className=" text-[red] text-xs">
                          This field is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="md:col-span-2">
                    <div className=" flex items-center md:space-x-3 space-x-1">
                      <input
                        {...register("policy", { required: true })}
                        id="policy"
                        name="policy"
                        // required
                        className="lg:w-[25px] md:w-[20px] w-[15px] lg:h-[25px] md:h-[20px] h-[15px] bg-[#f1f1f1] text-[#837575] border border-[#7E7777] rounded-lg"
                        type="checkbox"
                      />
                      <label className="text-[#fff] font-medium lg:text-base md:text-sm text-xs">
                        I agree to the{" "}
                        <span className=" text-green">
                          <button type="button" onClick={() => setIsOpen(true)}>
                            {" "}
                            Terms & Conditions
                          </button>
                        </span>{" "}
                        and{" "}
                        <span className=" text-green">
                          <button
                            type="button"
                            onClick={() => setIsOpen2(true)}
                          >
                            {" "}
                            Privacy Policy
                          </button>
                        </span>
                        .
                      </label>
                    </div>
                    {errors.policy && (
                      <span className=" text-[red] text-xs">
                        This field is required
                      </span>
                    )}
                    <div className=" flex items-center md:space-x-3 space-x-1 mt-5">
                      <input
                       {...register("allowMarketing", { required: false })}
                        id="allowMarketing"
                        name="allowMarketing"
                        // required
                        className="lg:w-[25px] md:w-[20px] w-[15px] lg:h-[25px] md:h-[20px] h-[15px] bg-[#f1f1f1] text-[#837575] border border-[#7E7777] rounded-lg"
                        type="checkbox"
                      />
                      <label className="text-[#fff] font-medium lg:text-base md:text-sm text-xs">
                        I agree to receive marketing newsletters and other
                        information.
                      </label>
                    </div>
                    <div className="text-center my-10">
                      
                    {!loginLoading ? (
                         <button
                         disabled={
                           showinput && (zipCode === null ||  HomeCourtValid  === true || charCheck === true)
                             ? true
                             : false
                         }
                         className={` font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto ${
                           showinput && (zipCode === null ||  HomeCourtValid  === true || charCheck === true ) === true
                             ? "opacity-50"
                             : ""
                         }`}
                         type="submit"
                         onClick={() => {
                           if (errPassword) {
                             handlescorll();
                           }
                         }}
                       >
                         Sign Up
                       </button>
                      ) : (
                        <button
                          type="button"
                          className="font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto flex justify-center items-center"
                        >
                          <svg
                            className="animate-spin mr-1 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          Signing Up...
                        </button>
                      )}

                     
                   
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Popup
        open={isOpen}
        closeOnDocumentClick
        onClose={() => setIsOpen(false)}
      >
        <div className="overflow-auto max-h-[500px] no-padding">
          <TermsCondition />
        </div>
      </Popup>

      <Popup
        open={isOpen2}
        closeOnDocumentClick
        onClose={() => setIsOpen2(false)}
      >
        <div className="overflow-auto max-h-[500px] no-padding">
          <PrivacyPolicy />
        </div>
      </Popup>
{/* skilpopup */}
      <Popup
        open={isOpen3}
        closeOnDocumentClick
        onClose={() => setIsOpen3(false)}
      >
        <div className="pt-10 px-4 overflow-auto max-h-[500px] bg-[#000] text-[#fff]">
          <div className="  text-center">
            <p className=" font-semibold 2xl:text-2xl text-2xl after-line relative">
              Skill Level
            </p>
          </div>
          {leveldropdown?.map((data, index) => (
            <div className="my-10">
              <p>
                <span className=" font-semibold text-base text-green">
                  {data?.name.toFixed(1) + " "}
                </span>
                {data?.description}
                <br />
              </p>
            </div>
          ))}
        </div>
      </Popup>
      {/* homecourt popup */}
      <Popup
        open={isHcpopup}
        closeOnDocumentClick
        onClose={() => setIsHcpopup(false)}
      >
        <div className="pt-10 px-4 overflow-auto max-h-[500px] bg-[#000] text-[#fff]">
          <div className="  text-center">
            <p className=" text-[#39fd13] font-semibold 2xl:text-2xl text-2xl after-line relative">
             Home Court 
            </p>
          </div>
       
            <div className="my-10">
              <p>
              This list shows all available Home Courts in your city. If your desired Home Court is not listed, you can submit a Home Court by clicking on the  <span className="text-[#39fd13]">+</span> Icon.
              
              <br/>
              <br/>
              Once a new Home Court is submitted, an FLT team member will review for approval within 48 hours. A notification will be sent when the Home Court submission status is updated.
              <br/>
              <br/>
              Please note that you can still register for leagues using an approved Home Court while your submission is pending.
              <br/>
              <br/>
              If you have any questions, please contact support@flexleaguetennis.com
              </p>
            </div>
      
        </div>
      </Popup>
    </div>
  );
});

export default ProfileComplete;
