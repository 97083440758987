import React from "react";
import { Route, Routes } from "react-router-dom";
import Homepage from "./Home/Homepage";
import SignUp from "./Auth/SignUp";
import Login from "./Auth/Login";
import ForgotPassword from "./Auth/ForgetPassword";
import ProfileComplete from "./Auth/ProfileComplete";
import Leagues from "./Leaguespage/Leagues";
import Register from "./Register/Register";
import TermsCondition from "./Common/TermsCondition";
import PrivacyPolicy from "./Common/PrivacyPolicy";
import TreeData from "./Tree";
import MyAccount from "./MyAccount/MyAccount";
import PlayerSearch from "./PlayerSearch/PlayerSearch";
import PlayerDetail from "./PlayerSearch/PlayerDetail";
import GettheApp from "./GettheApp/GettheApp";
import UpcomingLeague from "./UpcomingLeague/UpcomingLeague";
import Scheduling from "./Common/Scheduling";
import RatingSystem from "./Common/RatingSystem";
import PlayoffStructure from "./Common/PlayoffStructure";
import Aboutus from "./Common/AboutUs";
import Benefits from "./Common/Benefits";
import ContectUs from "./Common/ContactUs";
import MyMatch from "./MyMatch/MyMatch";
import MatchHistory from "./MatchHistory/MatchHistory";
import MatchPage from "./MatchPage/MatchPage";
import Message from "./Message/Message";
import Facility from "./Register/ui/Facility";
import MyTransaction from "./Transaction/MyTransaction";
import { MessageStore } from "./Message/store/MessageStore";
import AllNotification from "./Common/AllNotification";
import PlayersTree from "./PlayersTree/PlayersTree";
import { PageNotFound } from "./PageNotFind/PageNotFind";



const Routess = () => {
  const {matchId } = MessageStore();
  
  const pages = [
    { path: "/", component: <Homepage /> },
    { path: "/gettheapp", component: <GettheApp /> },
    { path: "/treeData", component: <TreeData /> },
    { path: "/signup", component: <SignUp /> },
    { path: "/login", component: <Login /> },
    { path: "/forgotpassword", component: <ForgotPassword /> },
    { path: "/verify", component: <ProfileComplete /> },
    { path: "/terms-condition", component: <TermsCondition /> },
    {path: "/scheduling", component: <Scheduling />},
    {path: '/rating_system', component: <RatingSystem />},
    {path: '/playoff_structure', component: <PlayoffStructure />},
    { path: "/aboutus", component: <Aboutus /> },
    { path: "/contactus", component: <ContectUs /> },
    { path: "/benefits", component: <Benefits /> },
    { path: "/privacy-policy", component: <PrivacyPolicy /> },
    { path: "/league", component: <Leagues /> },
    { path: "/register/:id", component: <Register /> },
    { path: "/myaccount/:id", component: <MyAccount /> },
    { path: "/playersearch", component: <PlayerSearch /> },
    { path: "/playerdetail/:id", component: <PlayerDetail /> },
    { path: "/upcomingleague/:id/:name", component: <UpcomingLeague /> },
    { path:  "/mymatch/:id", component: <MyMatch /> },
    { path: "/matchhistory/:id/:leagueid/:leaguename/:playername/:leaguetype", component: <MatchHistory /> },
    { path:  "/playerstree", component: <PlayersTree /> },
    { path:  "/matchpage", component: <MatchPage /> },
    { path:  "/message/:id", component: <Message /> },
    { path:  "/message", component: <Message /> },
    { path: "/transaction", component: <MyTransaction /> },
    { path: "/notification", component: <AllNotification/> },
    // { path: "/facility", component: <Facility /> }
  ];
  return (
    <Routes>
      {pages.map(({ component, path }) => (
        <Route key={path} exact element={component} path={path} />
      ))}
       <Route
        path="*"
        element={<PageNotFound/>}
      />
    </Routes>
  );
};

export default Routess;
