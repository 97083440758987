import { createContext, useContext, useState } from "react";
import {
  makeAutoObservable,
  useLocalObservable,
  observable,
  runInAction,
} from "mobx";
import {
  postLogin,
  postMethodData,
  patchMethodData,
  getMethodData,
} from "../../ApiMethods/api";
import { toast } from "react-toastify";


export class TransactionStore {

    Token = null;
    user = {};
    errloadseason = "";
    loadseason = false;
    nolist = false;

    leagues = [];
    transaction =[];

    saved = false;

    leagueid = "";
    leaguesiddata = "";
    leaguetype = "";
    fees=""
    leaugesfalse = false;


    registrationleague=[];

    currentleague=[];
    

  constructor(isloading=false) {
    makeAutoObservable(this);
    this.Token = localStorage.getItem("token");
    this.user = JSON.parse(localStorage.getItem("user"));

    // this.getTransaction()
    if(isloading.isloading){
    this.getTransaction()
    
     return
    }
  }

//   getleaugesfalse = (e) => {
//     this.leaugesfalse = e
//   }

  gettoken = (e) => {
    this.Token = e
  }
  getuser = (e) => {
    this.user = e
  }

//   changeStep = (e) => {
//     runInAction(() => {
//         this.step = e
//     })
//   }

   //get last name
//    getFees = (text) => {
//     this.fees = text;
//   }

  //get league id
//   getidleague = (text) => {
//     this.leagueid = text;
//   }


//get Transaction 
getTransaction = async() => {
    runInAction(() => {
    //   this.loadseason = true;
    //   this.errloadseason = "";
    //   this.saved = false;
    });
    await getMethodData("/api/user/transacton", this.Token)
      .then((resp) => {
        if (resp?.data?.status === false) {
          runInAction(() => {
            // this.leaugesfalse = false;
            // this.loadseason = false;
            // this.errloadseason = resp?.data?.message
          });
          return;
        }
        if (resp?.data?.status === true) {
            // console.log(resp?.data?.data.length)
          runInAction(() => {
            // this.leaugesfalse = false;
            // this.loadseason = false;
            // this.errloadseason = resp?.data?.message;
            this.transaction = resp?.data?.data;
            if(resp?.data?.data.length == 0) {
              this.nolist = true;
            }else{
              this.nolist = false;
            }
            // this.saved = false;
          });
          return;
        }
        runInAction(() => {
        //   this.leaugesfalse = false;
        //   this.loadseason = false;
        //   this.errloadseason = resp?.data?.message;
        });
      })
      .catch((error) => {
        runInAction(() => {
            // this.loadseason = false;
            if(!error.response){
                this.errloadseason = error?.message;
                toast.error(error?.message);
            }
            this.errloadseason = error?.response?.data?.message;
            toast.error(error?.message);
        })
        return;
    });
  };

 



}
export const TransactionStoreContext = createContext(new TransactionStore());
export const useTransactionStore = () => useContext(TransactionStoreContext);
