import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useAuthStore } from "../Auth/store/Auth";
import { useRegisterStore } from "../Register/store/RegisterStore";
import Input, { isValidPhoneNumber } from "react-phone-number-input/input";


const ContactForm = observer(() => {
    const {contactUs,popupload,getContactMobile,contactmobile} =  useRegisterStore();
    const { getProfile,authapproved,user , gender,allowuser } = useAuthStore();
    const [mobilenumber, setmobilenumber] = useState(contactmobile)
    const [Description, getDescription] =useState("")
    const {
        register: register2,
        reset:reset2,
        handleSubmit: handleSubmit2,
        watch: watch2,
        control:control2,
        formState: { errors: errors2,isSubmitSuccessful },
       } = useForm();

    useEffect(() => {
        if (isSubmitSuccessful) {
          reset2();
          setmobilenumber("")
          // console.log("===>>>>>>>",mobilenumber)
        }
      }, [isSubmitSuccessful]);
      const isMessageEmpty = Description.trim().length === 0;
      const isSendButtonDisabled = isMessageEmpty;
    return(
        <>
        <form onSubmit={handleSubmit2(contactUs)} >
            {/* e.preventDefault();
            setIsOpen(false) */}
          <div className="my-5">
            {authapproved
            ?
            <>
            <div className="py-3">
            <input
              name="Name"
               autoComplete="Name"
              id="Name"
              defaultValue={(user?.firstName) +" "+( user?.lastName)}
              {...register2("name", { required: true })}
              //  value ={user?.firstName}
              // onChange={(e) => getName(e.target.value)}
              //  required
              readOnly
              className=" bg-[#7777] text-[#fff] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
              // placeholder="Enter your name"
               type="text"
             />
             </div>
             <div className="py-3"> 
             <input
              name="email"
              autoComplete="email"
              id="email"
              defaultValue={user?.email}
              {...register2("email", { required: true })}
              // onChange={(e) => getEmail(e.target.value)}
              //  required
              readOnly
              className=" bg-[#7777] text-[#fff] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
              // placeholder="Enter your email"
              type="email"
             />
             </div>
             <div className="py-3">
              <Input
                readOnly
                id="mobile"
                defaultCountry="US"
                minLength={14}
                maxLength={14}
                name="mobile"
                autoComplete="mobile"
                // value={user?.mobile}
                {...register2("mobile", { required: false })}
                value={user?.mobile ? ("+1"+user?.mobile) : ""}
                // rules={{ required: true }}
                // control={control2}
                // {...register2("mobile", { required : true})}
                // {...register2("mobile", {
                //   onChange: (e) => setmobilenumber,
                //   required: true,
                //   minLength:14,
                //   maxLength:14
                // })}
                placeholder="Enter Your Mobile Number"
                // onChange={setmobilenumber} 
              //  value ={user?.firstName}
              // onChange={(e) => getName(e.target.value)}
              //  required
              className=" bg-[#7777] text-[#fff] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
              // placeholder="Enter your name"
               type="text"
             />
            </div>
            </>
            :
            <>
            <div className="py-3">
            <input
              name="name"
              autoComplete="name"
              id="name"
              defaultValue={""}
              {...register2("name", { required: true })}
              //  value ={user?.firstName}
              // onChange={(e) => getName(e.target.value)}
              //  required
              className=" bg-[#7777] text-[#fff] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
              placeholder="Enter Your Name"
               type="text"
             />
             {errors2.name && (
              <span className=" text-[red] text-xs">
                This field is required
              </span>
            )}
             </div>
             <div className="py-3"> 
             <input
              name="email"
              autoComplete="email"
              id="email"
              defaultValue={""}
              {...register2("email", { required: true })}
              // onChange={(e) => getEmail(e.target.value)}
              //  required
              className=" bg-[#7777] text-[#fff] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
              placeholder="Enter Your Email"
              type="email"
             />
              {errors2.email && (
              <span className=" text-[red] text-xs">
                This field is required
              </span>
            )}
             </div>
             <div className="py-3">
            <Input
                id="mobile"
                defaultCountry="US"
                minLength={14}
                maxLength={14}
                name="mobile"
                autoComplete="mobile"
                value={mobilenumber}
                rules={{ required: true }}
                control={control2}
                // {...register2("mobile", { required : true})}
                {...register2("mobile", {
                  onChange: (e) => setmobilenumber,
                  required: false,
                  minLength:14,
                  maxLength:14
                })}
                placeholder="Enter Your Mobile Number"
                onChange={setmobilenumber} 
              //  value ={user?.firstName}
              // onChange={(e) => getName(e.target.value)}
              //  required
              className=" bg-[#7777] text-[#fff] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
              // placeholder="Enter your name"
               type="text"
             />
                 {mobilenumber && mobilenumber.length < 12 && errors2?.mobile && errors2?.mobile.type == "minLength" && 
                    <span className=" text-[red] text-xs">
                        Mobile Number Should be of 10 digits.
                      </span>
                    }
              {(errors2.mobile && !mobilenumber) && (
              <span className=" text-[red] text-xs">
                This field is required
              </span>
            )}
            </div>
            </>}
             <div className="py-3">
             <textarea
              defaultValue=""
              name="description"
              autoComplete="description"
              id="description"
              {...register2("description", {   
                onChange: (e) => {getDescription(e.target.value)},
                required: true,
              })}
              // required
              className=" bg-[#7777] text-[#fff] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
              placeholder="Please let us know how we can help"
             ></textarea>
            {errors2.description && (
              <span className=" text-[red] text-xs">
                This field is required
              </span>
            )}
                  { Description.length > 0 && isSendButtonDisabled && (
              <span className=" text-[red] text-xs">
               Please Provide a valid content!
              </span>
            )}
            
             </div>
            <div className="text-center py-3">
              {!popupload
              ?
              Description.length > 0 && isSendButtonDisabled ?
              <span
                className="font-medium cursor-pointer lg:text-[20px] text-lg text-[#000] bg-[#39FD13] md:px-11 px-4 md:w-auto w-6/12 py-2 rounded-lg "
                >
                Submit
              </span>
              :
              <button
              className="font-medium lg:text-[20px] text-lg text-[#000] bg-[#39FD13] md:px-11 px-4 md:w-auto w-6/12 py-2 rounded-lg "
              type="submit"
              >
              Submit
            </button>
              :
              <span
                className="flex justify-center items-center mx-auto font-medium lg:text-[20px] text-lg text-[#000] bg-[#39FD13] md:px-11 px-4 w-fit py-2 rounded-lg "
                type="button">
                Submitting 
                <svg
                    className="animate-spin ml-1 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
              </span>
            }
              
             </div>
          </div>
          </form>
      </>
    )
})

export default ContactForm;