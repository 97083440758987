import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import { NavLink } from "react-router-dom";
import demopfp from "../assets/image/demopfp.webp";
import delnotify from "../assets/image/notifybtn.webp";
import moment from "moment";
import { SlBell } from "react-icons/sl";
import Moment from 'react-moment';

const Notification = observer((props) => {
  
  const {
    user,
    authapproved,
    logout,
    otpscreenremove,
    setloginNoEmail,
    setsocialType,
    setupcomingleauge,
    getNotification,
    notification,
    nolist,
    deleteNotification,
  } = useAuthStore();

  function handleClick() {
    props.setIsOpen(!props.isOpen);
  }


  return (
    <>
    
      <div className="flex mt-5 items-center justify-between ">
        <p className="text-[#000] 2xl:text-2xl ml-2 text-[22px]">Notifications</p>

 
          {/* <div className="flex mt-3 justify-end"> */}
          {!nolist && (
            <NavLink onClick={handleClick} to={"/notification"} className="bg-green rounded-lg px-3 text-[#000] text-base">View All</NavLink>
          
        )}
        {/* </div> */}


        {/* {!nolist && (
          <button onClick={() => deleteNotification()} className="text-green mr-2 ">
            Clear
          </button>
        )} */}

        {/* <p className="text-[#7E7777] 2xl:text-2xl text-[14px] ">
            <span> You have</span>
           <span className="text-[#39FD13]"> 3 notifications. </span>
            </p> */}
      </div>
      <div className="">
        {/* <p>Today</p> */}

        {/* console.log("data===>",data.body) */}
        {/* <table className="w-full">
        <tbody> */}
          {notification.map((data, index) => (
     
            <div key={index} className=' rounded-lg shadow-2xl w-full py-2 px-1 my-1 bg-[#fff]'>
            <div className='flex items-center w-full justify-between'>
            <p className='text-[12px]'>{data?.body?? " No Notification"}</p>
            </div>
            <div className="">
            <p className='text-[10px] text-end'>
             <Moment fromNow>
             {data?.createdAt ?? "N/A"}
             </Moment>
             </p>
             </div>
           
            </div>
        
          ))}
          {/* </tbody>
        </table> */}

        {nolist && (
          <div className="shadow-2xl rounded-xl py-5">
            <p className=" font-semibold text-lg text-center">
              No Notification
            </p>
          </div>
        )}
      </div>
    </>
  );
});

export default Notification;
