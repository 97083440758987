import React, { useEffect } from "react";
import { observer } from "mobx-react";
import {
  CommonStore,
  useCommonStore,
  CommonStoreContext,
} from "./Store/CommonStore";
const Aboutus = observer(() => {
  return (
    <CommonStoreContext.Provider
      value={
        new CommonStore({
          about: true,
        })
      }
    >
      <Screen />
    </CommonStoreContext.Provider>
  );
});
export default Aboutus;

const Screen = observer(() => {
  const { Aboutus, loadcommon } = useCommonStore();

  useEffect(() => {

      if(Aboutus?.length === 0){

        if(document.getElementById("cmsdata")){
          document.getElementById("cmsdata").innerHTML =
        "<h1 class='text-center'>NO ABOUT US DATA FOUND</h1>";
        }
     
    
    } else{
      let data = Aboutus[0]?.content.replace(/&quot;/g, '"');
      document.getElementById("cmsdata").innerHTML = data;
    }
     
  }, [loadcommon]);
  return (
    <div className="py-2 2xl:px-28 lg:px-20 bg-[black] md:px-10 px-4">
      <div className=" text-center">
        <p className=" font-semibold 2xl:text-2xl text-green text-2xl after-line relative">
          About Us
        </p>
      </div>

      {loadcommon ? 
       <div className="flex justify-center items-center min-h-[80vh] ">
       <svg
             className="animate-spin mt-11  h-16 w-16 text-white"
             xmlns="http://www.w3.org/2000/svg"
             fill="green"
             viewBox="0 0 24 24"
             >
             <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
             <path
                 className="opacity-75"
                 fill="green"
                 d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
             ></path>
         </svg>
              </div>
      
   :
   <div className="my-10 text-[white]" id="cmsdata"></div>}
      
    </div>
  );
});
// export default Aboutus;
