/* eslint-disable jsx-a11y/alt-text */
import { observer } from "mobx-react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import profilebg from "../assets/image/profilebg.webp";
import myaccount from "../assets/image/bgleague.webp";
import img1 from "../assets/image/img1.webp";
import img2 from "../assets/image/img2.webp";
import img3 from "../assets/image/img3.webp";
import img4 from "../assets/image/img4.webp";
import img5 from "../assets/image/img5.webp";
import img6 from "../assets/image/img6.webp";
import myprofile from "../assets/image/placeholder.webp";
import { useEffect, useState } from "react";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
import Input, { isValidPhoneNumber } from "react-phone-number-input/input";
import React from "react";
import Popup from "reactjs-popup";
import {
  usePlayerSearchStore,
  PlayerSearchStore,
  PlayerSearchStoreContext,
} from "./Store/PlayerSearchStore";
import "reactjs-popup/dist/index.css";
import { useAuthStore } from "../Auth/store/Auth";
import { useMyMatchPageStore } from "../MatchPage/store/MyMatchPageStore";
// const PlayerSearch = observer(() => {
//   const {
//     tablehide,
//     loadseason
//   } = usePlayerSearchStore();
//   useEffect(()=> {

//   },[tablehide,loadseason])

//   return (
//     <PlayerSearchStoreContext.Provider
//       value={
//         new PlayerSearchStore({
//           isloading: true,
//         })
//       }
//     >
//       <Screen />
//     </PlayerSearchStoreContext.Provider>
//   );
// });
// export default PlayerSearch;

const background =[
  img1,
  img2,
  img3,
  img4,
  img5,
]

const PlayerSearch = observer(() => {


  const {
    register,

    formState: { errors },
  } = useForm();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  // const reset = () => {
  //   if(searchList){
  //     getfirstname("");
  //   getlastname("");
  //   getcityid("");
  //   gettablehide(false);
  //   clearsearechlist();
  //   }
  // };
  const navigate = useNavigate();
  const {
    SearchPlayer,
    searchList,
    reset,
    ListTrue,
    homeCourtDropdown,
    cityDropdown,
    levels,
    homeCourtid,
    newcityname,
    levelid,
    nolist,
    fName = "",
    lName = "",
    getfirstname,
    getFirstNamedata,
    getlastname,
    gettablehide,
    tablehide,
    loadseason,
    getFilterPlayer,
    getplayerdetail,
    gethomeid,
    getcityid,
    cityid,
    clearsearechlist,
    getlevelid,
    gethomecourt,
    getcity,
    getlevel,
    gettoken

  } = usePlayerSearchStore();
  const { setRoutes } = useMyMatchPageStore();

  const { authapproved } = useAuthStore();
  // console.log(tablehide);
  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });
    // getFilterPlayer()
    if (!authapproved) {
      navigate("/");
    }
    else{
    gettoken(localStorage.getItem("token"));
    // gethomecourt()
    getcity()
    // getlevel()
    }
  }, [authapproved]);

  const handleClickScroll = () => {
    const element = document.getElementById("playertable");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  

  
  const [random, setrandom] = useState();

  useEffect(() => {
    setrandom(Math.floor(Math.random() * background.length))
  }, [])
  
  return (
    <>
      <div className="relative   py-9 min-h-[100vh] flex items-center z-1 bg-fixed w-full object-cover object-top"
      style={{ backgroundImage: `url(${img6})`,
           backgroundSize:'cover',  
           }}
      >
      {/* <img
          className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top brightness-50"
          src={background[random]}
        /> */}
        <div className="w-full  2xl:px-28 lg:px-20 md:px-10 px-4 relative z-1">
          <div className="flex  flex-wrap justify-center">
            <div className="2xl:w-[80%] border rounded-lg  border-[#ffffff] lg:w-[80%] md:w-[90%] w-full 2xl:max-w-[90%]">
              <div className=" bg-[#6C6C6C] bg-opacity-40 rounded-lg lg:px-10 md:px-5 px-4 md:pt-7 py-1">
                <form
                  autoComplete="new-password"
                  onSubmit={(e) => {
                    // console.log(e);
                    getFilterPlayer();
                    e.preventDefault();
                  }}
                >
                  <div className=" text-center">
                    <p className=" text-[#fff] font-medium md:text-3xl text-xl relative">
                      Find Player
                    </p>
                  </div>
                  <div className="grid gap-8 md:grid-cols-2 mt-5">
                    <div className="col-span-1">
                      <input
                        name="PlayerName"
                        autoComplete="PlayerName"
                        id="PlayerName"
                        value={fName}
                        onChange={(e) => {
                          getfirstname(e.target.value);
                          // getlastname(e.target.value);
                        }}
                        // required
                        className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                        placeholder="Player Name"
                        type="text"
                      />
                    </div>
                   
                    <div className="col-span-1">
                      <select
                        value={cityid}
                        onChange={(e) => {
                          getcityid(e.target.value);
                        }}
                        name="city"
                        id="city"
                        className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      >
                        <option  className="hidden">
                          City
                        </option>
                        {cityDropdown.map((data, index) => (
                          <option value={data?._id} key={index}>
                            {data?.city_name}
                          </option>
                        ))}
                      </select>
                  </div>
              
                  </div>

               
             

                  <div className="flex justify-center gap-3 md:col-span-2">
                    <div className="text-center mt-3 mb-5">
                      <button
                        onClick={(e) => {
                          gettablehide(true);
                          ListTrue(true);
                          handleClickScroll();
                        }}
                        className=" font-medium lg:text-[20px] text-[13px] text-[#000] bg-green md:px-11 px-5 py-2 rounded-lg mx-auto"
                        type="submit"
                        disabled={
                          fName == "" &&
                          lName == "" &&
                          newcityname == "" &&
                          homeCourtid == "" &&
                          levelid == "" &&
                          cityid == ""
                            ? true
                            : false
                        }
                      >
                        Find Player
                      </button>
                    </div>
                    <div className="text-center mt-3 mb-5">
                      <button
                        onClick={() => {
                          reset();
                        
                        }}
                        className=" font-medium lg:text-[20px] text-[13px] text-[#000] bg-green lg:px-11 px-5 py-2 rounded-lg mx-auto"
                        type="reset"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {tablehide === true ? (
            <>      
            <div
              id="playertable"
              className="flex  flex-wrap justify-center mt-5"
            >
              {SearchPlayer?.length !== 0 &&
            <div className=" text-end  lg:w-[80%] md:w-[90%] 2xl:w-[80%] w-full text-green">
              S-Singles, D-Doubles, M-Mixed
            </div>
            }
              
              <div id="style-1" className="2xl:w-[80%] border rounded-lg max-h-[600px] border-[#ffffff] lg:w-[80%] md:w-[90%] w-full 2xl:max-w-[90%] overflow-auto">
             
                <div>
                  <table className="w-full text-[#fff]">
                    <thead>
                      <tr className="bg-[#000] sticky top-0">
                        {/* <th className="py-5">S. No.</th> */}
                        <th className="py-5 flex justify-items-start md:pl-11 pl-5 ">Player</th>
                        <th className="text-left">Home Court</th>
                        {/* <th>City</th> */}
                        <th>Level</th>
                        {/* <th>Ranking</th> */}
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {SearchPlayer.length == 0 ?
                      <tr>
                      <td colSpan={13} className="my-10 py-20  text-center">
                        <p className=" font-semibold text-lg">
                          No Player Found
                        </p>
                      </td>
                    </tr>
                    :
                    <>
                    {SearchPlayer.map((data, index) => (
                        <tr
                          key={index}
                          onClick={(e) => {
                            setRoutes("");
                            navigate(`/playerdetail/${data?._id}`);
                            getplayerdetail(data?._id);
                          }}
                          className="capitalize cursor-pointer hover:bg-green"
                        >
                          <td className="md:pl-11 pl-5 mt-3">
                            <div className="flex items-center space-x-3">
                              <div className="avatar">
                                <div className="flex  items-center mask mask-squircle w-12 h-14">
                                  <img
                                    className=" aspect-[1]"
                                    crossOrigin="anonymous"
                                    src={BASE_URL+"/image/"+data?.image}
                                    alt="User Image"
                                  />
                                </div>
                              </div>
                              <div className="ml-3">
                                {data?.firstName + " " + data?.lastName}
                              </div>
                            </div>
                          </td>

                          <td className="text-left">
                       
                            <div>
                            {data?.homeCourt?.displayName??"N/A"}
                            </div>
                            <div className="text-sm">
                            {data?.city?.city_name??"N/A"}
                            </div>
                            
                            </td>

                          {/* <td>{data?.city?.city_name??"N/A"}</td> */}
                          <td className="px-5 whitespace-nowrap">
                            S-{data?.level?.name?.toFixed(1)?? 0},
                            D-{data?.doubles_level?.name?.toFixed(1)?? 0},
                            M-{data?.mixed_level?.name?.toFixed(1)?? 0}
                            </td>
                          {/* <td>{data?.ranking}</td> */}
                        </tr>
                      ))}
                    </>
                      }
                      

                      {/* {nolist && (
                        <tr>
                          <td colSpan={13} className="my-10 py-20  text-center">
                            <p className=" font-semibold text-lg">
                              No Player Found
                            </p>
                          </td>
                        </tr>
                      )} */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
});
export default PlayerSearch;
