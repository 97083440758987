/* eslint-disable jsx-a11y/alt-text */
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import bannerimage from "../assets/image/HomePage.webp";
import HomeBackImage from "../assets/image/HomeBackImage.webp";
import CalenderBackImage from "../assets/image/backgroundcalender.webp";
// import Map from "../assets/image/map.webp";
import MessageBackImage from "../assets/image/backgroundmessage.webp";
// import CommunityBackImage from "../assets/image/community.webp";
import calender from "../assets/image/calender.webp";
// import player from "../assets/image/player.webp";
import message from "../assets/image/message.webp";
// import img1 from "../assets/image/img1.webp";
import img2 from "../assets/image/img2.webp";
// import img3 from "../assets/image/img3.webp";
import img4 from "../assets/image/img4.webp";
// import img5 from "../assets/image/img5.webp";
import img6 from "../assets/image/img6.webp"
import img7 from "../assets/image/img7.webp"
import { useAuthStore } from "../Auth/store/Auth";
import Moment from "react-moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MyCalender } from "../MatchPage/MatchPage";
// import bgleague from "../assets/image/bgleague.webp";
import cardimage from "../assets/image/cardimage.webp";
import doublesimg from "../assets/image/doublesimg.webp";
import mixedimg from "../assets/image/mixedimg.webp";

import { FaAngleRight } from "react-icons/fa";
import {
  LeagueStore,
  useLeagueStore,
  LeagueStoreContext,
} from "../Leaguespage/store/Leaguestore";
import { useRegisterStore } from "../Register/store/RegisterStore";
import moment from "moment";
// import { toast } from "react-toastify";
// import { hover } from "@testing-library/user-event/dist/hover";
// import Chat from "../ChatWindow/Chat";

// const Homepage = observer(() => {
//   const {authapproved} = useAuthStore();
//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth'
//     });
//   }, [])
//   const store = new LeagueStore({
//     isloading: authapproved
//   });
//   return (
//     <LeagueStoreContext.Provider value={store}>
//       <Screen />
//     </LeagueStoreContext.Provider>
//   );
// });

// export default Homepage
const background = [img6,img7];
const random = Math.floor(Math.random() * background.length);

const Homepage = observer(() => {
  const navigate = useNavigate();

  // console.log(random, background[random]);
  // console.log(new Date().toISOString())
  const {
    user,
    authapproved,
    loginNoEmail,
    isEmailverfied,
    setallowuser,
    allowuser,
    setloginNoEmail,
    setRoute,
    signInWithFacebook,
    signInWithGoogle,
    signInWithApple,
    otpscreenremove,
    setsocialType,
    getProfile,
    getNotification
    
  } = useAuthStore();

  useEffect(() => {
    if (loginNoEmail) {
      navigate("/signup");
    }
    if (isEmailverfied) {
      navigate("/verify");
    }
  }, [loginNoEmail, isEmailverfied]);
useEffect(()=>{
  window.scrollTo({ top: 0, behavior: "smooth" });

},[])
  const {
    leagues,
    getSeason,
    gettoken,
    getuser,
    getCurrentleague,
    currentleague,
    nolistcurrent,
  } = useLeagueStore();
  const { paymentscreen } = useRegisterStore();
  const {
    getLeaguesid,
    changeStep,
    togglebtn,
    getShowtable,
    getShowmap,
    clearLeaugedata,
    getleaugesfalse,
  } = useRegisterStore();

  useEffect(() => {
    gettoken(localStorage.getItem("token"));
    getuser(JSON.parse(localStorage.getItem("user")));
    if (authapproved) {
      // getSeason();
      getCurrentleague();
      getProfile();
      getNotification();

    }
  }, [authapproved]);

  // const settings_1 = {
  //   dots: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 2,
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   arrows: false,
  //   adaptiveHeight: false,
  //   // centerMode: false,
  //   // centerPadding: '20%',
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1.3,
  //         // arrows: true,
  //         // dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1.3,
  //         // arrows: true,
  //         // dots: true,
  //       },
  //     },
  //   ],
  // };
  const location = useLocation();
const pattern = /^\/\w+/;
const result = location.pathname.match(pattern);
  return (
    <>
      {authapproved ? (
        <>
          <div
            className="relative py-9 min-h-[100vh] flex h-full bg-no-repeat bg-fixed w-full object-cover object-top"
            style={{
              backgroundImage: `url(${img6})`,
              backgroundSize: "cover",
            }}
          >
            {/* <img
              className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top"
              src={bgleague}
            /> */}
            <div className="w-full 2xl:px-10 lg:px-5 md:px-3 px-4 relative z-1  ">
              <div className="flex flex-wrap justify-center">
                <div className=" w-full ">
                  <div className="card-slider w-full flex lg:flex-row flex-col bg-[#6C6C6C] bg-opacity-40 rounded-lg lg:px-10 md:px-5 px-4 md:pt-8 py-10">
                    {/* <p className="  text-[#fff] font-medium md:text-2xl text-xl relative">
                    {user?.city?.city_name}
                    </p>  */}
                    <div className="lg:w-5/12 w-full">
                      <div className="">
                        <div>
                          <div className="flex justify-between items-center">
                            <p className=" text-[#fff] my-3 md:text-3xl text-xl relative flex items-center md:whitespace-nowrap ">
                              Your Active Leagues in {user?.city?.city_name}
                              <span className="h-[2px] w-[39.05px] block bg-green ml-3"></span>
                            </p>

                            {/* <button className='text-green  text-lg'>{index === 1 ? "View All" : null}</button>  */}
                          </div>
                        </div>

                        {!nolistcurrent && (
                          <div className="parent-idv grid my-10 md:grid-cols-2 gap-x-10 gap-y-16 mt-14">
                            {/* {data?.leagues?.length != 0 ? (
                                    <p className=" mt-3 text-[#fff] font-medium md:text-2xl relative">
                                      {data?.name}
                                    </p>
                                  ) : null} */}

                            {currentleague.map((leaguedata, index) => (
                              <div key={index}>
                                <div
                                  onClick={() => {
                                    paymentscreen(false);
                                    navigate(`/mymatch/${leaguedata?._id}`);
                                  }}
                                  key={index}
                                  className=" cursor-pointer w-full h-full relative bg-[#fff] rounded-bl-3xl rounded-tr-3xl md:px-5 px-4 py-3 inline-block"
                                >
                                  <div className="circle-shadow px-1 shadow-md absolute lg:top-[-32px] md:top-[-30px] top-[-33px] right-[50%] left-[50%] aspect-[1] translate-x-[-50%] h-16 bg-[#fff] rounded-full ">
                                    <img
                                      alt="img"
                                      className="m-auto h-full"
                                      src={
                                        leaguedata?.league_type === "singles"
                                          ? cardimage
                                          : leaguedata?.league_type ===
                                            "doubles"
                                          ? doublesimg
                                          : mixedimg
                                      }
                                    />
                                  </div>
                                  <p className=" uppercase lg:mt-5 md:mt-0 mt-5 text-[#39FD13] md:text-3xl text-2xl font-bold">
                                    {leaguedata?.league_type}
                                  </p>
                                  <div className="flex justify-between relative">
                                    <p className="capitalize ">
                                      {leaguedata?.name.replace("(Female)", "")?.replace("(Male)", "")}
                                    </p>
                                    {/* {res?.section !=
                                          "Current Leagues" ? (
                                          new Date().toISOString() >=
                                            leaguedata?.start_registration_date &&
                                            new Date().toISOString() <=
                                            leaguedata?.last_registration_date ? (
                                            <Link
                                              className="text-[#39FD13] text-2xl ml-5 "
                                              to={`/register/${leaguedata._id}`}
                                              onClick={() => {
                                                setallowuser(false);
                                                getleaugesfalse(true);
                                                changeStep("1");
                                                clearLeaugedata();
                                                togglebtn();
                                                getShowtable(false);
                                                getShowmap(false);
                                              }}
                                            >
                                              <FaAngleRight />
                                            </Link>
                                          ) : new Date().toISOString <
                                            leaguedata?.last_registration_date ? (
                                            <Link
                                              className="text-[#39FD13] text-2xl ml-5 "
                                              onClick={() => {
                                                toast.error(
                                                  "Registration is Closed."
                                                );
                                              }}
                                            >
                                              <FaAngleRight />
                                            </Link>
                                          ) : (
                                            <Link
                                              className="text-[#39FD13] text-2xl ml-5 "
                                              onClick={() => {
                                                toast.error(
                                                  "Registration Not Open Yet."
                                                );
                                              }}
                                            >
                                              <FaAngleRight />
                                            </Link>
                                          )
                                        ) : null} */}

                                    <FaAngleRight className="text-[#39FD13] text-2xl absolute top-0 right-[-10px] " />
                                  </div>
                                  <p className="text-[#837575]">
        
                                    {(
                                      <Moment
                                        format={
                                          moment(leaguedata?.start_date).format("MMM D").includes('May')
                                            ? "MMM DD"
                                            : "MMM. DD"
                                        }
                                      >
                                        {leaguedata?.start_date}
                                      </Moment>
                                    ) ?? "N/A"} - {(
                                      <Moment
                                        format={
                                         moment(leaguedata?.end_date).format("MMM D").includes('May')
                                            ? "MMM DD"
                                            : "MMM. DD"
                                        }
                                      >
                                        {leaguedata?.end_date}
                                      </Moment>
                                    ) ?? "N/A"}
                                  </p>
                                  {/* {leaguedata?.start_registration_date >
                                            new Date().toISOString() ? (
                                              <p className="text-[#837575] text-sm">
                                                Registration opens on -
                                                {(
                                                  <Moment format=" MMM, D ">
                                                    {
                                                      leaguedata?.start_registration_date
                                                    }
                                                  </Moment>
                                                ) ?? "N/A"}
                                              </p>
                                            ) : (
                                              <p className="text-[#837575] text-sm">
                                                Deadline -
                                                {(
                                                  <Moment format=" MMM, D ">
                                                    {
                                                      leaguedata?.last_registration_date
                                                    }
                                                  </Moment>
                                                ) ?? "N/A"}
                                              </p>
                                            )} */}
                                  <p className="text-sm text-[#837575] underline float-right">
                                    Schedule
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                        <>
                          {nolistcurrent && (
                            <div className="w-full lg:mt-14 relative bg-[#fff] rounded-bl-3xl rounded-tr-3xl  lg:px-10 md:px-5 px-4 py-10">
                              <p className=" lg:mt-0 md:mt-0 mt-0 text-[#000] text-base text-center uppercase font-bold">
                                You Are Not Registered For Any League Yet.
                              </p>
                              <p className="block text-center mt-4">
                                <Link
                                  to="/league"
                                  className="border-b-2 border-green text-center text-lg"
                                >
                                  Register Now
                                </Link>
                              </p>
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                    <div className="lg:w-7/12 w-full first:mt-0 ">
                      <div className="lg:w-4/5 lg:ml-auto">
                        <div className="">
                          <p className=" flex items-center justify-center text-[#fff] text-[24px] font-[Poppins] md:leading- text-xl md:text-3xl py-3">
                            <span className="h-[2px] w-[39.05px] block bg-green mr-3"></span>
                            Your Schedule
                            <span className="h-[2px] w-[39.05px] block bg-green ml-3"></span>
                          </p>
                        </div>
                        <div className="p-3 pb-5 md:mt-14 h-auto bg-[#fff] rounded-md">
                          <MyCalender setRoute={result} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <section className="">
          <div className="relative">
            <img
              alt="bgimg"
              className="w-full absolute left-0 top-0 h-full z-0 object-cover"
              src={HomeBackImage}
            />
            <div className="flex flex-wrap items-center 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-10 py-10">
              <div className="md:w-6/12">
                <div className="text-[#fff]">
                  <p className=" md:text-5xl text-4xl font-bold font-oswald md:leading-[67px] leading-[50px] mb-4">
                    America’s Premier{" "}
                    <span className=" text-green">
                      {" "}
                      Flex League
                      <br className="2xl:block hidden" /> Tennis
                    </span>
                  </p>
                  <p className=" md:text-xl text-base font-light">
                    Competitive tennis at the touch your fingertips!
                    <br /> Simple. Efficient. Convenient. Anywhere.
                  </p>
                  <div className="flex items-center mt-5 md:text-base text-sm">
                    <p className="">Sign Up Now With</p>
                    <span className="h-[1px] w-[149.05px] block bg-[#DDDBDB] text-base ml-3"></span>
                  </div>
                </div>
                <div className="mt-5 flex md:space-x-9 space-x-4">
                  <span
                    type="button"
                    onClick={() => signInWithFacebook()}
                    className=" rounded bg-[#fff] border border-[#DDDBDB] w-[40px] h-[40px] flex items-center justify-center cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className=" h-4"
                      viewBox="0 0 11 19"
                      fill="none"
                    >
                      <path
                        d="M11 1.50005C11 1.36745 10.9473 1.24027 10.8536 1.1465C10.7598 1.05273 10.6326 1.00005 10.5 1.00005H8C6.74112 0.937344 5.5086 1.37544 4.57172 2.21863C3.63485 3.06182 3.06978 4.24155 3 5.50005V8.20005H0.5C0.367392 8.20005 0.240215 8.25273 0.146447 8.3465C0.0526785 8.44027 0 8.56744 0 8.70005V11.3001C0 11.4327 0.0526785 11.5598 0.146447 11.6536C0.240215 11.7474 0.367392 11.8001 0.5 11.8001H3V18.5001C3 18.6327 3.05268 18.7598 3.14645 18.8536C3.24021 18.9474 3.36739 19.0001 3.5 19.0001H6.5C6.63261 19.0001 6.75979 18.9474 6.85355 18.8536C6.94732 18.7598 7 18.6327 7 18.5001V11.8001H9.62C9.73119 11.8017 9.83974 11.7661 9.92848 11.6991C10.0172 11.6321 10.0811 11.5374 10.11 11.4301L10.83 8.83005C10.8499 8.75618 10.8526 8.67872 10.8378 8.60364C10.8231 8.52857 10.7913 8.45788 10.7449 8.39702C10.6985 8.33617 10.6388 8.28676 10.5704 8.25261C10.5019 8.21846 10.4265 8.20048 10.35 8.20005H7V5.50005C7.02487 5.25253 7.14111 5.02317 7.32602 4.85675C7.51093 4.69033 7.75123 4.59881 8 4.60005H10.5C10.6326 4.60005 10.7598 4.54737 10.8536 4.45361C10.9473 4.35984 11 4.23266 11 4.10005V1.50005Z"
                        fill="#2F88FF"
                      />
                    </svg>
                  </span>
                  <span
                    type="button"
                    onClick={() => signInWithGoogle()}
                    className=" rounded bg-[#fff] border border-[#DDDBDB] w-[40px] h-[40px] flex items-center justify-center cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className=" h-4"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_264_7)">
                        <path
                          d="M23.9886 12.2247C23.9886 11.2414 23.9069 10.5239 23.7302 9.77979H12.2391V14.2178H18.9841C18.8482 15.3207 18.1138 16.9816 16.4819 18.0977L16.459 18.2463L20.0923 20.9965L20.344 21.021C22.6558 18.9349 23.9886 15.8654 23.9886 12.2247Z"
                          fill="#4285F4"
                        />
                        <path
                          d="M12.2391 23.9173C15.5436 23.9173 18.3177 22.8543 20.344 21.0206L16.4819 18.0973C15.4484 18.8016 14.0613 19.2932 12.2391 19.2932C9.00254 19.2932 6.25557 17.2071 5.27636 14.3237L5.13282 14.3356L1.35489 17.1925L1.30548 17.3267C3.31811 21.2332 7.4522 23.9173 12.2391 23.9173Z"
                          fill="#39FD13"
                        />
                        <path
                          d="M5.27634 14.3238C5.01797 13.5797 4.86844 12.7824 4.86844 11.9586C4.86844 11.1347 5.01797 10.3375 5.26275 9.59342L5.25591 9.43494L1.43063 6.53223L1.30547 6.59039C0.475969 8.21149 0 10.0319 0 11.9586C0 13.8853 0.475969 15.7056 1.30547 17.3267L5.27634 14.3238Z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M12.2391 4.62403C14.5373 4.62403 16.0875 5.59402 16.9715 6.40461L20.4256 3.10928C18.3042 1.1826 15.5436 0 12.2391 0C7.4522 0 3.31811 2.68406 1.30548 6.59057L5.26276 9.59359C6.25558 6.7102 9.00254 4.62403 12.2391 4.62403Z"
                          fill="#EB4335"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_264_7">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span
                    type="button"
                    onClick={() => signInWithApple()}
                    className=" rounded bg-[#fff] border border-[#DDDBDB] w-[40px] h-[40px] flex items-center justify-center cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className=" h-4"
                      viewBox="0 0 19 23"
                      fill="none"
                    >
                      <path
                        d="M15.6021 21.505C14.4179 22.6529 13.125 22.4717 11.8804 21.9279C10.5633 21.3721 9.35499 21.3479 7.96541 21.9279C6.22541 22.6771 5.30708 22.4596 4.26791 21.505C-1.62876 15.4271 -0.758755 6.17125 5.93541 5.83292C7.56666 5.9175 8.7025 6.72708 9.65708 6.79958C11.0829 6.50958 12.4483 5.67583 13.9708 5.78458C15.7954 5.92958 17.1729 6.65458 18.0792 7.95958C14.3092 10.2192 15.2033 15.1854 18.6592 16.575C17.9704 18.3875 17.0762 20.1879 15.59 21.5171L15.6021 21.505ZM9.53624 5.76042C9.35499 3.06583 11.5421 0.8425 14.0554 0.625C14.4058 3.7425 11.2279 6.0625 9.53624 5.76042Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <span
                    type="button"
                    onClick={() => {
                      navigate("/signup");
                      otpscreenremove(false);
                      setsocialType("email");
                    }}
                    className=" rounded bg-[#fff] border border-[#DDDBDB] w-[40px] h-[40px] flex items-center justify-center cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className=" h-4"
                      viewBox="0 0 20 16"
                      fill="none"
                    >
                      <path
                        d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V2C0 1.45 0.196 0.979333 0.588 0.588C0.979333 0.196 1.45 0 2 0H18C18.55 0 19.021 0.196 19.413 0.588C19.8043 0.979333 20 1.45 20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.021 15.8043 18.55 16 18 16H2ZM18 4L10.525 8.675C10.4417 8.725 10.354 8.76233 10.262 8.787C10.1707 8.81233 10.0833 8.825 10 8.825C9.91667 8.825 9.82933 8.81233 9.738 8.787C9.646 8.76233 9.55833 8.725 9.475 8.675L2 4V14H18V4ZM10 7L18 2H2L10 7ZM2 4.25V2.775V2.8V2.787V4.25Z"
                        fill="#39FD13"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              {/* <div className="md:w-6/12 md:mt-0 mt-8">
                <img className=" w-full md:pl-6" src={bannerimage} />
              </div> */}
            </div>
          </div>

          {/* chat ui */}

          <div className="relative">
            <img
              alt="calenderimg"
              className="w-full absolute left-0 top-0 h-full z-0 object-cover"
              src={CalenderBackImage}
            />
            <div className="flex flex-wrap md:flex-row flex-col-reverse items-center 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-10 py-10">
              <div className=" md:w-6/12 md:mt-0 mt-8">
                <img  alt="messageimg" className=" w-full" src={message} />
              </div>
              <div className=" md:w-6/12 relative">
                <div className=" text-center text-[#fff]">
                  <div className=" relative">
                    <p className="absolute w-full leading-[50px] left-[0] top-[0] z-0 opacity-30 md:text-[7rem] text-[4rem] font-[inter] outline text-center text-green">
                      CHAT
                    </p>
                    <p className="md:text-[54px] text-[34px] font-nunito font-bold leading-[55px] mb-6 relative">
                      Easily Schedule your <br /> Matches From Your Phone
                    </p>
                  </div>
                  <p className="md:text-base text-sm text-[#C4C9CC] leading-[24px] 2xl:px-24 xl:px-20 lg:px-14">
                    Easily Schedule matches through the FLT App!
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* match ui */}
          <div className="relative">
            <img
              alt="messageimg"
              className="w-full absolute left-0 top-0 h-full z-0 object-cover"
              src={MessageBackImage}
            />
            <div className="flex flex-wrap items-center 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-10 py-10">
              <div className=" md:w-7/12 relative">
                <div className=" text-center text-[#000]">
                  <div className=" relative">
                    <p className="absolute w-full leading-[50px] left-[0] top-[0] z-0 opacity-30 md:text-[7rem] text-[4rem] font-[inter] outline text-center text-green">
                      MATCHES
                    </p>
                    <p className="md:text-[54px] text-[34px] font-nunito font-bold leading-[55px] mb-6 z-10 relative">
                      Easily Manage Your <br /> Schedule
                    </p>
                  </div>
                  <p className="md:text-base text-sm text-[#C4C9CC] leading-[24px] 2xl:px-24 xl:px-20 lg:px-14">
                    Manage your matches through a shared calendar.
                  </p>
                </div>
              </div>
              <div className=" md:w-5/12 md:mt-0 mt-8">
                <img alt="callenderimg" className=" w-full" src={calender} />
              </div>
            </div>
          </div>

          {/*welcome ui */}

          {/* <div className="relative">
            <img
              className="w-full absolute left-0 top-0 h-full z-0 object-cover"
              src={player}
            />
            <div className="flex flex-wrap items-center 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-10 py-28">
              <div className="md:w-6/12 mx-auto text-center text-[#fff]">
                <p className="md:text-[54px] text-[34px] font-nunito font-bold leading-[55px] mb-6">
                  Welcome To Premier League Tennis
                </p>
                <p className="md:text-base text-sm leading-[24px]">
                  Praesent elementum erat vitae risus pellentesque consectetur.
                  Donec faucibus enim imperdiet tellus tempus consequat.
                </p>
                <div className=" mt-14 block">
                  <button
                    onClick={() => {
                      setloginNoEmail(false);
                      navigate("/login");
                    }}
                    className="bg-[#fff] text-[#000] md:text-base text-sm xl:px-[40px] px-[20px] py-[6px] rounded"
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </section>
      )}
    </>
  );
});
export default Homepage;
