/* eslint-disable jsx-a11y/alt-text */
import { observer } from "mobx-react-lite";
import React from "react";
import bgroger from "../assets/image/imgggggggggggggggg.webp";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Moment from "react-moment";
import moment from "moment";
import { useMyMatchStore } from "../MyMatch/Store/MyMatchStore";
import { FaAngleLeft } from "react-icons/fa";
import { useAuthStore } from "../Auth/store/Auth";

const MatchHistory = observer(() => {
  //   const { loadMatch, nolist, getMatchList, Matchlist, division_ranks } =
  //     useDivisionStore();
  const { id, leagueid, leaguename, playername ,leaguetype } = useParams();
  const { authapproved, user } = useAuthStore();

  const { gettoken, getuser, GetMatchHistory, MatchHistory, route,loadMatchHistory,nohistorylist } =
    useMyMatchStore();

  useEffect(() => {
    if (authapproved) {
      gettoken(localStorage.getItem("token"));
      getuser(JSON.parse(localStorage.getItem("user")));
    }
  });

  useEffect(() => {
    // if (saved) {
    //   navigate("../leaguemanagement")
    // }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    GetMatchHistory(id, leagueid);
  }, []);

  return (
    <>
      {/* <Filter /> */}
      {/* {!nolist ? ( */}
      <div className="relative h-full">
        <img
        alt=""
          className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top brightness-50"
          src={bgroger}
        />

        <div className="relative  z-1 py-5">
          <div className="block">
            <div className="align-baseline ">
              {route[0] === "/playerdetail" ? (
                <Link to={`/playerdetail/${id}`} className=" ">
                  <p className="flex items-center md:pl-20 pl-3 top-5 text-[20px] text-[#ffffff]  ">
                    <FaAngleLeft /> Back to Player
                  </p>
                </Link>
              ) : (
                <Link to={`/mymatch/${leagueid}`} className=" ">
                  <p className="flex items-center md:pl-20 pl-3 top-5 text-[20px]  text-[#ffffff]  ">
                    <FaAngleLeft /> Back to Matches
                  </p>
                </Link>
              )}
            </div>

            <div className="md:px-36 pb-1 md:mt-3  ">
              <p className=" text-center text-[#fff] text-lg md:text-3xl  capitalize">
                {/* {leaguesiddata.replace("mixed", "(Mixed)")} */}
                {/* {leaguesiddata?.replace("(Female)", "")?.replace("(Male)", "")} */}
                Match History for{" "}
                <span className="text-green">{playername}</span> In{" "}
                <span className="text-green">
                  {leaguename?.replace("(Female)", "")?.replace("(Male)", "")}
                </span>
                <br />
              </p>
            </div>
          </div>
          <div className="md:px-9 px-3">
            <div
              className=" bg-[#6C6C6C] flex-grow flex-shrink-0 border-2 border-green bg-opacity-40 rounded-lg my-5 max-h-[450px] overflow-auto "
              id="style-1"
            >
              <table className="w-full relative whitespace-nowrap  text-[black] ">
                <thead className="sticky top-0">
                  <tr className="bg-green top-0 md:text-[16px]  text-sm   ">
                    <th className="py-3   px-5 ">#</th>
                    <th className="md:text-left md:py-3">Opponent</th>
                    {leaguetype !=  "singles" &&
                    <th className="md:text-left md:py-3">Mate</th>
                    }
                    <th className="md:text-left md:py-3">Round</th>
                    <th className="md:text-left  ">Played On</th>
                    <th className="md:text-left">Home Court</th>
                    <th className="md:text-left">Result</th>
                  </tr>
                </thead>
                {loadMatchHistory && (
                            <>
                              <tr>
                                <td
                                  colSpan={7}
                                  className="animate-pulse py-6 bg-[#ffffff21]"
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={7}
                                  className="animate-pulse py-6 bg-[#ffffff36]"
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={7}
                                  className="animate-pulse py-6 bg-[#ffffff21]"
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={7}
                                  className="animate-pulse py-6 bg-[#ffffff36]"
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={7}
                                  className="animate-pulse py-6 bg-[#ffffff21]"
                                ></td>
                              </tr>
                              <tr>
                                <td
                                  colSpan={7}
                                  className="animate-pulse py-6 bg-[#ffffff36]"
                                ></td>
                              </tr>
                            </>
                          )}
                <tbody className="text-center md:text-[16px] text-sm  bg-[black] text-[white]">
                  {/* {nolistHistory && ( */}
                  {MatchHistory.map((res, index) => (
                    <tr
                      key={index}
                      className="hover:bg-[#39fd13] hover:bg-opacity-10 cursor-default "
                    >
                      <td>{index + 1}</td>
                      <td className="text-left py-4">

                        {res?.opponent.map((playername, index) => (
                          <span
                            key={index}
                            onClick={(e) => {
                              // RedirectChanged(`/mymatch/${id}`, true);
                              // navigate(
                              //   `/playerdetail/${playername?._id}`
                              // );
                            }}
                            className="capitalize"
                          >
                            {(index ? " & " : "") + playername?.firstName}{" "}
                            {playername?.lastName}
                          </span>
                        ))}
                        {/* {res.opponent.firstName} {res.opponent.lastName} */}
                      </td>
                      {leaguetype !=  "singles" && res?.mate != undefined &&
                         <td className="text-left capitalize py-4 pr-3">
                         {res?.mate?.firstName + " " + res?.mate?.lastName}
 
                         {/* {res.opponent.firstName} {res.opponent.lastName} */}
                       </td>
                      }
                   
                      {/* {playername?._id != user?._id ? <>{playername.firstName} {playername.lastName},</>  :"" }  */}
                      {/* {leaguetype != "singles" ? ( */}
                      {/* <td>
                                  <span
                                    title="View Profile"
                                    className="capitalize cursor-pointer  hover:text-green"
                                    onClick={(e) => {
                                      navigate(
                                        `/playerdetail/${res?.mate?._id}`
                                      );
                                    }}
                                  >
                                    {res?.mate?.firstName} {res?.mate?.lastName}
                                  </span>
                                </td> */}
                      {/* ) : (
                                ""
                              )} */}
                              <td className="text-left capitalize"> {res?.matchType}</td>
                      <td className="md:text-left md:px-0 px-5 py-4">
                        {" "}
                        {res?.schedule_date !== null ? (
                          <span
                            className="text-left flex"
                            title="Schedule Dates"
                          >
                            <Moment
                              format={
                                moment(res?.schedule_date)
                                  .format("MMM D")
                                  .includes("May")
                                  ? "MMM DD"
                                  : "MMM. DD"
                              }
                            >
                              {res?.schedule_date}
                            </Moment>
                            <p className="px-1"> @ </p>
                            {moment(res?.schedule_time).format("hh:mm A")}
                          </span>
                        ) : (
                          "N/A"
                        )}{" "}
                      </td>

                      <td className="py-4 md:px-0 px-5 md:text-left ">
                        {res?.home_court?.displayName}
                      </td>

                      <td className="md:text-left">
                        {" "}
                        <p className="text-[white]">
                          {res.match_result == "loss" ||
                          res.match_result == "win" ? (
                            <>
                              <span className="border border-green text-[white]  2xl:mx-2 mx-[1px] lg:p-1">
                                {res?.team_first_score?.set_one}-
                                {res?.team_second_score?.set_one}
                              </span>
                              <span className="border border-green text-[white]  2xl:mx-2 mx-[1px] lg:p-1">
                                {res?.team_first_score?.set_two}-
                                {res?.team_second_score?.set_two}
                              </span>
                              {res?.team_first_score?.set_three ||
                              res?.team_second_score?.set_three != null ? (
                                <span className="border border-green text-[white] 2xl:mx-2 mx-[1px] lg:p-1">
                                  {res?.team_first_score?.set_three}-
                                  {res?.team_second_score?.set_three}
                                </span>
                              ) : (
                                ""
                              )}
                          {"   "}    - {" "}
                              {res?.match_result === "loss" ? (
                                <span className="text-[white]  2xl:mx-2 mx-[1px] lg:p-1">
                                  Lost
                                </span>
                              ) : (
                                <span className="text-[white]  2xl:mx-2 mx-[1px] lg:p-1">
                                  Won
                                </span>
                              )}
                                 <span className="text-[white]">
                                {res?.updatedBy === "admin" && <span >(System)</span> } 
                                </span>
                            </>
                          ) : res?.result === "score" ? (
                            <>
                              <span className="border border-green text-[white]  2xl:mx-2 mx-[1px] lg:p-1">
                                {res?.team_first_score?.set_one}-
                                {res?.team_second_score?.set_one}
                              </span>
                              <span className="border border-green text-[white] 2xl:mx-2 mx-[1px] lg:p-1">
                                {res?.team_first_score?.set_two}-
                                {res?.team_second_score?.set_two}
                              </span>
                              {res?.team_first_score?.set_three ||
                              res?.team_second_score?.set_three != null ? (
                                <span className="border border-green text-[white] 2xl:mx-2 mx-[1px] lg:p-1">
                                  {res?.team_first_score?.set_three}-
                                  {res?.team_second_score?.set_three}
                                </span>
                             
                              ) : (
                                ""
                              )}
                                 <span className="text-[white]">
                                {res?.updatedBy === "admin" && <span >(System)</span> } 
                                </span>
                            </>
                          ) : res?.is_score_update === false ? (
                            <span className=" capitalize">-</span>
                          ) : (
                            <span className=" capitalize">
                            {res?.result === "Forfieted" ? (
                              <span>Forfeited By  {res?.forfiet_by !== "Opponent" ? playername +   `${leaguetype ==  "singles"  ? "":" team" }` : res?.forfiet_by  } {res.updatedBy === "admin" && <span >(System)</span> }  </span>
                            ) : res?.result === "withdrew" ? (
                              <span>Withdrew By  {res?.Withdraw_by !== "Opponent" ? playername +   `${leaguetype ==  "singles"  ? "":" team" }` : res?.Withdraw_by  } {res.updatedBy === "admin" && <span >(System)</span> } </span>
                            ) : (
                              res?.result === "byAdmin" ? (
                                <>
                                <span>{res?.winByAdmin !== "Opponent" ? playername + `${leaguetype ==  "singles"  ? "":" team" }` : res?.winByAdmin   } </span>
                                {" "}
                                <span >(System)</span>
                                </>
                              ):
                              "TBA"
                            )}
                          </span>
                          )}
                        </p>
                      </td>
                    </tr>
                  ))}
                  {/* )} */}
                  {/* {noSeasonList && (
                            <tr>
                              <td colSpan={6} className="py-20 ">
                                <p className=" text-center font-semibold text-3xl">
                                  You Have Not Played Any Matches Yet
                                </p>
                              </td>
                            </tr>
                          )} */}
                  {nohistorylist && (
                    <tr>
                      <td colSpan={7} className="py-20 ">
                        <p className=" capitalize text-center font-semibold text-3xl">
                          {playername} Has Not Played Any Matches Yet.
                        </p>
                      </td>
                    </tr>
                  ) }
                </tbody>
              </table>
            </div>
            {/* <div className="text-[#3636364d] flex justify-center mt-40 text-3xl ">
                    You have not played any season yet
                  </div> */}
          </div>
        </div>
      </div>
    </>
  );
});
export default MatchHistory;
