/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useAuthStore, AuthStoreContext, AuthStore } from "./store/Auth";
// import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
// import loginbg from "../assets/image/loginbg.webp"
import bground from "../assets/image/imgggggggggggggggg.webp";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { useUpcomingLeagueStore } from "../UpcomingLeague/Store/UpcomingLeagueStore";

// const SignUp = observer(() => {
//   return(
//     <AuthStoreContext.Provider value={new AuthStore()}>
//       <Screen/>
//     </AuthStoreContext.Provider>
//   )
// })
// export default SignUp;

const SignUp = observer(() => {
  const navigate = useNavigate();

  const {
    signUpWithEmail,
    loginLoading,
    errSigningIn,
    errSigningup,
    otpscreen,
    verifybtn,
    OTP,
    isChecked,
    emailChanged,
    passwordChanged,
    loginNoEmail,
    otpTimeStatus,
    setotpTimeStatus,
    resendOtp,
    upcomingleauge,
    setOTP,
    getsignupemail,
    otpVerify,
    setloginNoEmail,
    isEmailverfied,
    authapproved,
    upcomingcity,
    user
  } = useAuthStore();
  const {UpcomingLeagueid } = useUpcomingLeagueStore();
  useEffect(() => {
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    })
    if (authapproved) {
      if(upcomingleauge  &&  upcomingcity == user?.city){
        navigate(`/register/${UpcomingLeagueid}`);
      }
      else{
        navigate("/");
      }
    }
    if(isEmailverfied){
      navigate("/verify")
    }
    if(!isChecked){
      emailChanged("");
      passwordChanged("")
    }
    if(loginNoEmail){
      navigate("/signup")
    }
  }, [authapproved, isChecked, isEmailverfied, loginNoEmail,UpcomingLeagueid]);

  const [Resend,setResend] = useState(false)
  const [timeLeft, setTimeLeft] = useState(20);
  useEffect(() => {
    if(Resend) {
      if(timeLeft > 0){
        const interval = setInterval(() => {
          setTimeLeft((prevCounter) => prevCounter - 1);
        }, 1000);
        return () => clearInterval(interval);
      } 
      else{
        setotpTimeStatus(false)
        setResend(false)
        setTimeLeft(20)
      }
    }
  },[Resend,timeLeft,otpTimeStatus])
  
  useEffect(() => {
    if(OTP.otp?.length === 4 && otpscreen){
      otpVerify()   
    }
  
  }, [OTP])

  return (
    <>
      <div className="relative py-9 min-h-[86vh] flex items-center">
          <img alt="" className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top contrast-50" src={bground}/>
          <div className="w-full 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-1">
              <div className="flex flex-wrap">
                <div className="2xl:w-[40%] lg:w-2/4 md:w-[60%] w-full 2xl:max-w-[680px] mx-auto">
                  <div className=" bg-[#fff] rounded-lg lg:px-10 md:px-5 px-4 py-10">
                      {!otpscreen
                      ?
                      <form onSubmit={(e) => {signUpWithEmail();e.preventDefault();}}>
                      <div className=" text-center mb-10">
                        <p className=" font-semibold 2xl:text-2xl text-2xl after-line relative">Sign Up With Email</p>
                      </div>

                      <input
                      autoComplete="username"
                      required
                      onChange={(e) => getsignupemail(e.target.value)}
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                      placeholder="Email"
                      type="email"
                      autoFocus
                      />
                      <p className=" text-center font-normal text-sm text-[#7E7777] my-4">You will receive a One Time Password (OTP) on this e-mail address.</p>
                      <label className="label">
                        <span className=" text-xs text-[#f33]">{errSigningIn}</span>
                      </label>
                      <div className="text-center mt-10">
                      {!loginLoading
                        ?
                        <button className=" font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto" type="submit">Get OTP</button>
                        :
                        <button type="button" className="font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto flex justify-center items-center">
                            <svg
                                className="animate-spin mr-1 h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                >
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                            Generating OTP..
                        </button>
                        }
                        <p className="mt-4">
                          Already have an account? <Link onClick={()=>setloginNoEmail(false)} className=" underline" to="/login">Login</Link>
                        </p>
                      </div>
                  </form>
                  :
                  <form onSubmit={(e) => {otpVerify();e.preventDefault();}}>
                          <div className=" text-center mb-10">
                            <p className=" font-semibold 2xl:text-2xl text-2xl after-line relative">Enter OTP</p>
                            <p className=" font-normal text-sm text-[#7E7777] my-7">We have sent a OTP (One Time Password) on your email address. Please enter that OTP here</p>
                          </div>
                          <div className=" flex items-center justify-center otp-inputs">
                          <OtpInput
                            value={OTP.otp}
                            onChange={setOTP}
                            numInputs={4}
                            shouldAutoFocus
                            isInputNum="true"
                          />
                          </div>
                          <p className=" text-center font-normal text-sm text-[#7E7777] my-8">
                          {!Resend
                          ?
                          <span className=" underline cursor-pointer" onClick={() => {resendOtp();setResend(true)}}>Resend </span>
                          :
                          <span className=" opacity-60 underline cursor-pointer">Resend </span>
                          }
                          OTP {otpTimeStatus && <span className=" text-green">in {timeLeft} Sec</span>} </p>
                          <label className="label text-center block">
                            <span className=" text-xs text-[#f33]">{errSigningup}</span>
                          </label>
                          <div className="text-center mt-6">
                          {!loginLoading
                            ?
                            (
                              verifybtn
                              ?
                              <button className=" font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto" type="submit">Verify</button>
                              :
                              <button type="button" disabled className=" opacity-50 font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto">Verify</button>
                            )
                            :
                            <button type="button" className="font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto flex justify-center items-center">
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Verifying..
                            </button>
                            }
                          </div>
                      </form>}
                  </div>  
                </div>
              </div>
          </div>
      </div>
    </>
  )
})

export default SignUp;
      

