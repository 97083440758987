import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { RegisterStore, RegisterStoreContext, useRegisterStore } from '../store/RegisterStore';
import Moment from 'react-moment';
import { useAuthStore } from '../../Auth/store/Auth';
// import { useLeagueStore } from '../../Leaguespage/store/Leaguestore';
// import { toast } from 'react-toastify';

const Receipt = observer(() => {
  const {notificationNo,paymentapi,paymentdata,leaguesiddata,paymentstatus,paymentwithcode,postTransactionStatus,transactionid,transactionStatus} = useRegisterStore()
  const { getNotification} = useAuthStore();
  // console.log("paymentstatus",transactionStatus.subcription)
  // const {  } = useLeagueStore();
// console.log("leaguesiddata",leaguesiddata)
const navigate = useNavigate();
const [showInput, setShowInput] = useState(false);
const [failed,setFailed]=useState(0);
  useEffect(() => {
    // getLeaguesid()
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  }, [])



  useEffect(() => {
    setTimeout(() => {
      if(notificationNo){
        getNotification()
        }
  
    }, 1000);

  }, [notificationNo])

  function paymentpending(){
   
    if(paymentstatus===2 ){
      function timedInterval(task, delay, timeout) {
        let intervalId;
       
        function intervalHandler() {
          task();
          intervalId = setTimeout(intervalHandler, delay);
        }
        
        intervalHandler();
        setTimeout(() => {
          clearTimeout(intervalId);
          
        }, timeout);
      
        return intervalId;
      }
      // const api = postTransactionStatus()
      // Usage example
      timedInterval(() => {
  
        postTransactionStatus()
        // setFailed(failed+1)
        // console.log("failed",failed)
      }, 5000, 15000);
     
    }
  }
   useEffect(()=>{
      paymentpending();
    },[paymentstatus])
 
    const [text, setText] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setText(true);
      }, 20000);
  
      return () => {
        clearTimeout(timer);
      };
    }, []);
  
  
// setTimeout(() => {
//  if(transactionStatus.payment_status ==="pending") {
//   navigate("/")

//  }
// }, 20000); 
  return (
    <div className="w-full 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-1 py-10">
    <div className="flex flex-wrap justify-center">
      <div className="2xl:w-[80%] lg:w-[80%] md:w-[90%]  w-full 2xl:max-w-[90%]">
        <div className=" bg-[#6C6C6C] border border-[#fff] bg-opacity-40 rounded-lg lg:px-10 md:px-5 px-4 md:pt-10 py-5">
         {paymentstatus === 2
         ?
         <>
         {transactionStatus.payment_status ==="complete"?
         <>
            <div className=" text-center">
              <p className=" text-[#fff] font-Playfair font-medium md:text-5xl text-2xl relative">
                Thank You !
              </p>
            </div>
            <div>
              <p className=" py-4 text-[#fff] font-thin text-center">
              {paymentwithcode ? "Your Registration is Successful" : " Your Payment Has Been Received"}
              </p>
            </div>
            <div className="grid gap-4 md:grid-cols-2 text-[#fff] my-10 md:w-7/12 mx-auto">
        
              <p className="col-span-1 ">League</p>
              <p className=" capitalize">
              {leaguesiddata?.replace("(Female)","")?.replace("(Male)","") }
              </p>

              <p className="col-span-1 ">Register Date</p>
              <p className="">
              {/* {transactionStatus.subcription?.subcription_date} */}
              <Moment format="MMMM Do, YYYY" />
              </p>

              <p className="col-span-1 ">Transaction ID</p>
              <p className="">{transactionStatus?.subcription?.transection_id ?? transactionid }</p>

              <p className="col-span-1 ">Fees Paid</p>
              <p className="">${transactionStatus.subcription?.fees_amount}</p>
              </div>
            </>
         :
         transactionStatus.payment_status ==="failed"?
         <>
            <div className=" text-center">
              <p className=" my-5 text-[Red] font-Playfair font-medium md:text-5xl text-2xl relative">
               Payment Is Failed !
              </p>
              <p  className=' text-[#fff] font-medium md:text-lg text-2xl'>Sorry, your payment could not be processed due to the following reason:
              <br className='md:block hidden'/>
               An error occurred while processing the card.
               <br className='md:block hidden'/>
               Please try registering for League again.</p>
            </div>
            </>
            :
            <>
            {text===true
            ?
            <div>
            <div className=" text-center ">
              <p className=" my-5 flex items-center justify-center text-[#fff] font-Playfair font-medium md:text-5xl text-2xl relative">
              Your Payment Is Still Processing...
              </p>
              <p className='text-[#fff] font-medium md:text-lg text-2xl'> <span className='underline decoration-green'>It has taken longer than expected but haven't received any response from you bank yet.</span>
              <br className='md:block hidden'/>
             Don't worry we are keeping an eye and notify you via email when the payment is settled
             <br className='md:block hidden'/>
              You can continue using the website. </p>
            
            </div>
            </div>
            :
            <div className=" text-center ">
              <p className=" my-5 flex items-center justify-center text-[#fff] font-Playfair font-medium md:text-5xl text-2xl relative">
              Your Payment is Processing...
              <svg
                              className="animate-spin text-[#fff] ml-2 h-10 w-10 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              >
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                          </svg> 
              </p>
              <p className='text-[#fff] font-medium md:text-lg text-2xl'>We are waiting to hear back from your bank and it may take a few mins
              <br className='md:block hidden'/>
              Please sit back and relax, and please DO NOT leave or refresh the page.</p>
             
            
                     
            </div>
             }
            
            </>
         }
       
         {/* <div className=" text-center ">
              <p className=" my-5 text-[#fff] font-Playfair font-medium md:text-5xl text-2xl relative">
               Your Payment is Pending 
              </p>
            </div> */}
            </>
            :
            <>
            <div className=" text-center">
              <p className=" text-[#fff] font-Playfair font-medium md:text-5xl text-2xl relative">
                Thank You !
              </p>
            </div>
            <div>
              <p className=" py-4 text-[#fff] font-thin text-center">
              {paymentwithcode ? "Your Registration is Successful" : " Your Payment Has Been Received"}
              </p>
            </div>
            <div className="grid gap-4 md:grid-cols-2 text-[#fff] my-10 md:w-7/12 mx-auto">
        
              <p className="col-span-1 ">League</p>
              <p className=" capitalize"> {leaguesiddata?.replace("(Female)","")?.replace("(Male)","") }</p>

              <p className="col-span-1 ">Register Date</p>
              <p className="">
              {/* {paymentdata.subcription?.subcription_date} */}
              <Moment format="MMMM Do, YYYY" />
              </p>

              <p className="col-span-1 ">Transaction ID</p>
              <p className="">{transactionStatus.subcription?.transection_id ?? transactionid }</p>

              <p className="col-span-1 ">Fees Paid</p>
              <p className="">${paymentdata.subcription?.fees_amount}</p>
              </div>
            </>

            }
            {/* {failed===3?<h1>Failed</h1>:""} */}
            <div className="text-center mt-7">
                  <Link  className="text-[#39FD13] cursor-pointer" to="/">Go back for leagues</Link>
            </div>
         
        </div>
      </div>
    </div>
  </div>
  )
})

export default Receipt