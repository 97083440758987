import React, { useEffect } from "react";
import { observer } from "mobx-react";
import ContactForm from "./ContactForm";
import Logo from "../assets/image/logowhite.webp";
import UpcomingLeagues from "../assets/image/UpcomingLeague.webp";

const ContectUs = observer(() => {

    return(
      <div className="relative">
        <img
          alt=""
          className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top brightness-50"
          src={UpcomingLeagues}
        />
        <div className="py-2 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-10">
            <div className=" text-center">
                <p className=" font-semibold 2xl:text-2xl text-2xl after-line relative text-[#fff]">Contact Us</p>
            </div>
            <div className="my-10" id="cmsdata">
              <div className=" flex flex-wrap bg-[#6C6C6C] bg-opacity-40 rounded-2xl border border-green p-10">
                <div className=" md:w-6/12 w-full form-input-color">
                <div className="">
                  <p className="2xl:text-2xl font-semibold text-xl text-[#fff]">
                  We are here for you! How can we help you?
                  </p>
                </div>
                  <ContactForm/>
                </div>
                <div className=" md:w-4/12 w-full ml-auto">
                  <div className="">
                    <p className="2xl:text-2xl font-semibold text-xl text-[#fff]">
                      Contact Details
                    </p>
                  </div>
                  <div className="my-10 text-[#fff]">
                    <img alt="" className="max-w-[150px] md:w-auto w-[156.42px]" src={Logo}/>

                    <p className=" font-semibold text-base mt-10 text-green">EMAIL address:</p>
                    <p className=" text-base">Support@FlexLeagueTennis.com</p>
                    {/* <p className=" font-semibold text-base mt-10 text-green">Mailing address :</p>
                    <p className=" text-base">P.O. Box </p>
                    <p className=" text-base">Brookhaven, Georgia 30319</p> */}


                    {/* <p className=" font-semibold text-base mt-4 text-green">CONTACT US:</p>
                    <p className=" text-base">+91 000 0000 000</p> */}
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    )
});

export default ContectUs;



