/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useAuthStore } from "./store/Auth";
import { RxEyeClosed, RxEyeOpen } from "react-icons/rx";
// import loginbg from "../assets/image/loginbg.webp"
import bground from "../assets/image/imgggggggggggggggg.webp";
import { Link, useNavigate } from "react-router-dom";
import { useUpcomingLeagueStore } from "../UpcomingLeague/Store/UpcomingLeagueStore";

const Login = observer(() => {
  
  const navigate = useNavigate();
  const [showpassword,setshowpassword] = useState(false);

  const {
    loginuser,
    emailChanged,
    passwordChanged,
    otpscreenremove,
    errLoggingIn,
    loginLoading,
    authapproved,
    upcomingleauge,
    upcomingcity,
    isChecked,
    email,
    user,
    password,
    onChangeCheckbox,
    signInWithGoogle,
    signInWithFacebook,
    signInWithApple,
    isEmailverfied,
    setsocialType,
    gender,
    loginNoEmail,

  } = useAuthStore();

  const {UpcomingLeagueid } = useUpcomingLeagueStore();

  useEffect(() => {
    
    if (authapproved) {
      if(upcomingleauge &&  upcomingcity == user?.city){
        navigate(`/register/${UpcomingLeagueid}`);
      }
      else{
        navigate("/");
      }
    }
    if(isEmailverfied){
      navigate("/verify")
    }
    if(!isChecked){
      emailChanged("");
      passwordChanged("")
    }
    if(loginNoEmail){
      navigate("/signup")
    }
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    })
  }, [authapproved, isChecked, isEmailverfied, loginNoEmail,UpcomingLeagueid]);



  
  return (
    <>
      <div className="relative py-9 min-h-[86vh] flex items-center">
          <img alt="" className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top contrast-50" src={bground}/>
          <div className="w-full 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-1">
              <div className="flex flex-wrap">
                <div className="2xl:w-[40%] lg:w-2/4 md:w-[60%] w-full 2xl:max-w-[680px] mx-auto">
                  <div className=" bg-[#fff] rounded-lg lg:px-10 md:px-5 px-4 py-10">
                   
                    <form autoComplete="new-password" onSubmit={(e) => {loginuser();e.preventDefault();}}>
                          <div className=" text-center">
                            <p className=" font-semibold 2xl:text-2xl text-2xl after-line relative">Sign In</p>
                            <p className=" font-normal text-sm text-[#7E7777] my-7">Please Sign In to Continue. </p>
                          </div>

                          <input
                          autoComplete="username"
                          required
                          value={email}
                          onChange={(e) => emailChanged(e.target.value)}
                          className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                          placeholder="Email"
                          type="email"
                          />

                          <label className=" relative">
                            <input
                            autoComplete="new-password"
                            required
                            value={password}
                            onChange={(e) => passwordChanged(e.target.value)}
                            className="mt-5 bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                            placeholder="Password"
                            type={showpassword ? "text" : "password"}
                            />
                            <span className=" cursor-pointer absolute right-4 top-1" onClick={() => setshowpassword(!showpassword)}>
                              {showpassword
                                  ?
                                  <RxEyeOpen className=" text-[#837575]"/>
                                  :
                                  <RxEyeClosed className=" text-[#837575]"/>
                              }
                            </span>
                          </label>
                          {/* <label className="label">
                            <span className=" text-xs text-[#f33]">{errLoggingIn}</span>
                          </label> */}
                          <div className="mt-5 flex flex-wrap items-center justify-between">
                              <div className=" flex items-center md:space-x-3 space-x-1">
                                <input  
                                id="remember" 
                                checked={isChecked}
                                name="lsRememberMe"
                                onChange={(e) => onChangeCheckbox(e.target.checked)} className="lg:w-[25px] md:w-[20px] w-[15px] lg:h-[25px] md:h-[20px] h-[15px] bg-[#f1f1f1] text-[#837575] border border-[#7E7777] rounded-lg" type="checkbox"/>
                                <label htmlFor="remember" className="text-[#837575] font-medium lg:text-base md:text-sm text-xs">Remember Me</label>
                              </div>
                              <Link to={"/forgotpassword"} className="text-[#837575] font-medium lg:text-base md:text-sm text-xs underline">Forgot Your Password?</Link>
                          </div>
                          <div className="text-center mt-10">
                          {!loginLoading
                            ?
                            <button className=" font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto" type="submit">Sign In</button>
                            :
                            <button type="button" className="font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto flex justify-center items-center">
                                <svg
                                    className="animate-spin mr-1 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    >
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Signing In..
                            </button>
                            }
                          </div>
                          <div className="mt-5 flex items-center justify-center px-4">
                            <div className="h-[1px] bg-[#DDDBDB] w-[30%]"></div>
                              <p className=" text-[#000] md:font-medium text-[10px] md:text-base  px-4">Or Sign Up With </p>
                            <div className="h-[1px] bg-[#DDDBDB] w-[30%]"></div>
                            <div></div>
                          </div>
                          <div className="mt-5 flex items-center justify-center md:space-x-9 space-x-4">
                              <button onClick={() => signInWithFacebook()} type="button" className=" rounded bg-[#fff] border border-[#DDDBDB] px-4 py-3">
                                <svg xmlns="http://www.w3.org/2000/svg" className=" h-4" viewBox="0 0 11 19" fill="none">
                                <path d="M11 1.50005C11 1.36745 10.9473 1.24027 10.8536 1.1465C10.7598 1.05273 10.6326 1.00005 10.5 1.00005H8C6.74112 0.937344 5.5086 1.37544 4.57172 2.21863C3.63485 3.06182 3.06978 4.24155 3 5.50005V8.20005H0.5C0.367392 8.20005 0.240215 8.25273 0.146447 8.3465C0.0526785 8.44027 0 8.56744 0 8.70005V11.3001C0 11.4327 0.0526785 11.5598 0.146447 11.6536C0.240215 11.7474 0.367392 11.8001 0.5 11.8001H3V18.5001C3 18.6327 3.05268 18.7598 3.14645 18.8536C3.24021 18.9474 3.36739 19.0001 3.5 19.0001H6.5C6.63261 19.0001 6.75979 18.9474 6.85355 18.8536C6.94732 18.7598 7 18.6327 7 18.5001V11.8001H9.62C9.73119 11.8017 9.83974 11.7661 9.92848 11.6991C10.0172 11.6321 10.0811 11.5374 10.11 11.4301L10.83 8.83005C10.8499 8.75618 10.8526 8.67872 10.8378 8.60364C10.8231 8.52857 10.7913 8.45788 10.7449 8.39702C10.6985 8.33617 10.6388 8.28676 10.5704 8.25261C10.5019 8.21846 10.4265 8.20048 10.35 8.20005H7V5.50005C7.02487 5.25253 7.14111 5.02317 7.32602 4.85675C7.51093 4.69033 7.75123 4.59881 8 4.60005H10.5C10.6326 4.60005 10.7598 4.54737 10.8536 4.45361C10.9473 4.35984 11 4.23266 11 4.10005V1.50005Z" fill="#2F88FF"/>
                                </svg>
                              </button>
                              <button onClick={()=> signInWithGoogle()} type="button" className=" rounded bg-[#fff] border border-[#DDDBDB] px-4 py-3">
                                <svg xmlns="http://www.w3.org/2000/svg" className=" h-4" viewBox="0 0 24 24" fill="none">
                                <g clipPath="url(#clip0_264_7)">
                                <path d="M23.9886 12.2247C23.9886 11.2414 23.9069 10.5239 23.7302 9.77979H12.2391V14.2178H18.9841C18.8482 15.3207 18.1138 16.9816 16.4819 18.0977L16.459 18.2463L20.0923 20.9965L20.344 21.021C22.6558 18.9349 23.9886 15.8654 23.9886 12.2247Z" fill="#4285F4"/>
                                <path d="M12.2391 23.9173C15.5436 23.9173 18.3177 22.8543 20.344 21.0206L16.4819 18.0973C15.4484 18.8016 14.0613 19.2932 12.2391 19.2932C9.00254 19.2932 6.25557 17.2071 5.27636 14.3237L5.13282 14.3356L1.35489 17.1925L1.30548 17.3267C3.31811 21.2332 7.4522 23.9173 12.2391 23.9173Z" fill="#39FD13"/>
                                <path d="M5.27634 14.3238C5.01797 13.5797 4.86844 12.7824 4.86844 11.9586C4.86844 11.1347 5.01797 10.3375 5.26275 9.59342L5.25591 9.43494L1.43063 6.53223L1.30547 6.59039C0.475969 8.21149 0 10.0319 0 11.9586C0 13.8853 0.475969 15.7056 1.30547 17.3267L5.27634 14.3238Z" fill="#FBBC05"/>
                                <path d="M12.2391 4.62403C14.5373 4.62403 16.0875 5.59402 16.9715 6.40461L20.4256 3.10928C18.3042 1.1826 15.5436 0 12.2391 0C7.4522 0 3.31811 2.68406 1.30548 6.59057L5.26276 9.59359C6.25558 6.7102 9.00254 4.62403 12.2391 4.62403Z" fill="#EB4335"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_264_7">
                                <rect width="24" height="24" fill="white"/>
                                </clipPath>
                                </defs>
                                </svg>
                              </button>
                              <button onClick={()=> signInWithApple()} type="button" className=" rounded bg-[#fff] border border-[#DDDBDB] px-4 py-3">
                                <svg xmlns="http://www.w3.org/2000/svg" className=" h-4" viewBox="0 0 19 23" fill="none">
                                <path d="M15.6021 21.505C14.4179 22.6529 13.125 22.4717 11.8804 21.9279C10.5633 21.3721 9.35499 21.3479 7.96541 21.9279C6.22541 22.6771 5.30708 22.4596 4.26791 21.505C-1.62876 15.4271 -0.758755 6.17125 5.93541 5.83292C7.56666 5.9175 8.7025 6.72708 9.65708 6.79958C11.0829 6.50958 12.4483 5.67583 13.9708 5.78458C15.7954 5.92958 17.1729 6.65458 18.0792 7.95958C14.3092 10.2192 15.2033 15.1854 18.6592 16.575C17.9704 18.3875 17.0762 20.1879 15.59 21.5171L15.6021 21.505ZM9.53624 5.76042C9.35499 3.06583 11.5421 0.8425 14.0554 0.625C14.4058 3.7425 11.2279 6.0625 9.53624 5.76042Z" fill="black"/>
                                </svg>
                              </button>
                              <button onClick={() => {navigate("/signup");otpscreenremove(false);setsocialType("email")}} type="button" className=" rounded bg-[#fff] border border-[#DDDBDB] px-4 py-3">
                                <svg xmlns="http://www.w3.org/2000/svg" className=" h-4" viewBox="0 0 20 16" fill="none">
                                <path d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V2C0 1.45 0.196 0.979333 0.588 0.588C0.979333 0.196 1.45 0 2 0H18C18.55 0 19.021 0.196 19.413 0.588C19.8043 0.979333 20 1.45 20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.021 15.8043 18.55 16 18 16H2ZM18 4L10.525 8.675C10.4417 8.725 10.354 8.76233 10.262 8.787C10.1707 8.81233 10.0833 8.825 10 8.825C9.91667 8.825 9.82933 8.81233 9.738 8.787C9.646 8.76233 9.55833 8.725 9.475 8.675L2 4V14H18V4ZM10 7L18 2H2L10 7ZM2 4.25V2.775V2.8V2.787V4.25Z" fill="#39FD13"/>
                                </svg>
                              </button>
                          </div>
                      </form>
                      
                  </div>  
                </div>
              </div>
          </div>
      </div>
    </>
  )
})
      
export default Login;
