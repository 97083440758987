import React from "react";

const data = [
  {
    name: "Player 1",
    players: [
      {
        name: "Player 2",
        players: [],
      },
    ],
  },
  {
    name: "Player 2",
    players: [    {
      name: "",
      players: [
        {
          name: "Player 4",
          players: [],
        },
      ],
    },],
  },
  {
    name: "Player 3",
    players: [
      {
        name: "Player 4",
        players: [],
      },
    ],
  },
  {
    name: "Player 4",
    players: [   {
      name: "",
      players: [
        {
          name: "",
          players: [ {
            name: "",
            players: [
              {
                name: "Player 5",
                players: [],
              },
            ],
          }],
        },
      ],
    }],
  },
  {
    name: "Player 5",
    players: [
      {
        name: "Player 5",
        players: [
          {
            name: "Player 5",
            players: [],
          },
        ],
      },
    ],
  },
  {
    name: "Player 6",
    players: [],
  },
  {
    name: "Player 7",
    players: [
      {
        name: "Player 7",
        players: [],
      },
    ],
  },
  {
    name: "Bye",
    players: [],
  },
];

const Player = ({ name, players, indentLevel = 0 }) => {
  const indentStyle = {
    marginLeft: `${indentLevel * 110}px`,
    marginTop: `${indentLevel * 10}px`,
  };

  return (
    <div style={indentStyle}>
      <span>
        {name}
      </span>
      {players &&
        players.map((player, index) => (
          <Player
            key={index}
            name={player.name}
            players={player.players}
            indentLevel={indentLevel + 1}
          />
        ))}
    </div>
  );
};

const Trees = () => {
  return (
    <div>
      <div className="my-10 text-center">
        <p>Tree Structure</p>
      </div>

      {data.map((player, index) => (
        <Player
          key={index}
          name={player.name}
          players={player.players}
          indentLevel={0}
        />
      ))}
    </div>
  );
};

const TreeData = () => {
  return (
    <div className="w-full 2xl:px-10 lg:px-5 md:px-3 px-4 relative z-1  ">
      <Trees />
    </div>
  );
};

export default TreeData;
