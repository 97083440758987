import React, { useEffect } from "react";
import { Widget, dropMessages, toggleInputDisabled } from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import { useAuthStore } from "../Auth/store/Auth";
import { Link, useNavigate } from "react-router-dom";
import { MdArrowLeft, MdArrowRight, MdArrowUpward } from "react-icons/md";
import { BiExpand } from "react-icons/bi";
import { MessageStore } from "../Message/store/MessageStore";
import { CommonStore, useCommonStore } from "../Common/Store/CommonStore";
import { useMyMatchStore } from "../MyMatch/Store/MyMatchStore";

function ChatWindow(props) {
  const { handleNewUserMessage, defaultMessageSent, title, Name, id } = props;
  const { authapproved } = useAuthStore();
  const {grpName,grpPlayerName ,setCount } = useCommonStore();
 
  const navigate = useNavigate();
  const { CheckHistory } = useMyMatchStore();
  const { getcurrentID, getGroupOfUser } = MessageStore();
  useEffect(() => {
    if (!authapproved) {
      dropMessages();
    }
  });
  // useEffect(() => {
  // if(title === undefined){
  //   toggleInputDisabled()
  // }
  // },[]);
  return (
    defaultMessageSent && (
      <div className="relative">
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        title={
          <div className="relative">
            <span className="text-5xl capitalize">{title === undefined || title == "" ? "Chat": title?.map((name, index) => (
                      <span className="text-green capitalize" key={index}>
                        {(index ? `,` : "") + " "} {name?.firstName}
                      </span>
                    ))}</span>
            {/* {title === undefined ? (
              <div className="text-[black] text-[23px]  absolute  left-0 right-0 mt-[50%]  translate-y-[-50%]">
                <div>No Messages</div>
              </div>
            ) : null} */}
            {title && (
              <span className=" absolute z-[1] right-14 top-[50%] text-green bottom-[50%] cursor-pointer translate-y-[-50%]">
                <div
                  onClick={() => {
                    getcurrentID(id);
                    getGroupOfUser("");
                    setCount("0");
                    navigate(`/message/${id}`);
                  }}
                  to={`/message/${id}`}
                >
                  <BiExpand className=" text-2xl" />
                </div>
              </span>
            )}

            {CheckHistory === true && title !== undefined ? (
              <span className=" absolute text-green   left-0 right-0 top-[300%] opacity-70 cursor-pointer translate-y-[-50%]">
                <button
                  className=" lg:text-[9px] text-lg text-[#000] bg-green px-3  rounded-lg mx-auto"
                  onClick={() => {
                    getcurrentID(id);
                    getGroupOfUser("");
                    setCount("0");
                    navigate(`/message/${id}`);
                  }}
                  to={`/message/${id}`}
                >
                  View Past Conversations
                </button>
              </span>
            ): null}
          </div>
        }
        subtitle={Name !== undefined ? Name?.replace("(Female)", "")?.replace("(Male)", ""): "Flex League Tennis"}
        render
      ></Widget>
      </div>
    )
  );
}

export default ChatWindow;
