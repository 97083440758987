import { initializeApp } from 'firebase/app';
import {getAuth, Auth} from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyD0jF5GiWQnduNaAp0o8oa01yJAzgJlY5M",
  // authDomain: "premier-league-tennis-68ec0.firebaseapp.com",
  authDomain: "auth.flexleaguetennis.com",
  projectId: "premier-league-tennis-68ec0",
  storageBucket: "premier-league-tennis-68ec0.appspot.com",
  messagingSenderId: "261477689780",
  appId: "1:261477689780:web:af9a156e9c364138786ae1",
  measurementId: "G-TJ3LHJBJN5"
};
const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
export default app;
