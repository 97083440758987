/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import UpcomingLeagues from "../assets/image/UpcomingLeague.webp";
import cardimage from "../assets/image/cardimage.webp";
import doublesimg from "../assets/image/doublesimg.webp";
import mixedimg from "../assets/image/mixedimg.webp";
import { useUpcomingLeagueStore } from "./Store/UpcomingLeagueStore";
import { FaAngleRight } from "react-icons/fa";
import { observer } from "mobx-react";
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthStore } from "../Auth/store/Auth";
import { toast } from "react-toastify";
import { useRegisterStore } from "../Register/store/RegisterStore";
import moment from "moment";
// const UpcomingLeague = () => {
//   const store = new UpcomingLeagueStore({
//     isloading: true,
//   });
//   return (
//     <UpcomingLeagueStoreContext.Provider value={store}>
//       <Screen />
//     </UpcomingLeagueStoreContext.Provider>
//   );
// };
// export default UpcomingLeague;

const UpcomingLeague = observer(() => {
  const {
    getUpcomingLeague,
    Upcomingleaguesss,
    getcitydropdown,
    citydrop,
    getLeaguesid,
  } = useUpcomingLeagueStore();
  const navigate = useNavigate();
  const { id, name } = useParams();
  const { authapproved, genderCheck, setupcomingleauge, setloginNoEmail,setupcomingcity } =useAuthStore();
  const { paymentscreen } = useRegisterStore();

  useEffect(() => {
    if (authapproved) {
      navigate("/");
    } else getUpcomingLeague(id);
  }, [id, authapproved]);

  return (
    <>
      <div className="relative py-9 min-h-[50vh]">
        <img
        alt=""
          className="absolute h-full z-0 top-0 left-0 w-full object-cover object-top brightness-50"
          src={UpcomingLeagues}
        />

        <div className="w-full 2xl:px-28 lg:px-20 md:px-10  relative z-1  ">
          <div>
            <p className=" text-[#fff] mb-24 md:text-3xl text-2xl relative flex items-center ">
              Leagues In {name}
              <span className="h-[2px] w-[39.05px] block bg-green ml-3"> </span>
            </p>

            {/* <p className=" mt-3 text-[#fff] font-medium md:text-2xl text-xl relative">
                      ATLANTA
                    </p> */}
          </div>

          <div className="">
            {Upcomingleaguesss.map((res, index) => (
              <React.Fragment key={index}>
                {res.season.length === 0 ? (
                  <p className="uppercase text-[#39FD13] text-3xl font-bold text-center">
                    No League Found
                  </p>
                ) : (
                  <div className="grid lg:pl-9 px-3 md:px-0 md:pl-0 lg:grid-cols-3 md:grid-cols-3 gap-4 gap-y-20">
                    {res.season.map((league, index) => (                  
                          <React.Fragment key={index}>
                            <div className="card-slider ">
                              <div className="first:mt-0 flex h-full w-full">
                                <>
                                  <div className="w-full">
                                    <div
                                      onClick={() => {
                                        paymentscreen(false);
                                        if (
                                          new Date().toISOString() <
                                          league?.start_registration_date
                                        ) {
                                          toast.error(
                                            "Registration Not Open Yet."
                                          );
                                        } else if (
                                          moment(new Date().toISOString()).format("YYYY-MM-DD") 
                                           >
                                           moment(league?.last_registration_date).format("YYYY-MM-DD")
                                          
                                        ) {
                                          toast.error(
                                            "Registration is Closed."
                                          );
                                        } else {
                                          setloginNoEmail(false);
                                          navigate("/login");
                                          // console.log(league?.league_catagory);
                                          genderCheck(league?.league_catagory);
                                          setupcomingleauge(true);
                                          getLeaguesid(league._id);
                                          setupcomingcity(league?.city)
                                        }
                                      }}
                                      className=" cursor-pointer  w-full h-full relative bg-[#fff] rounded-bl-3xl rounded-tr-3xl  lg:px-10 md:px-5 px-4 py-10"
                                    >
                                      <span className=" absolute bg-green text-[#000] text-sm top-0 left-0 px-3 py-2">
                                        $
                                        {league?.league_type === "singles"
                                          ? league?.fees_amount
                                          : league?.fees_amount * 2}
                                      </span>
                                      <div className="circle-shadow px-1 shadow-md absolute lg:top-[-45px] md:top-[-40px] top-[-45px] right-[50%] left-[50%] aspect-[1] translate-x-[-50%] h-20 bg-[#fff] rounded-full ">
                                        <img
                                        alt=""
                                          className="m-auto h-full "
                                          src={
                                            league?.league_type === "singles"
                                              ? cardimage
                                              : league?.league_type ===
                                                "doubles"
                                              ? doublesimg
                                              : mixedimg
                                          }
                                        />
                                      </div>
                                      <p className=" uppercase lg:mt-5 md:mt-0 mt-5 text-[#39FD13] md:text-3xl text-2xl font-bold">
                                        {league?.league_type}
                                        {/* {league?.league_type !=="mixed"&& "("+league?.league_catagory + ")"}  */}
                                      </p>
                                      <div className="flex justify-between">
                                        {league?.name}

                                        {/* {new Date().toISOString() >= league?.start_registration_date && new Date().toISOString() <= league?.last_registration_date

                                        ?
                                        <Link className="text-[#39FD13] text-2xl ml-5" 
                                        to="/login"
                                        onClick={()=>{console.log(league?.league_catagory); genderCheck(league?.league_catagory);setupcomingleauge(true);getLeaguesid(league._id)}}>
                                            <FaAngleRight />
                                        </Link>
                                        :
                                        (
                                          new Date().toISOString < league?.last_registration_date
                                          ?
                                          <Link className="text-[#39FD13] text-2xl ml-5 "
                                          onClick={()=>{toast.error("Registration is Closed.")}}
                                          >
                                          <FaAngleRight />
                                          </Link>
                                          :
                                          <Link className="text-[#39FD13] text-2xl ml-5 "
                                          onClick={()=>{toast.error("Registration Not Open Yet.")}}
                                          >
                                          <FaAngleRight />
                                          </Link>
                                        )
                                        } */}
                                        <FaAngleRight className="text-[#39FD13] text-2xl ml-5" />
                                      </div>
                                      <p className="text-[#837575]">
                                        {(
                                          <Moment
                                            format={
                                              moment( league?.start_date).format("MMM D").includes('May')
                                                ? "MMM DD"
                                                : "MMM. DD"
                                            }
                                          >
                                            {league?.start_date}
                                          </Moment>
                                        ) ?? "N/A"}
                                        {" "}
                                        -
                                        {" "}
                                        {(
                                          <Moment
                                            format={
                                              moment( league?.end_date).format("MMM D").includes('May')
                                                ? "MMM DD"
                                                : "MMM. DD"
                                            }
                                          >
                                            {league?.end_date}
                                          </Moment>
                                        ) ?? "N/A"}
                                      </p>
                                      {league?.start_registration_date >
                                      new Date().toISOString() ? (
                                        <p className="text-[#837575] text-sm">
                                          Registration opens on - {(
                                            <Moment
                                              format={
                                                moment( league?.start_registration_date).format("MMM D").includes('May')
                                                
                                                  ? "MMM DD"
                                                  : "MMM. DD"
                                              }
                                            >
                                              {league?.start_registration_date}
                                            </Moment>
                                          ) ?? "N/A"}
                                        </p>
                                      ) : (
                                        <p className="text-[#837575] text-sm">
                                          Deadline -
                                         {" "} {(
                                            <Moment
                                              format={
                                                moment( league?.last_registration_date).format("MMM D").includes('May')
                                             
                                                  ? "MMM DD"
                                                  : "MMM. DD"
                                              }
                                            >
                                              {league?.last_registration_date}
                                            </Moment>
                                          ) ?? "N/A"}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </React.Fragment>                    
                    ))}
                  </div>
                )}
              </React.Fragment>
            ))}
            <p className=" text-[#fff] mt-12 mb-1 text-base relative">
            Flex League Tennis is always looking to partner with local non-profits, country clubs, tennis centers, or tennis stores.  If you think you would be a good partner for Flex League Tennis, please contact us at <span><a href="mailto:williamstone@flexleaguetennis.com" className="text-[#39fd13]">williamstone@flexleaguetennis.com</a></span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
});
export default UpcomingLeague;
