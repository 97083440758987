/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState,useRef } from "react";
import { observer } from "mobx-react";
import { useAuthStore } from "../Auth/store/Auth";
import {
  useNavigate,
  Link,
  NavLink,
  useLocation,
  matchPath,
} from "react-router-dom";
import { RxGear, RxTextAlignLeft } from "react-icons/rx";
import socketIOClient from "socket.io-client";
import { toast } from "react-toastify";
import { SlBell } from "react-icons/sl";
import ChatWindow from "../ChatWindow/ChatWindow";
import {
  MdArrowDropDown,
  MdCall,
  MdLogout,
  MdOutlineVpnKey,
} from "react-icons/md";
import Logo from "../assets/image/logowhite.webp";
import demopfp from "../assets/image/demopfp.webp";
import userimage from "../assets/image/placeholder.webp";
import { usePlayerSearchStore } from "../PlayerSearch/Store/PlayerSearchStore";
import { useUpcomingLeagueStore } from "../UpcomingLeague/Store/UpcomingLeagueStore";
import { useRegisterStore } from "../Register/store/RegisterStore";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Notification from "./Notification";
import { MessageStore } from "../Message/store/MessageStore";
import chaticon from "../assets/image/Chaticon.webp";
import { BsChatSquareText } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import {
  Widget,
  addLinkSnippet,
  addResponseMessage,
  dropMessages,
} from "react-chat-widget";
import "react-chat-widget/lib/styles.css";
import { useMyMatchStore } from "../MyMatch/Store/MyMatchStore";
import { useCommonStore } from "./Store/CommonStore";

const Header = observer(() => {
  const location = useLocation();
  const [active, setactive] = useState(false);
  const [SenderName, setSenderName] = useState("");

  const [activecity, setactivecity] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isbell, setIsbell] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const popupRef = useRef();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const { getUpcomingLeague, upcommingcitydrop, getupcomingcitydropdown } =
    useUpcomingLeagueStore();
  const {
    getfirstname,
    getlastname,
    getcityid,
    gettablehide,
    clearsearechlist,
    ListTrue,
    reset,
  } = usePlayerSearchStore();
  const [id, setid] = useState("");
  const {
    user,
    authapproved,
    logout,
    setloginNoEmail,
    setupcomingleauge,
    getNotification,
    RedirectChanged,
    notificationcount,
    getAllNotification,
    deletepopup,
    setdeletepopup,
    deleteload,
    deleteUser,
    deletenav
  } = useAuthStore();
  const { count, getGroupDeatails, gettoken, MessageCount, setCount } =
    useCommonStore();
  const { GroupList, getuser, getMessageId, getcurrentID } = MessageStore();
  const closeButton = document?.querySelector(".rcw-widget-container");
  const widget = document?.querySelector(".rcw-conversation-container");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const {
    matchid,
    grp_Id,
    grpName,
    grpPlayerName,
    getpurpose,
    defaultMessageSent,
    opponent,
    grpPlayer,
    setDefaultMessageSent,
    GetgrpName,
    setmessgae_initate,
  } = useMyMatchStore();
  const pattern = /^\/\w+/;
  const result = location.pathname.match(pattern);

  if (closeButton !== null) {
    if (result === "/message") {
      if (widget !== null) {
        widget.style.display = "none";
      }
      closeButton.style.display = "none";
    } else {
      closeButton.style.display = "block";
      if (widget !== null) {
        widget.style.display = "block";
      }
    }
  }

  useEffect(() => {
    if (authapproved) {
      if (matchid) {
        getGroupDeatails(matchid);
      }
    }
  }, [matchid]);

  useEffect(() => {
    if (!authapproved) {
      // navigate("/");
    } else {
      getNotification();
    }
    gettoken(localStorage.getItem("token"));
    getuser(JSON.parse(localStorage.getItem("user")));
  }, [authapproved]);

  // useEffect(()=>{
  //   if(deletenav){
  //     navigate("/signup"); 
      
  //   }

  // },[deletenav])

  useEffect(() => {
    // Update isMobileView based on the viewport width
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767); // Adjust the breakpoint as needed for mobile view
    };

    // Call the handleResize function on initial load and window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  function handleNewUserMessage(newMessage) {
    const socket = socketIOClient(BASE_URL);
    socket.emit("send_message", {
      name: user?.firstName + " " + user?.lastName,
      user_id: user?._id,
      roomId: matchid,
      message: newMessage,
      dateTime: new Date(),
    });
  }
  useEffect(() => {
    if (authapproved) {
      const socket = socketIOClient(BASE_URL);
      socket.emit("joinChat", { chatId: user?._id });
      socket.on("newMsg", (data) => {
        if (data?.message !== undefined) {
        socket.emit("joinRoom", { roomId: data.roomId });

          toast.success(`${ data?.name[0].toUpperCase() + data?.name.slice(1)} Messaged You`, {
            position: toast.POSITION.TOP_CENTER,
          });
          if (data?.roomId == matchid && data?.message != undefined ) {
            addResponseMessage(data?.message);
          }
        }

        setSenderName(data?.name);

       
        getMessageId(data.roomId);
        // GroupList();
        MessageCount();
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [SenderName, user?._id]);

  useEffect(() => {
    if (authapproved) {
      MessageCount();
    }
  }, [user?._id]);
  const { getleaugesfalse, changeStep, allClear } = useRegisterStore();
  
  useEffect(() => {
    checkUrl();
    checkUrlcity();
  }, [authapproved, location, active, activecity]);
  
  useEffect(() => {
    if (!authapproved) {
      getupcomingcitydropdown();
      checkUrl();
      setIsOpen(false);
      checkUrlcity();
    }
  }, [authapproved]);

  const checkUrl = () => {
    if (location.pathname === "/") {
      setactive(true);
      return;
    }
    if (location) {
      const isActive = /^\/mymatch\/\w+$/.test(location.pathname);
      setactive(isActive);
    } else {
      setactive(false);
    }
  };

  const checkUrlcity = () => {
    if (location) {
      const active = /^\/upcomingleague\/\w+\/\w+$/.test(location.pathname);
      setactivecity(active);
    } else {
      setactivecity(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
  
      if (popupRef.current && !popupRef.current.contains(event.target) && !isbell    ) {
        setIsOpen(false)
    
      }
      setIsbell(false)
   
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  },[isbell]);
  

  return (
    <header className="bg-[#282B2D] md:relative z-50 sticky top-0">
      {/* <div className="bg-[#282B2D] flex justify-between py-4 2xl:px-28 lg:px-20 md:px-10 px-4">
        <a
          href="tel:+91 000 0000 000"
          className="text-[#fff] md:text-base text-xs flex items-center"
        >
          <MdCall className=" text-green mr-3" />
          +91 000 0000 000
        </a>
        {authapproved ? (
          <div className=" flex items-center md:space-x-8 space-x-4">
            <button
              onClick={() => {
               
                navigate(`/myaccount/${user._id}`);
              }}
              className=" bg-[#fff] text-[#000] text-xs px-[10px] py-[5px] rounded"
            >
              My Account
            </button>
            <button
              onClick={() => {
                logout();
                setupcomingleauge(false)
                getleaugesfalse(false);
                changeStep("1");
                allClear()
              }}
              className=" bg-green text-[#000] text-xs px-[10px] py-[5px] rounded"
            >
              Sign Out
            </button>
          </div>
        ) : (
          <div className=" flex items-center md:space-x-8 space-x-4">
            <button
              onClick={() => {
                navigate("/signup");
                otpscreenremove(false);
                setloginNoEmail(false);
                setsocialType("email");
              }}
              className=" bg-[#fff] text-[#000] text-xs px-[10px] py-[5px] rounded"
            >
              Join Free
            </button>
            <button
              onClick={() => {
                setloginNoEmail(false);
                navigate("/login");
              }}
              className=" bg-green text-[#000] text-xs px-[10px] py-[5px] rounded"
            >
              Sign In
            </button>
          </div>
        )}
      </div> */}
      <div className="flex justify-between items-center py-2 2xl:px-28 lg:px-20 md:px-10 px-4 h-[73px]">
        <Link to="/" onClick={() => setloginNoEmail(false)}>
          <img alt="logo" className=" max-w-[150px] md:w-auto w-[156.42px]" src={Logo} />
        </Link>

        <div
          className={` h-full lg:block flex items-center ${
            menuOpen ? "sm:open " : ""
          }`}
        >
          {authapproved && (
            <div className=" text-3xl lg:hidden block text-[#fff]">
              <div className=" h-full flex items-center">
                <div className="popup static flex items-center ">
                  <button
                    type="button"
                    className="dropbtn selectw-full rounded-md focus:outline-offset-0 focus:outline-0 block"
                    onClick={() => {
                      setIsOpen(true);
                      setIsbell(true)
                    }}
                  >
                    <div className="relative">
                      <div
                        onClick={() => {
                          getNotification();
                        }}
                        className=" flex items-center cursor-pointer  w-[36px] aspect-[1]   overflow-hidden ml-3"
                      >
                        <SlBell className=" relative text-green text-2xl" />
                        {notificationcount !== 0 && (
                          <p className="absolute flex justify-center items-center bg-green border w-5 h-6 rounded-[50%] md:top-[-4px] top-[-5px] md:right-[2px] right-[1px] text-[#282B2D] text-[11px]">
                            {notificationcount > 9 ? "9+" : notificationcount}
                          </p>
                        )}
                      </div>
                    </div>
                  </button>
                  {isOpen && (
                    <div  className=" popup-content rounded-lg right-0 overflow-hidden min-w-[100%] z-20">
                      <div
                        id="style-1"
                        className="pt-2 px-1 rounded-xl overflow-auto font-poppins max-h-[350px] bg-[#fff] relative text-[#000]"
                      >
                        <div className="float-right absolute right-[10px] top-[0px] ">
                          <button
                            type="button"
                            onClick={() => setIsOpen(false)}
                            className=" text-[#000] text-2xl font-extralight "
                          >
                            <FaTimes />
                          </button>
                        </div>
                        <Notification isOpen={isOpen} setIsOpen={setIsOpen} />
                      </div>
                      {/* </p> */}
                      {/* </div> */}
                    </div>
                  )}
                </div>
                <div className=" flex items-center cursor-pointer w-[40px] aspect-[1] overflow-hidden">
                  <button
                    className="relative"
                    onClick={() => {
                      // setCount("0");
                      setMenuOpen(false);
                      getcurrentID(undefined);
                      navigate(`/message`);
                    }}
                  >
                    <BsChatSquareText className="text-[#fff] text-2xl" />
                    {count == 0 ? (
                      ""
                    ) : (
                      <p className="absolute flex justify-center items-center bg-[green] bg-opacity-80 border w-5 h-6 rounded-[50%] bottom-[-4px] right-[-8px] text-[#fff]  text-[11px]">
                        {count > 9 ? "9+" : count}
                      </p>
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
          <RxTextAlignLeft
            className=" text-3xl lg:hidden block text-[#fff]"
            onClick={toggleMenu}
          />
          <div className="header-menu lg:flex hidden items-center space-x-12 font-medium 2xl:text-lg lg:text-base text-sm h-full text-[#fff]">
            {!authapproved ? (
              <NavLink
                onClick={() => setloginNoEmail(false)}
                className=" h-full flex items-center relative"
                to="/"
              >
                Home
              </NavLink>
            ) : (
              <NavLink
                to="/"
                className={({ isActive }) =>
                  active
                    ? "h-full flex items-center relative active"
                    : "h-full flex items-center relative"
                }
              >
                My Leagues
              </NavLink>
            )}

            {authapproved ? (
              <>
                <NavLink
                  className=" h-full flex items-center relative"
                  to="/league"
                >
                  Upcoming Leagues
                </NavLink>
                {/* <NavLink
                  className=" h-full flex items-center relative"
                  to="/matchpage"
                >
                  My Matches
                </NavLink>
                <NavLink
                  className=" h-full flex items-center relative"
                  to="/playersearch"
                >
                  Player Search
                </NavLink> */}
                <NavLink
                  className=" h-full flex items-center relative"
                  to="/gettheapp"
                >
                  Get The APP
                </NavLink>

                {/* <button 
                onClick={() => {
                logout();
                setupcomingleauge(false)
                getleaugesfalse(false);
                changeStep("1");
                allClear()
              }} 
              className=" h-full flex items-center relative text-green cursor-pointer">
                  Sign Out
                </button> */}
              </>
            ) : (
              <>
                {/* <div className=" h-full flex items-center relative">
                  <div className="dropdown">
                    <button className="dropbtn select focus:outline-0 w-full rounded-md focus:outline-offset-0 select-bordered">
                      <div className="flex flex-wrap relative">
             
                        <p className=" flex items-center">About Us?
                        <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                        <path d="M5.9998 6.9748C5.86647 6.9748 5.73714 6.9498 5.6118 6.8998C5.48647 6.8498 5.38247 6.78314 5.2998 6.69981L0.699804 2.0998C0.516471 1.91647 0.424805 1.68314 0.424805 1.39981C0.424805 1.11647 0.516471 0.883139 0.699804 0.699805C0.883138 0.516472 1.11647 0.424805 1.3998 0.424805C1.68314 0.424805 1.91647 0.516472 2.0998 0.699805L5.9998 4.5998L9.8998 0.699805C10.0831 0.516472 10.3165 0.424805 10.5998 0.424805C10.8831 0.424805 11.1165 0.516472 11.2998 0.699805C11.4831 0.883139 11.5748 1.11647 11.5748 1.39981C11.5748 1.68314 11.4831 1.91647 11.2998 2.0998L6.6998 6.69981C6.5998 6.79981 6.49147 6.8708 6.3748 6.9128C6.25814 6.9548 6.13314 6.97547 5.9998 6.9748Z" fill="white"/>
                        </svg>
                        </p>
                      </div>
                    </button>
              
                    <div className="dropdown-content rounded-lg bg-[#f1f1f1] overflow-hidden">
                        <p className="hover_Leauge">
                        <Link to={`/aboutus`}>
                          <p className="px-3">About</p>
                        </Link>
                        </p>
                        <p className="hover_Leauge">
                        <Link to={`/benefits`}>
                          <p className="px-3">Benefits</p>
                        </Link>
                        </p>
                    </div>
                  </div>
                </div> */}

                <NavLink
                  className=" h-full flex items-center relative"
                  to="/aboutus"
                >
                  About Us
                </NavLink>

                <div className=" h-full flex items-center relative">
                  <div className="dropdown h-full">
                    <button
                      className={`h-full dropbtn select focus:outline-0 w-full rounded-md focus:outline-offset-0 select-bordered ${
                        activecity ? "active" : ""
                      }`}
                    >
                      <div className="flex flex-wrap relative">
                        <p className=" flex items-center">
                          Cities
                          <svg
                            className="ml-2"
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="7"
                            viewBox="0 0 12 7"
                            fill="none"
                          >
                            <path
                              d="M5.9998 6.9748C5.86647 6.9748 5.73714 6.9498 5.6118 6.8998C5.48647 6.8498 5.38247 6.78314 5.2998 6.69981L0.699804 2.0998C0.516471 1.91647 0.424805 1.68314 0.424805 1.39981C0.424805 1.11647 0.516471 0.883139 0.699804 0.699805C0.883138 0.516472 1.11647 0.424805 1.3998 0.424805C1.68314 0.424805 1.91647 0.516472 2.0998 0.699805L5.9998 4.5998L9.8998 0.699805C10.0831 0.516472 10.3165 0.424805 10.5998 0.424805C10.8831 0.424805 11.1165 0.516472 11.2998 0.699805C11.4831 0.883139 11.5748 1.11647 11.5748 1.39981C11.5748 1.68314 11.4831 1.91647 11.2998 2.0998L6.6998 6.69981C6.5998 6.79981 6.49147 6.8708 6.3748 6.9128C6.25814 6.9548 6.13314 6.97547 5.9998 6.9748Z"
                              fill="white"
                            />
                          </svg>
                        </p>
                      </div>
                    </button>

                    <div className="dropdown-content rounded-lg bg-[#f1f1f1] overflow-hidden">
                      {upcommingcitydrop.map((data, index) => (
                        <p key={index} className="hover_Leauge">
                          <Link
                            to={`/upcomingleague/${data?._id}/${data?.city_name}`}
                          >
                            <span
                              className=" px-3 flex"
                              value={data?._id}
                              key={index}
                            >
                              {data?.city_name}
                            </span>
                          </Link>
                        </p>
                      ))}
                    </div>
                  </div>
                </div>

                <NavLink
                  className=" h-full flex items-center relative"
                  to="/gettheapp"
                >
                  Get The APP
                </NavLink>
                <NavLink
                  className=" h-full flex items-center relative"
                  to="/contactus"
                >
                  Contact Us
                </NavLink>
              </>
            )}
          </div>
        </div>
        <div className="lg:block hidden">
          {!authapproved ? (
            <button
              onClick={() => {
                setloginNoEmail(false);
                navigate("/login");
              }}
              className="bg-green text-[#000] md:text-base text-sm xl:px-[40px] md:px-[20px] py-[6px] rounded"
            >
              Sign In
            </button>
          ) : (
            <div className=" flex items-center">
              {/* <button
            // onClick={() => {
            //   setloginNoEmail(false);
            //   navigate("/login");
            // }}
              onClick={() => {
              navigate(`/myaccount/${user?._id}`);
            }} 
            
            className="bg-[#fff] text-[#000] md:text-base text-sm xl:px-[40px] md:px-[20px] py-[6px] rounded"
          >
            Hey {user?.firstName}!
          </button> */}

              {/* <div 
            onClick={() => {
              navigate(`/myaccount/${user._id}`);
            }} 
            className=" cursor-pointer rounded-full w-[36px] aspect-[1] border border-[#fff] overflow-hidden ml-3">
            <img src={userimage}/>
          </div> */}

              <div className=" h-full flex items-center relative">
                <div className="popup relative flex items-center ">
                  <button
                    type="button"
                    className="dropbtn selectw-full rounded-md focus:outline-offset-0 focus:outline-0 block"
                    onClick={() => {
                      setIsOpen(true);
                      setIsbell(true)
                    }}
                  >
                    <div className="relative">
                      <div
                        onClick={() => {
                          getNotification();
                     
                        }}
                        className=" flex items-center cursor-pointer  w-[36px] aspect-[1]   overflow-hidden ml-3"
                      >
                       <SlBell className=" relative text-green text-2xl"   />
                        {notificationcount !== 0 && (
                          <p className="absolute text-center  bg-green border w-5 rounded-[50%] top-[-4px] right-[2px] text-[#282B2D]  text-[11px]">
                            {notificationcount > 9 ? "9+" : notificationcount}
                          </p>
                        )}
                      </div>
                    </div>
                  </button>
                  {isOpen && (
                    <div ref={popupRef} className=" popup-content rounded-lg right-0 overflow-hidden min-w-[450px]">
                      <div
                        id="style-1"
                        className="pt-2 px-1 rounded-xl overflow-auto font-poppins max-h-[350px] bg-[#fff] relative"
                      >
                        <div className="float-right absolute right-[10px] top-[0px] ">
                          <button
                            type="button"
                            onClick={() => setIsOpen(false)}
                            className=" text-[#000] text-2xl font-extralight "
                          >
                            <FaTimes />
                          </button>
                        </div>
                        <Notification isOpen={isOpen} setIsOpen={setIsOpen} />
                      </div>
                    </div>
                  )}
                </div>
                <div className=" flex items-center cursor-pointer w-[40px] aspect-[1] overflow-hidden">
                  <button
                    className="relative"
                    onClick={() => {
                      // setCount("0");
                      getcurrentID(undefined);
                      navigate(`/message`);
                    }}
                  >
                    <BsChatSquareText className="text-[#fff] text-2xl" />
                    {count == 0 ? (
                      ""
                    ) : (
                      <p className="absolute text-center bg-[green] bg-opacity-80 border w-5 rounded-[50%] bottom-[-4px] right-[-8px] text-[#fff]  text-[11px]">
                      {count > 9 ? "9+" : count}
                      </p>
                    )}
                  </button>
                </div>
              </div>

              <div className=" h-full flex items-center relative">
                <div className="dropdown flex items-center">
                  <button className="dropbtn select focus:outline-0 w-full rounded-md focus:outline-offset-0 select-bordered block">
                    <div className="relative">
                      <div
                        // onClick={() => {
                        //   navigate(`/myaccount/${user._id}`);
                        // }}
                        className=" cursor-pointer rounded-full w-[36px] aspect-[1] border border-[#fff] overflow-hidden "
                      >
                        <img
                          alt="user"
                          crossOrigin="anonymous"
                          src={BASE_URL + `/image/${user?.image}`}
                        />
                      </div>
                    </div>
                  </button>

                  <div className="dropdown-content rounded-lg right-0 overflow-hidden">
                    <div className="pb-5"></div>
                    <div className="bg-[#f1f1f1]">
                      <p className="hover_Leauge">
                        <Link to={`/myaccount/${user?._id}`}>
                          <span className="px-3">Profile</span>
                        </Link>
                      </p>

                      <p className="hover_Leauge">
                        <Link to="/matchpage">
                          <span className="px-3">Calendar</span>
                        </Link>
                      </p>

                      <p className="hover_Leauge">
                        <Link
                          onClick={() => {
                            RedirectChanged("/playersearch", false);
                            ListTrue(true);
                            reset();
                          }}
                          to="/playersearch"
                        >
                          <span className="px-3">Player Search</span>
                        </Link>
                      </p>
                      {/* <p className="hover_Leauge py-[5px]">
                   
                        <button onClick={()=>{setdeletepopup(true)}}>
                          <span className="px-3">Delete Account</span>
                          </button>
                   
                      </p> */}

                      {/* <p className="hover_Leauge">
                        <Link to="/transaction">
                          <span className="px-3">My Transaction</span>
                        </Link>
                      </p> */}

                      <p
                        onClick={() => {
                          logout();
                          gettablehide(false);
                          clearsearechlist();
                          getfirstname("");
                          getlastname("");
                          getcityid("");
                          grpPlayer("");
                          GetgrpName("");
                          setloginNoEmail(false);
                          setupcomingleauge(false);
                          getleaugesfalse(false);
                          changeStep("1");
                          allClear();
                          dropMessages();
                          setDefaultMessageSent(false);
                          setmessgae_initate(false);
                        }}
                        className="hover_Leauge px-3 cursor-pointer py-[5px]"
                      >
                        Sign Out
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className=" ml-3 cursor-pointer " onClick={() => setIsOpen(true)}><SlBell className=" rounded-full text-green text-2xl"/></div> */}
            </div>
          )}
        </div>
      </div>
      {/* <Popup
        open={isOpen}
        closeOnDocumentClick
        onClose={() =>{setIsOpen(false)}}
        contentStyle={{ width: '35%' }}
        className="rounded-xl"
      > */}

      {/* </Popup> */}

      {/* <Widget 
      handleNewUserMessage={handleNewUserMessage}
       title={SenderName}
       subtitle=""
      /> */}
      {authapproved ? (
        <ChatWindow
          title={grpPlayerName}
          Name={grpName}
          id={grp_Id}
          defaultMessageSent={defaultMessageSent}
          handleNewUserMessage={handleNewUserMessage}
          opponent={opponent}
          getpurpose={getpurpose}
        />
      ) : null}

      {menuOpen && (
        <div
          className={`top-[73px] space-y-8 px-7 pt-3 pb-3 menu text-[white] w-full ${
            menuOpen ? "open" : ""
          }`}
        >
          {authapproved ? (
            <>
              <div className="flex items-center">
                <button className="dropbtn w-auto select focus:outline-0 rounded-md focus:outline-offset-0 select-bordered block mr-3">
                  <div className="relative">
                    <div
                      // onClick={() => {
                      //   navigate(`/myaccount/${user._id}`);
                      // }}
                      className="cursor-pointer rounded-full w-[36px] aspect-[1] border border-[#fff] overflow-hidden "
                    >
                      <img
                        alt="user"
                        crossOrigin="anonymous"
                        src={BASE_URL + `/image/${user?.image}`}
                      />
                    </div>
                  </div>
                </button>
                <NavLink
                  onClick={toggleMenu}
                  className="flex items-center relative"
                  to={`/myaccount/${user?._id}`}
                >
                  Profile
                </NavLink>
              </div>
              <NavLink
                onClick={toggleMenu}
                className="flex items-center relative"
                to="/matchpage"
              >
                Calendar
              </NavLink>
              <NavLink
                onClick={() => {
                  RedirectChanged("/playersearch", false);
                  ListTrue(true);
                  reset();
                  toggleMenu();
                }}
                className="flex items-center relative"
                to="/playersearch"
              >
                Player Search
              </NavLink>
              <NavLink
                to="/"
                onClick={toggleMenu}
                className={({ isActive }) =>
                  active
                    ? "flex items-center relative active"
                    : "flex items-center relative"
                }
              >
                My Leagues
              </NavLink>
              <NavLink
                onClick={toggleMenu}
                className="flex items-center relative"
                to="/league"
              >
                Upcoming Leagues
              </NavLink>
            </>
          ) : (
            <>
              <NavLink
                onClick={toggleMenu}
                className="flex items-center relative"
                to="/aboutus"
              >
                About Us
              </NavLink>
              <div className="flex items-center relative">
                <div className="dropdown h-full">
                  <button
                    className={`h-full dropbtn select focus:outline-0 w-full rounded-md focus:outline-offset-0 select-bordered ${
                      activecity ? "active" : ""
                    }`}
                  >
                    <div className="flex flex-wrap relative">
                      <p className=" flex items-center">
                        Cities
                        <svg
                          className="ml-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="7"
                          viewBox="0 0 12 7"
                          fill="none"
                        >
                          <path
                            d="M5.9998 6.9748C5.86647 6.9748 5.73714 6.9498 5.6118 6.8998C5.48647 6.8498 5.38247 6.78314 5.2998 6.69981L0.699804 2.0998C0.516471 1.91647 0.424805 1.68314 0.424805 1.39981C0.424805 1.11647 0.516471 0.883139 0.699804 0.699805C0.883138 0.516472 1.11647 0.424805 1.3998 0.424805C1.68314 0.424805 1.91647 0.516472 2.0998 0.699805L5.9998 4.5998L9.8998 0.699805C10.0831 0.516472 10.3165 0.424805 10.5998 0.424805C10.8831 0.424805 11.1165 0.516472 11.2998 0.699805C11.4831 0.883139 11.5748 1.11647 11.5748 1.39981C11.5748 1.68314 11.4831 1.91647 11.2998 2.0998L6.6998 6.69981C6.5998 6.79981 6.49147 6.8708 6.3748 6.9128C6.25814 6.9548 6.13314 6.97547 5.9998 6.9748Z"
                            fill="white"
                          />
                        </svg>
                      </p>
                    </div>
                  </button>

                  <div className="">
                    {upcommingcitydrop.map((data, index) => (
                      <p key={index} className="hover_Leauge mt-2">
                        <Link
                          onClick={toggleMenu}
                          className=""
                          to={`/upcomingleague/${data?._id}/${data?.city_name}`}
                        >
                          <span className=" px-3 flex" value={data?._id} key={index}>
                            {data?.city_name}
                          </span>
                        </Link>
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              <NavLink
                onClick={toggleMenu}
                className="flex items-center relative"
                to="/contactus"
              >
                Contact Us
              </NavLink>
            </>
          )}
          <NavLink
            onClick={toggleMenu}
            className="flex items-center relative"
            to="/gettheapp"
          >
            Get The APP
          </NavLink>
          
          {authapproved && (
            <> 
            {/* <p
              onClick={()=>{setdeletepopup(true)}}
              className="hover_Leauge cursor-pointer py-[5px]"
            >
              Delete Account
            </p> */}

            <p
              onClick={() => {
                toggleMenu();
                logout();
                gettablehide(false);
                clearsearechlist();
                getfirstname("");
                getlastname("");
                getcityid("");
                grpPlayer("");
                GetgrpName("");
                setloginNoEmail(false);
                setupcomingleauge(false);
                getleaugesfalse(false);
                changeStep("1");
                allClear();
                dropMessages();
                setDefaultMessageSent(false);
                setmessgae_initate(false);

              }}
              className="hover_Leauge cursor-pointer py-[5px]"
            >
              Sign Out
            </p>
            </>
          )}
        </div>
      )}
      {/* popup of delete account */}

        {/* <Popup
                  open={deletepopup}
                  closeOnDocumentClick={false}
                  className=""
                  contentStyle={{
                   width: isMobileView ? "100%" : "50%",
                    background: "none",
                    border: "none",
                  }}
                  onClose={() => {
                    setdeletepopup(false);
                  }}
                >
                  <div className=" border-2 border-[white] px-1 w-3/4 m-auto bg-[#31343B] move overflow-auto max-h-[600px] rounded  relative">
                    <div className="float-right   ">
                      <button
                        className=" text-[#ffffff] text-2xl absolute font-poppins  md:leading-[2] top-[0px] right-[10px]"
                        // type="button"
                        onClick={() => {
                          // setopensubmit(false);
                          // setnewpopup(true)
                          setdeletepopup(false);
                          // getbuttondisable(false);
                        }}
                      >
                        X
                      </button>
                    </div>
                    <p className=" text-center text-[#fff] font-poppins  text-3xl md:text-2xl mt-16 my-2">
                    Are you sure?
                       <br />
                     </p>
                    <p className=" text-center text-[#fff] text-lg ">
                    Do you really want to delete your account with FLT?  
                      <br />
                    Please note this action cannot be reverted back.
                    </p>
                    <div className="flex justify-center text-lg  my-10 items-center">
                      <button
                        onClick={() => {
                          setdeletepopup(false);
                          // getbuttondisable(false);
                        }}
                        className="bg-[red] px-3 mx-5 rounded-md"
                      >
                        No
                      </button>

                      {!deleteload ? (
                        <button
                          // type="submit"
                          className="bg-green px-3 mx-5 rounded-md"
                          onClick={() => {
                            deleteUser();
                            gettablehide(false);
                            clearsearechlist();
                            getfirstname("");
                            getlastname("");
                            getcityid("");
                            grpPlayer("");
                            GetgrpName("");
                            setloginNoEmail(false);
                            setupcomingleauge(false);
                            getleaugesfalse(false);
                            changeStep("1");
                            allClear();
                            dropMessages();
                            setDefaultMessageSent(false);
                          }}
                        >
                          Yes
                        </button>
                      ) : (
                        <button
                          // type="submit"
                          disabled
                          className="flex items-center bg-green px-3 mx-5 rounded-md"
                          // onClick={() => {
                          //   PostMatchscore();
                          // }}
                        >
                          Yes..
                          <svg
                            className="animate-spin mr-1 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </Popup> */}
    </header>
   
  );
});

export default Header;
