import React, { Component } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

// const mapStyles = {
//   width: "50%",
//   height: "100%",
// };

export class MapContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showingInfoWindow: false,
      activeMarker: null,
      selectedPlace: null,
      markerAddress: "",
    };
  }

  componentDidMount() {
    this.geocodeLatLng();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.mylat !== this.props.mylat ||
      prevProps.mylng !== this.props.mylng
    ) {
      this.geocodeLatLng();
    }
  }

  geocodeLatLng() {
    const { google } = this.props;
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat: this.props.mylat, lng: this.props.mylng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        const address = results[0].formatted_address;
        this.setState({ markerAddress: address });
      } else {
        console.log("Geocode was not successful for the following reason: " + status);
      }
    });
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onClose = () => {
    this.setState({
      showingInfoWindow: false,
      activeMarker: null,
    });
  };

  // handleGoToGoogleMaps = () => {
  //   const { mylat, mylng } = this.props;
  //   const url = `https://www.google.com/maps/search/?api=1&query=${mylat},${mylng}`;

  //   // Open a new tab/window to the Google Maps URL
  //   window.open(url, "_blank");
  // };

  handleGoToGoogleMaps = () => {
    const { markerAddress } = this.state;
    const url = `https://www.google.com/maps/search/?api=1&query=${markerAddress}`;
  
    // Open a new tab/window to the Google Maps URL
    window.open(url, "_blank");
  };

  render() {
    const { mylat, mylng, style } = this.props;
    const { markerAddress, showingInfoWindow, activeMarker, selectedPlace } = this.state;

    return (
      <Map
        google={this.props.google}
        zoom={14}
        style={style}
        initialCenter={{
          lat: mylat,
          lng: mylng,
        }}
        center={{
          lat: mylat,
          lng: mylng,
        }}
      >
        <Marker
          position={{ lat: mylat, lng: mylng }}
          label={{
            text: markerAddress,
            className: "marker-label",
          }}
          //  onClick={this.handleGoToGoogleMaps}
        />
         {/* <button onClick={this.handleGoToGoogleMaps}>Go to Google Maps</button> */}
        <InfoWindow
          marker={activeMarker}
          visible={showingInfoWindow}
          onClose={this.onClose}
          onClick={this.handleGoToGoogleMaps}
        >
          <div>
            <h4>{selectedPlace && selectedPlace.name}</h4>
          </div>
        </InfoWindow>

        <button className="mt-2 go-to-google-maps-button font-medium" onClick={this.handleGoToGoogleMaps}>
        Get Directions &rarr;
        </button>
      </Map>
    );
  }
}

export default GoogleApiWrapper((props) => ({
  apiKey: props?.Mapkey,  
}))(MapContainer);