/* eslint-disable jsx-a11y/alt-text */
/* global google */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { useAuthStore } from '../../Auth/store/Auth';
import { RegisterStore, useRegisterStore, RegisterStoreContext } from "../store/RegisterStore";
import Select from 'react-select';
import mapblur from "../../assets/image/mapblur.webp";
import MapContainer from "../MapContainer/MapContainer";
// import { useLeagueStore } from "../../Leaguespage/store/Leaguestore";
// import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

// const Facility = () => {
//     return(
//       <RegisterStoreContext.Provider value={new RegisterStore({
//       isloading: false,
//       loadfacility: true,
//       })}>
//         <Screen/>
//       </RegisterStoreContext.Provider>
//     )
//   }


const Facility = observer(() => {
  const { user } = useAuthStore();

  const [userhomecourt, setUserhomecourt] = useState("")
  // const {} = useRegisterStore()
  const { leaguetype, leaguesiddata, onlyhomecourt, homecourtid, leaguedata, playerid, getHomecourtid, getCaption, changeStep, player, getPlayer, getLeaguesid, getFirstname, fName, lName, getPlayerdetails, contactUs, getName, getEmail, getDescription, getLastname, playerfname, playerlname, homecourtdrop, getState, getlocation, showtable, lat, lng, nolist, allClear, getShowmap, showmap, onSubmitsingle, gethomecourtdropdown, setpopisOpen, getSetting, keysss } = useRegisterStore();

 
  // const { } = useLeagueStore();
  const [ishome, setIsHome] = useState(null);
  const [SelectCheck, SetSelectCheck] = useState(null);


  useEffect(() => {
    gethomecourtdropdown(user?.city?._id,user?.homeCourt?.latitude,user?.homeCourt?.longitude)
    getSetting();
    getState();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setIsHome(user?.homeCourt?.isActive && user?.homeCourt?.isVerified  === true ? user?.homeCourt : "")
    // console.log("===>>>>>",ishome)
    // setUserhomecourt
  }, [])
  //  console.log("==>",lat)
  const {
    setValue,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const mapStyles = {
    width: "100%",
    height: "100%",
  };
  const handleClickScroll = () => {
    const element = document.getElementById('partner');
    if (element) {
      element.scrollIntoView({ top: 0, behavior: 'smooth' });
    }
  };

  const handleClickScrollpartner = () => {
    const element = document.getElementById('partnertable');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // const selectOnlyThis = (e) => {
  //   console.log(e.target?.id)
  //   const myCheckbox = document.getElementsByName("myCheckbox");
  //   myCheckbox.forEach((m)=> {
  //     console.log('>>>>>>>>>>Tttttt',e.target.id, m.id);
  //     if(e.target.id === m.id){
  //       e.checked = true;
  //     }else{
  //       m.checked = false;
  //     }
  //   })
  //   // id.checked = true;
  // }

  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');

  //  const placeholder = ishome===null ? "type your homecourt" : "Select Your Home Court"

  // const clearhome = () => {
  //   if(ishome != ""){
  //     setIsHome("")
  //   }
  //   else{
  //     setIsHome("")
  //   }
  //   // return
  // }

   useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click target is not within the react-select component
      if (!event.target.closest(".reactselect ") && !event.target.closest(".css-1nmdiq5-menu") && !event.target.closest(".continue")  ) {
        if( ishome == null){
          setIsHome(user?.homeCourt?.isActive && user?.homeCourt?.isVerified  === true ? user?.homeCourt : "")
          // getHomecourtid(user?.homeCourt?._id);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });


  return (
    <div className="w-full 2xl:px-28 lg:px-20 md:px-10 px-4 relative z-1 py-3">
      <div className="flex flex-wrap justify-center">
        <div className="2xl:w-[80%] lg:w-[80%] md:w-[90%] w-full 2xl:max-w-[90%]">
          {leaguetype === "singles" ?
            <div className=" bg-[#6C6C6C] border border-[#fff] bg-opacity-40 rounded-lg lg:px-10 md:px-5 px-4 md:pt-5 py-3">
              <form onSubmit={(e) => { e.preventDefault(); }}>
                <div className=" text-center">
                  <p className=" text-[#fff] font-medium  md:text-2xl text-xl   relative">
                    Choose Your Facility
                  </p>
                </div>

                <div className="grid gap-8 relative md:grid-cols-2 md:grid-rows-1 grid-rows-2 my-10">
                  {/* <map></map> */}
                  {lat == null || lat == "" ?
              
                      <div className="relative">
                        <img alt="" className="w-[400px] h-[200px]" src={mapblur} />
                        <p className="text-[#000] flex justify-center items-center absolute top-[50%] left-[25%] text-xl">Map Not Available</p>
                      </div>
        
                    :
                    keysss !== "" ? 
                    <div className="relative">
                    <MapContainer
                      mylat={parseFloat(lat)}
                      mylng={parseFloat(lng)}
                      style={mapStyles}
                      Mapkey={keysss}
                    />
                    </div> : 
                    <div className="relative">
                      <img alt="" className="w-[400px] h-[200px]" src={mapblur} />
                    </div>
                  }


                  <div className="">
                  <div className="reactselect">
                    <Select
                      required
                      id="location"
                      options={homecourtdrop}
                      placeholder="Type Your Home Court Name"
                      // onClick={() =>{ changeStep("1"); allClear()}}
                      // onClick={(e)=>{setIsHome("");getHomecourtid(""); getlocation("") }}
                      onChange={(e) => { getlocation(e); getHomecourtid(e?._id); setIsHome(e); SetSelectCheck(e) }}
                      getOptionLabel={(homecourtdrop) =>   homecourtdrop?.displayName   + " - " + homecourtdrop?.zipcode}
                      getOptionValue={(homecourtdrop) => homecourtdrop?._id}
                      // value={user?.homeCourt?._id}
                      // defaultInputValue={user?.homeCourt?.name}
                      // defaultValue={ishome}
                      // autoFocus={false}
                      // defaultValue={user.homeCourt.isVerified===true? user?.homeCourt: null}
                      value={ishome}
                      // defaultInputValue={ishome?.name}
                      isSearchable={true}
                      // isClearable
                      isMulti={false}
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg focus:outline-none"
                      // menuIsOpen={}
                      onMenuOpen={() => {
                        setIsHome(null)
                      }}
                    />
                    </div>




                    <div className=" flex space-x-4 text-center my-10  relative">
                      <button
                        className=" font-medium lg:text-[20px] text-lg text-[#000] bg-[#FFFFFF] px-11 py-2 rounded-lg mx-auto"
                        type="button"
                        onClick={() => { changeStep("1"); allClear() }}
                      >
                        Cancel
                      </button>
                      <button

                        onClick={() => {
                          if (ishome == "" || ishome == null ) {
                            toast.error('Please Select HomeCourt.');
                          } else {
                            onlyhomecourt(user.homeCourt );
                            getCaption(user._id)
                            changeStep("4");
                          }


                          //                     if (lat === null) {
                          //                       toast.error('Please Select HomeCourt.');
                          // } else {
                          //   changeStep("4");
                          // }
                        }}
                        className=" font-medium lg:text-[20px] text-lg text-[#000] bg-[#39FD13] px-11 py-2 rounded-lg mx-auto continue"
                        type="submit"
                        disabled={homecourtid == ""}

                      >
                        Continue
                      </button>
                    </div>
                    <div className="flex justify-center relative ">
                      <p className="text-[#fff]">
                        Need help ?
                        <span className="text-[#39FD13] cursor-pointer ml-2"
                          onClick={() => { setpopisOpen(true) }}
                        > Contact us</span>
                      </p>
                    </div>
                  </div>

                  {/* <div className="md:col-span-2"> */}

                  {/* </div> */}
                </div>
              </form>
            </div>
            :
            <div className=" bg-[#6C6C6C] border border-[#fff] bg-opacity-40 rounded-lg lg:px-10 md:px-5 px-4 md:pt-5 py-3">
              {/* <form
              autoComplete="new-password"
              onSubmit={(e) => {
                getPlayer();
                handleClickScrollpartner();
                e.preventDefault();
              }}
            >
              <div className=" text-center">
                <p className=" text-[#fff] font-medium md:text-3xl text-2xl  relative">
                 Choose Your Partner
                </p>
              </div>
              <div className="grid gap-8 md:grid-cols-2 mt-20">
                <div className="col-span-1">
                  <input
                    value={fName}
                    name="firstName"
                    onChange={(e) => getFirstname(e.target.value)}
                    autoComplete="FirstName"
                    id="firstName"
                    // required
                    className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    placeholder="First Name"
                    type="text"
                  />
                </div>
                <div>
                  <input
                    value={lName}
                    name="lastName"
                    onChange={(e) => getLastname(e.target.value)}
                    autoComplete="LastName"
                    id="lastName"
                    className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg px-6 py-3 focus:outline-none"
                    placeholder="Last Name"
                    type="text"
                  />
                </div>
                <div className="md:col-span-2 ">
                  <div className="text-center space-x-4 my-10">
                  <button
                    className=" font-medium lg:text-[20px] text-lg text-[#000] bg-[#fff] px-11 py-2 rounded-lg mx-auto"
                    type="button"
                    onClick={() =>{ changeStep("1"); allClear()}}
                  >
                    Cancel
                  </button>
                  {fName == "" && lName == "" ? 
                  <button
                  // onClick={()=> }
                  className=" font-medium opacity-80 lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto"
                  type="button"
                  disabled
                >
                  Search
                </button>
                 : 
                 <button
                    className=" font-medium lg:text-[20px] text-lg text-[#000] bg-green px-11 py-2 rounded-lg mx-auto"
                    type="submit">
                    Search
                  </button>}
                    
                  </div>
                </div>
              </div>
              <div id="partnertable">
              {showtable===true ?
            <div id="style-1" className=" bg-[#6C6C6C] border-2 border-[#fff] bg-opacity-40 rounded-lg my-10 max-h-[400px] overflow-auto">  
              <div id="partner">
              <table className="w-full text-[#fff]" id="partnertable">
              <thead >
                <tr className="bg-[#000]">
                  <th>Select</th>
                   <th className="py-5">S. No.</th> 
                  <th className="py-5">Player</th>
                  <th>Home Court</th>
                  <th>Rating</th>
                   <th>Ranking</th> 
                </tr>
              </thead>
              <tbody className="text-center" >
              {player.map((data,index)=>(
              <tr key={index}>
                <label className="w-full contents cursor-pointer">
                    <td> 
                      <input 
                      className=" md:h-[20px] md:w-[20px]"
                      id={index}
                      type="radio" 
                      name="myCheckbox" 
                      value={index}
                      onClick={(e)=>{getShowmap(true);getPlayerdetails(data);handleClickScroll();getFirstname("");getLastname("")}}
                      /> 
                    </td>
                     <td>{index + 1}</td> 
                    <td  className="py-5">{data?.firstName+" "+data?.lastName}</td>
                    <td>{data?.homeCourt?.name}</td>
                    <td>{data?.level?.name.toFixed(1)}</td>
                    <td>{data?.ranking}</td> 
                </label>
              </tr>
      
              ))}
              {nolist && (
                    <tr>
                      <td colSpan={6} className="py-20 ">
                        
                        <p className=" text-center font-semibold text-xl">No Player Found</p>
                      </td>
                    </tr>
                  )}
            
              </tbody>

              </table>
              </div>
            </div>
            :
            null
          }
              </div>
    
              
            </form> */}
              {/* {showmap ? */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }} >
                {/* <div className="my-10">
              <div className=" text-center my-5">
                <p className=" text-[#fff] font-medium md:text-3xl text-2xl  relative">
                Choose Your Captain
                </p>
              </div>
              <div className="flex justify-around rounded-lg py-5 ">
            
              <label className="flex cursor-pointer items-center">
                <input 
                className=" md:h-[20px] md:w-[20px]"
                type="radio" 
                name="captain" 
                defaultChecked={true} 
                value={user._id} 
                onClick={(e)=> {getCaption(e.target.value)}}/>
                <p className="text-[#fff] ml-2 text-lg capitalize">{user.firstName+" "+user.lastName}</p>
              </label>

              <label className="flex cursor-pointer items-center">
                <input 
                className=" md:h-[20px] md:w-[20px]"
                type="radio" 
                name="captain"
                value={playerid}  
                onClick={(e)=> {getCaption(e.target.value)}}/>
                <p className="text-[#fff] ml-2 text-lg capitalize">{playerfname + " "+ playerlname} </p>
              </label>
              </div>
              </div> */}

                <div className=" text-center">
                  <p className=" text-[#fff] font-medium  md:text-2xl text-xl relative">
                    Choose Your Facility
                  </p>
                </div>



                <div className="grid gap-8 relative md:grid-cols-2 md:grid-rows-1 grid-rows-2 my-10">
                  {/* <map></map> */}
                  {lat == null || lat == "" ?
                 
                      <div className="relative">
                        <img alt="" className="w-[400px] h-[200px]" src={mapblur} />
                        <p className="text-[#000] flex justify-center items-center absolute top-[50%] left-[25%] text-xl">Map Not Available</p>
                      </div>
                  
                    :
                    keysss !== "" ? 
                    <div className=" relative">
                    <MapContainer
                      mylat={parseFloat(lat)}
                      mylng={parseFloat(lng)}
                      style={mapStyles}
                      Mapkey={keysss}
                    />
                    </div> : null}



                  <div className="">
                  <div className="reactselect">
                    <Select
                      // autoFocus
                      isRequired={true}
                      id="location"
                      options={homecourtdrop}
                      placeholder="Type Your Home Court Name"
                      // onClick={() =>{ changeStep("1"); allClear()}}
                      onChange={(e) => { getlocation(e); getHomecourtid(e?._id); setIsHome(e); SetSelectCheck(e); }}
                      getOptionLabel={(homecourtdrop) =>   homecourtdrop.displayName   + " - " + homecourtdrop.zipcode}
                      getOptionValue={(homecourtdrop) => homecourtdrop?._id}
                      // value={user?.homeCourt?._id}
                      // defaultInputValue={user?.homeCourt?.name}
                      value={ishome}
                      isSearchable={true}
                      isMulti={false}
                      className=" bg-[#f1f1f1] text-[#837575] w-full border border-[#7E7777] rounded-lg focus:outline-none"
                      onMenuOpen={() => {
                        setIsHome(null)
                      }}
                    />
                    </div>




                    <div className=" flex space-x-4 justify-center text-center my-10 relative">
                      <button
                        className=" font-medium lg:text-[20px] text-lg text-[#000] bg-[#FFFFFF] px-11 py-2 rounded-lg "
                        type="button"
                        onClick={() => { changeStep("2"); }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          if (ishome == "" || ishome == null) {
                            toast.error('Please Select HomeCourt.');
                          }
                          else {
                            changeStep("4");
                          }


                        }}
                        className=" font-medium lg:text-[20px] text-lg text-[#000] bg-[#39FD13] px-11 py-2 rounded-lg  continue"
                        type="submit"
                      >
                        Continue
                      </button>
                    </div>
                    <div className="flex justify-center relative ">
                      <p className="text-[#fff]">
                        Need help ?
                        <span className="text-[#39FD13] cursor-pointer ml-2"
                          onClick={() => { setpopisOpen(true) }}
                        >
                          Contact us
                        </span>
                      </p>
                    </div>
                  </div>

                  {/* <div className="md:col-span-2"> */}

                  {/* </div> */}
                </div>
              </form>
              {/* :
          <></>
             } */}

            </div>
          }

        </div>
      </div>
    </div>
  );
});
export default Facility;

